import { query_stop_reason_list2 } from '@/api/dispatch-model';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
export default () => {
  const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const search_config: any = {
    model: {
      org_id: org?.id,
      is_active: true,
      pageSize: 2000,
    },
  };

  return {
    getData: query_stop_reason_list2,
    searchConfig: search_config,
  };
};
