export const defaultConfig = {
  input: {
    type: 'input',
    name: 'input',
    label: '文本框',
    disabled: false,
    allowClear: true,
    inputType: 'text',
    maxlength: 100,
  },
  textarea: {
    type: 'textarea',
    name: 'textarea',
    label: '文本域',
    allowClear: false,
    disabled: false,
    maxlength: 1000,
  },
  number: {
    type: 'number',
    name: 'number',
    label: '数字进步器',
    disabled: false,
  },
  select: {
    type: 'select',
    name: 'select',
    label: '下拉选',
    disabled: false,
    allowClear: true,
    datasourceType: 'local',
    datasource: 'select',
    mode: 'default',
    maxTagCount: 1,
    valueKey: 'id',
    labelKey: 'name',
  },
  treeselect: {
    type: 'treeselect',
    name: 'treeselect',
    label: '下拉树',
    disabled: false,
    allowClear: true,
    datasourceType: 'local',
    datasource: '',
    mode: 'multiple',
    valueKey: 'id',
    labelKey: 'name',
  },
  cascader: {
    type: 'cascader',
    name: 'cascader',
    label: '级联选择器',
    disabled: false,
    allowClear: true,
    datasourceType: 'local',
    datasource: '',
    valueKey: 'id',
    labelKey: 'name',
  },
  timepicker: {
    type: 'timepicker',
    name: 'timepicker',
    label: '时间选择器',
    disabled: false,
    allowClear: true,
    format: '',
    valueFormat: 'HH:mm:ss',
  },
  datepicker: {
    type: 'datepicker',
    name: 'datepicker',
    label: '日期选择器',
    disabled: false,
    allowClear: true,
    mode: 'time',
    showTime: true,
    valueFormat: 'YYYY-MM-DD HH:mm:ss',
  },
  rangepicker: {
    type: 'rangepicker',
    name: 'rangepicker',
    label: '时间范围选择器',
    disabled: false,
    allowClear: true,
    showTime: true,
    valueFormat: 'YYYY-MM-DD HH:mm:ss',
  },
  checkbox: {
    type: 'checkbox',
    name: 'checkbox',
    label: '多选框',
    disabled: false,
    allowClear: true,
    datasourceType: 'local',
    datasource: '',
    valueKey: 'id',
    labelKey: 'name',
  },
  radio: {
    type: 'radio',
    name: 'radio',
    label: '单选框',
    disabled: false,
    allowClear: true,
    datasourceType: 'local',
    datasource: '',
    valueKey: 'id',
    labelKey: 'name',
  },
  divider: {
    type: 'divider',
    name: 'divider',
    label: '分割线',
    orientation: 'left',
  },
};

export const defaultRules = {
  input: [
    {
      required: false,
      message: '请填写必填项',
      type: 'string',
    },
  ],
  textarea: [
    {
      required: false,
      message: '请填写必填项',
      type: 'string',
    },
  ],
  number: [
    {
      required: false,
      message: '请填写必填项',
      type: 'number',
    },
  ],
  select: [
    {
      required: false,
      message: '请选择必填项',
      type: 'array',
    },
  ],
  treeselect: [
    {
      required: false,
      message: '请选择必填项',
      type: 'array',
    },
  ],
  cascader: [
    {
      required: false,
      message: '请选择必填项',
      type: 'array',
    },
  ],
  datepicker: [
    {
      required: false,
      message: '请选择必填项',
      type: 'string',
    },
  ],
  rangepicker: [
    {
      required: false,
      message: '请选择必填项',
      type: 'array',
    },
  ],
  timepicker: [
    {
      required: false,
      message: '请选择必填项',
      type: 'string',
    },
  ],
  checkbox: [
    {
      required: false,
      message: '请选择必填项',
      type: 'array',
    },
  ],
  radio: [
    {
      required: false,
      message: '请选择必填项',
      type: 'string',
    },
  ],
};

export const defaultValue = {
  input: '',
  textarea: '',
  select: undefined,
  treeselect: [],
  cascader: [],
  datepicker: '',
  rangepicker: [],
  timepicker: '',
};

export const defaultPlaceholder = {
  input: '请填写',
  textarea: '请填写',
  select: '请选择',
  treeselect: '请选择',
  cascader: '请选择',
  datepicker: '请选择',
  rangepicker: '请选择',
  timepicker: '请选择',
};
