
import { Permission, Role } from '@/store/modules/user/typing';
import { isArray } from 'lodash-es';
import { computed, ref, defineComponent, PropType, VNodeChild } from 'vue';
import { useStore } from 'vuex';

export type AuthorityType = string | string[] | ((authority: Permission[]) => boolean);

export default defineComponent({
  name: 'Authority',
  props: {
    authority: {
      type: [String, Array, Function] as PropType<AuthorityType>,
      default: () => true,
    },
    noMatch: {
      type: [String, Boolean, Object] as PropType<string | boolean | VNodeChild>,
      default: () => undefined,
    },
  },
  setup(props) {
    const store = useStore();
    const role = computed<Role>(() => store.getters['user/role']);
    const auth = ref(false);
    if (role.value.permissions) {
      const permissions = role.value.permissions;
      const isArr = isArray(props.authority);

      if (typeof props.authority === 'string' || isArr) {
        auth.value = permissions.some(value => {
          return isArr
            ? (props.authority as string[]).includes(value.name)
            : props.authority === value.name;
        });
      } else if (typeof props.authority === 'function') {
        auth.value = props.authority(permissions);
      }
    }

    return {
      auth,
    };
  },
});
