
import { defineComponent, ref } from 'vue';
import { getDateTime } from '@/utils/function';
import { PictureOutlined } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  create_auto_reply_session_record_of_question_for_adviser,
  create_session_record_for_adviser,
  transfer_service_session_to_human,
} from '@/api/service-model';
import bus from '@/utils/bus';

export default defineComponent({
  name: 'Cell_talk',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    query_session_more: {
      type: Function,
      required: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const slice_num: any = ref(0);
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const gotoSession = (obj: any) => {
      // router.push({
      //   path:
      //     '/cc_adviser/kefu_question/' + obj.id + '/' +obj.name,
      // });
      create_auto_reply_session_record_of_question_for_adviser({
        org_id: current_org.id,
        session_id: props.record.session_id,
        question_id: obj.id,
      });
    };
    const Transfer_to_labor = (record: any) => {
      transfer_service_session_to_human({
        org_id: current_org.id,
        service_session_id: record.session_id,
      }).then(() => {
        bus.emit('is_auto_reply', { is_auto_reply: false });
      })
      create_session_record_for_adviser({
        org_id: current_org.id,
        content: '转人工',
        message_type: 0,
        session_id: record.session_id,
      }).then(() => {
        // message.success(t('page.kefu.Session_created_successfully'));
      })
    }
    const more_page = (record: any) => {
      if(slice_num.value >= record?.reply_content.length){
        slice_num.value = 0;
      }else{
        slice_num.value = slice_num.value + 8;
      }
    }
    return {
      t,
      slice_num,
      Transfer_to_labor,
      more_page,
      getDateTime,
      gotoSession,
    }
  },
  components: {
    PictureOutlined,
  },
});
