import { MutationTree } from 'vuex';
import { UserState } from './typing';
// import { state as initState } from './state';
import { getStoreByName, localStorage as ls, sourceStore } from '@/utils/local-storage';
import {
  STORAGE_CURRENT_ADVISER_KEY,
  STORAGE_CURRENT_COMPANY,
  STORAGE_CURRENT_JSC_KEY,
  STORAGE_CURRENT_MENU_KEY,
  STORAGE_CURRENT_ORG_KEY,
  STORAGE_CURRENT_SHOP_KEY,
  STORAGE_ORG_LIST_KEY,
  STORAGE_SESSIONID,
  STORAGE_SESSIONID2,
  STORAGE_SHOP_LIST_KEY,
  STORAGE_TOKEN_KEY,
} from '@/store/mutation-type';

export const SET_TOKEN = 'SET_TOKEN';
export const SET_SESSIONID2 = 'SET_SESSIONID2';
export const SET_SESSIONID = 'SET_SESSIONID';
export const SET_AVATAR = 'SET_AVATAR';
export const SET_ROLE = 'SET_ROLE';
export const SET_INFO = 'SET_INFO';
export const SET_USER = 'SET_USER';
export const SET_COMPANY = 'SET_COMPANY';
export const SET_NO_SIGN_CONTRACT = 'SET_NO_SIGN_CONTRACT';
export const SET_NO_READ_NUMBER = 'SET_NO_READ_NUMBER';
export const SET_SHOP_LIST = 'SET_SHOP_LIST';
export const SET_CURRENT_SHOP = 'SET_CURRENT_SHOP';
export const SET_ROUTERS = 'SET_ROUTERS';
export const RESET_CURRENT_USER = 'RESET_CURRENT_USER';
export const SET_ORGLIST = 'SET_ORGLIST';
export const SET_CURRENT_ORG = 'SET_CURRENT_ORG';
export const SET_USER_LIST = 'SET_USER_LIST';
export const SET_IS_SUPER_USER = 'SET_IS_SUPER_USER';
export const SET_CUSTOME_DATA_USER = 'SET_CUSTOME_DATA_USER';
export const UPDATE_CUSTOME_DATA_USER = 'UPDATE_CUSTOME_DATA_USER';
export const SET_MY_MENU_DATA_LIST = 'SET_MY_MENU_DATA_LIST';
export const SET_JSC_DATA_LIST = 'SET_JSC_DATA_LIST';
export const SET_JUMP_NAMPSPACE = 'SET_JUMP_NAMPSPACE';

export const mutations: MutationTree<UserState> = {
  [SET_TOKEN]: (state, token: string) => {
    state.token = token;
    sourceStore.set(STORAGE_TOKEN_KEY, token);
  },
  [SET_SESSIONID2]: (state, sessionid2: string) => {
    state.sessionid2 = sessionid2;
    sourceStore.set(STORAGE_SESSIONID2, sessionid2);
  },
  [SET_SESSIONID]: (state, sessionid: string) => {
    state.sessionid = sessionid;
    sourceStore.set(STORAGE_SESSIONID, sessionid);
  },
  [SET_ORGLIST]: (state, org_list: []) => {
    state.org_list = org_list;
    sourceStore.set(STORAGE_ORG_LIST_KEY, org_list);
  },
  [SET_CURRENT_ORG]: (state, org: any) => {
    state.current_org = org;
    const namespace = state.jump_namespce;
    if(namespace){
      const nameStore = getStoreByName(namespace);
      nameStore.set(STORAGE_CURRENT_ORG_KEY, org);
    }else{
      ls.set(STORAGE_CURRENT_ORG_KEY, org);
    }

  },
  [SET_SHOP_LIST]: (state, shop: any) => {
    state.shop_list = shop;
    ls.set(STORAGE_SHOP_LIST_KEY, shop);
  },
  [SET_CURRENT_SHOP]: (state, shop: any) => {
    state.current_shop = shop;
    ls.set(STORAGE_CURRENT_SHOP_KEY, shop);
  },
  [SET_INFO]: (state, info) => {
    if (info.role) {
      state.role = info.role;
    }
    if (info.id) {
      state.username = info.username;
      state.nickname = info.realname;
    }
    if (info.realname) {
      state.nickname = info.realname;
    }
    if (info.icon_url) {
      state.avatar = info.icon_url;
    }
    const org_list = sourceStore.get(STORAGE_ORG_LIST_KEY);
    state.org_list = org_list;
    const org = ls.get(STORAGE_CURRENT_ORG_KEY);
    state.current_org = org;

    // const shop_list = ls.get(STORAGE_SHOP_LIST_KEY);
    // state.shop_list = shop_list;
    // const shop = ls.get(STORAGE_CURRENT_SHOP_KEY);
    // state.current_shop = shop;
    const custome_data = ls.get(SET_CUSTOME_DATA_USER);
    state.custome_data_user = custome_data?.person_data;
    state.custome_data_org = custome_data?.data;
    const my_menu_data_list = ls.get(SET_MY_MENU_DATA_LIST);
    state.my_menu_data_list = my_menu_data_list;
    state.role_flag_list = info.role_flag_list;

    state.extra = { ...info };
  },
  [SET_USER]: (state, info) => {
    state.adviser = { ...info };
    ls.set(STORAGE_CURRENT_ADVISER_KEY, info);
  },
  [SET_COMPANY]: (state, obj) => {
    if (obj && obj?.name) {
      state.current_company = obj;
      ls.set(STORAGE_CURRENT_COMPANY, obj);
    } else if (ls.get(STORAGE_CURRENT_COMPANY) && ls.get(STORAGE_CURRENT_COMPANY)?.id) {
      state.current_company = ls.get(STORAGE_CURRENT_COMPANY);
    } else {
      state.current_company = { name: '全部', id: null };
      ls.set(STORAGE_CURRENT_COMPANY, { name: '全部', id: null });
    }
  },
  [SET_NO_READ_NUMBER]: (state, info) => {
    state.no_read_number = info;
  },
  [SET_NO_SIGN_CONTRACT]: (state, info) => {
    state.is_induction_process = info.sign_contract;
  },
  [SET_ROLE]: (state, role) => {
    state.role = role;
  },
  [SET_AVATAR]: (state, avatar) => {
    state.avatar = avatar;
  },
  [SET_ROUTERS]: (state, allowRoutes) => {
    state.allowRouters = allowRoutes;
  },
  [SET_USER_LIST]: (state, info) => {
    if (info.data) {
      state.user_list = info.data;
    }
  },
  [SET_IS_SUPER_USER]: (state, info) => {
    if (info.is_super_user) {
      state.is_super_user = info.is_super_user;
    }
  },
  [SET_CUSTOME_DATA_USER]: (state, info) => {
    const namespace = state.jump_namespce;
    if(namespace) {
      const nameStore = getStoreByName(namespace);
      if (info) {
        nameStore.set(SET_CUSTOME_DATA_USER, info);
      } else {
        nameStore.set(SET_CUSTOME_DATA_USER, {});
      }
    }else {
      if (info) {
        state.custome_data_user = info.person_data;
        state.custome_data_org = info.data;
        ls.set(SET_CUSTOME_DATA_USER, info);
      } else {
        state.custome_data_user = {};
        state.custome_data_org = {};
        ls.set(SET_CUSTOME_DATA_USER, {});
      }
    }
  },
  [UPDATE_CUSTOME_DATA_USER]: (state, info) => {
    if (info) {
      if(info.person_data){
        Object.assign(state.custome_data_user, info.person_data);
      }
      if(info.data){
        Object.assign(state.custome_data_org, info.data);
      }
      ls.set(SET_CUSTOME_DATA_USER, { person_data:state.custome_data_user , data: state.custome_data_org });
    }
  },
  [SET_MY_MENU_DATA_LIST]: (state, info) => {
    const namespace = state.jump_namespce;
    if(namespace) {
      const nameStore = getStoreByName(namespace);
      if (info) {
        nameStore.set(STORAGE_CURRENT_MENU_KEY, info);
      } else {
        nameStore.set(STORAGE_CURRENT_MENU_KEY, []);
      }
    }else{
      if (info) {
        state.my_menu_data_list = info;
        ls.set(STORAGE_CURRENT_MENU_KEY, info);
      } else {
        state.my_menu_data_list = [];
        ls.set(STORAGE_CURRENT_MENU_KEY, []);
      }
    }

  },
  [SET_JSC_DATA_LIST]: (state, info) => {
    const namespace = state.jump_namespce;
    if(namespace) {
      const nameStore = getStoreByName(namespace);
      if (info) {
        nameStore.set(STORAGE_CURRENT_JSC_KEY, info);
      } else {
        nameStore.set(STORAGE_CURRENT_JSC_KEY, []);
      }
    }else {
      if (info) {
        state.my_menu_data_list = info;
        ls.set(STORAGE_CURRENT_JSC_KEY, info);
      } else {
        state.my_menu_data_list = [];
        ls.set(STORAGE_CURRENT_JSC_KEY, []);
      }
    }
  },
  [SET_JUMP_NAMPSPACE]: (state, namespce) => {
    if (namespce) {
      state.jump_namespce = namespce;
    } else {
      state.jump_namespce = '';
    }
  },
};
