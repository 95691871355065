import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.text, (obj, obj_index) => {
    return (_openBlock(), _createBlock(_component_a_tag, {
      color: 'green',
      size: 'small',
      key: obj.id==undefined?obj_index:obj.id,
      style: {"margin-bottom":"1px"}
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(obj.name==undefined?obj:obj.name), 1)
      ]),
      _: 2
    }, 1024))
  }), 128))
}