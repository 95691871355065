import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';

import ls from '@/utils/local-storage';
import {STORAGE_CURRENT_ADVISER_KEY, STORAGE_CURRENT_ORG_KEY} from '@/store/mutation-type';
import bus from '@/utils/bus';
import {useI18n} from "vue-i18n";
import {computed} from "vue";
import {useStore} from "vuex";
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const { t } = useI18n();
const store = useStore();
const is_induction_process = computed(() => store.getters['user/is_induction_process']);
console.log('is_induction_process', is_induction_process.value);
export const event_obj = mitt();
export const btn_list = [
  {
    name: t('page.cerp.update'),
    clazz: 'primary',
    cb: (record: any) => {
      bus.emit('update_bank_account', {...record});
    },
  },
  // {
  //   name: t('page.information.delete'),
  //   clazz: 'danger',
  //   cb: (record: any) => {
  //     bus.emit('del_bank_account', {...record});
  //   },
  // },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [6, 5],
  updateCol: [6, 6],
};

export const head_btn_list = [
  {
    name: t('page.Induction.New_bank_account'),
    clazz: 'primary',
    cb: () => {
      bus.emit('create_bank_account');
    },
  },
];
