import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function update_tmarea(params: Record<string, any>) {
  return request.post('/tm/update_tmarea', params);
}

export async function delete_tmline(params: Record<string, any>) {
  return request.post('/tm/delete_tmline', params);
}

export async function query_new_tminfo_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/tm/query_new_tminfo_list', params);
}

export async function update_tminfo(params: Record<string, any>) {
  return request.post('/tm/update_tminfo', params);
}

export async function update_tmcode(params: Record<string, any>) {
  return request.post('/tm/update_tmcode', params);
}

export async function delete_tmcode(params: Record<string, any>) {
  return request.post('/tm/delete_tmcode', params);
}

export async function query_tmline_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/tm/query_tmline_list', params);
}

export async function create_tmarea(params: Record<string, any>) {
  return request.post('/tm/create_tmarea', params);
}

export async function create_tminfo(params: Record<string, any>) {
  return request.post('/tm/create_tminfo', params);
}

export async function create_tmline(params: Record<string, any>) {
  return request.post('/tm/create_tmline', params);
}

export async function delete_tmarea(params: Record<string, any>) {
  return request.post('/tm/delete_tmarea', params);
}

export async function get_tmline_default_area(params: Record<string, any>) {
  return request.post('/tm/get_tmline_default_area', params);
}

export async function query_tmarea_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/tm/query_tmarea_list', params);
}

export async function query_tminfo_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/tm/query_tminfo_list', params);
}

export async function update_tmline(params: Record<string, any>) {
  return request.post('/tm/update_tmline', params);
}

export async function query_tmcode_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/tm/query_tmcode_list', params);
}

export async function create_tmcode(params: Record<string, any>) {
  return request.post('/tm/create_tmcode', params);
}

export async function recover_tmcode(params: Record<string, any>) {
  return request.post('/tm/recover_tmcode', params);
}

export async function delete_tminfo(params: Record<string, any>) {
  return request.post('/tm/delete_tminfo', params);
}

export async function recover_tminfo(params: Record<string, any>) {
  return request.post('/tm/recover_tminfo', params);
}

export async function recover_tmarea(params: Record<string, any>) {
  return request.post('/tm/recover_tmarea', params);
}

export async function recover_tmline(params: Record<string, any>) {
  return request.post('/tm/recover_tmline', params);
}
