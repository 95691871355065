import { delete_bank_account, query_bank_account_list, recover_bank_account } from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { Record, SearchConfig } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ADVISER_KEY, STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {
  btn_list,
  config,
  event_obj,
  head_btn_list,
  slotList,
} from '@/components/page-model4/cerp/cerp-bankaccount-config';

import { reactive } from 'vue';
import { getDateTime } from '@/utils/function';

export default () => {
  const None = null;
  const True = true;
  const False = false;

  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const adviser = ls.get(STORAGE_CURRENT_ADVISER_KEY);

  const { t } = useI18n();
  const search_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'inline',
    },
    fields: [
      {
        type: 'input',
        name: 'bank_name',
        label: '银行名称',
        label_i18n: t('page.cerp.bankaccount.form.label.bank_name'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写银行名称',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.bank_name'),
      },
      {
        type: 'input',
        name: 'english_name',
        label: '银行英文名称',
        label_i18n: t('page.cerp.bankaccount.form.label.english_name'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写银行英文名称',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.english_name'),
      },
      {
        type: 'input',
        name: 'account_holder',
        label: '账户持有人',
        label_i18n: t('page.cerp.bankaccount.form.label.account_holder'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写账户持有人',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.account_holder'),
      },
      {
        type: 'input',
        name: 'account_number',
        label: '账号',
        label_i18n: t('page.cerp.bankaccount.form.label.account_number'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写账号',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.account_number'),
      },
      {
        type: 'input',
        name: 'bank_address',
        label: '开户行地址',
        label_i18n: t('page.cerp.bankaccount.form.label.bank_address'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写开户行地址',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.bank_address'),
      },
      {
        type: 'input',
        name: 'iban',
        label: 'iban',
        label_i18n: t('page.cerp.bankaccount.form.label.iban'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写iban',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.iban'),
      },
      {
        type: 'input',
        name: 'bic_or_swift_code',
        label: 'bic_or_swift_code',
        label_i18n: t('page.cerp.bankaccount.form.label.bic_or_swift_code'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写bic_or_swift_code',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.bic_or_swift_code'),
      },
      {
        type: 'input',
        name: 'sort_code_or_branch_code',
        label: 'sort_code_or_branch_code',
        label_i18n: t('page.cerp.bankaccount.form.label.sort_code_or_branch_code'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写sort_code_or_branch_code',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.sort_code_or_branch_code'),
      },
      {
        type: 'input',
        name: 'cnaps',
        label: 'cnaps',
        label_i18n: t('page.cerp.bankaccount.form.label.cnaps'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写cnaps',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.cnaps'),
      },
      {
        type: 'radio',
        name: 'is_current',
        label: '是否当前账号',
        label_i18n: t('page.cerp.bankaccount.form.label.is_current'),
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      bank_name: null,
      kind: 0,
      adviser_id: adviser.adviser_id,
      insurance_company_id: null,
      account_holder: null,
      account_number: null,
      bank_address: null,
      iban: null,
      bic_or_swift_code: null,
      sort_code_or_branch_code: null,
      cnaps: null,
      is_current: null,
    }),
  };
  const search_options = {
    is_current: [
      { value: None, name: '全部' },
      { value: True, name: '是' },
      { value: False, name: '否' },
    ],
    is_active: [
      { value: None, name: '全部' },
      { value: True, name: '可用' },
      { value: False, name: '禁用' },
    ],
  };

  const edit_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'input',
        name: 'bank_name',
        label: '银行名称',
        label_i18n: t('page.cerp.bankaccount.form.label.bank_name'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写银行名称',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.bank_name'),
      },
      {
        type: 'input',
        name: 'account_holder',
        label: '账户持有人',
        label_i18n: t('page.cerp.bankaccount.form.label.account_holder'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写账户持有人',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.account_holder'),
      },
      {
        type: 'input',
        name: 'account_number',
        label: '账号',
        label_i18n: t('page.cerp.bankaccount.form.label.account_number'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写账号',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.account_number'),
      },
      {
        type: 'input',
        name: 'bank_address',
        label: '开户行地址',
        label_i18n: t('page.cerp.bankaccount.form.label.bank_address'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写开户行地址',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.bank_address'),
      },
      {
        type: 'input',
        name: 'iban',
        label: 'iban',
        label_i18n: t('page.cerp.bankaccount.form.label.iban'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写iban',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.iban'),
      },
      {
        type: 'input',
        name: 'bic_or_swift_code',
        label: 'bic_or_swift_code',
        label_i18n: t('page.cerp.bankaccount.form.label.bic_or_swift_code'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写bic_or_swift_code',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.bic_or_swift_code'),
      },
      {
        type: 'input',
        name: 'sort_code_or_branch_code',
        label: 'sort_code_or_branch_code',
        label_i18n: t('page.cerp.bankaccount.form.label.sort_code_or_branch_code'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写sort_code_or_branch_code',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.sort_code_or_branch_code'),
      },
      {
        type: 'input',
        name: 'cnaps',
        label: 'cnaps',
        label_i18n: t('page.cerp.bankaccount.form.label.cnaps'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写cnaps',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.cnaps'),
      },
      {
        type: 'input',
        name: 'is_current',
        label: '是否当前账号',
        label_i18n: t('page.cerp.bankaccount.form.label.is_current'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写是否当前账号',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.is_current'),
      },
    ],
    rules: {
      bank_name: [{ required: true, message: '请填输入银行名称' }],
      account_holder: [{ required: true, message: '请填输入账户持有人' }],
      account_number: [{ required: true, message: '请填输入账号' }],
      bank_address: [{ required: true, message: '请填输入开户行地址' }],
      iban: [{ required: true, message: '请填输入iban' }],
      bic_or_swift_code: [{ required: true, message: '请填输入bic_or_swift_code' }],
      sort_code_or_branch_code: [{ required: true, message: '请填输入sort_code_or_branch_code' }],
      cnaps: [{ required: true, message: '请填输入cnaps' }],
      is_current: [{ required: true, message: '请填输入是否当前账号' }],
    },
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      adviser_id: adviser.adviser_id,
      bank_name: null,
      account_holder: null,
      account_number: null,
      bank_address: null,
      iban: null,
      bic_or_swift_code: null,
      sort_code_or_branch_code: null,
      cnaps: null,
      is_current: null,
    }),
  };
  const edit_model_config_options = {};

  const update_model_config: SearchConfig = {
    settings: {
      labelAlign: 'right',
      layout: 'vertical',
      col: config.creatCol,
    },
    fields: [
      {
        type: 'input',
        name: 'bank_name',
        label: '银行名称',
        label_i18n: t('page.cerp.bankaccount.form.label.bank_name'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写银行名称',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.bank_name'),
      },
      {
        type: 'input',
        name: 'account_holder',
        label: '账户持有人',
        label_i18n: t('page.cerp.bankaccount.form.label.account_holder'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写账户持有人',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.account_holder'),
      },
      {
        type: 'input',
        name: 'account_number',
        label: '账号',
        label_i18n: t('page.cerp.bankaccount.form.label.account_number'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写账号',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.account_number'),
      },
      {
        type: 'input',
        name: 'bank_address',
        label: '开户行地址',
        label_i18n: t('page.cerp.bankaccount.form.label.bank_address'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写开户行地址',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.bank_address'),
      },
      {
        type: 'input',
        name: 'iban',
        label: 'iban',
        label_i18n: t('page.cerp.bankaccount.form.label.iban'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写iban',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.iban'),
      },
      {
        type: 'input',
        name: 'bic_or_swift_code',
        label: 'bic_or_swift_code',
        label_i18n: t('page.cerp.bankaccount.form.label.bic_or_swift_code'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写bic_or_swift_code',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.bic_or_swift_code'),
      },
      {
        type: 'input',
        name: 'sort_code_or_branch_code',
        label: 'sort_code_or_branch_code',
        label_i18n: t('page.cerp.bankaccount.form.label.sort_code_or_branch_code'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写sort_code_or_branch_code',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.sort_code_or_branch_code'),
      },
      {
        type: 'input',
        name: 'cnaps',
        label: 'cnaps',
        label_i18n: t('page.cerp.bankaccount.form.label.cnaps'),
        disabled: false,
        allowClear: true,
        inputType: 'text',
        defaultValue: '',
        placeholder: '请填写cnaps',
        placeholder_i18n: t('page.cerp.bankaccount.form.placeholder.cnaps'),
      },
      {
        type: 'radio',
        name: 'is_current',
        label: '是否当前账号',
        label_i18n: t('page.cerp.bankaccount.form.label.is_current'),
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
      },
      {
        type: 'radio',
        name: 'kind',
        label: '账号种类',
        label_i18n: t('page.cerp.bankaccount.form.label.kind'),
        disabled: false,
        labelKey: 'name',
        valueKey: 'value',
        defaultValue: '',
      },
    ],
    rules: {},
    model: reactive({
      org_id: current_org?.id,
      is_active: true,
      adviser_id: adviser.adviser_id,
      insurance_company_id: null,
      bank_name: null,
      account_holder: null,
      account_number: null,
      bank_address: null,
      iban: null,
      bic_or_swift_code: null,
      sort_code_or_branch_code: null,
      cnaps: null,
      is_current: null,
      kind: 0,
    }),
  };
  const update_model_config_options = {
    is_current: [
      { value: True, name: '是' },
      { value: False, name: '否' },
    ],
    kind: [
      { value: 0, name: '个人账号' },
      { value: 1, name: '公司账号' },
    ],
  };

  const titleList = {
    title: '银行账户信息管理',
    addModalTitle: '新建银行账户信息',
    updateTitle: '修改银行账户信息',
    is_create: false, // 是否有【新建】按钮
    is_update: false, // 是否有【修改】按钮
    is_remove: false, // 是否有【删除】按钮
    is_recover: true, // 是否有【恢复】按钮
  };

  const api_fields: Record = {
    update: {
      bank_account_id: 'id',
    },
    delete: {
      bank_account_id: 'id',
    },
    recover: {
      bank_account_id: 'id',
    },
    updateData: {
      org_id: 'org_id',
      bank_account_id: 'id',
    },
  };

  const baseColumns: TableColumn[] = [
    {
      title: t('page.cerp.bankaccount.column.title.index'),
      dataIndex: 'index',
      width: 50,
      fixed: 'left',
    },
    {
      title: t('page.cerp.bankaccount.column.title.adviser__full_name'),
      minWidth: 100,
      dataIndex: 'adviser__full_name',
      checked: false,
      fixed: 'left',
    },
    // {
    //   title: t('page.cerp.bankaccount.column.title.insurance_company__company_name'),
    //   width: 100,
    //   dataIndex: 'insurance_company__company_name',
    //   checked: true,
    //   fixed: 'left',
    // },
    {
      title: t('page.cerp.bankaccount.column.title.id'),
      width: 150,
      dataIndex: 'id',
      checked: false,
    },
    {
      title: t('page.cerp.bankaccount.column.title.bank_name'),
      minWidth: 150,
      dataIndex: 'bank_name',
      checked: true,
    },
    {
      title: t('page.cerp.bankaccount.column.title.english_name'),
      width: 150,
      dataIndex: 'english_name',
      checked: true,
    },
    {
      title: t('page.cerp.bankaccount.column.title.account_holder'),
      width: 150,
      dataIndex: 'account_holder',
      checked: true,
    },
    {
      title: t('page.cerp.bankaccount.column.title.account_number'),
      width: 150,
      dataIndex: 'account_number',
      checked: true,
    },
    {
      title: t('page.cerp.bankaccount.column.title.bank_address'),
      width: 150,
      dataIndex: 'bank_address',
      checked: true,
    },
    {
      title: t('page.cerp.bankaccount.column.title.iban'),
      width: 150,
      dataIndex: 'iban',
      checked: true,
    },
    {
      title: t('page.cerp.bankaccount.column.title.bic_or_swift_code'),
      width: 150,
      dataIndex: 'bic_or_swift_code',
      checked: true,
    },
    {
      title: t('page.cerp.bankaccount.column.title.sort_code_or_branch_code'),
      width: 150,
      dataIndex: 'sort_code_or_branch_code',
      checked: true,
    },
    {
      title: t('page.cerp.bankaccount.column.title.cnaps'),
      width: 150,
      dataIndex: 'cnaps',
      checked: true,
    },
    {
      title: t('page.cerp.bankaccount.column.title.is_current'),
      width: 150,
      dataIndex: 'is_current',
      checked: true,
      cellComponent: 'boolean',
      name1: t('page.cerp.bankaccount.column.title.is_current.true'),
      name2: t('page.cerp.bankaccount.column.title.is_current.false'),
    },
    {
      title: t('page.cerp.bankaccount.column.title.note'),
      width: 300,
      dataIndex: 'note',
      checked: true,
    },
    {
      title: t('page.cerp.bankaccount.column.title.holder_address'),
      width: 300,
      dataIndex: 'holder_address',
      checked: true,
    },
    {
      title: t('page.cerp.bankaccount.column.title.create_time'),
      width: 150,
      dataIndex: 'create_time',
      checked: false,
      content: (record: any) => {
        return getDateTime(record.create_time);
      },
    },
    {
      title: t('page.cerp.bankaccount.column.title.update_time'),
      width: 150,
      dataIndex: 'update_time',
      checked: false,
      content: (record: any) => {
        return getDateTime(record.update_time);
      },
    },
    {
      title: t('page.cerp.bankaccount.column.title.is_active'),
      width: 150,
      dataIndex: 'is_active',
      checked: false,
      cellComponent: 'boolean',
      name1: t('page.cerp.bankaccount.column.title.is_active.true'),
      name2: t('page.cerp.bankaccount.column.title.is_active.false'),
      filterMultiple: false,
      filters: [
        { text: t('page.cerp.bankaccount.column.title.is_active.all'), value: '' },
        { text: t('page.cerp.bankaccount.column.title.is_active.true'), value: true },
        { text: t('page.cerp.bankaccount.column.title.is_active.false'), value: false },
      ],
    },

    {
      title: t('page.cerp.bankaccount.column.title.action'),
      width: 180,
      dataIndex: 'action',
      checked: false,
      cellComponent: 'action',
      fixed: 'right',
    },
  ];
  const newSlotList: any = [];
  Object.assign(newSlotList, slotList);
  // newSlotList.push({
  //   type: 'custom',
  //   slotName: 'is_active',
  //   field_true: '可用',
  //   field_false: '禁用',
  // });

  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_bank_account_list,
    addData: null,
    updateData: null,
    removeData: delete_bank_account,
    recoverData: recover_bank_account,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
