import {
  delete_person_reference_number,
  recover_person_reference_number,
  update_person_reference_number_by_wechat,
  query_person_reference_number_list,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { SearchConfig, Record } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import {STORAGE_CURRENT_ADVISER_KEY, STORAGE_CURRENT_ORG_KEY} from '@/store/mutation-type';
import {
  slotList,
  btn_list,
  head_btn_list,
  event_obj,
  config,
} from '@/components/page-model4/cerp/cerp-personreferencenumber-config';

import { createVNode, reactive, ref } from 'vue';
import axios from 'axios';
import { message, Modal, notification } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import {getDate, getDateTime} from "@/utils/function";

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const adviser = ls.get(STORAGE_CURRENT_ADVISER_KEY);
const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'search_text',
      label: '模糊搜索',
      label_i18n: t('page.cerp.personreferencenumber.form.label.search_text'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写搜索内容',
      placeholder_i18n: t('page.cerp.personreferencenumber.form.placeholder.search_text'),
    },
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.cerp.personreferencenumber.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.cerp.personreferencenumber.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'description',
      label: '描述',
      label_i18n: t('page.cerp.personreferencenumber.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.cerp.personreferencenumber.form.placeholder.description'),
    },
    {
      type: 'input',
      name: 'start_date',
      label: '开始时间',
      label_i18n: t('page.cerp.personreferencenumber.form.label.start_date'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写开始时间',
      placeholder_i18n: t('page.cerp.personreferencenumber.form.placeholder.start_date'),
    },
    {
      type: 'input',
      name: 'end_date',
      label: '截止时间',
      label_i18n: t('page.cerp.personreferencenumber.form.label.end_date'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写截止时间',
      placeholder_i18n: t('page.cerp.personreferencenumber.form.placeholder.end_date'),
    },
    {
      type: 'remoteselect',
      name: 'reference_number_type_id',
      label: '参考号类别',
      label_i18n: t('page.cerp.personreferencenumber.form.label.reference_number_type_id'),
      disabled: false,
      placeholder: '请选择参考号类别',
      placeholder_i18n: t(
        'page.cerp.personreferencenumber.form.placeholder.reference_number_type_id',
      ),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-referencenumbertype',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: adviser.adviser_id,
    name: null,
    search_text: null,
    description: null,
    start_date: null,
    end_date: null,
    is_adviser_select: true,
    reference_number_type_id: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.cerp.personreferencenumber.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.cerp.personreferencenumber.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'description',
      label: '描述',
      label_i18n: t('page.cerp.personreferencenumber.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.cerp.personreferencenumber.form.placeholder.description'),
    },
    {
      type: 'datepicker',
      name: 'start_date',
      label: '开始时间',
      label_i18n: t('page.cerp.personreferencenumber.form.label.start_date'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写开始时间',
      placeholder_i18n: t('page.cerp.personreferencenumber.form.placeholder.start_date'),
    },
    {
      type: 'datepicker',
      name: 'end_date',
      label: '截止时间',
      label_i18n: t('page.cerp.personreferencenumber.form.label.end_date'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写截止时间',
      placeholder_i18n: t('page.cerp.personreferencenumber.form.placeholder.end_date'),
    },
    {
      type: 'remoteselect',
      name: 'reference_number_type_id',
      label: '参考号类别',
      label_i18n: t('page.cerp.personreferencenumber.form.label.reference_number_type_id'),
      disabled: false,
      placeholder: '请选择参考号类别',
      placeholder_i18n: t(
        'page.cerp.personreferencenumber.form.placeholder.reference_number_type_id',
      ),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-referencenumbertype',
    },
  ],
  rules: {
    name: [{ required: true, message: '请填输入名称' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: adviser.adviser_id,
    name: null,
    description: null,
    start_date: null,
    end_date: null,
    reference_number_type_id: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: [],
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '名称',
      label_i18n: t('page.cerp.personreferencenumber.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写名称',
      placeholder_i18n: t('page.cerp.personreferencenumber.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'description',
      label: '描述',
      label_i18n: t('page.cerp.personreferencenumber.form.label.description'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写描述',
      placeholder_i18n: t('page.cerp.personreferencenumber.form.placeholder.description'),
    },
    {
      type: 'datepicker',
      name: 'start_date',
      label: '开始时间',
      label_i18n: t('page.cerp.personreferencenumber.form.label.start_date'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写开始时间',
      placeholder_i18n: t('page.cerp.personreferencenumber.form.placeholder.start_date'),
    },
    {
      type: 'datepicker',
      name: 'end_date',
      label: '截止时间',
      label_i18n: t('page.cerp.personreferencenumber.form.label.end_date'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写截止时间',
      placeholder_i18n: t('page.cerp.personreferencenumber.form.placeholder.end_date'),
    },
    {
      type: 'remoteselect',
      name: 'reference_number_type_id',
      label: '参考号类别',
      label_i18n: t('page.cerp.personreferencenumber.form.label.reference_number_type_id'),
      disabled: false,
      placeholder: '请选择参考号类别',
      placeholder_i18n: t(
        'page.cerp.personreferencenumber.form.placeholder.reference_number_type_id',
      ),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-referencenumbertype',
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: adviser.adviser_id,
    name: null,
    description: null,
    start_date: null,
    end_date: null,
    reference_number_type_id: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: '个人参考号管理',
  addModalTitle: t('page.information.New_personal_reference_number'),
  updateTitle: '修改个人参考号',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    person_reference_number_id: 'id',
    reference_number_type_id: 'reference_number_type',
  },
  delete: {
    person_reference_number_id: 'id',
  },
  recover: {
    person_reference_number_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    person_reference_number_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.personreferencenumber.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.cerp.personreferencenumber.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.cerp.personreferencenumber.column.title.adviser__full_name'),
    minWidth: 150,
    dataIndex: 'adviser__full_name',
    checked: false,
  },
  {
    title: t('page.cerp.personreferencenumber.column.title.name'),
    width: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('page.cerp.personreferencenumber.column.title.description'),
    minWidth: 150,
    dataIndex: 'description',
    checked: true,
  },
  {
    title: t('page.cerp.personreferencenumber.column.title.file_name'),
    minWidth: 300,
    dataIndex: 'appendix_json_list',
    checked: true,
    cellComponent: 'list2',
    cb: (record: any, obj: any) => {
      if (obj.file_url) {
        const url =
          'https://erp-cc.oss-cn-hongkong.aliyuncs.com/' + obj.file_url;
        window.open(url, '_blank');
      } else {
        notification.error({
          message: t('page.Induction.message.reminder'),
          description: t('page.Induction.openUrl.description'),
        });
      }
    }
  },
  {
    title: t('page.cerp.personreferencenumber.column.title.reference_number_type__name'),
    width: 150,
    dataIndex: 'reference_number_type__name',
    checked: true,
  },
  {
    title: t('page.cerp.personreferencenumber.column.title.start_date'),
    width: 150,
    dataIndex: 'start_date',
    checked: false,
    content: (record: any) => {
      return getDate(record.start_date);
    },
  },
  {
    title: t('page.cerp.personreferencenumber.column.title.end_date'),
    width: 150,
    dataIndex: 'end_date',
    checked: false,
    content: (record: any) => {
      return getDate(record.end_date);
    },
  },
  {
    title: t('page.cerp.personreferencenumber.column.title.is_active'),
    width: 150,
    dataIndex: 'is_active',
    checked: false,

    cellComponent: 'boolean',
    name1: '可用',
    name2: '禁用',
    filterMultiple: false,
    filters: [
      { text: '全部', value: '' },
      { text: '可用', value: true },
      { text: '禁用', value: false },
    ],
  },
  {
    title: t('page.cerp.personreferencenumber.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.cerp.personreferencenumber.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: true,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },

  {
    title: t('page.cerp.personreferencenumber.column.title.action'),
    width: 100,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push(
  {
    type: 'custom',
    // slotName: 'is_active',
    field_true: '可用',
    field_false: '禁用',
  },
  {
    type: 'clickList',
    slotName: 'appendix_json',
    cb: (record: any) => {
      if (record && record.file_url) {
        axios
          .get('/' + record.file_url, {
            responseType: 'blob',
          })
          .then((res: any) => {
            const blob = res.data;
            // FileReader主要用于将文件内容读入内存
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            // onload当读取操作成功完成时调用
            reader.onload = (e: any) => {
              const a = document.createElement('a');
              // 获取文件名fileName
              let fileName = res.config['url'].split('/');
              fileName = fileName[fileName.length - 1];
              // fileName = fileName.replace(/"/g, "");
              a.download = fileName;
              a.href = e.target.result;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
            };
          });
      } else {
        notification.error({
          message: '提示',
          description: '无对应附件',
        });
      }
    },
    del: (record: any, text: any, file: any) => {
      if (file.file_id) {
        Modal.confirm({
          title: '提示',
          content: '是否删除[' + file.name + ']',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            return new Promise(resolve => {
              let ids: any = [];
              text.forEach((itme: any) => {
                if (itme.file_id != file.file_id) {
                  ids.push(itme.file_id);
                }
              });
              if (ids.length == 0) {
                ids = 0;
              }
              update_person_reference_number_by_wechat({
                person_reference_number_id: record.id,
                appendix_ids: ids,
                org_id: current_org?.id,
              })
                .then(() => {
                  message.success('删除成功');
                  event_obj.emit('refresh');
                })
                .finally(() => {
                  resolve(null);
                });
            });
          },
          onCancel() {},
        });
      }
    },
  },
);

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_person_reference_number_list,
    addData: null,
    updateData: null,
    removeData: delete_person_reference_number,
    recoverData: recover_person_reference_number,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
