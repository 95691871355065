import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"border":"1px solid blue","padding":"0","margin":"0"} }
const _hoisted_2 = {
  height: "300px",
  width: "400px",
  style: {"background":"#fff"},
  ref: "eleCan"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: _ctx.t('page.kefu.Please_sign_in_the_blue_box'),
    width: "450px",
    visible: true,
    maskClosable: false,
    centered: "",
    onCancel: _cache[0] || (_cache[0] = 
      () => {
        // resetFields();
        _ctx.$emit('cancel');
      }
    ),
    onOk: _ctx.handleOK
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("canvas", _hoisted_2, [
          _createElementVNode("span", null, _toDisplayString(_ctx.t('page.kefu.canvas')), 1)
        ], 512)
      ])
    ]),
    _: 1
  }, 8, ["title", "onOk"]))
}