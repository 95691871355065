import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bar_chart_outlined = _resolveComponent("bar-chart-outlined")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(_component_a_button, {
    size: 'small',
    type: "link",
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.column.cb(_ctx.text, _ctx.record, _ctx.column)), ["stop"]))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_bar_chart_outlined, { style: {"font-weight":"bolder","font-size":"18px","color":"#0366d6"} })
    ]),
    _: 1
  }))
}