
import { useForm } from 'ant-design-vue/es/form';
import {computed, defineComponent, reactive, ref} from 'vue';
import {query_track_point_list, create_alarm, update_alarm} from "@/api/alarm-model";
import ls from "@/utils/local-storage";
import {STORAGE_CURRENT_ORG_KEY} from "@/store/mutation-type";
import { notification } from 'ant-design-vue';
const permissionActions = ['add', 'delete', 'update', 'query', 'import', 'export'];
export default defineComponent({
  name: 'AlarmModal',
  props: {
    title: {
      type: String,
      required: false,
      default: () => '',
    },
    loading: {
      type: Boolean,
    },
    type: {
      type: String,
      default: () => '',
    },
    record: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

    const form = reactive({
      settings: {
        labelAlign: 'right',
        layout: 'vertical',
        col: [],
      },
      fields: [
        // {
        //   type: 'remoteselect',
        //   name: 'track_point_id',
        //   label: '隶属工艺追踪点位',
        //   disabled: false,
        //   placeholder: '请选择隶属工艺追踪点位',
        //   datasourceType: 'remote',
        //   labelKey: 'name',
        //   valueKey: 'id',
        //   mode: 'default',
        //   allowClear: true,
        //   modalType: 'alarm-trackpoint',
        // },
        {
          type: 'remotetreeselect',
          name: 'track_point_id',
          label: '隶属工艺追踪点位',
          disabled: false,
          placeholder: '请选择隶属工艺追踪点位',
          datasourceType: 'remote',
          mode: 'default',
          allowClear: true,
          modalType: 'alarm-query_track_point_tree_list',
        },
        {
          type: 'input',
          name: 'name',
          label: '名称',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写名称',
        },
        // {
        //   type: 'input',
        //   name: 'opc_name',
        //   label: 'opc变量',
        //   disabled: false,
        //   allowClear: true,
        //   inputType: 'text',
        //   defaultValue: '',
        //   placeholder: '请填写opc变量',
        // },
        {
          type: 'remoteselect',
          name: 'group_id',
          label: '内控分组',
          disabled: false,
          placeholder: '请选择内控分组',
          datasourceType: 'remote',
          default: true,
          mode: 'default',
          allowClear: true,
          modalType: 'alarm-group',
        },
        {
          type: 'input',
          name: 'unit',
          label: '单位',
          disabled: false,
          allowClear: true,
          inputType: 'text',
          defaultValue: '',
          placeholder: '请填写单位',
        },
      ],
      rules: reactive({}),
      model: reactive({
        org_id: current_org?.id,
        is_active: true,
        unit: props.type == 'update' ? props.record.unit : null,
        name: props.type == 'update' ? props.record.name : null,
        opc_name: props.type == 'update' ? props.record.opc_name : null,
        track_point_id: props.type == 'update' ? props.record.track_point_id : null,
        group_id: props.type == 'update' ? props.record.group_id : null,
      }),
      watch: {
        track_point_id: (data: any) => {
          const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
          query_track_point_list({
            org_id: org.id,
            ids: [data.track_point_id],
          }).then((res: any) => {
            if(res && res.data && res.data.length > 0){
              data.name = res.data[0].name;
              data.opc_name = res.data[0].opc_name;
            }
          })
        }
      },
    });

    const options: any = reactive({
      is_open: [
        { value: true, name: '开' },
        { value: false, name: '关' },
      ],
      billet_type: [
        { value: 'C', name: '冷装' },
        { value: 'H', name: '热装' },
        { value: null, name: '通配' },
      ],
      billet_thick: [
        { name: '170', value: '170' },
        { name: '165', value: '165' },
        { name: '160', value: '160' },
        { name: '155', value: '155' },
        { name: '150', value: '150' },
      ],
    });
    const form1 = reactive({
      settings: {
        labelAlign: 'right',
        layout: 'vertical',
        col: [ 3, 3 ],
      },
      fields: [
        {
          type: 'radio',
          name: 'is_open',
          label: '报警开关',
          mode: 'default',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          datasource: 'is_open',
        },
        {
          type: 'input',
          name: 'first_max',
          label: '一级报警阈值上限',
          disabled: false,
          allowClear: true,
          inputType: 'number',
          defaultValue: '',
          placeholder: '请填写一级报警阈值上限',
        },
        {
          type: 'input',
          name: 'second_max',
          label: '二级报警阈值上限',
          disabled: false,
          allowClear: true,
          inputType: 'number',
          defaultValue: '',
          placeholder: '请填写二级报警阈值上限',
        },
        {
          type: 'radio',
          name: 'is_audio',
          label: '是否声音报警',
          mode: 'default',
          disabled: false,
          defaultValue: '',
          labelKey: 'name',
          valueKey: 'value',
          datasource: 'is_audio',
        },
        {
          type: 'input',
          name: 'first_min',
          label: '一级报警阈值下限',
          disabled: false,
          allowClear: true,
          inputType: 'number',
          defaultValue: '',
          placeholder: '请填写一级报警阈值下限',
        },
        {
          type: 'input',
          name: 'second_min',
          label: '二级报警阈值下限',
          disabled: false,
          allowClear: true,
          inputType: 'number',
          defaultValue: '',
          placeholder: '请填写二级报警阈值下限',
        },
        // {
        //   type: 'kong',
        // },
        // {
        //   type: 'input',
        //   name: 'first_point',
        //   label: '一级分值',
        //   disabled: false,
        //   allowClear: true,
        //   inputType: 'number',
        //   defaultValue: '',
        //   placeholder: '请填写一级分值',
        // },
        // {
        //   type: 'input',
        //   name: 'second_point',
        //   label: '二级分值',
        //   disabled: false,
        //   allowClear: true,
        //   inputType: 'number',
        //   defaultValue: '',
        //   placeholder: '请填写二级分值',
        // },
      ],
      rules: reactive({}),
      model: reactive({
        org_id: current_org?.id,
        is_open: props.type == 'update' ? props.record.is_open : true,
        is_audio: props.type == 'update' ? props.record.is_audio : false,
        is_single: props.type == 'update' ? props.record.is_single : false,
        first_min: props.type == 'update' ? props.record.first_min : null,
        first_max: props.type == 'update' ? props.record.first_max : null,
        second_min: props.type == 'update' ? props.record.second_min : null,
        second_max: props.type == 'update' ? props.record.second_max : null,
        first_point: props.type == 'update' ? props.record.first_point : null,
        second_point: props.type == 'update' ? props.record.second_point : null,
      }),
    });
    const options1: any = reactive({
      is_open: [
        { value: true, name: '开' },
        { value: false, name: '关' },
      ],
      is_audio: [
        { value: true, name: '是' },
        { value: false, name: '否' },
      ],
      is_single: [
        { value: true, name: '是' },
        { value: false, name: '否' },
      ],
    })
    const form2 = reactive({
      settings: {
        labelAlign: 'right',
        layout: 'vertical',
        col: [],
      },
      fields: [
        {
          type: 'remoteselect',
          name: 'billet_type',
          label: '坯料种类',
          disabled: false,
          placeholder: '请选择坯料种类',
          datasourceType: 'remote',
          show_search: true,
          mode: 'default',
          allowClear: true,
          labelKey: 'name',
          valueKey: 'flag',
          default: false,
          modalType: 'production_manage-query_billet_type_list',
        },
        {
          type: 'remoteselect',
          name: 'steel_type',
          label: '成品钢种',
          disabled: false,
          placeholder: '请选择成品钢种',
          datasourceType: 'remote',
          init_model: { is_madeup: true, aim_org_id: current_org.id },
          labelKey: 'name',
          valueKey: 'name',
          mode: 'default',
          allowClear: true,
          modalType: 'material_storage-query_steel_type_list',
        },
        {
          type: 'remoteselect',
          name: 'billet_grade',
          label: '原料钢种',
          disabled: false,
          placeholder: '请选择原料钢种',
          datasourceType: 'remote',
          labelKey: 'name',
          valueKey: 'name',
          mode: 'default',
          allowClear: true,
          modalType: 'production_manage-query_billet_grade_list',
        },
        {
          type: 'remoteselect',
          name: 'prod_thick',
          label: '成品口径',
          disabled: false,
          placeholder: '请选择成品口径',
          datasourceType: 'remote',
          labelKey: 'name',
          valueKey: 'diameter',
          mode: 'default',
          allowClear: true,
          modalType: 'production_manage-query_steel_diameter_list',
        },
        {
          type: 'remoteselect',
          name: 'prod_len',
          label: '定尺长度（mm）',
          disabled: false,
          placeholder: '请选择定尺长度（mm）',
          datasourceType: 'remote',
          labelKey: 'name',
          valueKey: 'name',
          mode: 'default',
          allowClear: true,
          modalType: 'production_manage-query_product_length_list',
        },
        {
          type: 'remoteselect',
          name: 'billet_thick',
          label: '钢坯截面',
          disabled: false,
          placeholder: '请选择钢坯截面',
          datasourceType: 'remote',
          labelKey: 'name',
          valueKey: 'name',
          mode: 'default',
          allowClear: true,
          modalType: 'production_manage-query_billet_thick_list',
        },
        // {
        //   type: 'attributetable',
        //   name: 'data',
        //   label: '备注数据',
        //   disabled: false,
        //   allowClear: true,
        //   inputType: 'text',
        //   defaultValue: '',
        //   placeholder: '请填写备注数据',
        // },
      ],
      rules: reactive({}),
      model: reactive({
        org_id: current_org?.id,
        is_active: true,
        billet_type: props.type == 'update' ? props.record.billet_type : null,
        billet_grade: props.type == 'update' ? props.record.billet_grade : null,
        steel_type: props.type == 'update' ? props.record.steel_type : null,
        prod_thick: props.type == 'update' ? props.record.prod_thick : null,
        prod_len: props.type == 'update' ? props.record.prod_len : null,
        billet_thick: props.type == 'update' ? props.record.billet_thick : null,
        data: props.type == 'update' ? props.record.data : null,
      }),
    });
    const options2: any = reactive({
      billet_type: [
        { value: 'C', name: '冷装' },
        { value: 'H', name: '热装' },
        { value: null, name: '通配' },
      ],
      billet_thick: [
        { name: '170', value: '170' },
        { name: '165', value: '165' },
        { name: '160', value: '160' },
        { name: '155', value: '155' },
        { name: '150', value: '150' },
      ],
    });
    const activeKey = ref(['1']);
    const { resetFields, validate, validateInfos } = useForm(form.model, form.rules);
    const { resetFields: resetFields1, validate: validate1, validateInfos: validateInfos1 } = useForm(form1.model, form1.rules);
    const { resetFields: resetFields2, validate: validate2, validateInfos: validateInfos2 } = useForm(form2.model, form2.rules);
    const handleSubmit = () => {
      if(!form1.model.first_min && !form1.model.first_max){
        notification.error({
          message: '错误',
          description:
            '至少有一个一级阈值有数据',
        });
      }else{
        if(form1.model.first_min && form1.model.first_max){
          form1.model.is_single = false;
        }else {
          form1.model.is_single = true;
        }
        const obj: any = {
          ...form.model,
          ...form1.model,
          ...form2.model,
        }
        if(props.type == 'update'){
          update_alarm({...obj, alarm_id: props.record.id}).then((res: any) => {
            emit('ok');
          })
        }else{
          create_alarm({...obj}).then((res: any) => {
            emit('ok');
          })
        }
      }
    };
    return {
      props,
      form,
      form1,
      form2,
      options,
      options1,
      options2,
      handleSubmit,
      resetFields,
      resetFields1,
      resetFields2,
      validateInfos,
      validateInfos1,
      validateInfos2,
      activeKey,
      permissionActions,
    };
  },
});
