
import PropTypes from 'ant-design-vue/es/_util/vue-types';
import { defineComponent } from 'vue';
import { useProProvider } from '../base-layouts/pro-provider';

export default defineComponent({
  props: {
    contentWidth: PropTypes.oneOf(['Fluid', 'Fixed']).def('Fluid'),
    fixedHeader: PropTypes.bool,
    fixSiderbar: PropTypes.bool,
    splitMenus: PropTypes.bool,
    layout: PropTypes.oneOf(['side', 'top', 'mix', 'left']),
    i18n: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]).def(false),
  },
  setup(props, { emit }) {
    const { i18n } = useProProvider();
    const locale = props.i18n || i18n;

    const handleChange = (type: string, value: string) => {
      emit('change', { type, value });
    };

    return {
      locale,
      handleChange,
    };
  },
});
