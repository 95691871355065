import { query_product_gongyi_list } from '@/api/production_manage-model';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const search_config: any = {
  model: {
    org_id: org?.id,
    is_active: true,
    page_index: 1,
    page_size: 2000,
  },
  watch: ['aim_org_id'],
  attr_use: ['aim_org_id'],
};

export default () => {
  return {
    getData: query_product_gongyi_list,
    searchConfig: search_config,
  };
};
