import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function create_company(params: Record<string, any>) {
  return request.post('/roll/create_company', params);
}

export async function update_company(params: Record<string, any>) {
  return request.post('/roll/update_company', params);
}

export async function delete_company(params: Record<string, any>) {
  return request.post('/roll/delete_company', params);
}

export async function recover_company(params: Record<string, any>) {
  return request.post('/roll/recover_company', params);
}

export async function query_company_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/roll/query_company_list', params);
}

export async function create_data_field(params: Record<string, any>) {
  return request.post('/roll/create_data_field', params);
}

export async function update_data_field(params: Record<string, any>) {
  return request.post('/roll/update_data_field', params);
}

export async function delete_data_field(params: Record<string, any>) {
  return request.post('/roll/delete_data_field', params);
}

export async function recover_data_field(params: Record<string, any>) {
  return request.post('/roll/recover_data_field', params);
}

export async function query_data_field_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/roll/query_data_field_list', params);
}

export async function create_material(params: Record<string, any>) {
  return request.post('/roll/create_material', params);
}

export async function update_material(params: Record<string, any>) {
  return request.post('/roll/update_material', params);
}

export async function delete_material(params: Record<string, any>) {
  return request.post('/roll/delete_material', params);
}

export async function recover_material(params: Record<string, any>) {
  return request.post('/roll/recover_material', params);
}

export async function query_material_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/roll/query_material_list', params);
}

export async function create_product_word(params: Record<string, any>) {
  return request.post('/roll/create_product_word', params);
}

export async function update_product_word(params: Record<string, any>) {
  return request.post('/roll/update_product_word', params);
}

export async function delete_product_word(params: Record<string, any>) {
  return request.post('/roll/delete_product_word', params);
}

export async function recover_product_word(params: Record<string, any>) {
  return request.post('/roll/recover_product_word', params);
}

export async function query_product_word_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll/query_product_word_list', params);
}

export async function create_reason(params: Record<string, any>) {
  return request.post('/roll/create_reason', params);
}

export async function update_reason(params: Record<string, any>) {
  return request.post('/roll/update_reason', params);
}

export async function delete_reason(params: Record<string, any>) {
  return request.post('/roll/delete_reason', params);
}

export async function recover_reason(params: Record<string, any>) {
  return request.post('/roll/recover_reason', params);
}

export async function query_reason_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/roll/query_reason_list', params);
}

export async function create_rolled_machine(params: Record<string, any>) {
  return request.post('/roll/create_rolled_machine', params);
}

export async function update_rolled_machine(params: Record<string, any>) {
  return request.post('/roll/update_rolled_machine', params);
}

export async function delete_rolled_machine(params: Record<string, any>) {
  return request.post('/roll/delete_rolled_machine', params);
}

export async function recover_rolled_machine(params: Record<string, any>) {
  return request.post('/roll/recover_rolled_machine', params);
}

export async function query_rolled_machine_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll/query_rolled_machine_list', params);
}
export async function query_rolled_machine_for_new_group_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll/query_rolled_machine_for_new_group_list', params);
}

export async function create_rolled_machine_group(params: Record<string, any>) {
  return request.post('/roll/create_rolled_machine_group', params);
}

export async function update_rolled_machine_group(params: Record<string, any>) {
  return request.post('/roll/update_rolled_machine_group', params);
}

export async function delete_rolled_machine_group(params: Record<string, any>) {
  return request.post('/roll/delete_rolled_machine_group', params);
}

export async function recover_rolled_machine_group(params: Record<string, any>) {
  return request.post('/roll/recover_rolled_machine_group', params);
}

export async function query_rolled_machine_group_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll/query_rolled_machine_group_list', params);
}

export async function create_rolled_machine_group_record(params: Record<string, any>) {
  return request.post('/roll/create_rolled_machine_group_record', params);
}

export async function update_rolled_machine_group_record(params: Record<string, any>) {
  return request.post('/roll/update_rolled_machine_group_record', params);
}

export async function delete_rolled_machine_group_record(params: Record<string, any>) {
  return request.post('/roll/delete_rolled_machine_group_record', params);
}

export async function recover_rolled_machine_group_record(params: Record<string, any>) {
  return request.post('/roll/recover_rolled_machine_group_record', params);
}

export async function query_rolled_machine_group_record_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll/query_rolled_machine_group_record_list', params);
}

export async function create_rolled_machine_scope(params: Record<string, any>) {
  return request.post('/roll/create_rolled_machine_scope', params);
}

export async function update_rolled_machine_scope(params: Record<string, any>) {
  return request.post('/roll/update_rolled_machine_scope', params);
}

export async function delete_rolled_machine_scope(params: Record<string, any>) {
  return request.post('/roll/delete_rolled_machine_scope', params);
}

export async function recover_rolled_machine_scope(params: Record<string, any>) {
  return request.post('/roll/recover_rolled_machine_scope', params);
}

export async function query_rolled_machine_scope_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll/query_rolled_machine_scope_list', params);
}

export async function create_roller(params: Record<string, any>) {
  return request.post('/roll/create_roller', params);
}

export async function update_roller(params: Record<string, any>) {
  return request.post('/roll/update_roller', params);
}

export async function delete_roller(params: Record<string, any>) {
  return request.post('/roll/delete_roller', params);
}

export async function recover_roller(params: Record<string, any>) {
  return request.post('/roll/recover_roller', params);
}

export async function query_roller_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/roll/query_roller_list', params);
}

export async function create_roller_slot(params: Record<string, any>) {
  return request.post('/roll/create_roller_slot', params);
}

export async function update_roller_slot(params: Record<string, any>) {
  return request.post('/roll/update_roller_slot', params);
}

export async function delete_roller_slot(params: Record<string, any>) {
  return request.post('/roll/delete_roller_slot', params);
}

export async function recover_roller_slot(params: Record<string, any>) {
  return request.post('/roll/recover_roller_slot', params);
}

export async function query_roller_slot_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/roll/query_roller_slot_list', params);
}

export async function create_roller_slot_record(params: Record<string, any>) {
  return request.post('/roll/create_roller_slot_record', params);
}

export async function update_roller_slot_record(params: Record<string, any>) {
  return request.post('/roll/update_roller_slot_record', params);
}

export async function delete_roller_slot_record(params: Record<string, any>) {
  return request.post('/roll/delete_roller_slot_record', params);
}

export async function recover_roller_slot_record(params: Record<string, any>) {
  return request.post('/roll/recover_roller_slot_record', params);
}

export async function query_roller_slot_record_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll/query_roller_slot_record_list', params);
}

export async function create_roller_standard(params: Record<string, any>) {
  return request.post('/roll/create_roller_standard', params);
}

export async function update_roller_standard(params: Record<string, any>) {
  return request.post('/roll/update_roller_standard', params);
}

export async function delete_roller_standard(params: Record<string, any>) {
  return request.post('/roll/delete_roller_standard', params);
}

export async function recover_roller_standard(params: Record<string, any>) {
  return request.post('/roll/recover_roller_standard', params);
}

export async function query_roller_standard_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll/query_roller_standard_list', params);
}

export async function create_roller_times(params: Record<string, any>) {
  return request.post('/roll/create_roller_times', params);
}

export async function update_roller_times(params: Record<string, any>) {
  return request.post('/roll/update_roller_times', params);
}

export async function delete_roller_times(params: Record<string, any>) {
  return request.post('/roll/delete_roller_times', params);
}

export async function recover_roller_times(params: Record<string, any>) {
  return request.post('/roll/recover_roller_times', params);
}

export async function query_roller_times_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll/query_roller_times_list', params);
}

export async function create_slot_info(params: Record<string, any>) {
  return request.post('/roll/create_slot_info', params);
}

export async function update_slot_info(params: Record<string, any>) {
  return request.post('/roll/update_slot_info', params);
}

export async function delete_slot_info(params: Record<string, any>) {
  return request.post('/roll/delete_slot_info', params);
}

export async function recover_slot_info(params: Record<string, any>) {
  return request.post('/roll/recover_slot_info', params);
}

export async function query_slot_info_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/roll/query_slot_info_list', params);
}

export async function create_technics_info(params: Record<string, any>) {
  return request.post('/roll/create_technics_info', params);
}

export async function update_technics_info(params: Record<string, any>) {
  return request.post('/roll/update_technics_info', params);
}

export async function delete_technics_info(params: Record<string, any>) {
  return request.post('/roll/delete_technics_info', params);
}

export async function recover_technics_info(params: Record<string, any>) {
  return request.post('/roll/recover_technics_info', params);
}

export async function query_technics_info_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll/query_technics_info_list', params);
}

export async function rollers_match(params: Record<string, any>) {
  return request.post('/roll/rollers_match', params);
}

export async function rollers_release(params: Record<string, any>) {
  return request.post('/roll/rollers_release', params);
}

export async function set_roller_scrap(params: Record<string, any>) {
  return request.post('/roll/set_roller_scrap', params);
}

export async function cancel_roller_scrap(params: Record<string, any>) {
  return request.post('/roll/cancel_roller_scrap', params);
}

export async function rolled_machine_group_acceptance_start(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_acceptance_start', params);
}

export async function rolled_machine_group_acceptance_fail(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_acceptance_fail', params);
}

export async function rolled_machine_group_acceptance_pass(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_acceptance_pass', params);
}

export async function rolled_machine_group_uninstall(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_uninstall', params);
}

export async function rolled_machine_group_up(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_up', params);
}

export async function rolled_machine_group_down(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_down', params);
}
export async function rolled_machine_group_slot_change(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_slot_change', params);
}
export async function create_roller_and_slot(params: Record<string, any>) {
  return request.post('/roll/create_roller_and_slot', params);
}
export async function query_roller_machine_record_bak_list(params: Record<string, any>) {
  return request.post('/roll/query_roller_machine_record_bak_list', params);
}
export async function rolled_machine_group_roller_change(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_roller_change', params);
}
export async function rolled_machine_group_offline_slot_change(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_offline_slot_change', params);
}
export async function get_roller_times_for_rolled_machine_group(params: Record<string, any>) {
  return request.post('/roll/get_roller_times_for_rolled_machine_group', params);
}
export async function create_roller_count_and_slot(params: Record<string, any>) {
  return request.post('/roll/create_roller_count_and_slot', params);
}
export async function rolled_machine_group_up_time_change(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_up_time_change', params);
}
export async function rolled_machine_group_down_time_change(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_down_time_change', params);
}
export async function rolled_machine_group_record_del(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_record_del', params);
}
export async function query_roller_slot_and_record_list(params: Record<string, any>) {
  return request.post('/roll/query_roller_slot_and_record_list', params);
}
export async function rolled_machine_group_record_set_reason(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_record_set_reason', params);
}
export async function query_roller_standard_by_slot_info_list(params: Record<string, any>) {
  return request.post('/roll/query_roller_standard_by_slot_info_list', params);
}

export async function rolled_machine_group_online_single_slot_change(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_online_single_slot_change', params);
}

export async function rolled_machine_group_offline_single_slot_change(params: Record<string, any>) {
  return request.post('/roll/rolled_machine_group_offline_single_slot_change', params);
}
export async function query_roller_pair_list(params: Record<string, any>) {
  return request.post('/roll/query_roller_pair_list', params);
}

export async function query_rolled_machine_group_record_all_list(params: Record<string, any>) {
  return request.post('/roll/query_rolled_machine_group_record_all_list', params);
}
