import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function create_alarm(params: Record<string, any>) {
  return request.post('/alarm/create_alarm', params);
}

export async function update_alarm(params: Record<string, any>) {
  return request.post('/alarm/update_alarm', params);
}

export async function delete_alarm(params: Record<string, any>) {
  return request.post('/alarm/delete_alarm', params);
}

export async function recover_alarm(params: Record<string, any>) {
  return request.post('/alarm/recover_alarm', params);
}

export async function query_alarm_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/alarm/query_alarm_list', params);
}

export async function create_alarm_event(params: Record<string, any>) {
  return request.post('/alarm/create_alarm_event', params);
}

export async function update_alarm_event(params: Record<string, any>) {
  return request.post('/alarm/update_alarm_event', params);
}

export async function delete_alarm_event(params: Record<string, any>) {
  return request.post('/alarm/delete_alarm_event', params);
}

export async function recover_alarm_event(params: Record<string, any>) {
  return request.post('/alarm/recover_alarm_event', params);
}

export async function query_alarm_event_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/alarm/query_alarm_event_list', params);
}

export async function create_alarm_record(params: Record<string, any>) {
  return request.post('/alarm/create_alarm_record', params);
}

export async function update_alarm_record(params: Record<string, any>) {
  return request.post('/alarm/update_alarm_record', params);
}

export async function delete_alarm_record(params: Record<string, any>) {
  return request.post('/alarm/delete_alarm_record', params);
}

export async function bulk_create_track_point(params: Record<string, any>) {
  return request.post('/alarm/bulk_create_track_point', params);
}

export async function recover_alarm_record(params: Record<string, any>) {
  return request.post('/alarm/recover_alarm_record', params);
}

export async function query_alarm_record_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/alarm/query_alarm_record_list', params);
}

export async function query_track_point_tree_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/alarm/query_track_point_tree_list', params);
}

export async function create_alarm_type(params: Record<string, any>) {
  return request.post('/alarm/create_alarm_type', params);
}

export async function update_alarm_type(params: Record<string, any>) {
  return request.post('/alarm/update_alarm_type', params);
}

export async function delete_alarm_type(params: Record<string, any>) {
  return request.post('/alarm/delete_alarm_type', params);
}

export async function recover_alarm_type(params: Record<string, any>) {
  return request.post('/alarm/recover_alarm_type', params);
}

export async function query_alarm_type_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/alarm/query_alarm_type_list', params);
}

export async function create_alarm_system(params: Record<string, any>) {
  return request.post('/alarm/create_alarm_system', params);
}

export async function update_alarm_system(params: Record<string, any>) {
  return request.post('/alarm/update_alarm_system', params);
}

export async function delete_alarm_system(params: Record<string, any>) {
  return request.post('/alarm/delete_alarm_system', params);
}

export async function recover_alarm_system(params: Record<string, any>) {
  return request.post('/alarm/recover_alarm_system', params);
}

export async function query_alarm_system_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/alarm/query_alarm_system_list', params);
}

export async function create_group(params: Record<string, any>) {
  return request.post('/alarm/create_group', params);
}

export async function update_group(params: Record<string, any>) {
  return request.post('/alarm/update_group', params);
}

export async function delete_group(params: Record<string, any>) {
  return request.post('/alarm/delete_group', params);
}

export async function recover_group(params: Record<string, any>) {
  return request.post('/alarm/recover_group', params);
}

export async function query_group_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/alarm/query_group_list', params);
}

export async function create_track_point(params: Record<string, any>) {
  return request.post('/alarm/create_track_point', params);
}

export async function update_track_point(params: Record<string, any>) {
  return request.post('/alarm/update_track_point', params);
}

export async function delete_track_point(params: Record<string, any>) {
  return request.post('/alarm/delete_track_point', params);
}

export async function recover_track_point(params: Record<string, any>) {
  return request.post('/alarm/recover_track_point', params);
}

export async function query_track_point_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/alarm/query_track_point_list', params);
}
