import 'ant-design-vue/dist/antd.less';
import '@surely-vue/table/dist/index.css';
import {
  Layout,
  Affix,
  Menu,
  Row,
  Col,
  Card,
  Form,
  Dropdown,
  Select,
  Button,
  Checkbox,
  Tabs,
  Tag,
  Input,
  InputNumber,
  DatePicker,
  TimePicker,
  Radio,
  Tooltip,
  Space,
  Steps,
  Divider,
  Descriptions,
  Alert,
  Calendar,
  Result,
  Statistic,
  Popconfirm,
  Popover,
  Table,
  Avatar,
  List,
  Progress,
  Switch,
  Modal,
  Rate,
  ConfigProvider,
  Empty,
  Spin,
  Drawer,
  PageHeader,
  Carousel,
  BackTop,
  Upload,
  Badge,
  TreeSelect,
  Slider,
  Tree,
  Image,
  Breadcrumb,
  Transfer,
  Collapse,
  Cascader,
  Timeline,
} from 'ant-design-vue';
import SurelyTable from '@surely-vue/table';
import { setLicenseKey } from '@surely-vue/table';
import { createApp } from 'vue';
import router from './router';
import store from './store';
import locale from './locales';
import App from './App.vue';

import FormDesign from '@/components/FormDesign';

import ZhaFeiModal from '@/components/form-modal/zhafei-modal.vue';
import StopEventModal from '@/components/form-modal/stop-event-modal.vue';
import SearchRender from '@/components/FormRender/SearchRender.vue';
import RemoteSelect from '@/components/FormRender/RemoteSelect/RemoteSelect.vue';
import RemoteModal from '@/components/FormRender/RemoteModal/RemoteModal.vue';
import FieldRender from '@/components/FormRender/FieldRender.vue';
import AttributeTable from '@/components/FormRender/AttributeTable/index.vue';
import AttributeTableModal from '@/components/info-modal/AttributeTableModal/index.vue';
import PageModel4 from '@/components/page-model4/index.vue';
import PageModel5 from '@/components/page-model_kefu/index.vue';
import TableTreeModal from '@/components/table_tree_modal.vue';
import RollNoModal from '@/components/info-modal/roll_no-modal.vue';
import ExportExcelByBackEndModal from '@/components/info-modal/export-excel-by-back-end-modal/index.vue';
import ExportExcelModal from '@/components/info-modal/export-excel-modal/index.vue';
import MenuDrawer from '@/components/info-modal/menuDrawer/index.vue';
import Cell_index from '@/components/cellComponent/cell_index.vue';
import Cell_custom from '@/components/cellComponent/cell_custom.vue';
import Cell_custom1 from '@/components/cellComponent/cell_custom1.vue';
import Cell_datetime from '@/components/cellComponent/cell_datetime.vue';
import Cell_date from '@/components/cellComponent/cell_date.vue';
import Cell_date_month from '@/components/cellComponent/cell_date_month.vue';
import Cell_month from '@/components/cellComponent/cell_month.vue';
import Cell_time from '@/components/cellComponent/cell_time.vue';
import Cell_percent from '@/components/cellComponent/cell_percent.vue';
import Cell_toFixed2 from '@/components/cellComponent/cell_toFixed2.vue';
import Cell_money from '@/components/cellComponent/cell_money.vue';
import Cell_attributetable from '@/components/cellComponent/cell_attributetable.vue';
import Cell_click from '@/components/cellComponent/cell_click.vue';
import Cell_click1 from '@/components/cellComponent/cell_click1.vue';
import Cell_color from '@/components/cellComponent/cell_color.vue';
import Cell_boolean from '@/components/cellComponent/cell_boolean.vue';
import Cell_list from '@/components/cellComponent/cell_list.vue';
import Cell_list1 from '@/components/cellComponent/cell_list1.vue';
import Cell_tag from '@/components/cellComponent/cell_tag.vue';
import Cell_real_weight from '@/components/cellComponent/cell_real_weight.vue';
import Cell_weight_offset_percent from '@/components/cellComponent/cell_weight_offset_percent.vue';
import Cell_collect_status from '@/components/cellComponent/cell_collect_status.vue';
import Cell_send_robot from '@/components/cellComponent/cell_send_robot.vue';
import Cell_tag_tag from '@/components/cellComponent/cell_tag-tag.vue';
import Cell_progress from '@/components/cellComponent/cell_progress.vue';
import Cell_bind_no from '@/components/cellComponent/cell_bind_no.vue';
import Cell_action from '@/components/cellComponent/cell_action.vue';
import Cell_stop_tag from '@/components/cellComponent/cell_stop_tag.vue';
import Cell_report_tag from '@/components/cellComponent/cell_report_tag.vue';
import Cell_text from '@/components/cellComponent/cell_text.vue';
import Cell_obj from '@/components/cellComponent/cell_obj.vue';
import Cell_bolder from '@/components/cellComponent/cell_bolder.vue';
import Cell_share_tag from '@/components/cellComponent/cell_share_tag.vue';
import Cell_script_log from '@/components/cellComponent/cell_script_log.vue';
import Cell_click_copy from '@/components/cellComponent/cell_click_copy.vue';
import Cell_link from '@/components/cellComponent/cell_link.vue';
import Cell_clicklist from '@/components/cellComponent/cell_clicklist.vue';
import Cell_hui_hua from '@/components/cellComponent/cell_hui_hua.vue';
import Cell_name from '@/components/cellComponent/cell_name.vue';
import Cell_talk from '@/components/cellComponent/cell_talk.vue';
import GroupModal from '@/components/form-modal/group_modal/index.vue';
import configurationFileModal from '@/components/form-modal/configurationFileModal/index.vue';
import configureModal from '@/components/form-modal/configureModal/index.vue';
import FormOkModal from '@/components/form-modal/form_ok-modal.vue';
import AlarmModal from '@/components/form-modal/alarm-modal.vue';
import UploadImageModal from '@/components/form-modal/upload_image_modal/index.vue';
import StatementModal from '@/components/form-modal/statement-modal.vue';
import SignModal from '@/components/info-modal/signModal/index.vue';
import UploadImageKefuModal from '@/components/form-modal/upload_image_kefu_modal/index.vue';
import Cell_list2 from '@/components/cellComponent/cell_list2.vue';

import DragIcon from '@/components/table/drag-icon.vue';
import { Container as DragContainer, Draggable } from '@/components/draggable';


import {
  ProProvider,
  PageContainer,
  TransformVnode,
  PageContainer2,
  PageContainer3,
} from '@/components';
import { useIcons } from '@/icons';
import Authority from './utils/authority/authority.vue';
import './app.less';
import './router/router-guards';
import bus from './utils/bus';
import numerals from './utils/numeral';
import { getTon } from '@/utils/function';
// import * as Sentry from '@sentry/vue';
// import { Integrations } from '@sentry/tracing';
// if (process.env.NODE_ENV == 'production') {
//   Sentry.init({
//     dsn: 'http://20d465198a2440d3b1cb134379a5e8be@8.140.118.52:19000/8',
//     integrations: [new Integrations.BrowserTracing()],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
//   });
// }
setLicenseKey('7e739ffcce912bfb784bd89279d2272bT1JERVI6MTAwMDA5LEVYUElSWT0xNjc3MjgzMjAwMDAwLERPTUFJTj1zdXJlbHkuY29vbCxVTFRJTUFURT0xLEtFWVZFUlNJT049MQ==');

const app = createApp(App);
app.config.globalProperties.$bus = bus;
app.config.globalProperties.$numeral = numerals;
app.config.globalProperties.$getTon = getTon;

app
  .use(router)
  .use(locale as any)
  .use(store)
  .use(Affix)
  .use(Calendar)
  .use(Layout)
  .use(Menu)
  .use(Row)
  .use(Col)
  .use(Card)
  .use(Form)
  .use(Dropdown)
  .use(Select)
  .use(Button)
  .use(Checkbox)
  .use(Tabs)
  .use(Tag)
  .use(Input)
  .use(Image)
  .use(InputNumber)
  .use(DatePicker)
  .use(TimePicker)
  .use(Radio)
  .use(Tooltip)
  .use(Space)
  .use(Steps)
  .use(Divider)
  .use(Descriptions)
  .use(Alert)
  .use(Result)
  .use(Statistic)
  .use(Popconfirm)
  .use(Popover)
  .use(Table)
  .use(Avatar)
  .use(List)
  .use(Progress)
  .use(Switch)
  .use(Modal)
  .use(Rate)
  .use(ConfigProvider)
  .use(Empty)
  .use(Spin)
  .use(Drawer)
  .use(PageHeader)
  .use(TreeSelect)
  .use(ProProvider)
  .use(Slider)
  .use(FormDesign)
  .use(Badge)
  .use(Carousel)
  .use(BackTop)
  .use(Upload)
  .use(Tree)
  .use(Breadcrumb)
  .use(Transfer)
  .use(Collapse)
  .use(Cascader)
  .use(Timeline)
  .use(SurelyTable as any)
  .component('DragContainer', DragContainer)
  .component('Draggable', Draggable)
  .component(DragIcon.name, DragIcon)
  .component(PageContainer.name, PageContainer)
  .component(Cell_list2.name, Cell_list2)
  .component(PageContainer2.name, PageContainer2)
  .component(PageContainer3.name, PageContainer3)
  .component(ExportExcelByBackEndModal.name, ExportExcelByBackEndModal)
  .component(MenuDrawer.name, MenuDrawer)
  .component(TransformVnode.name, TransformVnode)
  .component(Authority.name, Authority)
  .component(FieldRender.name, FieldRender)
  .component(AttributeTable.name, AttributeTable)
  .component(RollNoModal.name, RollNoModal)
  .component(AttributeTableModal.name, AttributeTableModal)
  .component(ZhaFeiModal.name, ZhaFeiModal)
  .component(StopEventModal.name, StopEventModal)
  .component(SearchRender.name, SearchRender)
  .component(RemoteSelect.name, RemoteSelect)
  .component(RemoteModal.name, RemoteModal)
  .component(PageModel4.name, PageModel4)
  .component(PageModel5.name, PageModel5)
  .component(TableTreeModal.name, TableTreeModal)
  .component(ExportExcelModal.name, ExportExcelModal)
  .component(Cell_index.name, Cell_index)
  .component(Cell_custom.name, Cell_custom)
  .component(Cell_custom1.name, Cell_custom1)
  .component(Cell_datetime.name, Cell_datetime)
  .component(Cell_date.name, Cell_date)
  .component(Cell_date_month.name, Cell_date_month)
  .component(Cell_month.name, Cell_month)
  .component(Cell_time.name, Cell_time)
  .component(Cell_percent.name, Cell_percent)
  .component(Cell_toFixed2.name, Cell_toFixed2)
  .component(Cell_money.name, Cell_money)
  .component(Cell_attributetable.name, Cell_attributetable)
  .component(Cell_click.name, Cell_click)
  .component(Cell_click1.name, Cell_click1)
  .component(Cell_color.name, Cell_color)
  .component(Cell_boolean.name, Cell_boolean)
  .component(Cell_list.name, Cell_list)
  .component(Cell_list1.name, Cell_list1)
  .component(Cell_tag.name, Cell_tag)
  .component(Cell_real_weight.name, Cell_real_weight)
  .component(Cell_weight_offset_percent.name, Cell_weight_offset_percent)
  .component(Cell_collect_status.name, Cell_collect_status)
  .component(Cell_send_robot.name, Cell_send_robot)
  .component(Cell_tag_tag.name, Cell_tag_tag)
  .component(Cell_progress.name, Cell_progress)
  .component(Cell_bind_no.name, Cell_bind_no)
  .component(Cell_action.name, Cell_action)
  .component(Cell_stop_tag.name, Cell_stop_tag)
  .component(Cell_report_tag.name, Cell_report_tag)
  .component(Cell_text.name, Cell_text)
  .component(Cell_obj.name, Cell_obj)
  .component(Cell_bolder.name, Cell_bolder)
  .component(Cell_share_tag.name, Cell_share_tag)
  .component(Cell_script_log.name, Cell_script_log)
  .component(Cell_click_copy.name, Cell_click_copy)
  .component(Cell_link.name, Cell_link)
  .component(Cell_clicklist.name, Cell_clicklist)
  .component(Cell_hui_hua.name, Cell_hui_hua)
  .component(Cell_name.name, Cell_name)
  .component(Cell_talk.name, Cell_talk)
  .component(GroupModal.name, GroupModal)
  .component(AlarmModal.name, AlarmModal)
  .component(UploadImageModal.name, UploadImageModal)
  .component(configureModal.name, configureModal)
  .component(FormOkModal.name, FormOkModal)
  .component(StatementModal.name, StatementModal)
  .component(configurationFileModal.name, configurationFileModal)
  .component(UploadImageKefuModal.name, UploadImageKefuModal)
  .component(SignModal.name, SignModal)
useIcons(app);

app.mount('#app');
