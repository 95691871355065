
import { defineComponent } from 'vue';
import { RouteProps } from '../typing';

export interface SubMenuProps {
  menu: RouteProps;
}

export default defineComponent({
  name: 'SubMenu',
  emits: ['itemHover'],
  props: {
    i18n: {
      type: Function,
      default: (t: any): any => t,
    },
    menu: {
      type: Object,
      required: true,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
});
