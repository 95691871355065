import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, withCtx as _withCtx, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_layout_header = _resolveComponent("a-layout-header")!
  const _component_top_nav_header = _resolveComponent("top-nav-header")!
  const _component_global_header2 = _resolveComponent("global-header2")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.needFixedHeader)
      ? (_openBlock(), _createBlock(_component_a_layout_header, {
          key: 0,
          style: _normalizeStyle({
      height: `${_ctx.headerHeight}px`,
      lineHeight: `${_ctx.headerHeight}px`,
      background: 'transparent',
    })
        }, null, 8, ["style"]))
      : _createCommentVNode("", true),
    _createVNode(_component_a_layout_header, {
      class: _normalizeClass(_ctx.classNames),
      style: _normalizeStyle({
      padding: 0,
      height: `${_ctx.headerHeight}px`,
      lineHeight: `${_ctx.headerHeight}px`,
      width: _ctx.width,
      zIndex: _ctx.isMix ? 99 : 9,
      right: _ctx.right,
    })
    }, {
      default: _withCtx(() => [
        ((_ctx.isTop || _ctx.isMix) && !_ctx.isMobile)
          ? (_openBlock(), _createBlock(_component_top_nav_header, {
              key: 0,
              mode: "horizontal",
              theme: _ctx.theme,
              layout: _ctx.layout,
              menus: _ctx.needShowMenu ? _ctx.computedMenus : [],
              "open-keys": _ctx.openKeys,
              "selected-keys": _ctx.splitMenus ? _ctx.openKeys : _ctx.selectedKeys,
              "onUpdate:openKeys": _ctx.handleOpenKeys,
              "onUpdate:selectedKeys": _ctx.handleSelectedKeys
            }, {
              rightContent: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            }, 8, ["theme", "layout", "menus", "open-keys", "selected-keys", "onUpdate:openKeys", "onUpdate:selectedKeys"]))
          : (_openBlock(), _createBlock(_component_global_header2, {
              key: 1,
              isMobile: _ctx.isMobile,
              layout: _ctx.layout,
              collapsed: _ctx.collapsed,
              "collapsed-button": _ctx.collapsedButton,
              onCollapse: _ctx.handleCollapse
            }, {
              rightContent: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            }, 8, ["isMobile", "layout", "collapsed", "collapsed-button", "onCollapse"]))
      ]),
      _: 3
    }, 8, ["class", "style"])
  ], 64))
}