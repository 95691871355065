import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_left_menu_layout = _resolveComponent("left-menu-layout")!
  const _component_basic_layout = _resolveComponent("basic-layout")!

  return (_ctx.layout === 'left')
    ? (_openBlock(), _createBlock(_component_left_menu_layout, { key: "leftmenu-layout" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }))
    : (_openBlock(), _createBlock(_component_basic_layout, { key: "basic-layout" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      }))
}