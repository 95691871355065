import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_sub_menu = _resolveComponent("sub-menu", true)!
  const _component_a_sub_menu = _resolveComponent("a-sub-menu")!

  return (!_ctx.menu.meta?.hideInMenu)
    ? (_openBlock(), _createBlock(_component_a_sub_menu, {
        key: _ctx.menu.path
      }, {
        icon: _withCtx(() => [
          (_ctx.menu.meta.icon !== undefined)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.menu.meta.icon), {
                key: _ctx.menu.meta.icon
              }))
            : (_ctx.collapsed && _ctx.menu.meta.collapsedIcon !== undefined)
              ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.menu.meta.collapsedIcon), {
                  key: _ctx.menu.meta.collapsedIcon
                }))
              : _createCommentVNode("", true)
        ]),
        title: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.i18n(_ctx.menu.meta.title)), 1)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu.children, (child) => {
            return (_openBlock(), _createElementBlock(_Fragment, null, [
              ((!child.children || child.meta?.hideChildrenInMenu) && !child.meta?.hideInMenu)
                ? (_openBlock(), _createBlock(_component_a_menu_item, {
                    onMouseenter: ($event: any) => (_ctx.$emit('itemHover', { key: child.path })),
                    key: child.path
                  }, {
                    icon: _withCtx(() => [
                      (child.meta.icon !== undefined)
                        ? (_openBlock(), _createBlock(_resolveDynamicComponent(child.meta.icon), {
                            key: child.meta.icon
                          }))
                        : (_ctx.collapsed && child.meta.collapsedIcon !== undefined)
                          ? (_openBlock(), _createBlock(_resolveDynamicComponent(child.meta.collapsedIcon), {
                              key: child.meta.collapsedIcon
                            }))
                          : _createCommentVNode("", true)
                    ]),
                    default: _withCtx(() => [
                      _createTextVNode(" " + _toDisplayString(_ctx.i18n(child.meta.title)), 1)
                    ]),
                    _: 2
                  }, 1032, ["onMouseenter"]))
                : (_openBlock(), _createBlock(_component_sub_menu, {
                    menu: child,
                    key: child.path,
                    i18n: _ctx.i18n,
                    collapsed: _ctx.collapsed
                  }, null, 8, ["menu", "i18n", "collapsed"]))
            ], 64))
          }), 256))
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}