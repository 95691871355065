
import { defineComponent, ref, computed, inject } from 'vue';
import { ReloadOutlined, EllipsisOutlined, CloseOutlined } from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
import { injectMultiTabStore, useMultiTab } from './multi-tab-store';
import { injectMenuState } from '@/layouts/use-menu-state';
import { useRoute } from 'vue-router';

export default defineComponent({
  name: 'MultiTab',
  inheritAttrs: false,
  props: {
    fixed: {
      type: Boolean,
      default: () => false,
    },
    defaultHomePage: {
      type: String,
      default: () => '/dashboard/analysis',
    },
  },
  setup(props) {
    const menuState = injectMenuState();
    const store = injectMultiTabStore();
    const cacheListLength = computed(() => (store ? store.cacheList.length : 0));
    const route = useRoute();
    const activeKey = computed(() => {
      return (
        menuState.selectedKeys &&
        menuState.selectedKeys.value[menuState.selectedKeys.value.length - 1]
      );
    });
    const isMobile = inject('isMobile', ref(false));
    const sideWidth = computed(
      () =>
        (menuState.sideWidth &&
          menuState.sideWidth.value &&
          !isMobile.value &&
          props.fixed &&
          `calc(100% - ${menuState.sideWidth.value}px)`) ||
        '100%',
    );
    const { t } = useI18n();
    const spin = ref(false);
    const [
      { refresh, close, closeLeft, closeRight, closeOther },
    ] = useMultiTab(/*{ defaultHomePage: props.defaultHomePage }*/);

    const handleActiveKeyChange = (key: string) => {
      menuState.selectedKeys!.value = [key];
    };
    const handleReloadPage = async (key?: string) => {
      spin.value = true;
      await refresh(key);
      spin.value = false;
    };

    const handleClose = (e: Event, target: string) => {
      close(target);
      e.stopPropagation();
    };

    return {
      t,
      activeKey,
      handleActiveKeyChange,
      handleReloadPage,
      closeLeft,
      closeRight,
      closeOther,
      handleClose,
      spin,
      sideWidth,
      cacheListLength,
      route,
      store,
    };
  },
  components: {
    ReloadOutlined,
    EllipsisOutlined,
    CloseOutlined,
  },
});
