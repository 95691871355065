import mitt from 'mitt';
import defaultSlotList from '@/components/page-model/slotList';

import {
  update_person_reference_number_by_wechat,
} from '@/api/cerp-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { reactive, ref } from 'vue';
import ls from '@/utils/local-storage';
import {STORAGE_CURRENT_ADVISER_KEY, STORAGE_CURRENT_ORG_KEY} from '@/store/mutation-type';
import bus from "@/utils/bus";
import {useI18n} from "vue-i18n";
export const event_obj = mitt();
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const { t } = useI18n();
export const btn_list = [
  {
    name: t('page.information.update_personal'),
    clazz: 'primary',
    cb: (record: any) => {
      bus.emit('update_personal', {...record});
    },
  },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: t('page.information.New_personal_reference_number'),
    clazz: 'primary',
    cb: () => {
      bus.emit('create_personal');
    },
  },
];
