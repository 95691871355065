import { notification } from 'ant-design-vue';

const base_url = 'http://static.8mu.com.cn/pc/www/index.html';
const urlmap: any = {
  'user/login': `${base_url}/login`,
  '/permission/menu-manage': `${base_url}#/main/menu_manage`,
  '/permission/role-manage': `${base_url}#/main/role_manage`,
  '/permission/auth-manage': `${base_url}#/main/auth_manage`,
  '/permission/user-manage': `${base_url}#/main/user_manage`,
  '/permission/group-manage': `${base_url}#/main/department_manage`,
  '/permission/apiurl-manage/index-1': `${base_url}#/main/apiurl_manage`,
  '/permission/apiurl-manage/index-2': `${base_url}#/main/apiurl_manage`,
  '/parameter_setting/billet_type': `${base_url}#/main/billet_type`,
  '/parameter_setting/steel_type': `${base_url}#/main/steel_type`,
  '/parameter_setting/roller_specification': `${base_url}#/main/roller_specification`,
  '/parameter_setting/roller_shift_manage': `${base_url}#/main/roller_shift_manage`,
  '/parameter_setting/roller_position': `${base_url}#/main/roller_position`,
  '/parameter_setting/roller_material': `${base_url}#/main/roller_material`,
  '/parameter_setting/arrange_roller_shift_record': `${base_url}#/main/arrange_roller_shift_record`,
  '/parameter_setting/production_process_plan': `${base_url}#/main/production_process_plan`,
  '/parameter_setting/roller_diameter_difference': `${base_url}#/main/roller_diameter_difference`,
  '/parameter_setting/word_engraved': `${base_url}#/main/word_engraved`,
  '/parameter_setting/company_manage': `${base_url}#/main/company_manage`,
  '/parameter_setting/new_roller_specification': `${base_url}#/main/new_roller_specification`,
  '/parameter_setting/roller_equipment_batch': `${base_url}#/main/roller_equipment_batch`,
  '/parameter_setting/problem_reason': `${base_url}#/main/problem_reason`,
  '/parameter_setting/user_manage': `${base_url}#/main/user_manage`,
  '/parameter_setting/department_manage': `${base_url}#/main/department_manage`,
  '/parameter_setting/task_tag': `${base_url}#/main/task_tag`,
  '/main/arrange_shift_record': `${base_url}#/main/arrange_shift_record`,
  '/main/other_handover_records': `${base_url}#/main/other_handover_records`,
  '/main/attendance_plan': `${base_url}#/main/attendance_plan`,
  '/main/job_log': `${base_url}#/main/job_log`,
  '/main/production_plan_query': `${base_url}#/main/production_plan_query`,
  '/main/production_plan_marketing': `${base_url}#/main/production_plan_marketing`,
  '/main/production_plan_shift': `${base_url}#/main/production_plan_shift`,
  '/main/production_plan': `${base_url}#/main/production_plan`,
  '/main/roller_device_record': `${base_url}#/main/roller_device_record`,
  '/main/device_all_standard_weight': `${base_url}#/main/device_all_standard_weight`,
  '/main/jian_ren_position_and_spec': `${base_url}#/main/jian_ren_position_and_spec`,
  '/main/jian_ren_position': `${base_url}#/main/jian_ren_position`,
  '/main/jian_ren_manage': `${base_url}#/main/jian_ren_manage`,
  '/main/jian_ren_administrations': `${base_url}#/main/jian_ren_administrations`,
  '/main/jian_ren_material': `${base_url}#/main/jian_ren_material`,
  '/main/jia_song_gun_position_and_spec': `${base_url}#/main/jia_song_gun_position_and_spec`,
  '/main/jia_song_gun_position': `${base_url}#/main/jia_song_gun_position`,
  '/main/jia_song_gun_manage': `${base_url}#/main/jia_song_gun_manage`,
  '/main/jia_song_gun_administrations': `${base_url}#/main/jia_song_gun_administrations`,
  '/stop_event/dispatch-stop-event': `${base_url}#/main/stop_event`,
  '/stop_event/dispatch-stop-event-reason': `${base_url}#/main/stop_event`,
  '/stop_event/dispatch-stop_event-time': `${base_url}#/main/stop_event`,
  '/stop_event/dispatch-stop-event-unit': `${base_url}#/main/stop_event`,
  '/main/dispatch_manage': `${base_url}#/main/dispatch_manage`,
  '/steel_tapping': `${base_url}#/main/steel_tapping`,
  '/main/product_printing_tmline': `${base_url}#/main/product_printing_tmline`,
  '/main/product_printing_manage': `${base_url}#/main/product_printing_manage`,
  '/main/product_printing_tminfo': `${base_url}#/main/product_printing_tminfo`,
  '/main/product_printing_tmarea': `${base_url}#/main/product_printing_tmarea`,
  '/main/product_nameplate_printing': `${base_url}#/main/product_nameplate_printing`,
  '/main/missing_record_item': `${base_url}#/main/missing_record_item`,
  '/main/middle_scrap': `${base_url}#/main/middle_scrap`,
  '/main/products_release': `${base_url}#/main/products_release`,
  '/main/steel_tapping_record': `${base_url}#/main/steel_tapping_record`,
  '/main/billet_consumption': `${base_url}#/main/billet_consumption`,
  '/main/heating_furnace_record': `${base_url}#/main/heating_furnace_record`,
  '/main/receive_record': `${base_url}#/main/receive_record`,
  '/main/charging_record': `${base_url}#/main/charging_record`,
  '/main/discharge_record': `${base_url}#/main/discharge_record`,
  '/main/product_management': `${base_url}#/main/product_management`,
  '/main/planned_batch_production': `${base_url}#/main/planned_batch_production`,
  '/main/shift_production_record': `${base_url}#/main/shift_production_record`,
  '/main/negative_differential_detection': `${base_url}#/main/negative_differential_detection`,
  '/main/longitudinal_detection': `${base_url}#/main/longitudinal_detection`,
  '/main/cross_rib_detection': `${base_url}#/main/cross_rib_detection`,
  '/main/stop_power': `${base_url}#/main/stop_power`,
  '/main/power_config': `${base_url}#/main/power_config`,
  '/main/TV_device_cfg': `${base_url}#/main/TV_device_cfg`,
  '/main/steel_roll_record': `${base_url}#/main/steel_roll_record`,
  '/main/config_rule': `${base_url}#/main/config_rule`,
  '/main/auto_sys': `${base_url}#/main/auto_sys`,
  '/main/sys_plan': `${base_url}#/main/sys_plan`,
  '/main/sys_plan_filter': `${base_url}#/main/sys_plan_filter`,
  '/main/out_config_item': `${base_url}#/main/out_config_item`,
  '/main/test_case': `${base_url}#/main/test_case`,
  '/main/upload_report': `${base_url}#/main/upload_report`,
  '/main/opc_name_list': `${base_url}#/main/opc_name_list`,
  '/main/roller_weight_record_item': `${base_url}#/main/roller_weight_record_item`,
  '/main/return_steel_manage': `${base_url}#/main/return_steel_manage`,
  '/main/roller_manage': `${base_url}#/main/roller_manage`,
  '/main/roller_analysis_statistics': `${base_url}#/main/roller_analysis_statistics`,
  '/main/roller_scrap': `${base_url}#/main/roller_scrap`,
  '/main/rolling_in_progress': `${base_url}#/main/rolling_in_progress`,
  '/main/roller_mend': `${base_url}#/main/roller_mend`,
  '/main/roller_huan_mend': `${base_url}#/main/roller_huan_mend`,
  '/main/mend_number_statistics': `${base_url}#/main/mend_number_statistics`,
  '/main/roller_mend_record': `${base_url}#/main/roller_mend_record`,
  '/main/roller_device_record_all_record': `${base_url}#/main/roller_device_record_all_record`,
  '/main/analyse_roller_quality': `${base_url}#/main/analyse_roller_quality`,
  '/main/shift_rate_analysis': `${base_url}#/main/shift_rate_analysis`,
  '/main/productivity_analysis': `${base_url}#/main/productivity_analysis`,
  '/main/stop_event_chart': `${base_url}#/main/stop_event_chart`,
  '/main/turn_roller_record': `${base_url}#/main/turn_roller_record`,
  '/main/material_stock_chart': `${base_url}#/main/material_stock_chart`,
  '/main/production_gp_used_chart': `${base_url}#/main/production_gp_used_chart`,
  '/main/production_dc_chart': `${base_url}#/main/production_dc_chart`,
  '/main/operating_rate_chart': `${base_url}#/main/operating_rate_chart`,
  '/main/production_gp_performance_chart': `${base_url}#/main/production_gp_performance_chart`,
  '/main/scrap_item_statistics': `${base_url}#/main/scrap_item_statistics`,
  '/main/material_change_report': `${base_url}#/main/material_change_report`,
  '/main/daily_weight_statistics': `${base_url}#/main/daily_weight_statistics`,
  '/main/daily_production_summary': `${base_url}#/main/daily_production_summary`,
  '/main/analyse_hot_charging': `${base_url}#/main/analyse_hot_charging`,
  '/main/event_subscription': `${base_url}#/main/event_subscription`,
  '/main/role_subscribe': `${base_url}#/main/role_subscribe`,
  '/main/production_event': `${base_url}#/main/production_event`,
  '/main/examine_and_approve': `${base_url}#/main/examine_and_approve`,
  '/main/my_task': `${base_url}#/main/my_task`,
  '/main/tracking_task': `${base_url}#/main/tracking_task`,
  '/main/form_manage': `${base_url}#/main/form_manage`,
  '/main/log_query': `${base_url}#/main/log_query`,
  '/main/new_log_query': `${base_url}#/main/new_log_query`,
  '/main/log_server_query': `${base_url}#/main/log_server_query`,
  '/main/sql_query': `${base_url}#/main/sql_query`,
  '/main/device_type': `${base_url}#/main/device_type`,
  '/main/device_manage': `${base_url}#/main/device_manage`,
  '/main/print_scrap_bei_jian_process': `${base_url}#/main/print_scrap_bei_jian_process`,
  '/main/bear_steel_info': `${base_url}#/main/bear_steel_info`,
  '/main/gyj_store_record': `${base_url}#/main/gyj_store_record`,
  '/main/gyj_erp_record': `${base_url}#/main/gyj_erp_record`,
  '/main/gyj_store_record_month': `${base_url}#/main/gyj_store_record_month`,
  '/main/gyj_device_group': `${base_url}#/main/gyj_device_group`,
  '/main/gyj_device_kind': `${base_url}#/main/gyj_device_kind`,
  '/main/gyj_store_address': `${base_url}#/main/gyj_store_address`,
  '/main/gyj_store_record_used': `${base_url}#/main/gyj_store_record_used`,
  '/main/sum_price_by_date': `${base_url}#/main/sum_price_by_date`,
  '/main/alarm_number': `${base_url}#/main/alarm_number`,
  '/main/gyj_yi_yi_record': `${base_url}#/main/gyj_yi_yi_record`,
  '/main/production_list': `${base_url}#/main/production_list`,
  '/main/audio_message_record': `${base_url}#/main/audio_message_record`,
  '/main/sandbox_task': `${base_url}#/main/sandbox_task`,
  '/main/script_name': `${base_url}#/main/script_name`,
  '/main/script_interface': `${base_url}#/main/script_interface`,
  '/main/script_sentence': `${base_url}#/main/script_sentence`,
  '/main/sandbox_drive_task_approval': `${base_url}#/main/sandbox_drive_task_approval`,
  '/main/data_view_record': `${base_url}#/main/data_view_record`,
  '/main/data_analysis_record': `${base_url}#/main/data_analysis_record`,
  '/main/radio_record': `${base_url}#/main/radio_record`,
  '/main/radio_manage': `${base_url}#/main/radio_manage`,
  '/main/webpage_manage': `${base_url}#/main/webpage_manage`,
  '/main/data_view_record_base': `${base_url}#/main/data_view_record_base`,
  '/main/data_analysis_record_base': `${base_url}#/main/data_analysis_record_base`,
  '/main/data_collection_type': `${base_url}#/main/data_collection_type`,
  '/main/design_canvas_plc': `${base_url}#/main/design_canvas_plc`,
  '/main/opc_group': `${base_url}#/main/opc_group`,
  '/main/datascript_collection': `${base_url}#/main/datascript_collection`,
  '/main/docker_tag': `${base_url}#/main/docker_tag`,
  '/main/docker_group': `${base_url}#/main/docker_group`,
  '/main/server': `${base_url}#/main/server`,
  '/main/docker_configure': `${base_url}#/main/docker_configure`,
  '/main/container_configure': `${base_url}#/main/container_configure`,
  '/main/container_list': `${base_url}#/main/container_list`,
  '/main/net_tag': `${base_url}#/main/net_tag`,
  '/main/net_group': `${base_url}#/main/net_group`,
  '/main/network_monitor': `${base_url}#/main/network_monitor`,
  '/main/script_monitor': `${base_url}#/main/script_monitor`,
  '/main/general_tag': `${base_url}#/main/general_tag`,
  '/main/general_group': `${base_url}#/main/general_group`,
  '/main/general_monitor': `${base_url}#/main/general_monitor`,
  '/main/report_form': `${base_url}#/main/report_form`,
  '/main/drawing_chart': `${base_url}#/main/drawing_chart`,
  '/main/dao_wei_position_and_spec': `${base_url}#/main/dao_wei_position_and_spec`,
  '/main/dao_wei_position': `${base_url}#/main/dao_wei_position`,
  '/main/dao_wei_relates_to_roll_position': `${base_url}#/main/dao_wei_relates_to_roll_position`,
  '/main/guide_spare_parts_manage': `${base_url}#/main/guide_spare_parts_manage`,
  '/main/dao_wei_manage': `${base_url}#/main/dao_wei_manage`,
  '/main/dao_wei_administration': `${base_url}#/main/dao_wei_administration`,
  '/main/kpi_tag_list': `${base_url}#/main/kpi_tag_list`,
  '/main/day_cost_accounting': `${base_url}#/main/day_cost_accounting`,
  '/main/batch_download': `${base_url}#/main/batch_download`,
  '/main/month_cost_accounting': `${base_url}#/main/month_cost_accounting`,
  '/main/kpi_group_list': `${base_url}#/main/kpi_group_list`,
  '/main/table_design': `${base_url}#/main/table_design`,
  '/main/erp_kpi': `${base_url}#/main/erp_kpi`,
  '/main/report_form_icon': `${base_url}#/main/report_form_icon`,
  '/main/daily_cost_accounting': `${base_url}#/main/daily_cost_accounting`,
  '/main/entry_record': `${base_url}#/main/entry_record`,
  '/main/help_page': `${base_url}#/main/help_page`,
  '/main/material_stock': `${base_url}#/main/material_stock`,
  '/main/material_detail': `${base_url}#/main/material_detail`,
  '/main/material_stock_list': `${base_url}#/main/material_stock_list`,
  '/main/finish_goods_inventory': `${base_url}#/main/finish_goods_inventory`,
  '/main/finish_inventory': `${base_url}#/main/finish_inventory`,
  '/main/finish_goods_inventory_used': `${base_url}#/main/finish_goods_inventory_used`,
  '/main/delivery_overview': `${base_url}#/main/delivery_overview`,
  '/main/storage_manage': `${base_url}#/main/storage_manage`,
  '/main/storage_info': `${base_url}#/main/storage_info`,
  '/main/stack_info_manage': `${base_url}#/main/stack_info_manage`,
  '/main/stock_pan': `${base_url}#/main/stock_pan`,
  '/main/pan_comparison': `${base_url}#/main/pan_comparison`,
  '/main/storage_print_scutcheon': `${base_url}#/main/storage_print_scutcheon`,
  '/main/storage_field_scan_sum': `${base_url}#/main/storage_field_scan_sum`,
  '/main/storage_comparison': `${base_url}#/main/storage_comparison`,
  '/main/signs_repeat': `${base_url}#/main/signs_repeat`,
  '/main/car_info': `${base_url}#/main/car_info`,
  '/main/car_time_node': `${base_url}#/main/car_time_node`,
  '/main/no_show_car': `${base_url}#/main/no_show_car`,
  '/main/bind_nos_info': `${base_url}#/main/bind_nos_info`,
  '/main/dingding_news': `${base_url}#/main/dingding_news`,
  '/main/dingding_production_event_binding': `${base_url}#/main/dingding_production_event_binding`,
  '/main/problem_list': `${base_url}#/main/problem_list`,
  '/main/develop': `${base_url}#/main/develop`,
  '/main/upload_version': `${base_url}#/main/upload_version`,
  '/main/upload_json': `${base_url}#/main/upload_json`,
  '/main/upload_form_and_task_template': `${base_url}#/main/upload_form_and_task_template`,
  '/main/upload_help_document': `${base_url}#/main/upload_help_document`,
  '/main/all_nsfile_list': `${base_url}#/main/all_nsfile_list`,
  '/main/third_table_sanzha_mizhong': `${base_url}#/main/third_table_sanzha_mizhong`,
  '/main/third_table_sanzha_mizhong_fujian': `${base_url}#/main/third_table_sanzha_mizhong_fujian`,
  '/main/third_table_erzha_mizhong': `${base_url}#/main/third_table_erzha_mizhong`,
  '/main/third_table_yizha_kunzhong_fujian': `${base_url}#/main/third_table_yizha_kunzhong_fujian`,
  '/main/third_table_erzha_kunzhong_fujian': `${base_url}#/main/third_table_erzha_kunzhong_fujian`,
  '/main/third_table_sanzha_mizhong_fenxi': `${base_url}#/main/third_table_sanzha_mizhong_fenxi`,
  '/main/third_table_xiaobang_kunzhong_fujian1': `${base_url}#/main/third_table_xiaobang_kunzhong_fujian1`,
  '/main/third_table_xiaobang_kunzhong_fujian2': `${base_url}#/main/third_table_xiaobang_kunzhong_fujian2`,
  '/main/third_table_gaobang_kunzhong_fujian1': `${base_url}#/main/third_table_gaobang_kunzhong_fujian1`,
  '/main/third_table_gaobang_kunzhong_fujian2': `${base_url}#/main/third_table_gaobang_kunzhong_fujian2`,
};

const isGotoOldRouter = (path: string) => {
  if (urlmap[path]) {
    window.location.href = urlmap[path];
  } else {
    notification.error({
      message: '返回失败',
      description: '无对应旧版界面',
    });
  }
};
export default isGotoOldRouter;
