import { isNumber } from 'lodash-es';

const numerals = {
  money: (num: any) => {
    // 金额过滤器
    if (isNumber(num)) {
      let str = num.toString();
      if (str.indexOf('.') == -1) {
        str += '.00';
      }
      return str.replace(/\B(?=(\d{3})+\.)/g, ',');
    }
  },
};

export default numerals;
