
import {computed, createVNode, defineComponent, h, onMounted, reactive, ref, watch} from 'vue';

import { useStore } from 'vuex';
import modalBox from '@/components/form-modal/modal-tools';
import {Button, message, Modal, notification} from 'ant-design-vue';
import { query_stop_event_list } from '@/api/dispatch-model';

import { SearchConfig } from '@/components/page-model/typing';
import moment from 'moment';
import {
  query_rolled_machine_group_list,
  query_rolled_machine_group_record_list,
} from '@/api/roll-model';
import {delete_back_order} from "@/api/jms-model";
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
import {getObjList} from "@/utils/function";
import {update_production_record_items_conclusion_status} from "@/api/production_manage-model";
import ls from "@/utils/local-storage";
import {STORAGE_CURRENT_ORG_KEY} from "@/store/mutation-type";
import { get_plan_excel_import_setting_by_flag, set_plan_excel_import_setting_by_flag } from '@/api/sys-model';

export default defineComponent({
  name: 'configurationFileModal',
  props: {
    json: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
  },
  components: {},
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const visible = ref<boolean>(false);
    const title = ref<string>('配置文件');
    const activeKey = ref<string>('关键点信号（轧机负荷）');
    const hot_opc_info_num = ref<number>(3);
    const tableHeight = reactive({ y: window.innerHeight - 220, x: window.innerWidth-100 });
    onMounted(() => {
      window.onresize = function () {
        tableHeight.y = window.innerHeight - 220;
        tableHeight.x = window.innerWidth - 100;
      };
    });
    const slotList: any = [
      {
        slotName: 'index', // 槽名
      },
      {
        type: 'list',
        slotName: 'relative_opc_name_end_list',
      },
      {
        type: 'list',
        slotName: 'relative_opc_name_start_list',
      },
    ]
    const baseColumns1: any = [{
      title: '序号',
      dataIndex: 'index',
      width: 50,
      checked: true,
    },
      {
        title: '标记',
        width: 150,
        dataIndex: 'flag',
        checked: true,
      },
      {
        title: '当前状态到下一状态时间差(ms)',
        width: 150,
        dataIndex: 'time_long1',
        checked: true,
      },
      // {
      //   title: '上一状态到当前状态时间差(ms)',
      //   width: 150,
      //   dataIndex: 'time_long2',
      //   checked: true,
      // },
      {
        title: '操作',
        width: 150,
        dataIndex: 'action',
        checked: true,
        slots: { customRender: 'action' },
      },
    ]
    const baseColumns2: any = [
      {
        title: '序号',
        dataIndex: 'index',
        width: 50,
        checked: true,
      },
      {
        title: '名称',
        width: 150,
        dataIndex: 'name',
        checked: true,
      },
      {
        title: '值',
        width: 150,
        dataIndex: 'value',
        checked: true,
      },
      {
        title: '操作',
        width: 150,
        dataIndex: 'action',
        checked: true,
        slots: { customRender: 'action' },
      },
    ]
    const baseColumns3: any = [
      {
        title: '序号',
        dataIndex: 'index1',
        width: 50,
        checked: true,
      },
      {
        title: '名称',
        width: 150,
        dataIndex: 'name',
        checked: true,
      },
      {
        title: '标记',
        width: 180,
        dataIndex: 'flag',
        checked: true,
      },
      {
        title: '开始位置及时间差(s)',
        width: 150,
        dataIndex: 'relative_opc_name_start_list',
        checked: true,
        slots: { customRender: 'relative_opc_name_start_list' },
      },
      {
        title: '结束位置及时间差(s)',
        width: 150,
        dataIndex: 'relative_opc_name_end_list',
        checked: true,
        slots: { customRender: 'relative_opc_name_end_list' },
      },
      {
        title: '数据值',
        width: 60,
        dataIndex: 'value_type',
        checked: true,
      },
      {
        title: '数据值1',
        width: 60,
        dataIndex: 'value_type1',
        checked: true,
      },
      {
        title: '次序',
        width: 60,
        dataIndex: 'index',
        checked: true,
      },
      {
        title: '最小值',
        width: 60,
        dataIndex: 'min',
        checked: true,
      },
      {
        title: '操作',
        width: 150,
        dataIndex: 'action',
        checked: true,
        slots: { customRender: 'action' },
      },
    ]
    const configuration_files: any = { ...props.json }

    const dataSource1: any = ref([]);
    const dataSource3: any = ref([]);
    configuration_files.opc_xz_list.map((item: any, index: number) => {
      let obj: any = {};
      obj.index = index + 1;
      obj.flag = item[0];
      obj.time_long1 = item[1];
      obj.time_long2 = item[2];
      dataSource1.value.push(obj);
    })
    configuration_files.head_list.map((item: any, index: number) => {
      dataSource3.value.push(item);
    })
    dataSource3.value.map((item1: any, index: number) => {
      item1.index1 = index + 1;
      configuration_files.opc_info.map((item: any) => {
        if(item.opc_name == item1.flag){
          item1.index = item?.index;
          if(item?.min || item?.min == 0){
            item1.min = item?.min;
          }
          item1.value_type1 = item.value_type;
          item1.eq_opc_name = item.eq_opc_name;
          if(item?.relative_opc_name_start_list && item?.relative_opc_name_start_list.length > 0){
            item1.relative_opc_name_start_list = item?.relative_opc_name_start_list;
            item1.relative_opc_name_end_list = item?.relative_opc_name_end_list;
          }else if(item?.relative_opc_name_end){
            item1.relative_opc_name_end_list = [{
              seconds: item?.seconds_end,
              opc_name: item?.relative_opc_name_end,
            }]
            item1.relative_opc_name_start_list = [{
              seconds: item?.seconds_start,
              opc_name: item?.relative_opc_name_start,
            }]
          }
        }
      })
    })
    console.log('123', dataSource3.value)
    console.log('323', configuration_files.opc_info)
    const dataSource2: any = ref([
      {
        index: 1,
        flag: 'lu_1_temperature',
        name: '1段点位名称',
        value: '',
      },
      {
        index: 2,
        flag: 'lu_1_temperature_qian_num',
        name: '1段温度点前步进数',
        value: null,
      },
      {
        index: 3,
        flag: 'lu_1_temperature_hou_num',
        name: '1段温度点后步进数',
        value: null,
      },
      {
        index: 4,
        flag: 'lu_1_temperature_min_seconds',
        name: '1段入炉口到该点位最短时间（s）',
        value: null,
      },
      {
        index: 5,
        flag: 'lu_2_temperature',
        name: '2段点位名称',
        value: '',
      },
      {
        index: 6,
        flag: 'lu_2_temperature_qian_num',
        name: '2段温度点前步进数',
        value: null,
      },
      {
        index: 7,
        flag: 'lu_2_temperature_hou_num',
        name: '2段温度点后步进数',
        value: null,
      },
      {
        index: 8,
        flag: 'lu_2_temperature_min_seconds',
        name: '2段入炉口到该点位最短时间（s）',
        value: null,
      },
      {
        index: 9,
        flag: 'lu_3_temperature',
        name: '3段点位名称',
        value: '',
      },
      {
        index: 10,
        flag: 'lu_3_temperature_qian_num',
        name: '3段温度点前步进数',
        value: null,
      },
      {
        index: 11,
        flag: 'lu_3_temperature_hou_num',
        name: '3段温度点后步进数',
        value: null,
      },
      {
        index: 12,
        flag: 'lu_3_temperature_min_seconds',
        name: '3段入炉口到该点位最短时间（s）',
        value: null,
      },
    ]);
    if(configuration_files.hot_opc_info.length/4 > 3){
      hot_opc_info_num.value = 4;
      dataSource2.value.push(
        {
          index: 13,
          flag: 'lu_4_temperature',
          name: '4段点位名称',
          value: '',
        },
        {
          index: 14,
          flag: 'lu_4_temperature_qian_num',
          name: '4段温度点前步进数',
          value: null,
        },
        {
          index: 15,
          flag: 'lu_4_temperature_hou_num',
          name: '4段温度点后步进数',
          value: null,
        },
        {
          index: 16,
          flag: 'lu_4_temperature_min_seconds',
          name: '4段入炉口到该点位最短时间（s）',
          value: null,
        },
      )
    }
    dataSource2.value.map((item: any) => {
      item.value = configuration_files.hot_opc_info[item.flag];
    })
    const tableList = reactive([
      {
        title: '关键点信号（轧机负荷）',
        baseColumns: baseColumns1,
        dataSource: dataSource1.value,
      },
      {
        title: '轧线物料追踪配置',
        baseColumns: baseColumns3,
        dataSource: dataSource3.value,
      },
      {
        title: '加热炉物料追踪配置',
        baseColumns: baseColumns2,
        dataSource: dataSource2.value,
      },
    ]);
    watch(
      ()=> hot_opc_info_num.value,
      () => {
        if(hot_opc_info_num.value == 4){
          dataSource2.value.push(
            {
              index: 13,
              flag: 'lu_4_temperature',
              name: '4段点位名称',
              value: '',
            },
            {
              index: 14,
              flag: 'lu_4_temperature_qian_num',
              name: '4段温度点前步进数',
              value: null,
            },
            {
              index: 15,
              flag: 'lu_4_temperature_hou_num',
              name: '4段温度点后步进数',
              value: null,
            },
            {
              index: 16,
              flag: 'lu_4_temperature_min_seconds',
              name: '4段入炉口到该点位最短时间（s）',
              value: null,
            },
          )
        }
        if(hot_opc_info_num.value == 3){
          dataSource2.value.reverse();
          dataSource2.value.splice(0,4);
          dataSource2.value.reverse();
        }
      }
    )


    const handleSubmit = () => {
      const opc_list: any = [];
      const opc_xz_list: any = [];
      const hot_opc_info: any = {};
      const head_list: any = [];
      const opc_info: any = [];
      dataSource1.value.map((item: any) => {
        opc_list.push(item.flag);
        opc_xz_list.push([item.flag, item.time_long1, item.time_long2]);
      })
      dataSource2.value.map((item: any) => {
        if(item.value.length < 6){
          hot_opc_info[item.flag] = Number(item.value);
        }else{
          hot_opc_info[item.flag] = item.value;
        }
      })
      dataSource3.value.map((item: any) => {
        head_list.push({
          flag: item.flag,
          value_type: item.value_type,
          name: item.name,
        })
        if(item.relative_opc_name_start_list && item.relative_opc_name_start_list.length > 0){
          let obj: any = {};
          obj.opc_name = item.flag;
          obj.index = item.index;
          obj.relative_opc_name_start_list = item.relative_opc_name_start_list;
          obj.relative_opc_name_end_list = item.relative_opc_name_end_list;
          obj.value_type = item.value_type1;
          obj.eq_opc_name = item.eq_opc_name;
          if(item.min){
            obj.min = item.min;
          }
          opc_info.push(obj)
        }
      })
      configuration_files.hot_opc_info = hot_opc_info;
      configuration_files.opc_list = opc_list;
      configuration_files.opc_xz_list = opc_xz_list;
      configuration_files.head_list = head_list;
      configuration_files.opc_info = opc_info;
      console.log('123', configuration_files);
      const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
      const editModal = reactive({
        visible: true,
        title: '保存备份',
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'select',
              name: 'num',
              label: '选择备份',
              mode: 'default',
              disabled: false,
              defaultValue: '',
              labelKey: 'name',
              valueKey: 'value',
              placeholder: '请选择备份',
              datasource: 'num',
            },
          ],
          rules: reactive({
            num: [{ required: true, message: '请选择备份' }],
          }),
          model: reactive({
            org_id: current_org?.id,
            num: null,
          }),
        },
        options: {
          num: [
            { value: 1, name: '备份1' },
            { value: 2, name: '备份2' },
            { value: 3, name: '备份3' },
            { value: 4, name: '备份4' },
            { value: 5, name: '备份5' },
          ],
        },
        ok: (payload: any) => {
          return new Promise(resolve => {
            set_plan_excel_import_setting_by_flag({
              org_id: current_org?.id,
              flag: "product_record_item_" + current_org?.id + "_" + payload.num,
              data: JSON.stringify(configuration_files),
            })
              .then(() => {
                message.success('操作成功');
                emit('ok', payload.num);
                resolve(null);
              })
              .finally(() => {
              });
          });
        },
      });
      modalBox(editModal);
    }
    const handleCreate = () => {
      const editModal = reactive({
        visible: true,
        loading: false,
        title: '新建关键点信息',
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'flag',
              label: '标记',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写标记',
            },
            {
              type: 'number',
              name: 'time_long1',
              label: '当前状态到下一状态时间差(ms)',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写当前状态到下一状态时间差(ms)',
            },
          ],
          rules: reactive({}),
          model: reactive({
            flag: null,
            time_long1: 0,
            time_long2: 0,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            if(!payload.flag){
              notification.error({
                message: '错误',
                description: '标记必须填入数据',
              });
              return;
            }
            if(!payload.time_long1){
              payload.time_long1 = 0;
            }
            if(!payload.time_long2){
              payload.time_long2 = 0;
            }
            dataSource1.value.push({
              index: dataSource1.value.length + 1,
              ...payload,
            })
            resolve(null);
            message.success('新建成功');
          });
        },
      });
      modalBox(editModal);
    }
    const handleDown = (record: any, index: any) => {
      const num = record.index;
      record.index = dataSource1.value[index + 1].index;
      dataSource1.value[index + 1].index = num;
      dataSource1.value.sort((a: any, b: any) => {
        return a.index-b.index;
      })
    }
    const handleDown1 = (record: any, index: any) => {
      const num = record.index1;
      record.index1 = dataSource3.value[index + 1].index1;
      dataSource3.value[index + 1].index1 = num;
      dataSource3.value.sort((a: any, b: any) => {
        return a.index1-b.index1;
      })
    }
    const handleUp = (record: any, index: any) => {
      const num = record.index;
      record.index = dataSource1.value[index - 1].index;
      dataSource1.value[index - 1].index = num;
      dataSource1.value.sort((a: any, b: any) => {
        return a.index-b.index;
      })
    }
    const handleUp1 = (record: any, index: any) => {
      const num = record.index1;
      record.index1 = dataSource3.value[index - 1].index1;
      dataSource3.value[index - 1].index1 = num;
      dataSource3.value.sort((a: any, b: any) => {
        return a.index1-b.index1;
      })
    }
    const handleDelete = (record: any, index: any) => {
      Modal.confirm({
        title: '是否删除 ' + record.flag +' ?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          dataSource1.value.splice(index, 1);
          dataSource1.value.sort((a: any, b: any) => {
            return a.index-b.index;
          })
          dataSource1.value.map((item: any, index: any) => {
            item.index = index + 1;
          })
        },
        onCancel() {},
      });
    }
    const handleDelete1 = (record: any, index: any) => {
      Modal.confirm({
        title: '是否删除 ' + record.flag +' ?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          dataSource3.value.splice(index, 1);
          dataSource3.value.sort((a: any, b: any) => {
            return a.index1-b.index1;
          })
          dataSource3.value.map((item: any, index: any) => {
            item.index1 = index + 1;
          })
        },
        onCancel() {},
      });
    }
    const handleCreate1 = () => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'configureModal',
        title: '新建轧线物料追踪配置',
        type: '',
        obj: {},
        opc_list: configuration_files.opc_list,
      });
      modifyModal.visible = true;
      modifyModal.ok = (data: any) => {
        dataSource3.value.push({...data, index1: dataSource3.value.length + 1});
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    }
    const handleUpdate2 = (record: any) => {
      const modifyModal: any = reactive({
        visible: false,
        component: 'configureModal',
        title: '修改轧线物料追踪配置',
        type: 'update',
        obj: {
          ...record,
        },
        opc_list: configuration_files.opc_list,
      });
      modifyModal.visible = true;
      modifyModal.ok = (data: any) => {
        Object.assign(record, data);
      };
      modifyModal.cancel = () => {};
      modalBox(modifyModal);
    }
    const handleUpdate1 = (record: any) => {
      const editModal = reactive({
        visible: true,
        loading: false,
        title: '修改加热物料追踪配置',
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'name',
              label: '名称',
              disabled: true,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
            },
            {
              type: 'input',
              name: 'value',
              label: '值',
              disabled: false,
              allowClear: true,
              inputType: 'input',
              defaultValue: '',
              placeholder: '请填写值',
            },
          ],
          rules: reactive({}),
          model: reactive({
            index: record.index,
            name: record.name,
            value: record.value,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            if(!payload.value){
              notification.error({
                message: '错误',
                description: '值必须填入数据',
              });
              return;
            }
            dataSource2.value.map((item: any) => {
              if(item.index == payload.index){
                Object.assign(item, payload);
              }
            })
            resolve(null);
            message.success('修改成功');
          });
        },
      });
      modalBox(editModal);
    }
    const handleUpdate = (record: any) => {
      const editModal = reactive({
        visible: true,
        loading: false,
        title: '修改关键点信息',
        form: {
          settings: {
            labelAlign: 'right',
            layout: 'vertical',
            col: [],
          },
          fields: [
            {
              type: 'input',
              name: 'flag',
              label: '标记',
              disabled: false,
              allowClear: true,
              inputType: 'text',
              defaultValue: '',
              placeholder: '请填写标记',
            },
            {
              type: 'number',
              name: 'time_long1',
              label: '当前状态到下一状态时间差(ms)',
              disabled: false,
              allowClear: true,
              inputType: 'number',
              defaultValue: '',
              placeholder: '请填写当前状态到下一状态时间差(ms)',
            },
          ],
          rules: reactive({}),
          model: reactive({
            index: record.index,
            flag: record.flag,
            time_long1: record.time_long1,
            time_long2: record.time_long2,
          }),
        },
        options: {},
        ok: (payload: any) => {
          return new Promise(resolve => {
            if(!payload.flag){
              notification.error({
                message: '错误',
                description: '标记必须填入数据',
              });
              return;
            }
            if(!payload.time_long1){
              payload.time_long1 = 0;
            }
            if(!payload.time_long2){
              payload.time_long2 = 0;
            }
            dataSource1.value.map((item: any) => {
              if(item.index == payload.index){
                Object.assign(item, payload);
              }
            })
            dataSource1.value.map((item: any, index: any) => {
              if(index == 0) {
                item.time_long2 = 0;
              }else{
                item.time_long2 = dataSource1.value[index - 1].time_long1;
              }
            })
            resolve(null);
            message.success('修改成功');
          });
        },
      });
      modalBox(editModal);
    }
    return {
      handleSubmit,
      handleDown,
      handleDown1,
      handleUp,
      handleUp1,
      handleCreate,
      handleCreate1,
      handleUpdate,
      handleUpdate1,
      handleUpdate2,
      handleDelete,
      handleDelete1,
      visible,
      hot_opc_info_num,
      slotList,
      tableHeight,
      activeKey,
      tableList,
      props,
      title,
      // init,
    };
  },
});
