
import { defineComponent, onMounted, ref } from 'vue';
import { get_upload_files_url, upload_file } from '@/api/nsbcs-modal';
import { message } from 'ant-design-vue';
import {useI18n} from "vue-i18n";


export default defineComponent({
  name: 'SignModal',
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const {t} = useI18n();
    const eleCan: any = ref<any>();

    const init = ()=>{
      var canvas = eleCan.value;
      canvas.width = 400;
      canvas.height = 300;
      // var canvasRect = ;
      var ctx = canvas.getContext('2d');
      let flag = true;
      ctx.lineWidth = 4.0;
      // ctx.strokeStyle='#FF5A44';
      canvas.addEventListener('touchstart', (e:any) => {
        console.log('开始触摸！');
        let touch = e.targetTouches[0];
        // let screen_x = e.targetTouches[0].clientX;
        // let screen_y = e.targetTouches[0].clientY;
        console.log(e);
        ctx.beginPath();
        ctx.moveTo(touch.clientX - canvas.getBoundingClientRect().left, touch.clientY - canvas.getBoundingClientRect().top);
        // ctx.moveTo(touch.offsetX, touch.offsetY);
        canvas.addEventListener('touchmove', (e:any) => {
          console.log('开始移动');
          console.log(e);
          if (e.targetTouches.length === 1) {
            let touch = e.targetTouches[0];
            //现在的坐标减去原来的坐标
            ctx.lineTo(touch.clientX - canvas.getBoundingClientRect().left, touch.clientY - canvas.getBoundingClientRect().top);
            // ctx.lineTo(touch.offsetX, touch.offsetY);
            ctx.stroke();
          }

        }, false);
        canvas.addEventListener('touchend', (e:any) => {
          ctx.closePath();
          console.log('我没了');
        }, false);
      }, false);
      canvas.addEventListener('mousedown', (e:any) => {
        console.log('鼠标点击啦！');
        console.log(e);
        flag = false;
        ctx.beginPath();
        ctx.moveTo(e.clientX - canvas.getBoundingClientRect().left, e.clientY - canvas.getBoundingClientRect().top);
        // ctx.moveTo(e.offsetX, e.offsetY);


      }, false);
      canvas.addEventListener('mousemove', (e:any) => {
        if (flag) {
          return false;
        }
        console.log('我动啦');
        ctx.lineTo(e.clientX - canvas.getBoundingClientRect().left, e.clientY - canvas.getBoundingClientRect().top);
        // ctx.lineTo(e.offsetX, e.offsetY);
        ctx.stroke();
      }, true);
      canvas.addEventListener('mouseup', (e:any) => {
        console.log('我没了');
        flag = true;
        e.stopPropagation();
        ctx.closePath();
      }, false);
      canvas.addEventListener('mouseout', (e:any) => {
        console.log('我没了');
        flag = true;
        e.stopPropagation();
        ctx.closePath();
      }, false);
    };

    onMounted(() => {
      setTimeout(init, 200);
    });
    const  dataURLtoFile = (dataurl:any) =>{
      var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], 'sign.png', { type:mime });
    };

    const handleOK = ()=>{
      get_upload_files_url({
        access_type: 'public',
        filetype: 'png',
        filename: 'sing.png',
        group: 'sys',
        author: 0,
      }).then((res: any) => {
        let formData = new FormData();
        var imgData =eleCan.value.toDataURL({format: 'png', quality:1, width:eleCan.value.width, height:eleCan.value.height});
        var file = dataURLtoFile(imgData);

        formData.append('file', file);
        formData.append('file_id', res.params.file_id);
        formData.append('key', res.params.key);
        upload_file(formData, {

        }).then((res1: any) => {
          console.log('res1', res1);
          emit('ok', { fileid: res.params.file_id });
          message.success(t('page.Induction.Signature_completed'));
        });
      });
    };

    return {
      t,
      eleCan,
      handleOK,
    };
  },

  components: {},
});
