import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"border-radius":"2px","height":"40px","width":"40px","background-color":"#0089FF"}
}
const _hoisted_2 = { style: {"padding-left":"4px","padding-top":"2px"} }
const _hoisted_3 = { style: {"color":"#ebedf0","font-size":"10px"} }
const _hoisted_4 = { style: {"height":"30px","width":"40px","position":"relative","top":"-3px"} }
const _hoisted_5 = { style: {"font-size":"22px","padding-bottom":"4px","padding-right":"4px","float":"right","color":"#ebedf0"} }
const _hoisted_6 = {
  key: 1,
  style: {"border-radius":"2px","height":"40px","width":"40px","background-color":"#999999"}
}
const _hoisted_7 = { style: {"padding-left":"4px","padding-top":"2px"} }
const _hoisted_8 = { style: {"color":"#ebedf0","font-size":"10px"} }
const _hoisted_9 = { style: {"height":"30px","width":"40px","position":"relative","top":"-3px"} }
const _hoisted_10 = { style: {"font-size":"22px","padding-bottom":"4px","padding-right":"4px","float":"right","color":"#ebedf0"} }
const _hoisted_11 = { style: {"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap","width":"200px","user-select":"text"} }
const _hoisted_12 = { style: {"color":"#999999","font-size":"12px","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap","width":"200px","margin-top":"3px","user-select":"text"} }
const _hoisted_13 = { style: {"color":"#999999","font-size":"12px","float":"right","margin-right":"3px"} }
const _hoisted_14 = { style: {"color":"#999999","font-size":"12px","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap","width":"95px","margin-top":"5px","float":"right","margin-right":"3px","user-select":"text"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createBlock(_component_a_row, {
    gutter: 24,
    type: "flex"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_col, { span: 3 }, {
        default: _withCtx(() => [
          _createVNode(_component_a_tooltip, null, _createSlots({
            default: _withCtx(() => [
              _createVNode(_component_a_badge, {
                count: _ctx.record.no_read_num,
                style: {"margin-top":"3px"}
              }, {
                default: _withCtx(() => [
                  (!_ctx.record.is_closed)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                        _createElementVNode("div", _hoisted_2, [
                          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.getMonth_no_year(_ctx.record.create_time)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_4, [
                          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.getDay_no_year(_ctx.record.create_time)), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_ctx.record.is_closed)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.getMonth_no_year(_ctx.record.create_time)), 1)
                        ]),
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.getDay_no_year(_ctx.record.create_time)), 1)
                        ])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["count"])
            ]),
            _: 2
          }, [
            (_ctx.record.service__name)
              ? {
                  name: "title",
                  fn: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.record.service__name ? _ctx.record.service__name : _ctx.t('page.kefu.name')), 1)
                  ])
                }
              : undefined
          ]), 1024)
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, {
        span: 12,
        style: {"width":"100%"}
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.record.name), 1),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.record.message_type == 1 ? _ctx.t('page.kefu.picture') : _ctx.record.content), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_col, { span: 9 }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.getDateTime(_ctx.record.update_time)), 1),
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.record.service__name), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}