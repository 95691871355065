import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_progress = _resolveComponent("a-progress")!

  return (_openBlock(), _createBlock(_component_a_progress, {
    strokeColor: '#87d068',
    strokeWidth: 15,
    percent: _ctx.column.content(_ctx.record)
  }, null, 8, ["percent"]))
}