
import { defineComponent } from 'vue';
import BasicLayout from './basic-layout.vue';
import LeftMenuLayout from './leftmenu-layout.vue';
import { injectMenuState } from './use-menu-state';

export default defineComponent({
  name: 'GlobalLayout',
  setup() {
    return {
      ...injectMenuState(),
    };
  },
  components: {
    BasicLayout,
    LeftMenuLayout,
  },
});
