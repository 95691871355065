import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function up_arrange_shift(params: Record<string, any>, config?: any) {
  return request.post('/shift/up_arrange_shift', params, config);
}

export async function query_roller_relation_org_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_roller_relation_org_list', params);
}

export async function permission_export(params: Record<string, any>) {
  return request.post('/org/permission_export', params);
}

export async function permission_inport(params: Record<string, any>) {
  return request.post('/org/permission_inport', params);
}

export async function get_shift_and_user_by_person_id(params: Record<string, any>) {
  return request.post('/shift/get_shift_and_user_by_person_id', params);
}

export async function query_org_for_login_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_org_for_login_list', params);
}

export async function query_my_rel_org_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_my_rel_org_list', params);
}

export async function create_attend_group(params: Record<string, any>) {
  return request.post('/shift/create_attend_group', params);
}

export async function create_role_and_person(params: Record<string, any>) {
  return request.post('/org/create_role_and_person', params);
}

export async function query_line_arrange_shift_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/shift/query_line_arrange_shift_list', params);
}

export async function create_arrange_shift_job_log(params: Record<string, any>) {
  return request.post('/org/create_arrange_shift_job_log', params);
}

export async function remove_person_group(params: Record<string, any>) {
  return request.post('/org/remove_person_group', params);
}

export async function query_all_member_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_all_member_list', params);
}

export async function get_shift_by_now(params: Record<string, any>) {
  return request.post('/shift/get_shift_by_now', params);
}

export async function create_arrange_shift_plan(params: Record<string, any>) {
  return request.post('/org/create_arrange_shift_plan', params);
}

export async function get_attend_group(params: Record<string, any>) {
  return request.post('/shift/get_attend_group', params);
}

export async function query_work_org_by_zhagun_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_work_org_by_zhagun_list', params);
}

export async function add_charge_group(params: Record<string, any>) {
  return request.post('/org/add_charge_group', params);
}

export async function update_organization(params: Record<string, any>) {
  return request.post('/org/update_organization', params);
}

export async function create_role_list_and_person(params: Record<string, any>) {
  return request.post('/org/create_role_list_and_person', params);
}

export async function add_appinfo(params: Record<string, any>) {
  return request.post('/org/add_appinfo', params);
}

export async function create_periodic_attendgroup_plan(params: Record<string, any>) {
  return request.post('/org/create_periodic_attendgroup_plan', params);
}

export async function query_work_org_by_zhaxian_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_work_org_by_zhaxian_list', params);
}

export async function remove_charge_group(params: Record<string, any>) {
  return request.post('/org/remove_charge_group', params);
}

export async function query_my_relate_org_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_my_relate_org_list', params);
}

export async function make_appinfo_permission(params: Record<string, any>) {
  return request.post('/org/make_appinfo_permission', params);
}

export async function query_shift_time_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/shift/query_shift_time_list', params);
}

export async function delete_attend_group(params: Record<string, any>) {
  return request.post('/shift/delete_attend_group', params);
}

export async function create_roleapply_by_new_user(params: Record<string, any>) {
  return request.post('/org/create_roleapply_by_new_user', params);
}

export async function delete_company(params: Record<string, any>) {
  return request.post('/org/delete_company', params);
}

export async function create_shift(params: Record<string, any>) {
  return request.post('/shift/create_shift', params);
}

export async function create_org_headicon(params: Record<string, any>) {
  return request.post('/org/create_org_headicon', params);
}

export async function get_org_or_group_contacts(params: Record<string, any>) {
  return request.post('/org/get_org_or_group_contacts', params);
}

export async function check_shift_and_user(params: Record<string, any>) {
  return request.post('/shift/check_shift_and_user', params);
}

export async function get_person_info(params: Record<string, any>) {
  return request.post('/org/get_person_info', params);
}

export async function automatic_change_shift_line(params: Record<string, any>) {
  return request.post('/shift/automatic_change_shift_line', params);
}

export async function query_not_used_appinfo_by_org_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_not_used_appinfo_by_org_list', params);
}

export async function query_page_api(params: Record<string, any>) {
  return request.post('/org/query_page_api', params);
}

export async function cancel_role_apply(params: Record<string, any>) {
  return request.post('/org/cancel_role_apply', params);
}

export async function query_all_member_name_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_all_member_name_list', params);
}

export async function remove_manager_org(params: Record<string, any>) {
  return request.post('/org/remove_manager_org', params);
}

export async function qrcode_join_org_string(params: Record<string, any>) {
  return request.post('/org/qrcode_join_org_string', params);
}

export async function modefy_contact_nickname(params: Record<string, any>) {
  return request.post('/org/modefy_contact_nickname', params);
}

export async function query_company_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_company_list', params);
}

export async function make_user_appflag_role(params: Record<string, any>) {
  return request.post('/org/make_user_appflag_role', params);
}

export async function get_arrange_shift_job_log(params: Record<string, any>) {
  return request.post('/org/get_arrange_shift_job_log', params);
}

export async function query_arrange_shift_plan_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_arrange_shift_plan_list', params);
}

export async function delete_arrange_shift_plan(params: Record<string, any>) {
  return request.post('/org/delete_arrange_shift_plan', params);
}

export async function query_job_log_by_person(params: Record<string, any>) {
  return request.post('/org/query_job_log_by_person', params);
}

export async function refuse_role_apply(params: Record<string, any>) {
  return request.post('/org/refuse_role_apply', params);
}

export async function make_user_appid_role(params: Record<string, any>) {
  return request.post('/org/make_user_appid_role', params);
}

export async function update_shift_time(params: Record<string, any>) {
  return request.post('/shift/update_shift_time', params);
}

export async function query_attend_group_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/shift/query_attend_group_list', params);
}

export async function create_role_and_page_api(params: Record<string, any>) {
  return request.post('/org/create_role_and_page_api', params);
}

export async function get_arrange_shift_by_time(params: Record<string, any>) {
  return request.post('/shift/get_arrange_shift_by_time', params);
}

export async function query_role_and_person(params: Record<string, any>) {
  return request.post('/org/query_role_and_person', params);
}

export async function update_shift(params: Record<string, any>) {
  return request.post('/shift/update_shift', params);
}

export async function query_journal_seconds_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_journal_seconds_list', params);
}

export async function query_attend_group_list_no_login(params: Record<string, any>) {
  return request.post('/shift/query_attend_group_list_no_login', params);
}

export async function query_attendgroup_shift_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/shift/query_attendgroup_shift_list', params);
}

export async function query_shift_task_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_shift_task_list', params);
}

export async function get_organization(params: Record<string, any>) {
  return request.post('/org/get_organization', params);
}

export async function update_arrange_shift_plan(params: Record<string, any>) {
  return request.post('/org/update_arrange_shift_plan', params);
}

export async function query_my_org_by_flag_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_my_org_by_flag_list', params);
}

export async function query_zha_gun_company_by_line_org_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_zha_gun_company_by_line_org_list', params);
}

export async function get_journal_by_id(params: Record<string, any>) {
  return request.post('/org/get_journal_by_id', params);
}

export async function query_role_by_apps_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_role_by_apps_list', params);
}

export async function transfer_manager_org(params: Record<string, any>) {
  return request.post('/org/transfer_manager_org', params);
}

export async function update_person_role_pos_shift(params: Record<string, any>) {
  return request.post('/org/update_person_role_pos_shift', params);
}

export async function recover_shift_time(params: Record<string, any>) {
  return request.post('/shift/recover_shift_time', params);
}

export async function remove_group(params: Record<string, any>) {
  return request.post('/org/remove_group', params);
}

export async function recover_group(params: Record<string, any>) {
  return request.post('/org/recover_group', params);
}

export async function update_member(params: Record<string, any>) {
  return request.post('/org/update_member', params);
}

export async function confirm_jiaoban(params: Record<string, any>) {
  return request.post('/org/confirm_jiaoban', params);
}

export async function get_erp_shift_sql(params: Record<string, any>) {
  return request.post('/shift/get_erp_shift_sql', params);
}

export async function update_group(params: Record<string, any>) {
  return request.post('/org/update_group', params);
}

export async function create_company(params: Record<string, any>) {
  return request.post('/org/create_company', params);
}

export async function add_aide_group(params: Record<string, any>) {
  return request.post('/org/add_aide_group', params);
}

export async function query_person_by_org_no_login_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_person_by_org_no_login_list', params);
}

export async function delete_role(params: Record<string, any>) {
  return request.post('/org/delete_role', params);
}

export async function query_company_name_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_company_name_list', params);
}

export async function update_role(params: Record<string, any>) {
  return request.post('/org/update_role', params);
}

export async function query_person_by_role_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_person_by_role_list', params);
}

export async function query_group_by_group_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_group_by_group_list', params);
}

export async function set_erp_shift_sql_result(params: Record<string, any>) {
  return request.post('/shift/set_erp_shift_sql_result', params);
}

export async function recover_company(params: Record<string, any>) {
  return request.post('/org/recover_company', params);
}

export async function query_journal_new_data_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_journal_new_data_list', params);
}

export async function down_arrange_shift(params: Record<string, any>, config?: any) {
  return request.post('/shift/down_arrange_shift', params, config);
}

export async function apply_organization(params: Record<string, any>) {
  return request.post('/org/apply_organization', params);
}

export async function agree_role_apply(params: Record<string, any>) {
  return request.post('/org/agree_role_apply', params);
}

export async function query_journal_data_count_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_journal_data_count_list', params);
}

export async function get_company(params: Record<string, any>) {
  return request.post('/org/get_company', params);
}

export async function reject_organization(params: Record<string, any>) {
  return request.post('/org/reject_organization', params);
}

export async function recover_shift_and_user(params: Record<string, any>) {
  return request.post('/shift/recover_shift_and_user', params);
}

export async function create_member(params: Record<string, any>) {
  return request.post('/org/create_member', params);
}

export async function get_now_arrange_by_org(params: Record<string, any>) {
  return request.post('/shift/get_now_arrange_by_org', params);
}

export async function delete_user_appid_role(params: Record<string, any>) {
  return request.post('/org/delete_user_appid_role', params);
}

export async function query_role_and_page_api(params: Record<string, any>) {
  return request.post('/org/query_role_and_page_api', params);
}

export async function query_shift_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/shift/query_shift_list', params);
}

export async function query_user_by_message_select_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_user_by_message_select_list', params);
}

export async function update_shift_flag(params: Record<string, any>) {
  return request.post('/shift/update_shift_flag', params);
}

export async function query_role_apply_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_role_apply_list', params);
}

export async function query_person_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_person_list', params);
}

export async function query_arrange_shift_job_log_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_arrange_shift_job_log_list', params);
}

export async function query_shift_name_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/shift/query_shift_name_list', params);
}

export async function confirm_jieban(params: Record<string, any>) {
  return request.post('/org/confirm_jieban', params);
}

export async function update_company(params: Record<string, any>) {
  return request.post('/org/update_company', params);
}

export async function create_shift_and_user(params: Record<string, any>) {
  return request.post('/shift/create_shift_and_user', params);
}

export async function query_attendgroup_shift_list_no_login(params: Record<string, any>) {
  return request.post('/shift/query_attendgroup_shift_list_no_login', params);
}

export async function query_appinfo_by_org_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_appinfo_by_org_list', params);
}

export async function query_apply_organization_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_apply_organization_list', params);
}

export async function create_group(params: Record<string, any>) {
  return request.post('/org/create_group', params);
}

export async function add_person_org(params: Record<string, any>) {
  return request.post('/org/add_person_org', params);
}

export async function query_work_org_zhaxian_by_my_rel_org_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_work_org_zhaxian_by_my_rel_org_list', params);
}

export async function query_user_permissions_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_user_permissions_list', params);
}

export async function close_initstep(params: Record<string, any>) {
  return request.post('/org/close_initstep', params);
}

export async function add_person_group(params: Record<string, any>) {
  return request.post('/org/add_person_group', params);
}

export async function create_shift_time(params: Record<string, any>) {
  return request.post('/shift/create_shift_time', params);
}

export async function query_group_by_org_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_group_by_org_list', params);
}

export async function remove_person_org(params: Record<string, any>) {
  return request.post('/org/remove_person_org', params);
}

export async function add_manager_org(params: Record<string, any>) {
  return request.post('/org/add_manager_org', params);
}

export async function update_attend_group(params: Record<string, any>) {
  return request.post('/shift/update_attend_group', params);
}

export async function query_my_contact_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_my_contact_list', params);
}

export async function query_group_and_no_group_member(params: Record<string, any>) {
  return request.post('/org/query_group_and_no_group_member', params);
}

export async function delete_shift_and_user(params: Record<string, any>) {
  return request.post('/shift/delete_shift_and_user', params);
}

export async function create_contact(params: Record<string, any>) {
  return request.post('/org/create_contact', params);
}

export async function query_person_by_org_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_person_by_org_list', params);
}

export async function query_user_permissions_by_appflag_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_user_permissions_by_appflag_list', params);
}

export async function query_role_id_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_role_id_list', params);
}

export async function update_shifttask(params: Record<string, any>) {
  return request.post('/org/update_shifttask', params);
}

export async function get_initstep(params: Record<string, any>) {
  return request.post('/org/get_initstep', params);
}

export async function query_my_org_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_my_org_list', params);
}

export async function query_member_by_group_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_member_by_group_list', params);
}

export async function delete_contact(params: Record<string, any>) {
  return request.post('/org/delete_contact', params);
}

export async function query_journal_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_journal_list', params);
}

export async function revert_role(params: Record<string, any>) {
  return request.post('/org/revert_role', params);
}

export async function create_organization(params: Record<string, any>) {
  return request.post('/org/create_organization', params);
}

export async function delete_user_appflag_role(params: Record<string, any>) {
  return request.post('/org/delete_user_appflag_role', params);
}

export async function query_work_org_zhagangchang_by_my_rel_org_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_work_org_zhagangchang_by_my_rel_org_list', params);
}

export async function query_work_by_my_rel_org_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/org/query_work_by_my_rel_org_list', params);
}

export async function get_arrange_shift_by_new(params: Record<string, any>) {
  return request.post('/shift/get_arrange_shift_by_new', params);
}

export async function recover_shift(params: Record<string, any>) {
  return request.post('/shift/recover_shift', params);
}

export async function get_other_org_person_info(params: Record<string, any>) {
  return request.post('/org/get_other_org_person_info', params);
}

export async function create_arrange_shift(params: Record<string, any>) {
  return request.post('/shift/create_arrange_shift', params);
}

export async function query_arrange_shift_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/shift/query_arrange_shift_list', params);
}

export async function remove_appinfo(params: Record<string, any>) {
  return request.post('/org/remove_appinfo', params);
}

export async function get_role_and_shift_info_by_person(params: Record<string, any>) {
  return request.post('/org/get_role_and_shift_info_by_person', params);
}

export async function query_arrange_shift_all_by_attend_group(params: Record<string, any>) {
  return request.post('/org/query_arrange_shift_all_by_attend_group', params);
}

export async function update_arrangeshift(params: Record<string, any>) {
  return request.post('/shift/update_arrangeshift', params);
}

export async function create_role_apply(params: Record<string, any>) {
  return request.post('/org/create_role_apply', params);
}

export async function query_group_by_my_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_group_by_my_list', params);
}

export async function change_error_arrange_shift(params: Record<string, any>) {
  return request.post('/shift/change_error_arrange_shift', params);
}

export async function query_role_by_appflag_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_role_by_appflag_list', params);
}

export async function delete_shift_time(params: Record<string, any>) {
  return request.post('/shift/delete_shift_time', params);
}

export async function create_role(params: Record<string, any>) {
  return request.post('/org/create_role', params);
}

export async function create_role_and_page(params: Record<string, any>) {
  return request.post('/org/create_role_and_page', params);
}

export async function query_org_by_id_flag_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_org_by_id_flag_list', params);
}

export async function query_now_shift_line(params: Record<string, any>) {
  return request.post('/shift/query_now_shift_line', params);
}

export async function agree_organization(params: Record<string, any>) {
  return request.post('/org/agree_organization', params);
}

export async function query_shift_and_user_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/shift/query_shift_and_user_list', params);
}

export async function recover_arrangeshift(params: Record<string, any>) {
  return request.post('/shift/recover_arrangeshift', params);
}

export async function remove_aide_group(params: Record<string, any>) {
  return request.post('/org/remove_aide_group', params);
}

export async function set_member_active(params: Record<string, any>) {
  return request.post('/org/set_member_active', params);
}

export async function update_person_group(params: Record<string, any>) {
  return request.post('/org/update_person_group', params);
}

export async function set_org_status(params: Record<string, any>) {
  return request.post('/org/set_org_status', params);
}

export async function update_attendgroup_periodic_day(params: Record<string, any>) {
  return request.post('/org/update_attendgroup_periodic_day', params);
}

export async function delete_arrangeshift(params: Record<string, any>) {
  return request.post('/shift/delete_arrangeshift', params);
}

export async function query_org_by_flag_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/org/query_org_by_flag_list', params);
}

export async function delete_shift(params: Record<string, any>) {
  return request.post('/shift/delete_shift', params);
}
