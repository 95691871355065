
import { useForm } from 'ant-design-vue/es/form';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { get_plan_excel_import_setting_by_flag } from '@/api/sys-model';
import { useStore } from 'vuex';
import { query_all_rolled_position_by_roller_list } from '@/api/line_info-model';

const permissionActions = ['add', 'delete', 'update', 'query', 'import', 'export'];
export default defineComponent({
  name: 'StopEventModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    form: {
      type: Object,
      default: () => {},
    },
    options: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    let a_num = 0;
    let b_num = 0;
    const width = computed(() => {
      //?width:form.settings.col.length==0?'400px':'800px'
      if (props.form.settings.col == null || props.form.settings.col.length == 0) {
        return '500px';
      }
      if (props.form.settings.col != null && props.form.settings.col.length == 1) {
        return '500px';
      }
      if (props.form.settings.col != null && props.form.settings.col.length == 2) {
        return '800px';
      }
      if (props.form.settings.col != null && props.form.settings.col.length == 3) {
        return '1200px';
      }
    });
    const modelRef: any = reactive({ ...props.form.model });
    let old_cardDataSource: any = null;
    let old_cardDataSource1: any = null;
    const slotList: any = ref([]);
    const slotList1: any = ref([]);
    const columns: any = ref([{
      title: '',
      dataIndex: 'index',
      width: 70,
    }]);
    const columns1: any = ref([
      {
        title: '',
        dataIndex: 'index',
        width: 70,
        fixed: 'left',
      }
    ]);
    const cardDataSource: any = reactive([
      {
        index: '换辊',
        key: 1,
      },
      {
        index: '换槽',
        key: 2,
      },
      {
        index: '导卫',
        key: 3,
      },
    ]);
    const cardDataSource1: any = reactive([
      {
        index: '换辊',
        key: 1,
      },
      {
        index: '换槽',
        key: 2,
      },
      {
        index: '导卫',
        key: 3,
      },
    ]);
    query_all_rolled_position_by_roller_list({
      org_id: current_org.id,
    }).then((res: any) => {
      res.data.map((item: any, index: any) => {
        if(index <= 14){
          slotList.value.push('checked_' + item.id);
          columns.value.push(
            {
              title: item.name,
              dataIndex: 'checked_' + item.id,
              slots: { customRender: 'checked_' + item.id },
            }
          )
          cardDataSource[0]['checked_' + item.id] = false;
          cardDataSource[1]['checked_' + item.id] = false;
          cardDataSource[2]['checked_' + item.id] = false;
          cardDataSource[0]['name_' + item.id] = item.name;
          cardDataSource[1]['name_' + item.id] = item.name;
          cardDataSource[2]['name_' + item.id] = item.name;
          cardDataSource[0]['flag_' + item.id] = item.flag;
          cardDataSource[1]['flag_' + item.id] = item.flag;
          cardDataSource[2]['flag_' + item.id] = item.flag;
        }else if(index > 14){
          slotList1.value.push('checked_' + item.id);
          columns1.value.push(
            {
              title: item.name,
              dataIndex: 'checked_' + item.id,
              width: 70,
              slots: { customRender: 'checked_' + item.id },
            }
          )
          cardDataSource1[0]['checked_' + item.id] = false;
          cardDataSource1[1]['checked_' + item.id] = false;
          cardDataSource1[2]['checked_' + item.id] = false;
          cardDataSource1[0]['name_' + item.id] = item.name;
          cardDataSource1[1]['name_' + item.id] = item.name;
          cardDataSource1[2]['name_' + item.id] = item.name;
          cardDataSource1[0]['flag_' + item.id] = item.flag;
          cardDataSource1[1]['flag_' + item.id] = item.flag;
          cardDataSource1[2]['flag_' + item.id] = item.flag;
        }
      })
        if (modelRef.data_json) {
          JSON.parse(modelRef.data_json)
            .change_roller_position.split(',')
            .map((item: any) => {
              if (cardDataSource[0]['checked_' + item] == false) {
                cardDataSource[0]['checked_' + item] = true;
              }
              if (cardDataSource1[0]['checked_' + item] == false) {
                cardDataSource1[0]['checked_' + item] = true;
              }
            });
          JSON.parse(modelRef.data_json)
            .change_groove_position.split(',')
            .map((item: any) => {
              if (cardDataSource[1]['checked_' + item] == false) {
                cardDataSource[1]['checked_' + item] = true;
              }
              if (cardDataSource1[1]['checked_' + item] == false) {
                cardDataSource1[1]['checked_' + item] = true;
              }
            });
          if (JSON.parse(modelRef.data_json).change_daowei_position) {
            JSON.parse(modelRef.data_json)
              .change_daowei_position.split(',')
              .map((item: any) => {
                if (cardDataSource[2]['checked_' + item] == false) {
                  cardDataSource[2]['checked_' + item] = true;
                }
                if (cardDataSource1[2]['checked_' + item] == false) {
                  cardDataSource1[2]['checked_' + item] = true;
                }
              });
          }
        }
        old_cardDataSource = JSON.parse(JSON.stringify(cardDataSource));
      old_cardDataSource1 = JSON.parse(JSON.stringify(cardDataSource1));
    })
    // get_plan_excel_import_setting_by_flag({
    //   flag: 'stop_event_zhaji_position_json_' + current_org.id_flag,
    // }).then((res: any) => {
    //   // slotList.value = res.slotList;
    //   slotList1.value = res.slotList1;
    //   // columns.value = res.columns;
    //   columns1.value = res.columns1;
    //   // res.cardDataSource.map((item: any) => {
    //   //   cardDataSource.push(item);
    //   // });
    //   res.cardDataSource1.map((item: any) => {
    //     cardDataSource1.push(item);
    //   });
    //   // if (modelRef.data_json) {
    //   //   JSON.parse(modelRef.data_json)
    //   //     .change_roller_position.split(',')
    //   //     .map((item: any) => {
    //   //       if (cardDataSource[0]['checked_' + item] && cardDataSource[0]['checked_' + item] == false) {
    //   //         cardDataSource[0]['checked_' + item] = true;
    //   //       }
    //   //       if (cardDataSource[0]['checked_' + item] && cardDataSource1[0]['checked_' + item] == false) {
    //   //         cardDataSource1[0]['checked_' + item] = true;
    //   //       }
    //   //     });
    //   //   JSON.parse(modelRef.data_json)
    //   //     .change_groove_position.split(',')
    //   //     .map((item: any) => {
    //   //       if (cardDataSource[1]['checked_' + item] && cardDataSource[1]['checked_' + item] == false) {
    //   //         cardDataSource[1]['checked_' + item] = true;
    //   //       }
    //   //       if (cardDataSource[1]['checked_' + item] && cardDataSource1[1]['checked_' + item] == false) {
    //   //         cardDataSource1[1]['checked_' + item] = true;
    //   //       }
    //   //     });
    //     // if (JSON.parse(modelRef.data_json).change_daowei_position) {
    //     //   JSON.parse(modelRef.data_json)
    //     //     .change_daowei_position.split(',')
    //     //     .map((item: any) => {
    //     //       if (cardDataSource[0]['checked_' + item] && cardDataSource[2]['checked_' + item] == false) {
    //     //         cardDataSource[2]['checked_' + item] = true;
    //     //       }
    //     //       if (cardDataSource[0]['checked_' + item] && cardDataSource1[2]['checked_' + item] == false) {
    //     //         cardDataSource1[2]['checked_' + item] = true;
    //     //       }
    //     //     });
    //     // }
    //   // }
    //   // old_cardDataSource = JSON.parse(JSON.stringify(cardDataSource));
    //   old_cardDataSource1 = JSON.parse(JSON.stringify(cardDataSource1));
    // });

    watch(
      () => cardDataSource,
      () => {
        old_cardDataSource.map((item: any, index: any) => {
          Object.getOwnPropertyNames(item).map((item1: any) => {
            if (index == 0) {
              if (
                old_cardDataSource[0][item1] != cardDataSource[0][item1] &&
                old_cardDataSource[1][item1]
              ) {
                cardDataSource[1][item1] = false;
              }
            }
            if (index == 1) {
              if (
                old_cardDataSource[1][item1] != cardDataSource[1][item1] &&
                old_cardDataSource[0][item1]
              ) {
                cardDataSource[0][item1] = false;
              }
            }
          });
        });
        old_cardDataSource = JSON.parse(JSON.stringify(cardDataSource));
        const change_roller_position: any = [];
        const change_groove_position: any = [];
        const change_daowei_position: any = [];
        modelRef.roller_position_ids = [];
        cardDataSource.map((item: any, index: any) => {
          Object.getOwnPropertyNames(item).map((item1: any) => {
            if (index == 0 && item[item1] == true && item1.split('_')[1]) {
              change_roller_position.push(item1.split('_')[1]);
              modelRef.roller_position_ids.push(item1.split('_')[1]);
            }
            if (index == 1 && item[item1] == true && item1.split('_')[1]) {
              change_groove_position.push(item1.split('_')[1]);
              modelRef.roller_position_ids.push(item1.split('_')[1]);
            }
            if (index == 2 && item[item1] == true && item1.split('_')[1]) {
              change_daowei_position.push(item1.split('_')[1]);
            }
          });
        });
        cardDataSource1.map((item: any, index: any) => {
          Object.getOwnPropertyNames(item).map((item1: any) => {
            if (index == 0 && item[item1] == true && item1.split('_')[1]) {
              change_roller_position.push(item1.split('_')[1]);
              modelRef.roller_position_ids.push(item1.split('_')[1]);
            }
            if (index == 1 && item[item1] == true && item1.split('_')[1]) {
              change_groove_position.push(item1.split('_')[1]);
              modelRef.roller_position_ids.push(item1.split('_')[1]);
            }
            if (index == 2 && item[item1] == true && item1.split('_')[1]) {
              change_daowei_position.push(item1.split('_')[1]);
            }
          });
        });
        let str = '';
        if (change_roller_position.length > 0) {
          change_roller_position.map((item: any, index: any) => {
                if (index == 0) {
                  if(cardDataSource[0]['name_' + item]){
                    str = str + '换辊：' + cardDataSource[0]['name_' + item] + ';';
                  }else{
                    str = str + '换辊：' + cardDataSource1[0]['name_' + item] + ';';
                  }
                } else {
                  if(cardDataSource[0]['name_' + item]){
                    str = str + cardDataSource[0]['name_' + item] + ';';
                  }else{
                    str = str + cardDataSource1[0]['name_' + item] + ';';
                  }
                }
          });
        }
        if (change_groove_position.length > 0) {
          change_groove_position.map((item: any, index: any) => {
                if (index == 0) {
                  if(cardDataSource[0]['name_' + item]){
                    str = str + '换槽：' + cardDataSource[0]['name_' + item] + ';';
                  }else{
                    str = str + '换槽：' + cardDataSource1[0]['name_' + item] + ';';
                  }
                } else {
                  if(cardDataSource[0]['name_' + item]){
                    str = str + cardDataSource[0]['name_' + item] + ';';
                  }else{
                    str = str + cardDataSource1[0]['name_' + item] + ';';
                  }
                }
          });
        }
        if (change_daowei_position.length > 0) {
          change_daowei_position.map((item: any, index: any) => {
                if (index == 0) {
                  if(cardDataSource[0]['name_' + item]){
                    str = str + '换导卫：' + cardDataSource[0]['name_' + item] + ';';
                  }else{
                    str = str + '换导卫：' + cardDataSource1[0]['name_' + item] + ';';
                  }
                } else {
                  if(cardDataSource[0]['name_' + item]){
                    str = str + cardDataSource[0]['name_' + item] + ';';
                  }else{
                    str = str + cardDataSource1[0]['name_' + item] + ';';
                  }
                }
          });
        }
        if(a_num == 0){
          a_num = a_num + 1;
        }else{
          modelRef.bz = str;
        }
        modelRef.data_json = JSON.stringify({
          change_roller_position: change_roller_position.join(','),
          change_groove_position: change_groove_position.join(','),
          change_daowei_position: change_daowei_position.join(','),
        });
      },
      { deep: true },
    );
    watch(
      () => cardDataSource1,
      () => {
        old_cardDataSource1.map((item: any, index: any) => {
          Object.getOwnPropertyNames(item).map((item1: any) => {
            if (index == 0) {
              if (
                old_cardDataSource1[0][item1] != cardDataSource1[0][item1] &&
                old_cardDataSource1[1][item1]
              ) {
                cardDataSource1[1][item1] = false;
              }
            }
            if (index == 1) {
              if (
                old_cardDataSource1[1][item1] != cardDataSource1[1][item1] &&
                old_cardDataSource1[0][item1]
              ) {
                cardDataSource1[0][item1] = false;
              }
            }
          });
        });
        old_cardDataSource1 = JSON.parse(JSON.stringify(cardDataSource1));
        const change_roller_position: any = [];
        const change_groove_position: any = [];
        const change_daowei_position: any = [];
        modelRef.roller_position_ids = [];
        cardDataSource.map((item: any, index: any) => {
          Object.getOwnPropertyNames(item).map((item1: any) => {
            if (index == 0 && item[item1] == true && item1.split('_')[1]) {
              change_roller_position.push(item1.split('_')[1]);
              modelRef.roller_position_ids.push(item1.split('_')[1]);
            }
            if (index == 1 && item[item1] == true && item1.split('_')[1]) {
              change_groove_position.push(item1.split('_')[1]);
              modelRef.roller_position_ids.push(item1.split('_')[1]);
            }
            if (index == 2 && item[item1] == true && item1.split('_')[1]) {
              change_daowei_position.push(item1.split('_')[1]);
            }
          });
        });
        cardDataSource1.map((item: any, index: any) => {
          Object.getOwnPropertyNames(item).map((item1: any) => {
            if (index == 0 && item[item1] == true && item1.split('_')[1]) {
              change_roller_position.push(item1.split('_')[1]);
              modelRef.roller_position_ids.push(item1.split('_')[1]);
            }
            if (index == 1 && item[item1] == true && item1.split('_')[1]) {
              change_groove_position.push(item1.split('_')[1]);
              modelRef.roller_position_ids.push(item1.split('_')[1]);
            }
            if (index == 2 && item[item1] == true && item1.split('_')[1]) {
              change_daowei_position.push(item1.split('_')[1]);
            }
          });
        });
        let str = '';
        if (change_roller_position.length > 0) {
          change_roller_position.map((item: any, index: any) => {
            if (index == 0) {
              if(cardDataSource[0]['name_' + item]){
                str = str + '换辊：' + cardDataSource[0]['name_' + item] + ';';
              }else{
                str = str + '换辊：' + cardDataSource1[0]['name_' + item] + ';';
              }
            } else {
              if(cardDataSource[0]['name_' + item]){
                str = str + cardDataSource[0]['name_' + item] + ';';
              }else{
                str = str + cardDataSource1[0]['name_' + item] + ';';
              }
            }
          });
        }
        if (change_groove_position.length > 0) {
          change_groove_position.map((item: any, index: any) => {
            if (index == 0) {
              if(cardDataSource[0]['name_' + item]){
                str = str + '换槽：' + cardDataSource[0]['name_' + item] + ';';
              }else{
                str = str + '换槽：' + cardDataSource1[0]['name_' + item] + ';';
              }
            } else {
              if(cardDataSource[0]['name_' + item]){
                str = str + cardDataSource[0]['name_' + item] + ';';
              }else{
                str = str + cardDataSource1[0]['name_' + item] + ';';
              }
            }
          });
        }
        if (change_daowei_position.length > 0) {
          change_daowei_position.map((item: any, index: any) => {
            if (index == 0) {
              if(cardDataSource[0]['name_' + item]){
                str = str + '换导卫：' + cardDataSource[0]['name_' + item] + ';';
              }else{
                str = str + '换导卫：' + cardDataSource1[0]['name_' + item] + ';';
              }
            } else {
              if(cardDataSource[0]['name_' + item]){
                str = str + cardDataSource[0]['name_' + item] + ';';
              }else{
                str = str + cardDataSource1[0]['name_' + item] + ';';
              }
            }
          });
        }
        if(b_num == 0){
          b_num = b_num + 1;
        }else{
          modelRef.bz = str;
        }
        modelRef.data_json = JSON.stringify({
          change_roller_position: change_roller_position.join(','),
          change_groove_position: change_groove_position.join(','),
          change_daowei_position: change_daowei_position.join(','),
        });
      },
      { deep: true },
    );
    if (props.form.rules) {
      for (const key of Object.keys(props.form.rules)) {
        if (!modelRef.hasOwnProperty(key)) {
          modelRef[key] = null;
        }
      }
    }
    const rulesRef = reactive({ ...props.form.rules });
    // watchEffect(() => {
    //   if (props.form.model) {
    //     console.log('update model', props.form.model);
    //   }
    // });
    const { resetFields, validate, validateInfos } = useForm(modelRef, rulesRef);
    const handleSubmit = (e: Event) => {
      e.preventDefault();
      validate()
        .then(() => {
          emit('ok', { ...modelRef });
        })
        .finally(() => {})
        .catch(err => {
          console.log('error', err);
        });
    };
    return {
      props,
      width,
      modelRef,
      cardDataSource,
      columns,
      slotList,
      columns1,
      cardDataSource1,
      slotList1,
      handleSubmit,
      resetFields,
      validateInfos,
      permissionActions,
    };
  },
});
