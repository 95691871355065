import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createSlots as _createSlots, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StarFilled = _resolveComponent("StarFilled")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_select_outlined = _resolveComponent("select-outlined")!
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_select, {
      mode: _ctx.field.mode=='default'?null:_ctx.field.mode,
      value: _ctx.modalRef.selectedRows,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalRef.selectedRows) = $event)),
      "show-search": _ctx.field.show_search == undefined ? true : _ctx.field.show_search,
      disabled: _ctx.field.disabled,
      placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请选择' + _ctx.field.label,
      dropdownMatchSelectWidth: _ctx.field.dropdownMatchSelectWidth,
      "default-value": _ctx.field.defaultValue,
      maxTagCount: _ctx.field.maxTagCount,
      onChange: _ctx.handleChange,
      size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
      "allow-clear": _ctx.field.allowClear,
      "filter-option": _ctx.filterOption,
      style: _normalizeStyle(_ctx.select_style)
    }, _createSlots({
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.datasource, (option) => {
          return (_openBlock(), _createBlock(_component_a_select_option, {
            key: option.value,
            value: option.value
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(option.label), 1)
            ]),
            _: 2
          }, 1032, ["value"]))
        }), 128))
      ]),
      _: 2
    }, [
      (_ctx.field?.suffix_icon == undefined ? false : _ctx.field?.suffix_icon)
        ? {
            name: "suffixIcon",
            fn: _withCtx(() => [
              _createVNode(_component_StarFilled, { style: {"color":"#ff0000"} })
            ])
          }
        : undefined
    ]), 1032, ["mode", "value", "show-search", "disabled", "placeholder", "dropdownMatchSelectWidth", "default-value", "maxTagCount", "onChange", "size", "allow-clear", "filter-option", "style"]),
    (_ctx.field.cb)
      ? (_openBlock(), _createBlock(_component_a_button, {
          key: 0,
          size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.btnClick(_ctx.field.cb))),
          style: _normalizeStyle(_ctx.button_style),
          title: '点击新建' + _ctx.field.label
        }, {
          icon: _withCtx(() => [
            (_ctx.field.btnIconName === 'SelectOutlined')
              ? (_openBlock(), _createBlock(_component_select_outlined, {
                  key: 0,
                  theme: "filled"
                }))
              : (_openBlock(), _createBlock(_component_plus_outlined, {
                  key: 1,
                  theme: "filled"
                }))
          ]),
          _: 1
        }, 8, ["size", "style", "title"]))
      : _createCommentVNode("", true)
  ]))
}