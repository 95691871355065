
import { defineComponent, reactive, watch, onMounted, computed, onActivated, PropType } from 'vue';
import { RequestData } from '@/utils/hooks/useFetchData';
import getFormConfig from '@/components/FormRender/RemoteSelect/index';
import { PlusOutlined, SelectOutlined, StarFilled } from '@ant-design/icons-vue';
import {useI18n} from "vue-i18n";

type Field = {
  mode: 'multiple' | 'tags' | 'combobox' | 'default';
  modalType: string;
  cb?: any;
  select_style: any;
  default: string;
  name: string;
};

export default defineComponent({
  name: 'RemoteSelect',
  props: {
    field: {
      type: Object as PropType<Field>,
      required: true,
    },
    valueKey: {
      type: String,
    },
    labelKey: {
      type: String,
    },
    labelSpace: {
      type: String,
      default: '-',
    },
    labelKeyList: {
      type: Array,
    },
    value: {
      type: [Array, Number, String],
      required: false,
      default: () => [],
    },
    label: {
      type: [Array, String],
      required: false,
      default: () => [],
    },
    formModel: {
      type: Object,
      required: true,
    },
    init_model: {
      type: Object,
      default: () => {
      },
    },
  },
  emits: ['update:value', 'update:label'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const modalRef = reactive({
      selectedRows: new Array<number | any>(),
      datasource: new Array<any>(),
    });
    const { searchConfig, getData } = getFormConfig(props.field.modalType);
    const search_modelRef = reactive({
      ...searchConfig.model,
      ...props.init_model,
    });
    if (searchConfig.model.pageSize == undefined) {
      search_modelRef.pageSize = 2000;
    }
    const old_obj: { [key: string]: any } = {};
    const button_style = computed(() => {
      return props.field.cb ? 'width: 14%;min-width:30px;' : 'width: 100%;min-width:30px;';
    });
    const select_style = computed(() => {
      return props.field.cb
        ? 'width: 85%;min-width:150px;'
        : props.field.select_style
          ? props.field.select_style
          : 'width:100%;min-width:150px;';
    });

    const get_value_key = () => {
      return props.valueKey ? (props.valueKey as string) : 'id';
    };
    const get_label_key = () => {
      return props.labelKey ? (props.labelKey as string) : 'name';
    };
    const datasource = computed(() => {
      return modalRef.datasource.map(item => {
        if (props.labelKeyList) {
          let label = [];
          for (let key of props.labelKeyList) {
            label.push(item[key as string]);
          }
          return {
            value: item[get_value_key()],
            label: label.join(props.labelSpace),
          };
        } else {
          return {
            value: item[get_value_key()],
            label: item[get_label_key()],
          };
        }
      });
    });
    const getDefaultValue = (list: any) => {
      if (
        props.field.default &&
        !props.formModel[props.field.name] &&
        props.field.mode == 'default' &&
        list.length > 0
      ) {
        props.formModel[props.field.name] = list[0][get_value_key()];
        emit('update:label', list[0][get_label_key()]);
        // modalRef.selectedRows.length = 0;
        // modalRef.selectedRows.push(list[0][get_value_key()]);
      }
      if (props.field.mode == 'default') {
        const selectItems: any = datasource.value.filter((item: any) => modalRef.selectedRows == item.value).map((item: any) => item.label);
        emit('update:label', selectItems.length > 0 ? selectItems[0] : '');
      } else {
        emit('update:label', datasource.value.filter((item: any) => modalRef.selectedRows.includes(item.value)).map((item: any) => item.label));
      }
    };

    const check_params_need_request = () => {
      let is_ok = true;
      if (searchConfig.watch) {
        for (let k of searchConfig.watch) {
          if (props.formModel) {
            if (props.formModel[k] === null || props.formModel[k] === undefined) {
              is_ok = false;
            }
          }
        }
      }
      return is_ok;
    };
    watch(
      () => props.formModel[props.field.name],
      () => {
        if (props.field.mode === 'default') {
          if (props.formModel[props.field.name]) {
            modalRef.selectedRows = [props.formModel[props.field.name]];
          } else {
            modalRef.selectedRows = [];
          }
        } else {
          //modalRef.selectedRows.length = 0;
          if (props.formModel[props.field.name] && props.formModel[props.field.name].length > 0) {
            modalRef.selectedRows = props.formModel[props.field.name].map((item: any) => {
              return item;
            });
          }
        }
      },
    );

    onMounted(() => {
      let is_use = false;
      if (searchConfig.watch) {
        for (let k of searchConfig.watch) {
          if (props.formModel) {
            old_obj[k] = props.formModel[k];
          }
        }
      }
      if (searchConfig.watch && props.formModel) {
        for (let k of searchConfig.watch) {
          search_modelRef[k] = props.formModel[k];
          watch(
            () => props.formModel[k],
            () => {
              let change = false;
              if (searchConfig.watch && props.formModel) {
                for (let k of searchConfig.watch) {
                  if (old_obj[k] != props.formModel[k] && props.formModel[k]) {
                    change = true;
                    search_modelRef[k] = props.formModel[k];
                  }
                }
              }
              if (change && check_params_need_request()) {
                getData({ ...search_modelRef }).then(function(res: RequestData<any>) {
                  modalRef.datasource = res.data;
                  getDefaultValue(res.data);
                });
              }
            },
            { immediate: true },
          );
        }
      }
      if (searchConfig.attr_use && searchConfig.attr_use.length > 0) {
        for (let k of searchConfig.attr_use) {
          if (props.formModel && props.formModel[k]) {
            is_use = true;
          } else {
            is_use = false;
            break;
          }
        }
      } else {
        if (check_params_need_request()) {
          getData({ ...search_modelRef }).then(function(res: RequestData<any>) {
            modalRef.datasource = res.data;
            getDefaultValue(res.data);
          });
        }
      }
      if (is_use && check_params_need_request()) {
        getData({ ...search_modelRef }).then(function(res: RequestData<any>) {
          modalRef.datasource = res.data;
          getDefaultValue(res.data);
        });
      }
    });

    if (props.field.mode === 'default') {
      if (props.value) {
        if (isNaN(Number(props.value))) {
          modalRef.selectedRows.push(props.value);
        } else {
          modalRef.selectedRows.push(Number(props.value));
        }
      }
    } else {
      if (typeof props.value == 'string') {
        (props.value as String).split(',').map((item: any) => {
          modalRef.selectedRows.push(Number(item));
        });
      } else if (props.value instanceof Array) {
        props.value.map((item: any) => {
          modalRef.selectedRows.push(Number(item));
        });
      }else{
        modalRef.selectedRows.push(props.value);
      }
    }
    const btnClick = (cb: any) => {
      cb().then(function(value: number) {
        getData({ ...search_modelRef, ids: value }).then(function(res: RequestData<any>) {
          modalRef.datasource = res.data;
          if (props.field.mode === 'default') {
            modalRef.selectedRows = [Number(value)];
          } else {
            if (modalRef.selectedRows && modalRef.selectedRows.length > 0) {
              modalRef.selectedRows.push(Number(value));
            } else {
              modalRef.selectedRows = [Number(value)];
            }
          }
          if (props.field.mode == 'default') {
            if (modalRef.selectedRows.length == 1) {
              emit('update:value', modalRef.selectedRows[0]);
            } else {
              emit('update:value', null);
            }
            const selectItems: any = datasource.value.filter((item: any) => {
              return modalRef.selectedRows == item.value;
            }).map((item: any) => item.label);
            emit('update:label', selectItems.length > 0 ? selectItems[0] : '');
          } else {
            if (modalRef.selectedRows.length > 1) {
              emit('update:value', modalRef.selectedRows);
            } else {
              emit('update:value', modalRef.selectedRows);
            }
            emit('update:label', datasource.value.filter((item: any) => modalRef.selectedRows.includes(item.value)).map((item: any) => item.label));
          }
        });
      });
    };

    const handleChange = () => {
      if (props.field.mode == 'default') {
        emit('update:value', modalRef.selectedRows);
        const selectItems: any = datasource.value.filter((item: any) => {
          return modalRef.selectedRows == item.value;
        }).map((item: any) => item.label);
        emit('update:label', selectItems.length > 0 ? selectItems[0] : '');
      } else {
        if (modalRef.selectedRows.length > 1) {
          emit('update:value', modalRef.selectedRows);
        } else {
          emit('update:value', modalRef.selectedRows);
        }
        emit('update:label', datasource.value.filter((item: any) => modalRef.selectedRows.includes(item.value)).map((item: any) => item.label));
      }
    };
    const filterOption = (val: string, option: any) => {
      return option.props.children[0].children.toLowerCase().indexOf(val.toLowerCase()) >= 0;
    };

    return {
      t,
      props,
      modalRef,
      search_modelRef,
      searchConfig,
      handleChange,
      filterOption,
      datasource,
      select_style,
      button_style,
      btnClick,
    };
  },

  data() {
    return {
      model: this.value,
    };
  },
  components: {
    PlusOutlined,
    SelectOutlined,
    StarFilled,
  },
});
