import request from '@/utils/request';
import { PageResult } from '@/api/typing';


export async function create_form_group(params: Record<string, any>) {
  return request.post('/check_form/create_form_group', params);
}

export async function update_form_group(params: Record<string, any>) {
  return request.post('/check_form/update_form_group', params);
}

export async function copy_form_group(params: Record<string, any>) {
  return request.post('/check_form/copy_form_group', params);
}

export async function delete_form_group(params: Record<string, any>) {
  return request.post('/check_form/delete_form_group', params);
}

export async function recover_form_group(params: Record<string, any>) {
  return request.post('/check_form/recover_form_group', params);
}
export async function get_form_item_by_group(params: Record<string, any>) {
  return request.post('/check_form/get_form_item_by_group', params);
}
export async function set_default_value_by_form_group(params: Record<string, any>) {
  return request.post('/check_form/set_default_value_by_form_group', params);
}

export async function query_form_group_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/check_form/query_form_group_list', params);
}


export async function create_form_item(params: Record<string, any>) {
  return request.post('/check_form/create_form_item', params);
}

export async function update_form_item(params: Record<string, any>) {
  return request.post('/check_form/update_form_item', params);
}

export async function delete_form_item(params: Record<string, any>) {
  return request.post('/check_form/delete_form_item', params);
}

export async function recover_form_item(params: Record<string, any>) {
  return request.post('/check_form/recover_form_item', params);
}

export async function query_form_item_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/check_form/query_form_item_list', params);
}


export async function create_page(params: Record<string, any>) {
  return request.post('/check_form/create_page', params);
}

export async function update_page(params: Record<string, any>) {
  return request.post('/check_form/update_page', params);
}

export async function delete_page(params: Record<string, any>) {
  return request.post('/check_form/delete_page', params);
}

export async function recover_page(params: Record<string, any>) {
  return request.post('/check_form/recover_page', params);
}

export async function query_page_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/check_form/query_page_list', params);
}


export async function create_page_record(params: Record<string, any>) {
  return request.post('/check_form/create_page_record', params);
}

export async function update_page_record(params: Record<string, any>) {
  return request.post('/check_form/update_page_record', params);
}

export async function delete_page_record(params: Record<string, any>) {
  return request.post('/check_form/delete_page_record', params);
}

export async function recover_page_record(params: Record<string, any>) {
  return request.post('/check_form/recover_page_record', params);
}

export async function query_page_record_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/check_form/query_page_record_list', params);
}

export async function get_form_item_by_page(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/check_form/get_form_item_by_page', params);
}


export async function create_page_type(params: Record<string, any>) {
  return request.post('/check_form/create_page_type', params);
}

export async function update_page_type(params: Record<string, any>) {
  return request.post('/check_form/update_page_type', params);
}

export async function delete_page_type(params: Record<string, any>) {
  return request.post('/check_form/delete_page_type', params);
}

export async function recover_page_type(params: Record<string, any>) {
  return request.post('/check_form/recover_page_type', params);
}

export async function query_page_type_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/check_form/query_page_type_list', params);
}
export async function batch_create_form_item(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/check_form/batch_create_form_item', params);
}

export async function get_record_by_page(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/check_form/get_record_by_page', params);
}
