
import { useForm } from 'ant-design-vue/es/form';
import { computed, defineComponent, reactive, ref, watch } from 'vue';
import { get_plan_excel_import_setting_by_flag } from '@/api/sys-model';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'ZhaFeiModal',
  props: {
    title: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const search = {
      current: 1,
      pageSize: 2000,
      tableSize: 'small', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        org_id: current_org.id,
        aim_org_id: current_org.id,
        stop_event_id: props.id,
        stop_event_recommend: true,
        conclusion_all: true,
      },
    };
    const roller_shift_time = reactive({
      title: '根支信息',
      column_flag: 'genzhi',
      search: { ...search, requestParams: { ...search.requestParams } },
      modalType: 'production_manage-query_production_record_item_list',
      height: 280,
      mode: 'null',
    });
    return {
      roller_shift_time,
    };
  },
});
