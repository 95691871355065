<template>
  <div class="body">
    <a-form layout="vertical" style="padding: 10px">
      <a-form-item label="表单名称">
        <a-input v-model:value="settings.name" />
      </a-form-item>
      <a-form-item label="表单布局">
        <a-radio-group v-model:value="settings.layout">
          <a-radio-button value="horizontal">horizontal</a-radio-button>
          <a-radio-button value="vertical">vertical</a-radio-button>
          <!--<a-radio-button value="inline">
            inline
          </a-radio-button>-->
        </a-radio-group>
      </a-form-item>
      <a-form-item v-if="settings.layout === 'horizontal'" label="Label位置">
        <a-radio-group v-model:value="settings.labelAlign">
          <a-radio-button value="left">左</a-radio-button>
          <a-radio-button value="right">右</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item v-if="settings.layout === 'horizontal'" label="Label宽度">
        <a-slider v-model:value="settings.labelCol.span" :min="1" :max="24" />
      </a-form-item>
      <a-form-item v-if="settings.layout === 'horizontal'" label="Wrapper宽度">
        <a-slider v-model:value="settings.wrapperCol.span" :min="1" :max="24" />
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
export default {
  name: 'FormSetting',
  props: {
    settings: {
      type: Object,
      required: true,
    },
  },
  methods: {},
  watch: {
    'settings.layout': {
      handler(newValue) {
        if (newValue === 'vertical') {
          this.settings.labelCol.span = 24;
          this.settings.wrapperCol.span = 24;
        } else {
          this.settings.labelCol.span = 3;
          this.settings.wrapperCol.span = 21;
        }
      },
    },
  },
};
</script>

<style scoped></style>
