import { queryOrgList } from '@/api/sys';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';



export default () => {
  const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const search_config: any = {
    model: {
      org_id: org?.id,
      aim_org_id: org?.id,
      is_active: true,
    },
  };
  return {
    getData: queryOrgList,
    searchConfig: search_config,
  };
};
