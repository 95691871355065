import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { style: { opacity: 1 } }
const _hoisted_2 = { style: { opacity: 1 } }
const _hoisted_3 = { style: { opacity: 1 } }
const _hoisted_4 = { style: { opacity: 1 } }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_list_item = _resolveComponent("a-list-item")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_switch = _resolveComponent("a-switch")!
  const _component_a_list = _resolveComponent("a-list")!

  return (_openBlock(), _createBlock(_component_a_list, { split: false }, {
    default: _withCtx(() => [
      _createVNode(_component_a_tooltip, null, {
        default: _withCtx(() => [
          _createVNode(_component_a_list_item, null, {
            actions: _withCtx(() => [
              _createVNode(_component_a_select, {
                size: 'small',
                value: _ctx.contentWidth,
                onSelect: _cache[0] || (_cache[0] = val => _ctx.handleChange('contentWidth', val))
              }, {
                default: _withCtx(() => [
                  (_ctx.layout === 'side')
                    ? (_openBlock(), _createBlock(_component_a_select_option, {
                        key: 0,
                        value: "Fixed"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.locale('app.setting.content-width.fixed')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_a_select_option, { value: "Fluid" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.locale('app.setting.content-width.fluid')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["value"])
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.locale('app.setting.content-width')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_tooltip, {
        placement: "left",
        title: _ctx.layout === 'mix' ? _ctx.locale('app.setting.fixedheader.hint') : ''
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_list_item, null, {
            actions: _withCtx(() => [
              _createVNode(_component_a_switch, {
                size: 'small',
                checked: !!_ctx.fixedHeader,
                disabled: _ctx.layout === 'mix',
                onChange: _cache[1] || (_cache[1] = checked => _ctx.handleChange('fixedHeader', checked))
              }, null, 8, ["checked", "disabled"])
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.locale('app.setting.fixedheader')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_a_tooltip, {
        title: _ctx.layout === 'top' ? _ctx.locale('app.setting.fixedsidebar.hint') : ''
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_list_item, null, {
            actions: _withCtx(() => [
              _createVNode(_component_a_switch, {
                size: 'small',
                disabled: _ctx.layout === 'top',
                checked: !!_ctx.fixSiderbar,
                onChange: _cache[2] || (_cache[2] = checked => _ctx.handleChange('fixSiderbar', checked))
              }, null, 8, ["disabled", "checked"])
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.locale('app.setting.fixedsidebar')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_a_tooltip, {
        placement: "left",
        title: _ctx.layout === 'mix' ? '' : _ctx.locale('app.setting.layout.mix.hint')
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_list_item, null, {
            actions: _withCtx(() => [
              _createVNode(_component_a_switch, {
                size: 'small',
                disabled: _ctx.layout !== 'mix',
                checked: !!_ctx.splitMenus,
                onChange: _cache[3] || (_cache[3] = checked => _ctx.handleChange('splitmenus', checked))
              }, null, 8, ["disabled", "checked"])
            ]),
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.locale('app.setting.split.menus')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _: 1
  }))
}