import { defineAsyncComponent, h } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import { MenuDataItem } from './typing';
import Layout from '@/layouts/index.vue';
import UserLayout2 from '@/layouts/user-layout2.vue';
import UserLayout3 from '@/layouts/user-layout3.vue';
import RouteView from '@/layouts/route-view.vue';

const page_model = defineAsyncComponent(() => import('@/components/page-model/index.vue'));
const workplace = defineAsyncComponent(() => import('@/views/dashboard/workplace/index.vue'));
const markdown = defineAsyncComponent(() => import('@/views/markdown/index.vue'));
const personal_information = defineAsyncComponent(() => import('@/views/personal_information/index.vue'));
const Induction_process = defineAsyncComponent(() => import('@/views/Induction_process/index.vue'));
const payment_progress = defineAsyncComponent(() => import('@/views/payment_progress/index.vue'));
const curriculum_vitae = defineAsyncComponent(() => import('@/views/curriculum_vitae/index.vue'));
const kefu = defineAsyncComponent(() => import('@/views/kefu/index.vue'));
const adviser_sign = defineAsyncComponent(() => import('@/views/adviser_sign/index.vue'));
const kefu_question = defineAsyncComponent(() => import('@/views/kefu_question/index.vue'));

export const routes: MenuDataItem[] = [
  {
    name: 'index',
    path: '/',
    meta: {title: '欢迎页', authority: 'adviser_desktop', icon: 'ProjectOutlined'},
    redirect: '/adviser_workplace',
    component: Layout,
    children: [
      {
        path: '/adviser_workplace',
        name: 'adviser_workplace',
        meta: {
          title: 'router.title.workplace',
          icon: 'HomeOutlined',
        },
        component: h(RouteView, {}, () => h(workplace)),
      },
      {
        path: '/static-info-manage/baseinfo-notice/:id/:notice_record_id',
        name: 'baseinfo-notice',
        meta: {
          icon: 'HeartOutlined',
          title: 'router.title.notice',
          hideInMenu: true,
        },
        component: h(RouteView, {}, () => h(markdown)),
      },

      {
        path: '/cc_adviser/personal_information',
        name: 'personal_information',
        meta: {
          icon: 'IdcardOutlined',
          title: 'router.title.personal_information',
        },
        component: h(RouteView, {}, () => h(personal_information)),
      },
      {
        path: '/cc_adviser/cerp-contract',
        name: 'cerp-contract',
        meta: {
          icon: 'AuditOutlined',
          title: 'router.title.cerp-contract',
        },
        component: h(RouteView, {}, () => h(page_model)),
      },
      {
        path: '/cc_adviser/cerp-insurance',
        name: 'cerp-insurance',
        meta: {
          icon: 'HeartOutlined',
          title: 'router.title.cerp-insurance',
        },
        component: h(RouteView, {}, () => h(page_model)),
      },
      {
        path: '/cc_adviser/cerp-payslip',
        name: 'cerp-payslip',
        meta: {
          icon: 'MoneyCollectOutlined',
          title: 'router.title.cerp-payslip',
        },
        component: h(RouteView, {}, () => h(page_model)),
      },
      {
        path: '/cc_adviser/payment_progress',
        name: 'payment_progress',
        meta: {
          icon: 'PartitionOutlined',
          title: 'router.title.payment_progress',
        },
        component: h(RouteView, {}, () => h(payment_progress)),
      },
      // {
      //   path: '/cc_adviser/curriculum_vitae',
      //   name: 'curriculum_vitae',
      //   meta: {
      //     icon: 'HeartOutlined',
      //     title: 'router.title.curriculum_vitae',
      //   },
      //   component: h(RouteView, {}, () => h(curriculum_vitae)),
      // },
      {
        path: '/cc_adviser/receipt-adviser_sign',
        name: 'receipt-adviser_sign',
        meta: {
          icon: 'AuditOutlined',
          title: '票据合同签署',
        },
        component: h(RouteView, {}, () => h(page_model)),
      },
      {
        path: '/cc_adviser/receipt-adviser',
        name: 'receipt-adviser',
        meta: {
          icon: 'AuditOutlined',
          title: '票据合同',
        },
        component: h(RouteView, {}, () => h(page_model)),
      },
      {
        path: '/cc_adviser/receipt-receipt',
        name: 'receipt-receipt',
        meta: {
          icon: 'AuditOutlined',
          title: '发票清单',
        },
        component: h(RouteView, {}, () => h(page_model)),
      },
      {
        path: '/cc_adviser/kefu',
        name: 'kefu',
        meta: {
          icon: 'HeartOutlined',
          title: 'router.title.kefu',
          // hideInMenu: true,
        },
        component: h(RouteView, {}, () => h(kefu)),
      },
      {
        path: '/cc_adviser/kefu_question/:id/:name',
        name: 'kefu_question',
        meta: {
          icon: 'HeartOutlined',
          title: 'router.title.kefu_question',
          hideInMenu: true,
        },
        component: h(RouteView, {}, () => h(kefu_question)),
      },
      {
        path: '/cc_adviser/adviser_sign/:id',
        name: 'adviser_sign',
        meta: {
          icon: 'HeartOutlined',
          title: '合同签署详情',
          hideInMenu: true,
        },
        component: h(RouteView, {}, () => h(adviser_sign)),
      },
      // {
      //   path: '/static-info-manage',
      //   name: 'static-info-manage',
      //   meta: {icon: 'HeartOutlined', title: 'router.title.adviser-manage', authority: 'static-info-manage'},
      //   component: RouteView,
      //   redirect: '/adviser-manage/cerp-beneficiary',
      //   children: [
      //
      //     {
      //       path: '/adviser-manage/cerp-beneficiary',
      //       name: 'cerp-beneficiary',
      //       meta: {
      //         icon: 'HeartOutlined',
      //         title: 'router.title.cerp-beneficiary',
      //
      //       },
      //       component: (): Component => import('@/components/page-model/index.vue'),
      //     },
      //     {
      //       path: '/adviser-manage/cerp-bankaccount',
      //       name: 'cerp-bankaccount',
      //       meta: {
      //         icon: 'HeartOutlined',
      //         title: 'router.title.cerp-bankaccount',
      //       },
      //       component: (): Component => import('@/components/page-model/index.vue'),
      //     },
      //     {
      //       path: '/adviser-manage/cerp-personreferencenumber',
      //       name: 'cerp-personreferencenumber',
      //       meta: {
      //         icon: 'HeartOutlined',
      //         title: 'router.title.cerp-personreferencenumber',
      //       },
      //       component: (): Component => import('@/components/page-model/index.vue'),
      //     },
      //
      //     {
      //       path: '/adviser-manage/cerp-nextofkin',
      //       name: 'cerp-nextofkin',
      //       meta: {
      //         icon: 'HeartOutlined',
      //         title: 'router.title.cerp-nextofkin',
      //       },
      //       component: (): Component => import('@/components/page-model/index.vue'),
      //     },
      //     // {
      //     //   path: '/static-info-manage/baseinfo-notice/:id/:notice_record_id',
      //     //   name: 'baseinfo-notice',
      //     //   meta: {
      //     //     icon: 'HeartOutlined',
      //     //     title: '公告详情',
      //     //     hideInMenu: true,
      //     //   },
      //     //   component: (): Component => import('@/views/markdown/index.vue'),
      //     // },
      //   ],
      // },
    ],
  },
];

export const staticRoutes: MenuDataItem[] = [
  {
    path: '/user',
    name: 'user',
    meta: {hideInMenu: true, title: 'pages.layouts.userLayout.title'},
    component: UserLayout2,
    children: [
      {
        path: '/user/login',
        name: 'login',
        meta: {title: 'page.login.accountLogin.tab'},
        component: () => import('@/views/user/login.vue'),
      },
      {
        path: '/user/register',
        name: 'register',
        meta: {title: 'page.login.registerAccount'},
        component: () => import('@/views/user/register.vue'),
      },
      {
        path: '/user/register-result',
        name: 'register-result',
        meta: {title: 'page.login.registerAccount'},
        component: () => import('@/views/user/register-result.vue'),
      },
      {
        path: '/user/retrieve_password/:username/:token',
        name: 'retrieve_password',
        meta: {title: 'page.login.retrieve_password'},
        component: () => import('@/views/user/retrieve_passwor.vue'),
      },
    ],
  },
  {
    path: '/cc_adviser',
    name: 'cc_adviser',
    meta: {hideInMenu: true, title: 'pages.layouts.userLayout.title'},
    component: UserLayout3,
    children: [
      {
        path: '/cc_adviser/Induction_process',
        name: 'Induction_process',
        meta: {
          title: 'router.title.Induction_process',
        },
        component: () => import('@/views/Induction_process/index.vue'),
      },
    ],
  },
  {
    path: '/:pathMatch(.*)',
    component: () => import('@/views/exception/404.vue'),
  },
];

const reg = /static_file[_A-Za-z0-9]*/;
const namespace_arr: any = reg.exec(window.location.href);
let namespace: any = null;
if (namespace_arr && namespace_arr.length > 0) {
  namespace = namespace_arr[0].trim();
}
const router = createRouter({
  history: createWebHistory(namespace ? namespace : process.env.VUE_APP_PUBLIC_PATH),
  routes: staticRoutes,
});

export default router;
