
import {defineComponent} from 'vue';
import { message } from 'ant-design-vue';
export default defineComponent({
  name: 'Cell_click_copy',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
  setup() {
    const onCopy = (certB64: any) => {
      let oInput = document.createElement('input');
      oInput.value = certB64;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand('Copy'); // 执行浏览器复制命令
      message.success('复制成功');
      oInput.remove();
    };
    return {
      onCopy
    }
  }
});
