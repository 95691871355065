
import { computed, defineComponent, getCurrentInstance, inject, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { genMenuInfo } from '@/utils/menu-util';
import { default as WrapContent } from '@/components/base-layouts/wrap-content/index.vue';
import { default as GlobalFooter } from '@/components/base-layouts/global-footer/index.vue';
import { default as SiderMenu } from '@/components/base-layouts/sider-menu/index.vue';
import { default as HeaderView2 } from '@/components/base-layouts/header/index2.vue';
import { default as SelectLang } from '@/components/select-lang/index.vue';
import { default as SettingDrawer } from '@/components/setting-drawer/index.vue';
import { MultiTab } from '@/components/multi-tab';
import { useStore } from 'vuex';
import { injectMenuState } from './use-menu-state';
import isGotoOldRouter from '@/router/return-old-router';
import FormModal from '@/components/form-modal/form-modal.vue';
import { BellOutlined, PlusOutlined, UserSwitchOutlined } from '@ant-design/icons-vue';
import { useRoute, useRouter } from 'vue-router';
import { default as AvatarDropdown } from '@/components/avatar-dropdown.vue';
import { default as ShopNameNow } from '@/components/shop-name-now.vue';
import { default as OrgNameNow } from '@/components/org-name-now.vue';
import modalBox from '@/components/form-modal/modal-tools';

import bus from '@/utils/bus';
import mqtt_service from '@/utils/service/mqtt';
import { get_adviser_sign_contract, get_cc_company_by_adviser_id } from '@/api/cerp-model';
import { GET_COMPANY, GET_NO_SIGN_CONTRACT, LOGOUT } from '@/store/modules/user/actions';
import { notification } from 'ant-design-vue';

export default defineComponent({
  name: 'BasicLayout',
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const { t } = useI18n();
    const menuState = injectMenuState();
    const select_jump: any = ref('1');
    const select_company: any = ref(null);
    const company_list: any = ref([]);
    const current_company = computed(() => store.getters['user/current_company']);
    select_company.value = current_company.value?.id ? current_company.value?.id : null;
    const isMobile = inject('isMobile', ref(false));
    const no_read_number = computed(() => store.getters['user/no_read_number']);
    const adviser = computed(() => store.getters[`user/adviser`]);
    const currentUser = computed(() => store.getters['user/currentUser']);
    const current_org = computed(() => store.getters['user/current_org']);
    const org_list = computed(() => store.getters['user/org_list']);
    const shop_list = computed(() => store.getters['user/shop_list']);
    const current_shop = computed(() => store.getters['user/current_shop']);
    const mqtt_state = ref(0);
    const audio_event_list: any = ref([]);
    const audio_obj = new Audio();
    bus.on('mqtt_state', mqttstate => {
      mqtt_state.value = mqttstate;
    });

    bus.on('sys_audio_event', event => {
      audio_event_list.value.push(event);
      audio_event_list.value = audio_event_list.value.filter((item: any) => {
        if (item.error || (item.play_times && item.play_times >= item.times)) {
          return false;
        }
        return true;
      });
    });
    bus.on('menuDrawer', () => {
      modalBox(
        reactive({
          component: 'menuDrawer',
        }),
      );
    });
    const is_sign_contract: any = ref(true);
    get_adviser_sign_contract({
      org_id: current_org.value.id,
      adviser_id: adviser.value.adviser_id,
    }).then((res: any) => {
      if (res && (!res.qiyuesuo_status || res.qiyuesuo_status < 3)) {
        store.dispatch(`user/${GET_NO_SIGN_CONTRACT}`, { sign_contract: true });
        is_sign_contract.value = true;
      } else {
        store.dispatch(`user/${GET_NO_SIGN_CONTRACT}`, { sign_contract: false });
        is_sign_contract.value = false;
      }
    })
    get_cc_company_by_adviser_id({
      org_id: current_org.value?.id,
      adviser_id: adviser.value.adviser_id,
    }).then((res: any) => {
      company_list.value.length = 0;
      if (res && res.data && res.data.length > 0) {
        const ids: any = [];
        res.data.forEach((item: any) => {
          ids.push(item.id)
        })
        company_list.value.push({
          name: '全部公司',
          id: ids.join(','),
        });
        res.data.forEach((item: any) => {
          company_list.value.push({
            name: item.name,
            id: item.id,
          });
        });
      }
    });
    const to_kefu = () => {
      router.push({
        path: '/cc_adviser/kefu',
      });
    };
    const play_audio = () => {
      if ((audio_obj.paused || audio_obj.error != null) && audio_event_list.value.length > 0) {
        const event = audio_event_list.value[0];
        if (event.play_times == undefined) {
          event.play_times = 1;
        } else {
          event.play_times += 1;
        }
        if (event.play_times >= event.times) {
          audio_event_list.value = audio_event_list.value.filter((item: any) => {
            if (item.error || (item.play_times && item.play_times >= item.times)) {
              return false;
            }
            return true;
          });
        }
        audio_obj.src = process.env.VUE_APP_STATIC_URL+event.src;

        audio_obj.autoplay = true;
        audio_obj.play();
        event.playing = true;
      } else if (audio_event_list.value.length > 0) {
        const event = audio_event_list.value[0];
        if (audio_obj.error != null) {
          console.error(audio_obj.error);
          audio_obj.src = '';
          event.error = audio_obj.error;
        }
      }
    };
    setInterval(play_audio, 500);

    const add_audio_event = () => {
      bus.emit('sys_audio_event', {
        times: 3,
        src: 'https://s8m.oss-cn-beijing.aliyuncs.com/audio/audio_first.mp3',
      });
    };
    const to_Induction_process = () => {
      router.push({ path: '/cc_adviser/Induction_process' });
    };

    const { init } = mqtt_service();
    init();
    const hasSideMenu = computed(
      () => menuState.layout.value === 'side' || menuState.layout.value === 'left',
    );
    const hasTopMenu = computed(() => menuState.layout.value === 'top');

    const modal_list: any = reactive([]);
    let $bus: {
      emit: (arg0: string, arg1: any) => void;
      off: (arg0: string) => void;
      on: (arg0: string, arg1: any) => void;
    } | null = null;
    $bus = getCurrentInstance()?.appContext.config.globalProperties.$bus;

    $bus?.on('modal.add', (modal: any) => {
      if (!modal.id) {
        modal.id = Date.now();
      }
      modal_list.push(modal);
    });
    // let is_color = true;
    // let is_color2 = true;
    // const nod1 = document.createElement('style');
    // nod1.setAttribute('id', 'custom_style');
    // const textNode = document.createTextNode('.clickRowStyl1 {background-color: #fff !important;}');
    // const textNode2 = document.createTextNode(
    //   '.clickRowStyl1 {background-color: #FFB7DD !important;}',
    // );
    // const textNode3 = document.createTextNode(
    //   '.clickRowStyl2 {background-color: #fff !important;}',
    // );
    // const textNode4 = document.createTextNode(
    //   '.clickRowStyl2 {background-color: #BBFFEE !important;}',
    // );
    // const changeStyle1 = () => {
    //   const obj = document.getElementById('custom_style');
    //   if (obj != null && is_color) {
    //     obj.appendChild(textNode);
    //     try {
    //       obj.removeChild(textNode2);
    //     } catch (e) {
    //
    //     }
    //
    //     is_color = false;
    //   } else if (obj != null) {
    //     obj.appendChild(textNode2);
    //     try {
    //       obj.removeChild(textNode);
    //     } catch (e) {
    //       console.error(e);
    //     }
    //     is_color = true;
    //   }
    // };
    // const changeStyle2 = () => {
    //   const obj = document.getElementById('custom_style');
    //   if (obj != null && is_color2) {
    //     obj.appendChild(textNode3);
    //     try {
    //       obj.removeChild(textNode4);
    //     } catch (e) {
    //       console.error(e);
    //     }
    //
    //     is_color2 = false;
    //   } else if (obj != null) {
    //     obj.appendChild(textNode4);
    //     try {
    //       obj.removeChild(textNode3);
    //     } catch (e) {
    //       console.error(e);
    //     }
    //     is_color2 = true;
    //   }
    // };
    // let times1: any = null;
    // let times2: any = null;
    // onMounted(() => {
    //   times1 = setInterval(changeStyle1, 700);
    //   times2 = setInterval(changeStyle2, 700);
    //   document.getElementsByTagName('head')[0].appendChild(nod1);
    // });
    //
    // onBeforeUnmount(() => {
    //   clearInterval(times1);
    //   clearInterval(times2);
    //   document.getElementsByTagName('head')[0].removeChild(nod1);
    // });
    // const select_button_dict: any = ref({
    //   newcomer: { name: '新客', show_role_flag: ['shop_manager', 'admin'], is_show: false },
    //   reservation: { name: '预约', show_role_flag: ['shop_manager', 'admin'], is_show: false },
    // });
    // const source_select_option_list = [
    //   { value: '1', label: '顾客', show_role_flag: ['admin', 'shop_manager', 'chairman'] },
    //   { value: '2', label: '加盟商', show_role_flag: ['admin', 'supply_keeper', 'supply_manager'] },
    //   {
    //     value: '3',
    //     label: '库存',
    //     show_role_flag: ['admin', 'supply_keeper', 'supply_manager', 'shop_manager'],
    //   },
    // ];
    // const select_option_list = ref(new Array<any>());
    // select_option_list.value = source_select_option_list.filter((item: any) => {
    //   return item.show_role_flag.some((obj: any) => {
    //     return currentUser.value.role_flag_list.indexOf(obj) > -1;
    //   });
    // });
    // for (let key in select_button_dict.value) {
    //   const some = select_button_dict.value[key as string].show_role_flag.some((obj: any) => {
    //     return currentUser.value.role_flag_list.indexOf(obj) > -1;
    //   });
    //   if (some) {
    //     select_button_dict.value[key as string].is_show = true;
    //   } else {
    //     select_button_dict.value[key as string].is_show = false;
    //   }
    // }
    // if (select_option_list.value && select_option_list.value.length > 0) {
    //   select_jump.value = select_option_list.value[0].value;
    // }
    const modal_ok = (data: any, modal: any) => {
      if (modal.ok) {
        const result = modal.ok(data, modal);
        if (result instanceof Promise) {
          result.then(function () {
            modal_list.splice(modal_list.indexOf(modal, 0), 1);
          });
        } else if (result === true || result === null || result === undefined) {
          modal_list.splice(modal_list.indexOf(modal, 0), 1);
        }
      } else {
        modal_list.splice(modal_list.indexOf(modal, 0), 1);
      }
    };
    const modal_update = (data: any, modal: any) => {
      if (modal.update) {
        const result = modal.update(data, modal);
        if (result instanceof Promise) {
          result.then(function () {
            modal_list.splice(modal_list.indexOf(modal, 0), 1);
          });
        } else if (result === true || result === null || result === undefined) {
          modal_list.splice(modal_list.indexOf(modal, 0), 1);
        }
      } else {
        modal_list.splice(modal_list.indexOf(modal, 0), 1);
      }
    };

    const modal_cancel = (data: any, modal: any) => {
      if (modal.cancel) {
        modal.cancel(data, modal);
      }
      modal_list.splice(modal_list.indexOf(modal, 0), 1);
    };

    // gen menus
    const allowRouters = computed(() => store.getters[`user/allowRouters`]); // genMenuInfo(filterMenu(routes)).menus;
    const menus = computed(() => genMenuInfo(allowRouters.value).menus);
    // console.log('menus', menus.value);
    //
    // const adviser = computed(() => store.getters[`user/adviser`]);
    // // let old_menus: any = JSON.stringify( JSON.parse( menus.value as any ) );
    // // let menus_list: any = [];
    // get_adviser_sign_contract({
    //   org_id: current_org.value.id,
    //   adviser_id: adviser.value.adviser_id,
    // }).then((res: any) => {
    //   if(res && (!res.qiyuesuo_status || res.qiyuesuo_status < 3)){
    //     store.dispatch(`user/${GET_NO_SIGN_CONTRACT}`,{ sign_contract: true })
    //     menus.value.map((item: any) => {
    //       if(item.name == 'Induction_process' || item.meta.hideInMenu){
    //         item.meta.authority = null;
    //       }else{
    //         item.meta.authority = '123';
    //       }
    //     })
    //   }else{
    //     store.dispatch(`user/${GET_NO_SIGN_CONTRACT}`,{ sign_contract: false })
    //   }
    // })
    // 返回旧版本
    const returnOldRouter = (selectedKeys: any) => {
      isGotoOldRouter(selectedKeys[selectedKeys.length - 1]);
    };

    const field = reactive({
      visible: false,
      title: '搜索用户',
      mode: 'default',
      search_text: '',
    });
    const openSearch = (searchValue: string) => {
      if (searchValue && select_jump.value == '1') {
        router.push({ path: '/business_manage/mjstore-customer', query: { q: searchValue } });
      } else if (searchValue && select_jump.value == '2') {
        router.push({ path: '/mjstore_company/jms-franchisee', query: { q: searchValue } });
      } else if (searchValue && select_jump.value == '3') {
        router.push({
          path: '/mjstock/caigou/mjstock-productinrecord1',
          query: { q: searchValue },
        });
      }
    };
    // const goBasic = (data: any) => {
    //   if (data) {
    //     router.push({ path: '/business_manage/profile/basic/' + data });
    //   }
    //   field.visible = false;
    // };
    const goAppointment = () => {
      router.push({ path: '/business_manage/mjstore-appointment' });
    };

    const handleCreateArrangeShift = () => {
      modalBox(
        reactive({
          visible: true,
          loading: false,
          component: 'CreateArrangeShiftModal',
        }),
      );
    };
    const selectCompany = (value: any) => {
      const path_arr = route.path.split('/');
      let list = company_list.value.filter((item: any) => item.id == value);
      if (list && list?.length > 0) {
        store.dispatch(`user/${GET_COMPANY}`, { id: list[0].id, name: list[0].name });
        if (path_arr[path_arr.length - 1] == 'cerp-contract') {
          router.push({ path: '/cc_adviser/cerp-contract', query: { cc_company_id: list[0].id, cc_company_name: list[0].name } });
        } else if (path_arr[path_arr.length - 1] == 'cerp-payslip') {
          router.push({ path: '/cc_adviser/cerp-payslip', query: { cc_company_id: list[0].id, cc_company_name: list[0].name } });
        } else if (path_arr[path_arr.length - 1] == 'receipt-adviser_sign') {
          router.push({ path: '/cc_adviser/receipt-adviser_sign', query: { cc_company_id: list[0].id, cc_company_name: list[0].name } });
        } else if (path_arr[path_arr.length - 1] == 'receipt-adviser') {
          router.push({ path: '/cc_adviser/receipt-adviser', query: { cc_company_id: list[0].id, cc_company_name: list[0].name } });
        } else if (path_arr[path_arr.length - 1] == 'receipt-receipt') {
          router.push({ path: '/cc_adviser/receipt-receipt', query: { cc_company_id: list[0].id, cc_company_name: list[0].name } });
        }
      }
    };

    bus.on('LOGOUT', () => {
      notification.error({
        message: t('登录过期'),
        description: t('请重新登录'),
      });
      store.dispatch(`user/${LOGOUT}`).then(() => {
        location.href = '/';
      });
    });
    return {
      t,
      current_shop,
      shop_list,
      field,
      select_jump,
      select_company,
      company_list,
      current_org,
      menus,
      ...menuState,
      hasSideMenu,
      hasTopMenu,
      no_read_number,
      isMobile,
      // goBasic,
      goAppointment,
      currentUser,
      org_list,
      returnOldRouter,
      isGotoOldRouter,
      modal_list,
      modal_ok,
      modal_cancel,
      openSearch,
      modal_update,
      to_kefu,
      is_sign_contract,
      mqtt_state,
      // select_option_list,
      // select_button_dict,
      //other
      handleCreateArrangeShift,
      to_Induction_process,
      add_audio_event,
      selectCompany,
    };
  },
  components: {
    FormModal,
    MultiTab,
    WrapContent,
    SiderMenu,
    GlobalFooter,
    HeaderView2,
    SelectLang,
    SettingDrawer,
    PlusOutlined,
    UserSwitchOutlined,
    BellOutlined,

    AvatarDropdown,
    ShopNameNow,
    OrgNameNow,
  },
});
