
import { defineComponent, reactive } from 'vue';
import { RequestData } from '@/utils/hooks/useFetchData';
import getFormConfig from '@/components/FormRender/RemoteTreeSelect/index';

export default defineComponent({
  name: 'RemoteTreeSelect',
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      type: [Array, Number, String],
      required: false,
      default: () => null,
    },
    search_config: {
      type: Object,
      required: false,
      default: () => {
      },
    },
    label: {
      type: [Array, String],
      required: false,
    },
  },
  emits: ['update:value', 'update:label'],
  setup(props, {emit}) {
    const modalRef = reactive({
      selectedRows: new Array<any>(),
      datasource: new Array<any>(),
    });
    const { searchConfig, getData } = getFormConfig(props.field.modalType);
    const search_modelRef = reactive({
      ...searchConfig.model,
    });

    if (props.value) {
      modalRef.selectedRows.push(props.value);
    }

    getData({ ...search_modelRef, ...props.search_config }).then(function (res: RequestData<any>) {
      modalRef.datasource = res.data;
      if(modalRef.datasource.length>0 && modalRef.selectedRows.length>0){
        if(typeof modalRef.datasource[0][props.field.valueKey] == 'number' && typeof modalRef.selectedRows[0] == 'string'){
          const list:Array<number> = [];
          modalRef.selectedRows.map((item:string)=>{
            item.split(',').map(obj=>{
              list.push(Number(obj));
            })
          });
          if(props.field.multiple){
            modalRef.selectedRows = list;
          }else{
            if(list.length>1){
              list.length = 1;
            }
            modalRef.selectedRows = list;
          }

        }
      }
      emit('update:label', getLabelFromDatasource(modalRef.datasource));
    });

    const getLabelFromDatasource = (datasource:Array<any>)=>{
      const labelList:Array<string> = [];
      datasource.forEach((item:any)=>{
        if(modalRef.selectedRows instanceof Array && modalRef.selectedRows.includes(item[props.field.valueKey])){
          labelList.push(item[props.field.labelKey]);
        }
        if(!(modalRef.selectedRows instanceof Array) && modalRef.selectedRows ==item[props.field.valueKey]){
          labelList.push(item[props.field.labelKey]);
        }
        if(item.children && item.children.length>0){
          getLabelFromDatasource(item.children).forEach(obj=>labelList.push(obj));
        }
      })
      return labelList;
    };

    const handleChange = () => {
      emit('update:value', modalRef.selectedRows);
      emit('update:label', getLabelFromDatasource(modalRef.datasource));
    };

    return {
      props,
      modalRef,
      search_modelRef,
      handleChange,
    };
  },

  data() {
    return {
      model: this.value,
    };
  },
});
