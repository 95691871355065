import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { style: {"font-size":"15px","color":"#ffffff","padding-left":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppstoreOutlined = _resolveComponent("AppstoreOutlined")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_base_menu = _resolveComponent("base-menu")!
  const _component_right_content = _resolveComponent("right-content")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classNames)
  }, [
    _createElementVNode("div", {
      ref: "headRef",
      class: _normalizeClass(_ctx.headerClassName)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(`${_ctx.prefixedClassName}-main-left`),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleMenuHeaderClick && _ctx.handleMenuHeaderClick(...args)))
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(`${_ctx.prefixedClassName}-logo`),
          key: "logo",
          id: "logo"
        }, [
          (_ctx.hasLogoSlot)
            ? _renderSlot(_ctx.$slots, "logo", { key: 0 })
            : (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_router_link, { to: { name: 'index' } }, {
                  default: _withCtx(() => [
                    _createVNode(_component_AppstoreOutlined, { style: {"color":"white"} }),
                    _createElementVNode("span", _hoisted_2, [
                      _createElementVNode("b", null, _toDisplayString(_ctx.appName), 1)
                    ])
                  ]),
                  _: 1
                })
              ]))
        ], 2)
      ], 2),
      _createElementVNode("div", {
        style: { flex: 1 },
        class: _normalizeClass(`${_ctx.prefixedClassName}-menu`)
      }, [
        (_ctx.menus && _ctx.menus.length > 0)
          ? (_openBlock(), _createBlock(_component_base_menu, {
              key: 0,
              mode: "horizontal",
              i18n: _ctx.i18n,
              theme: _ctx.theme,
              menus: _ctx.menus,
              "open-keys": _ctx.openKeys,
              "selected-keys": _ctx.selectedKeys,
              "onUpdate:openKeys": _ctx.handleOpenKeys,
              "onUpdate:selectedKeys": _ctx.handleSelectedKeys
            }, null, 8, ["i18n", "theme", "menus", "open-keys", "selected-keys", "onUpdate:openKeys", "onUpdate:selectedKeys"]))
          : _createCommentVNode("", true)
      ], 2),
      _createVNode(_component_right_content, null, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "rightContent")
        ]),
        _: 3
      })
    ], 2)
  ], 2))
}