import request from '@/utils/request';
import { PageResult } from '@/api/typing';
import { get_remote_tree_data } from '@/utils/function';

export async function recover_opcname(params: Record<string, any>) {
  return request.post('/data_acquisition/recover_opcname', params);
}

export async function query_script_record_by_influx_db_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/data_acquisition/query_script_record_by_influx_db_list', params);
}

export async function change_is_current(params: Record<string, any>) {
  return request.post('/data_acquisition/change_is_current', params);
}

export async function query_data_funs_by_influx_db_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/data_acquisition/query_data_funs_by_influx_db_list', params);
}

export async function query_opc_record_by_influx_db_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/data_acquisition/query_opc_record_by_influx_db_list', params);
}

export async function set_opc_name_subscribe_by_webhook(params: Record<string, any>) {
  return request.post('/data_acquisition/set_opc_name_subscribe_by_webhook', params);
}

export async function query_dataplan_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/data_acquisition/query_dataplan_list', params);
}

export async function query_max_value_by_influx_db_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/data_acquisition/query_max_value_by_influx_db_list', params);
}

export async function query_opc_data_by_influx_db_sort_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/data_acquisition/query_opc_data_by_influx_db_sort_list', params);
}

export async function query_data_dict_by_influx_db_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/data_acquisition/query_data_dict_by_influx_db_list', params);
}

export async function query_opc_record_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/data_acquisition/query_opc_record_list', params);
}

export async function query_new_opc_data_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/data_acquisition/query_new_opc_data_list', params);
}

export async function update_opcgroup(params: Record<string, any>) {
  return request.post('/data_acquisition/update_opcgroup', params);
}

export async function query_element_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/data_acquisition/query_element_list', params);
}

export async function create_menu_page(params: Record<string, any>) {
  return request.post('/data_acquisition/create_menu_page', params);
}

export async function delete_menu(params: Record<string, any>) {
  return request.post('/data_acquisition/delete_menu', params);
}

export async function update_menu_page_version(params: Record<string, any>) {
  return request.post('/data_acquisition/update_menu_page_version', params);
}

export async function query_plc_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/data_acquisition/query_plc_list', params);
}

export async function query_datascript_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/data_acquisition/query_datascript_list', params);
}

export async function query_menu_page_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/data_acquisition/query_menu_page_list', params);
}

export async function delete_datascript(params: Record<string, any>) {
  return request.post('/data_acquisition/delete_datascript', params);
}

export async function delete_plc(params: Record<string, any>) {
  return request.post('/data_acquisition/delete_plc', params);
}

export async function query_plc_change_record_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/data_acquisition/query_plc_change_record_list', params);
}

export async function update_plc(params: Record<string, any>) {
  return request.post('/data_acquisition/update_plc', params);
}

export async function create_plc(params: Record<string, any>) {
  return request.post('/data_acquisition/create_plc', params);
}

export async function update_opcname(params: Record<string, any>) {
  return request.post('/data_acquisition/update_opcname', params);
}

export async function create_group_and_plan_index(params: Record<string, any>) {
  return request.post('/data_acquisition/create_group_and_plan_index', params);
}

export async function query_dataplan_by_opcname_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/data_acquisition/query_dataplan_by_opcname_list', params);
}

export async function update_menu(params: Record<string, any>) {
  return request.post('/data_acquisition/update_menu', params);
}

export async function recover_menu_page(params: Record<string, any>) {
  return request.post('/data_acquisition/recover_menu_page', params);
}

export async function delete_dataplan(params: Record<string, any>) {
  return request.post('/data_acquisition/delete_dataplan', params);
}

export async function recover_new_opc_data(params: Record<string, any>) {
  return request.post('/data_acquisition/recover_new_opc_data', params);
}

export async function update_opcname_cfg(params: Record<string, any>) {
  return request.post('/data_acquisition/update_opcname_cfg', params);
}

export async function create_opc_and_group_index(params: Record<string, any>) {
  return request.post('/data_acquisition/create_opc_and_group_index', params);
}

export async function query_plc_by_influx_db_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/data_acquisition/query_plc_by_influx_db_list', params);
}

export async function query_opcname_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/data_acquisition/query_opcname_list', params);
}

export async function query_opc_flag_by_opcgroup_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/data_acquisition/query_opc_flag_by_opcgroup_list', params);
}

export async function delete_element(params: Record<string, any>) {
  return request.post('/data_acquisition/delete_element', params);
}

export async function create_plc_customer_value(params: Record<string, any>) {
  return request.post('/data_acquisition/create_plc_customer_value', params);
}

export async function query_menu_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/data_acquisition/query_menu_list', params);
}

export async function create_datascript(params: Record<string, any>) {
  return request.post('/data_acquisition/create_datascript', params);
}

export async function delete_new_opc_data(params: Record<string, any>) {
  return request.post('/data_acquisition/delete_new_opc_data', params);
}

export async function query_opcgroup_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/data_acquisition/query_opcgroup_list', params);
}

export async function query_opcgroup_list1(params: Record<string, any>): Promise<PageResult> {
  const res: PageResult = await request.post('/data_acquisition/query_opcgroup_list', params);
  res.data = get_remote_tree_data(res);
  return res;
}

export async function recover_dataplan(params: Record<string, any>) {
  return request.post('/data_acquisition/recover_dataplan', params);
}

export async function query_opc_data_by_influx_db_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/data_acquisition/query_opc_data_by_influx_db_list', params);
}

export async function query_opcua_by_influx_db_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/data_acquisition/query_opcua_by_influx_db_list', params);
}

export async function query_opc_var_tpye_and_data_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/data_acquisition/query_opc_var_tpye_and_data_list', params);
}

export async function create_element(params: Record<string, any>) {
  return request.post('/data_acquisition/create_element', params);
}

export async function get_one_menu_page(params: Record<string, any>) {
  return request.post('/data_acquisition/get_one_menu_page', params);
}

export async function create_opcname(params: Record<string, any>) {
  return request.post('/data_acquisition/create_opcname', params);
}

export async function delete_opcgroup(params: Record<string, any>) {
  return request.post('/data_acquisition/delete_opcgroup', params);
}

export async function update_datascript(params: Record<string, any>) {
  return request.post('/data_acquisition/update_datascript', params);
}

export async function delete_opcname(params: Record<string, any>) {
  return request.post('/data_acquisition/delete_opcname', params);
}

export async function query_verson_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/data_acquisition/query_verson_list', params);
}

export async function create_opcgroup(params: Record<string, any>) {
  return request.post('/data_acquisition/create_opcgroup', params);
}

export async function recover_datascript(params: Record<string, any>) {
  return request.post('/data_acquisition/recover_datascript', params);
}

export async function create_menu(params: Record<string, any>) {
  return request.post('/data_acquisition/create_menu', params);
}

export async function export_opc_record_excel(params: Record<string, any>) {
  return request.post('/data_acquisition/export_opc_record_excel', params);
}

export async function update_dataplan(params: Record<string, any>) {
  return request.post('/data_acquisition/update_dataplan', params);
}

export async function delete_menu_page(params: Record<string, any>) {
  return request.post('/data_acquisition/delete_menu_page', params);
}

export async function add_opc_flag_to_opcgroup(params: Record<string, any>) {
  return request.post('/data_acquisition/add_opc_flag_to_opcgroup', params);
}

export async function create_dataplan(params: Record<string, any>) {
  return request.post('/data_acquisition/create_dataplan', params);
}

export async function recover_opcgroup(params: Record<string, any>) {
  return request.post('/data_acquisition/recover_opcgroup', params);
}
