
import { defineComponent, reactive, ref, onMounted, computed } from 'vue';
import { DownloadOutlined } from '@ant-design/icons-vue';
import { useForm } from 'ant-design-vue/es/form';
import { export_productionrecorditem_datas } from '@/api/production_manage-model';
import { get_file_url } from '@/api/nsbcs-model';
import { query_all_nsfile_list } from '@/api/nsbcs-model';
import { message, notification } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
export default defineComponent({
  name: 'ExportExcelByBackEndModal',
  props: {
    head_list: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: () => false,
    },
  },
  components: {
    DownloadOutlined,
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const loading = ref(false);
    const tableLoading = ref(false);
    const state = reactive({
      dataSource: [],
      form: {
        settings: {
          labelAlign: 'right',
          layout: 'vertical',
          col: [],
        },
        fields: [
          {
            type: 'rangepicker',
            name: 'time_interval',
            label: '开始-截止时间',
            disabled: false,
            allowClear: true,
            inputType: 'number',
            defaultValue: '',
            showTime: false,
            valueFormat: 'YYYY-MM-DD',
            placeholder: '请选择时间',
          },
          {
            type: 'select',
            name: 'head_list',
            label: '导出列',
            mode: 'multiple',
            disabled: false,
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            datasource: 'head_list',
            maxTagCount: 10,
          },
        ],
        rules: reactive({}),
        model: reactive({
          org_id: current_org?.id,
          aim_org_id: current_org?.id,
          head_list: props.head_list.map((item: any) => {
            return item.value;
          }),
        }),
      },
      options: {
        head_list: props.head_list,
      },
    });
    const columns: any = reactive([
      {
        title: '序号',
        dataIndex: 'index',
        width: 80,
        slots: { customRender: 'index' },
      },
      {
        title: '名称',
        dataIndex: 'name',
      },
      {
        title: 'url',
        dataIndex: 'fileurl',
        slots: { customRender: 'fileurl' },
        width: 185,
      },
      {
        title: '大小',
        dataIndex: 'size',
        slots: { customRender: 'size' },
        width: 80,
      },
      {
        title: '创建时间',
        dataIndex: 'create_time',
        slots: { customRender: 'create_time' },
        width: 150,
      },
    ]);
    const tableHeight = reactive({ y: 500, x: 300 });

    onMounted(() => {
      loadData();
    });

    const { resetFields, validate, validateInfos } = useForm(state.form.model, state.form.rules);
    const handleSubmit = (e: Event) => {
      e.preventDefault();
      validate()
        .then(() => {
          loading.value = true;
          let payload = {
            ...state.form.model,
            head_list: JSON.stringify(state.form.model.head_list),
          };
          export_productionrecorditem_datas(payload)
            .then((param: any) => {
              message.success('正在导出，请稍后');
              setTimeout(() => {
                loadData();
              }, 10000);
            })
            .finally(() => {
              loading.value = false;
            });
        })
        .finally(() => {})
        .catch(err => {
          console.log('error', err);
        });
    };
    const loadData = () => {
      tableLoading.value = true;
      state.dataSource.length = 0 ;
      query_all_nsfile_list({
        current: 1,
        pageSize: 20,
        org_id: current_org?.id,
        group: 'productionrecorditem',
      })
        .then((res: any) => {
          state.dataSource = res.data;
        })
        .finally(() => {
          tableLoading.value = false;
        });
    };
    const selectedAll = () => {
      if (state.form.model.head_list.length > 0) {
        state.form.model.head_list.length = 0;
      } else {
        state.form.model.head_list = props.head_list.map((item: any) => {
          return item.value;
        });
      }
    };
    const refreshTable = () => {
      loadData();
    };
    const download = (record: any) => {
      get_file_url({
        fileid: record.id,
      }).then((res: any) => {
        if(res){
          window.open(res, '_blank');
        }else{
          notification.error({
            message: '数据错误',
            description:
              '请重新导出数据',
          });
        }
      })
    };
    return {
      props,
      state,
      columns,
      resetFields,
      validateInfos,
      tableHeight,
      loading,
      selectedAll,
      download,
      refreshTable,
      handleSubmit,
    };
  },
});
