
import { defineComponent, reactive, ref, UnwrapRef } from 'vue';

const columns = [
  {
    title: '序号',
    dataIndex: 'key',
    width: '10%',
  },
  {
    title: '属性',
    dataIndex: 'name',
    width: '45%',
    slots: { customRender: 'name' },
  },
  {
    title: '值',
    dataIndex: 'value',
    width: '45%',
    slots: { customRender: 'value' },
  },
];
interface DataItem {
  key: string;
  name: string;
  value: string;
}

export default defineComponent({
  name: 'AttributeTableModal',
  props: {
    value: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const dataSource: any = ref([]);
    if (props.value && props.value.hasOwnProperty('length')) {
      props.value.map((item: any, index: number) => {
        dataSource.value.push({ key: (index + 1).toString(), ...item });
      });
    }

    return {
      props,
      columns,
      dataSource,
    };
  },

  data() {
    return {
      model: this.value,
    };
  },
  components: {},
});
