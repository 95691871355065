import { query_org_for_login_list } from '@/api/org-model';

export default () => {

  const search_config: any = {
    model: {},
    watch: [],
  };

  return {
    getData: query_org_for_login_list,
    searchConfig: search_config,
  };
};
