
import { defineComponent, ref, computed, watch, cloneVNode, VNode } from 'vue';
import { useI18n } from 'vue-i18n';
import { genMenuInfo, getMenuFirstChildren } from '@/utils/menu-util';
import useDelayReset from '@/utils/hooks/useDelayReset';
import { default as WrapContent } from '@/components/base-layouts/wrap-content/index.vue';
import { default as GlobalFooter } from '@/components/base-layouts/global-footer/index.vue';
import { default as SiderMenu } from '@/components/base-layouts/sider-menu/index.vue';
import { default as HeaderView } from '@/components/base-layouts/header/index.vue';
import { default as SelectLang } from '@/components/select-lang/index.vue';
import { default as AvatarDropdown } from '@/components/avatar-dropdown.vue';
import { default as SettingDrawer } from '@/components/setting-drawer/index.vue';
import { injectMenuState } from './use-menu-state';
import { useStore } from 'vuex';

export default defineComponent({
  name: 'LeftMenuLayout',
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const currentUser = computed(() => store.getters['user/currentUser']);

    // gen menus
    const allowRouters = computed(() => store.getters[`user/allowRouters`]); // genMenuInfo(filterMenu(routes)).menus;
    const menus = computed(() => genMenuInfo(allowRouters.value).menus);
    const mainMenus = computed(() => getMenuFirstChildren(menus.value));
    const menuState = injectMenuState();
    const firstSelectedKeys = computed(() => {
      const key = menuState.selectedKeys ? menuState.selectedKeys.value[0] : undefined;
      return key ? [`/${key.split('/')[1]}`] : [];
    });
    const [menuHover, setMenuHover, cancelSetMenuHover] = useDelayReset(100);
    const hoverMenuKey = ref('');
    const handleItemHover = (item: { key: string }) => {
      console.log('key', item);
      cancelSetMenuHover();
      menuHover.value = true;
      hoverMenuKey.value = item.key;
    };
    const handleSelectedKeysChange = (keys: string[]) => {
      const menu = menus.value.find(m => m.path === keys[0]);
      const toKey = menu && menu.redirect ? [menu.redirect] : keys;
      menuState.updateSelectKeys(toKey);
    };
    const handleMouseLeave = () => {
      setMenuHover(false);
    };
    watch(menuHover, () => {
      // 重置为空
      if (!menuHover.value) {
        hoverMenuKey.value = '';
      }
    });
    const secondMenus = computed(() => {
      return menus.value.find(m => m.path === hoverMenuKey.value)?.children || [];
    });
    // 是否展示hover一级时，展示对应的二级菜单
    const showHoverSiderMenu = computed(() => {
      return (
        menuHover.value &&
        !menuState.selectedKeys?.value.includes(hoverMenuKey.value) &&
        secondMenus.value &&
        secondMenus.value.length
      );
    });

    const customItem = (vnode: VNode, opt: any) => {
      return cloneVNode(vnode, {
        class: opt.path === hoverMenuKey.value ? 'hover-menu-active' : '',
      });
    };

    return {
      t,
      menus,
      mainMenus,
      collapsedFirst: ref(false),
      ...menuState,
      firstSelectedKeys,
      currentUser,
      handleItemHover,
      handleMouseLeave,
      showHoverSiderMenu,
      secondMenus,
      hoverMenuKey,
      cancelSetMenuHover,
      customItem,
      handleSelectedKeysChange,
    };
  },
  components: {
    WrapContent,
    SiderMenu,
    GlobalFooter,
    HeaderView,
    SelectLang,
    AvatarDropdown,
    SettingDrawer,
  },
});
