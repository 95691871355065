import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "ant-pro-table mytable" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_file_two_tone = _resolveComponent("file-two-tone")!
  const _component_s_table = _resolveComponent("s-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_s_table, {
      class: "steel_tapping2",
      bordered: true,
      scroll: { y: _ctx.height },
      columns: _ctx.columns,
      rowClassName: _ctx.setRowClassName,
      customRow: _ctx.custom,
      loading: _ctx.loading,
      rowKey: 'key',
      "data-source": _ctx.cardDataSource,
      pagination: false,
      showHeader: false,
      expandRowByClick: true,
      "row-height": 30
    }, {
      bodyCell: _withCtx(({ column, index, text, record }) => [
        (column.key === 'name')
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_file_two_tone),
              _createTextVNode(" " + _toDisplayString(record['name']), 1)
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["scroll", "columns", "rowClassName", "customRow", "loading", "data-source"])
  ]))
}