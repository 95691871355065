import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  style: {"margin-top":"auto"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_avatar = _resolveComponent("a-avatar")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return ((_ctx.column.content ? _ctx.column.content(_ctx.record, _ctx.text) : '') && _ctx.record.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_a_tooltip, null, {
          title: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(_ctx.record.user), 1)
          ]),
          default: _withCtx(() => [
            _createVNode(_component_a_badge, { style: {"margin-top":"2px"} }, {
              default: _withCtx(() => [
                _createVNode(_component_a_avatar, {
                  shape: "square",
                  size: "large",
                  style: { backgroundColor: '#0089FF', verticalAlign: 'middle' }
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.column.cb ? _ctx.column.cb(_ctx.record) : ''), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}