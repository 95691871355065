import { query_arrange_shift_list } from '@/api/org-model';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';



export default () => {
  const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const search_config: any = {
    model: {
      org_id: org?.id,
      is_active: true,
      is_type: 10,
      is_me: false,
      pageSize: 100,
    },
    watch: [],
  };
  return {
    getData: query_arrange_shift_list,
    searchConfig: search_config,
  };
};
