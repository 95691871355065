import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!

  return (_ctx.column.content(_ctx.record, _ctx.text) || _ctx.text)
    ? (_openBlock(), _createBlock(_component_a_tag, {
        key: 0,
        color: _ctx.column.color ? _ctx.column.color(_ctx.record, _ctx.text) : 'green',
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.column.cb ? _ctx.column.cb(_ctx.record, _ctx.text) : ''))
      }, {
        default: _withCtx(() => [
          (_ctx.column.content)
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.column.content(_ctx.record, _ctx.text)), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.text), 1))
        ]),
        _: 1
      }, 8, ["color"]))
    : _createCommentVNode("", true)
}