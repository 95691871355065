import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sider_menu = _resolveComponent("sider-menu")!
  const _component_a_drawer = _resolveComponent("a-drawer")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_BellOutlined = _resolveComponent("BellOutlined")!
  const _component_avatar_dropdown = _resolveComponent("avatar-dropdown")!
  const _component_select_lang = _resolveComponent("select-lang")!
  const _component_a_input_group = _resolveComponent("a-input-group")!
  const _component_header_view2 = _resolveComponent("header-view2")!
  const _component_multi_tab = _resolveComponent("multi-tab")!
  const _component_wrap_content = _resolveComponent("wrap-content")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_pro_provider = _resolveComponent("pro-provider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_pro_provider, {
      i18n: _ctx.t,
      "content-width": _ctx.contentWidth
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_layout, { class: "ant-pro-basicLayout" }, {
          default: _withCtx(() => [
            (_ctx.isMobile)
              ? (_openBlock(), _createBlock(_component_a_drawer, {
                  key: 0,
                  bodyStyle: { padding: 0, height: '100%' },
                  placement: "left",
                  width: _ctx.sideWidth,
                  closable: false,
                  visible: !_ctx.collapsed,
                  "onUpdate:visible": _ctx.updateCollapsed
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_sider_menu, {
                      style: {"height":"100%"},
                      theme: _ctx.theme,
                      layout: _ctx.layout,
                      fixed: _ctx.fixedSidebar,
                      menus: _ctx.menus,
                      "sider-width": _ctx.sideWidth,
                      "split-menus": false,
                      "collapsed-button": false,
                      collapsed: false,
                      "open-keys": _ctx.openKeys,
                      "onUpdate:open-keys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.openKeys) = $event)),
                      "selected-keys": _ctx.selectedKeys,
                      "onUpdate:selected-keys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedKeys) = $event))
                    }, null, 8, ["theme", "layout", "fixed", "menus", "sider-width", "open-keys", "selected-keys"])
                  ]),
                  _: 1
                }, 8, ["width", "visible", "onUpdate:visible"]))
              : (!_ctx.hasTopMenu)
                ? (_openBlock(), _createBlock(_component_sider_menu, {
                    key: 1,
                    theme: _ctx.theme,
                    layout: _ctx.layout,
                    fixed: _ctx.fixedSidebar,
                    menus: _ctx.menus,
                    "sider-width": _ctx.sideWidth,
                    "split-menus": _ctx.splitMenus,
                    "collapsed-button": !_ctx.splitMenus,
                    collapsed: _ctx.collapsed,
                    "onUpdate:collapsed": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.collapsed) = $event)),
                    "open-keys": _ctx.openKeys,
                    "onUpdate:open-keys": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.openKeys) = $event)),
                    "selected-keys": _ctx.selectedKeys,
                    "onUpdate:selected-keys": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedKeys) = $event))
                  }, null, 8, ["theme", "layout", "fixed", "menus", "sider-width", "split-menus", "collapsed-button", "collapsed", "open-keys", "selected-keys"]))
                : _createCommentVNode("", true),
            _createVNode(_component_a_layout, null, {
              default: _withCtx(() => [
                _createVNode(_component_header_view2, {
                  theme: _ctx.theme,
                  layout: _ctx.layout,
                  menus: _ctx.menus,
                  "sider-width": _ctx.sideWidth,
                  "has-sider-menu": _ctx.hasSideMenu,
                  "fixed-header": _ctx.fixedHeader,
                  "split-menus": _ctx.splitMenus,
                  collapsed: _ctx.collapsed,
                  "onUpdate:collapsed": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.collapsed) = $event)),
                  "open-keys": _ctx.openKeys,
                  "onUpdate:open-keys": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.openKeys) = $event)),
                  "selected-keys": _ctx.selectedKeys,
                  "onUpdate:selected-keys": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.selectedKeys) = $event))
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode(_component_a_input_group, { compact: "" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_select, {
                            value: _ctx.select_company,
                            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.select_company) = $event)),
                            dropdownMatchSelectWidth: false,
                            style: {"width":"200px","margin-right":"10px","margin-top":"8px"},
                            onChange: _ctx.selectCompany
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.company_list, (obj, index) => {
                                return (_openBlock(), _createBlock(_component_a_select_option, {
                                  key: index,
                                  value: obj.id
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(obj.name), 1)
                                  ]),
                                  _: 2
                                }, 1032, ["value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["value", "onChange"]),
                          _createVNode(_component_a_tooltip, null, {
                            title: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.t('page.kefu.new_contract')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_badge, {
                                dot: _ctx.is_sign_contract,
                                offset: [-8,8]
                              }, {
                                default: _withCtx(() => [
                                  (_ctx.is_sign_contract)
                                    ? (_openBlock(), _createBlock(_component_a_button, {
                                        key: 0,
                                        size: "small",
                                        style: {"margin-top":"8px","margin-right":"10px"},
                                        type: "primary",
                                        onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.to_Induction_process()))
                                      }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.t('router.title.Induction_process')), 1)
                                        ]),
                                        _: 1
                                      }))
                                    : _createCommentVNode("", true)
                                ]),
                                _: 1
                              }, 8, ["dot"])
                            ]),
                            _: 1
                          }),
                          (_ctx.mqtt_state == -1)
                            ? (_openBlock(), _createBlock(_component_a_tag, {
                                key: 0,
                                color: "red",
                                style: {"margin-top":"10px","margin-right":"5px"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('page.kefu.off_line')), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.mqtt_state == 1)
                            ? (_openBlock(), _createBlock(_component_a_tag, {
                                key: 1,
                                style: {"margin-top":"10px","margin-right":"5px"},
                                color: "green"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('page.kefu.on_line')), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_ctx.mqtt_state == 0)
                            ? (_openBlock(), _createBlock(_component_a_tag, {
                                key: 2,
                                style: {"margin-top":"10px","margin-right":"5px"},
                                color: "blue"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('page.kefu.No_push')), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _createVNode(_component_a_tooltip, null, {
                            title: _withCtx(() => [
                              _createElementVNode("span", null, _toDisplayString(_ctx.t('page.kefu.customer_service')), 1)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_a_badge, {
                                count: _ctx.no_read_number,
                                style: {"margin-top":"5px","font-size":"8px","margin-right":"5px"}
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_BellOutlined, {
                                    style: {"margin-top":"10px","font-size":"16px","margin-right":"10px"},
                                    onClick: _ctx.to_kefu
                                  }, null, 8, ["onClick"])
                                ]),
                                _: 1
                              }, 8, ["count"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_avatar_dropdown, { "current-user": _ctx.currentUser }, null, 8, ["current-user"]),
                          _createVNode(_component_select_lang)
                        ]),
                        _: 1
                      })
                    ])
                  ]),
                  _: 1
                }, 8, ["theme", "layout", "menus", "sider-width", "has-sider-menu", "fixed-header", "split-menus", "collapsed", "open-keys", "selected-keys"]),
                (_ctx.multiTab)
                  ? (_openBlock(), _createBlock(_component_multi_tab, {
                      key: 0,
                      fixed: _ctx.multiTabFixed,
                      "sider-width": _ctx.sideWidth
                    }, null, 8, ["fixed", "sider-width"]))
                  : _createCommentVNode("", true),
                _createVNode(_component_router_view, null, {
                  default: _withCtx(({ Component }) => [
                    _createVNode(_component_wrap_content, null, {
                      default: _withCtx(() => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["i18n", "content-width"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.modal_list, (modal) => {
      return (_openBlock(), _createBlock(_resolveDynamicComponent(modal.component ? modal.component : 'form-modal'), _mergeProps({
        key: modal.id
      }, modal, {
        onUpdate: 
      data => {
        _ctx.modal_update(data, modal);
      }
    ,
        onOk: 
      data => {
        _ctx.modal_ok(data, modal);
      }
    ,
        onCancel: 
      data => {
        _ctx.modal_cancel(data, modal);
      }
    
      }), null, 16, ["onUpdate", "onOk", "onCancel"]))
    }), 128))
  ], 64))
}