const doc = require('@/assets/file-icon/doc.png');
const docx = require('@/assets/file-icon/docx.png');
const file = require('@/assets/file-icon/file.png');
const pdf = require('@/assets/file-icon/pdf.png');
const ppt = require('@/assets/file-icon/ppt.png');
const pptx = require('@/assets/file-icon/pptx.png');
const rar = require('@/assets/file-icon/rar.png');
const xls = require('@/assets/file-icon/xls.png');
const xlsx = require('@/assets/file-icon/xlsx.png');
const zip = require('@/assets/file-icon/zip.png');

export default (type: string) => {
  switch (type) {
    case 'doc':
      return doc;
    case 'docx':
      return docx;
    case 'file':
      return file;
    case 'pdf':
      return pdf;
    case 'ppt':
      return ppt;
    case 'pptx':
      return pptx;
    case 'rar':
      return rar;
    case 'xls':
      return xls;
    case 'xlsx':
      return xlsx;
    case 'zip':
      return zip;
    default:
      return file;
  }
};
