import { App } from 'vue';
import {
  HeartOutlined,
  HistoryOutlined,
  FormOutlined,
  ScissorOutlined,
  TableOutlined,
  AppstoreAddOutlined,
  UnorderedListOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  HomeOutlined,
  PartitionOutlined,
  MoneyCollectOutlined,
  AuditOutlined,
  IdcardOutlined,
  BankOutlined,
  TagOutlined,
  LinkOutlined,
  QuestionCircleOutlined,
  DeploymentUnitOutlined,
  NotificationOutlined,
  GatewayOutlined,
  KeyOutlined,
  SettingOutlined,
  SolutionOutlined,
  TeamOutlined,
  PieChartOutlined,
  UnlockOutlined,
  PicCenterOutlined,
  CarryOutOutlined,
  LoginOutlined,
  BarsOutlined,
  BarChartOutlined,
  UserSwitchOutlined,
  SnippetsOutlined,
  BugOutlined,
  PoweroffOutlined,
  ArrowLeftOutlined,
  InteractionOutlined,
  ArrowRightOutlined,
  ProjectOutlined,
  FileSyncOutlined,
  ContainerOutlined,
  ConsoleSqlOutlined,
  InfoCircleOutlined,
  StopOutlined,
  UserOutlined,
  IssuesCloseOutlined,
  ArrowUpOutlined,
  ProfileOutlined,
  HighlightOutlined,
  LockOutlined,
  ToolOutlined,
  ExpandOutlined,
  InsertRowBelowOutlined,
  BlockOutlined,
  UsbOutlined,
  CarOutlined,
  ArrowDownOutlined,
  FileDoneOutlined,
  ReloadOutlined,
  PlusCircleOutlined,
  DownOutlined,
  CodepenCircleOutlined,
  AppstoreOutlined,
  ColumnHeightOutlined,
  RedoOutlined,
  DeleteOutlined,
  EditOutlined,
  DownloadOutlined,
} from '@ant-design/icons-vue';

export const useIcons = (app: App) => {
  app.component(HeartOutlined.displayName, HeartOutlined);
  app.component(InsertRowBelowOutlined.displayName, InsertRowBelowOutlined);
  app.component(LoginOutlined.displayName, LoginOutlined);
  app.component(UserOutlined.displayName, UserOutlined);
  app.component(TeamOutlined.displayName, TeamOutlined);
  app.component(ToolOutlined.displayName, ToolOutlined);
  app.component(FileDoneOutlined.displayName, FileDoneOutlined);
  app.component(UsbOutlined.displayName, UsbOutlined);
  app.component(HighlightOutlined.displayName, HighlightOutlined);
  app.component(ExpandOutlined.displayName, ExpandOutlined);
  app.component(BlockOutlined.displayName, BlockOutlined);
  app.component(CarOutlined.displayName, CarOutlined);
  app.component(ProfileOutlined.displayName, ProfileOutlined);
  app.component(LockOutlined.displayName, LockOutlined);
  app.component(BankOutlined.displayName, BankOutlined);
  app.component(FileSyncOutlined.displayName, FileSyncOutlined);
  app.component(HistoryOutlined.displayName, HistoryOutlined);
  app.component(FormOutlined.displayName, FormOutlined);
  app.component(ScissorOutlined.displayName, ScissorOutlined);
  app.component(TableOutlined.displayName, TableOutlined);
  app.component(AppstoreAddOutlined.displayName, AppstoreAddOutlined);
  app.component(UnorderedListOutlined.displayName, UnorderedListOutlined);
  app.component(WarningOutlined.displayName, WarningOutlined);
  app.component(PicCenterOutlined.displayName, PicCenterOutlined);
  app.component(CheckCircleOutlined.displayName, CheckCircleOutlined);
  app.component(HomeOutlined.displayName, HomeOutlined);
  app.component(PartitionOutlined.displayName, PartitionOutlined);
  app.component(MoneyCollectOutlined.displayName, MoneyCollectOutlined);
  app.component(IdcardOutlined.displayName, IdcardOutlined);
  app.component(AuditOutlined.displayName, AuditOutlined);
  app.component(LinkOutlined.displayName, LinkOutlined);
  app.component(QuestionCircleOutlined.displayName, QuestionCircleOutlined);
  app.component(TagOutlined.displayName, TagOutlined);
  app.component(DeploymentUnitOutlined.displayName, DeploymentUnitOutlined);
  app.component(NotificationOutlined.displayName, NotificationOutlined);
  app.component(GatewayOutlined.displayName, GatewayOutlined);
  app.component(KeyOutlined.displayName, KeyOutlined);
  app.component(SettingOutlined.displayName, SettingOutlined);
  app.component(UnlockOutlined.displayName, UnlockOutlined);
  app.component(ProjectOutlined.displayName, ProjectOutlined);
  app.component(ConsoleSqlOutlined.displayName, ConsoleSqlOutlined);
  app.component(PieChartOutlined.displayName, PieChartOutlined);
  app.component(ContainerOutlined.displayName, ContainerOutlined);
  app.component(CarryOutOutlined.displayName, CarryOutOutlined);
  app.component(BarsOutlined.displayName, BarsOutlined);
  app.component(InteractionOutlined.displayName, InteractionOutlined);
  app.component(PoweroffOutlined.displayName, PoweroffOutlined);
  app.component(BarChartOutlined.displayName, BarChartOutlined);
  app.component(UserSwitchOutlined.displayName, UserSwitchOutlined);
  app.component(SnippetsOutlined.displayName, SnippetsOutlined);
  app.component(SolutionOutlined.displayName, SolutionOutlined);
  app.component(BugOutlined.displayName, BugOutlined);
  app.component(ArrowLeftOutlined.displayName, ArrowLeftOutlined);
  app.component(ArrowRightOutlined.displayName, ArrowRightOutlined);
  app.component(InfoCircleOutlined.displayName, InfoCircleOutlined);
  app.component(StopOutlined.displayName, StopOutlined);
  app.component(IssuesCloseOutlined.displayName, IssuesCloseOutlined);
  app.component(ArrowUpOutlined.displayName, ArrowUpOutlined);
  app.component(ReloadOutlined.displayName, ReloadOutlined);
  app.component(PlusCircleOutlined.displayName, PlusCircleOutlined);
  app.component(DownOutlined.displayName, DownOutlined);
  app.component(ArrowDownOutlined.displayName, ArrowDownOutlined);
  app.component(AppstoreOutlined.displayName, AppstoreOutlined);
  app.component(CodepenCircleOutlined.displayName, CodepenCircleOutlined);
  app.component(ColumnHeightOutlined.displayName, ColumnHeightOutlined);
  app.component(RedoOutlined.displayName, RedoOutlined);
  app.component(DeleteOutlined.displayName, DeleteOutlined);
  app.component(EditOutlined.displayName, EditOutlined);
  app.component(DownloadOutlined.displayName, DownloadOutlined);
};
