import {
  delete_next_of_kin,
  recover_next_of_kin,
  query_next_of_kin_list,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { SearchConfig, Record } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import {STORAGE_CURRENT_ADVISER_KEY, STORAGE_CURRENT_ORG_KEY} from '@/store/mutation-type';
import {
  slotList,
  btn_list,
  head_btn_list,
  event_obj,
  config,
} from '@/components/page-model4/cerp/cerp-nextofkin-config';

import { reactive } from 'vue';
import {getDateTime} from "@/utils/function";

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const adviser = ls.get(STORAGE_CURRENT_ADVISER_KEY);
const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'search_text',
      label: '模糊搜索',
      label_i18n: t('page.cerp.nextofkin.form.label.search_text'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写搜索内容',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.search_text'),
    },
    {
      type: 'input',
      name: 'name',
      label: '姓名',
      label_i18n: t('page.cerp.nextofkin.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓名',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'releationship',
      label: '关系',
      label_i18n: t('page.cerp.nextofkin.form.label.releationship'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写关系',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.releationship'),
    },
    {
      type: 'input',
      name: 'contact_number',
      label: '联系电话',
      label_i18n: t('page.cerp.nextofkin.form.label.contact_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系电话',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.contact_number'),
    },
    {
      type: 'input',
      name: 'email',
      label: '联系邮箱',
      label_i18n: t('page.cerp.nextofkin.form.label.email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系邮箱',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.email'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    search_text: '',
    adviser_id: adviser.adviser_id,
    name: null,
    releationship: null,
    contact_number: null,
    email: null,
  }),
};
const search_options = {
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '姓名',
      label_i18n: t('page.cerp.nextofkin.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓名',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'releationship',
      label: '关系',
      label_i18n: t('page.cerp.nextofkin.form.label.releationship'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写关系',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.releationship'),
    },
    {
      type: 'input',
      name: 'contact_number',
      label: '联系电话',
      label_i18n: t('page.cerp.nextofkin.form.label.contact_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系电话',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.contact_number'),
    },
    {
      type: 'input',
      name: 'email',
      label: '联系邮箱',
      label_i18n: t('page.cerp.nextofkin.form.label.email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系邮箱',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.email'),
    },
  ],
  rules: {
    name: [{ required: true, message: '请填输入姓名' }],
    releationship: [{ required: true, message: '请填输入关系' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: adviser.adviser_id,
    name: null,
    releationship: null,
    contact_number: null,
    email: null,
  }),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '姓名',
      label_i18n: t('page.cerp.nextofkin.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓名',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.name'),
    },
    {
      type: 'input',
      name: 'releationship',
      label: '关系',
      label_i18n: t('page.cerp.nextofkin.form.label.releationship'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写关系',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.releationship'),
    },
    {
      type: 'input',
      name: 'contact_number',
      label: '联系电话',
      label_i18n: t('page.cerp.nextofkin.form.label.contact_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系电话',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.contact_number'),
    },
    {
      type: 'input',
      name: 'email',
      label: '联系邮箱',
      label_i18n: t('page.cerp.nextofkin.form.label.email'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写联系邮箱',
      placeholder_i18n: t('page.cerp.nextofkin.form.placeholder.email'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: adviser.adviser_id,
    name: null,
    releationship: null,
    contact_number: null,
    email: null,
  }),
};
const update_model_config_options = {};

const titleList = {
  title: '紧急联系人管理',
  addModalTitle: '新建紧急联系人',
  updateTitle: '修改紧急联系人',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    next_of_kin: 'id',
  },
  delete: {
    next_of_kin: 'id',
  },
  recover: {
    next_of_kin: 'id',
  },
  updateData: {
    org_id: 'org_id',
    next_of_kin: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.nextofkin.column.title.index'),
    dataIndex: 'index',
    width: 50,
  },
  {
    title: t('page.cerp.nextofkin.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.cerp.nextofkin.column.title.adviser__full_name'),
    minWidth: 150,
    dataIndex: 'adviser__full_name',
    checked: false,
  },
  {
    title: t('page.cerp.nextofkin.column.title.name'),
    minWidth: 150,
    dataIndex: 'name',
    checked: true,
  },
  {
    title: t('page.cerp.nextofkin.column.title.releationship'),
    width: 150,
    dataIndex: 'releationship',
    checked: true,
  },
  {
    title: t('page.cerp.nextofkin.column.title.contact_number'),
    width: 150,
    dataIndex: 'contact_number',
    checked: true,
  },
  {
    title: t('page.cerp.nextofkin.column.title.email'),
    width: 150,
    dataIndex: 'email',
    checked: true,
  },
  {
    title: t('page.cerp.nextofkin.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: false,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.cerp.nextofkin.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: false,
    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.cerp.nextofkin.column.title.is_active'),
    width: 150,
    dataIndex: 'is_active',
    checked: false,

    cellComponent: 'boolean',
    name1: '可用',
    name2: '禁用',
    filterMultiple: false,
    filters: [
      { text: '全部', value: '' },
      { text: '可用', value: true },
      { text: '禁用', value: false },
    ],
  },

  {
    title: t('page.cerp.nextofkin.column.title.action'),
    width: 100,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_next_of_kin_list,
    addData: null,
    updateData: null,
    removeData: delete_next_of_kin,
    recoverData: recover_next_of_kin,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
