
import {defineComponent, getCurrentInstance, onMounted, onUnmounted, reactive} from 'vue';
import {useI18n} from "vue-i18n";
import FormModal from '@/components/form-modal/form-modal.vue';
import router from "@/router";

export default defineComponent({
  name: 'UserLayout',
  setup() {
    onMounted(() => {
      document.body.classList.add('userLayout');
    });
    onUnmounted(() => {
      document.body.classList.remove('userLayout');
    });
    const modal_list: any = reactive([]);
    let $bus: {
      emit: (arg0: string, arg1: any) => void;
      off: (arg0: string) => void;
      on: (arg0: string, arg1: any) => void;
    } | null = null;
    $bus = getCurrentInstance()?.appContext.config.globalProperties.$bus;

    $bus?.on('modal.add', (modal: any) => {
      if (!modal.id) {
        modal.id = Date.now();
      }
      modal_list.push(modal);
    });
    const modal_ok = (data: any, modal: any) => {
      if (modal.ok) {
        const result = modal.ok(data, modal);
        if (result instanceof Promise) {
          result.then(function () {
            modal_list.splice(modal_list.indexOf(modal, 0), 1);
          });
        } else if (result === true || result === null || result === undefined) {
          modal_list.splice(modal_list.indexOf(modal, 0), 1);
        }
      } else {
        modal_list.splice(modal_list.indexOf(modal, 0), 1);
      }
    };
    const modal_update = (data: any, modal: any) => {
      if (modal.update) {
        const result = modal.update(data, modal);
        if (result instanceof Promise) {
          result.then(function () {
            modal_list.splice(modal_list.indexOf(modal, 0), 1);
          });
        } else if (result === true || result === null || result === undefined) {
          modal_list.splice(modal_list.indexOf(modal, 0), 1);
        }
      } else {
        modal_list.splice(modal_list.indexOf(modal, 0), 1);
      }
    };

    const modal_cancel = (data: any, modal: any) => {
      if (modal.cancel) {
        modal.cancel(data, modal);
      }
      modal_list.splice(modal_list.indexOf(modal, 0), 1);
    };
    const { t } = useI18n();
    return {
      t,
      modal_list,
      modal_ok,
      modal_update,
      modal_cancel,
    };
  },
  components: {
    FormModal,
  },
});
