<template>
  <div class="setting-content">
    <a-form :model="field" layout="vertical" style="padding: 10px">
      <a-divider orientation="left">基本配置</a-divider>
      <a-form-item v-if="field.type !== 'divider'" label="字段名称">
        <a-input v-model:value="field.name" />
      </a-form-item>
      <a-form-item label="名称">
        <a-input v-model:value="field.label" />
      </a-form-item>
      <a-form-item label="提示文字" v-if="defaultPlaceholder[field.type] !== undefined">
        <a-input v-model:value="field.placeholder" />
      </a-form-item>
      <a-form-item label="是否显示清空按钮" v-if="field.allowClear !== undefined">
        <a-switch v-model:checked="field.allowClear" />
      </a-form-item>
      <a-form-item label="模式" v-if="field.type === 'select'">
        <a-radio-group v-model:value="field.mode">
          <a-radio-button value="default">单选</a-radio-button>
          <a-radio-button value="multiple">多选</a-radio-button>
          <a-radio-button value="tags">标签</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <template v-if="'select,tree,cascader,checkbox,radio'.indexOf(field.type) > -1">
        <a-form-item label="数据源类型">
          <a-radio-group v-model:value="field.datasourceType">
            <a-radio-button value="remote">远程地址</a-radio-button>
            <a-radio-button value="dictionary">字典数据</a-radio-button>
            <a-radio-button value="table">表数据</a-radio-button>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="地址" v-if="field.datasourceType === 'remote'">
          <a-input v-model:value="field.datasource" />
        </a-form-item>
        <a-form-item label="字典名" v-if="field.datasourceType === 'dictionary'">
          <a-input v-model:value="field.datasource" />
        </a-form-item>
        <a-form-item label="表名" v-if="field.datasourceType === 'table'">
          <a-input v-model:value="field.datasource" />
        </a-form-item>
        <a-form-item label="下拉菜单Value对应Key">
          <a-input v-model:value="field.valueKey" />
        </a-form-item>
        <a-form-item label="下拉菜单Label对应Key">
          <a-input v-model:value="field.labelKey" />
        </a-form-item>
      </template>
      <a-form-item label="文字位置" v-if="field.type === 'divider'">
        <a-radio-group v-model:value="field.orientation">
          <a-radio-button value="left">左</a-radio-button>
          <a-radio-button value="center">中</a-radio-button>
          <a-radio-button value="right">右</a-radio-button>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { defaultRules, defaultValue, defaultPlaceholder } from './defaultConfig';

export default {
  name: 'FieldSetting',
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      defaultPlaceholder: defaultPlaceholder,
      defaultValue: defaultValue,
      defaultRules: defaultRules,
    };
  },
};
</script>
