import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
import {
  send_payslip_by_email,
  confirm_payslip,
  create_payslip_pdf,
  query_contract_list,
  update_payslip,
  create_bank_transaction,
  modify_payslip_mangefee,
  pay_payslip, cancel_pay_payslip, bulk_pay_payslip_zero, set_payslip_pay_together_status,
} from '@/api/cerp-model';
import { message, Modal } from 'ant-design-vue';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import {createVNode, reactive} from 'vue';
import modalBox from '@/components/form-modal/modal-tools';
import moment from 'moment';
import {ExclamationCircleOutlined} from "@ant-design/icons-vue";
const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org

export const event_obj = mitt();
export const btn_list = [];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [7, 7],
  updateCol: [],
};

export const head_btn_list = [];
