
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'RightContent',
  props: {
    rightSize: {
      type: Number,
      default: 208,
    },
  },
  setup() {
    return {};
  },
});
