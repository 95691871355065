import { query_person_by_org_list } from '@/api/sys/index';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';



export default () => {
  const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const search_config: any = {
    model: {
      org_id: org?.id,
      is_active: true,
      page_index: 1,
      page_size: 2000,
    },
    watch: [],
  };
  return {
    getData: query_person_by_org_list,
    searchConfig: search_config,
  };
};
