import request from '@/utils/request';
import { PageResult } from '@/api/typing';
import {CONFIGURATION_FILE, ROLL_NO, STORAGE_CURRENT_ORG_KEY} from "@/store/mutation-type";
import ls from '@/utils/local-storage';

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
export async function set_stop_event_plan_position_rollers(params: Record<string, any>) {
  return request.post('/production_manage/set_stop_event_plan_position_rollers', params);
}

export async function update_product_item_gong_yi(params: Record<string, any>) {
  return request.post('/production_manage/update_product_item_gong_yi', params);
}
export async function create_custom_production_record(params: Record<string, any>) {
  return request.post('/production_manage/create_custom_production_record', params);
}

export async function delete_custom_production_record(params: Record<string, any>) {
  return request.post('/production_manage/delete_custom_production_record', params);
}

export async function update_production_record_heat_no(params: Record<string, any>) {
  return request.post('/production_manage/update_production_record_heat_no', params);
}
export async function create_custom_production_record_item(params: Record<string, any>) {
  return request.post('/production_manage/create_custom_production_record_item', params);
}
export async function create_weight_priority_selection_item(params: Record<string, any>) {
  return request.post('/production_manage/create_weight_priority_selection_item', params);
}
export async function get_weight_priority_selection_item_list(params: Record<string, any>) {
  return request.post('/production_manage/get_weight_priority_selection_item_list', params);
}
export async function get_production_record_echart_data(params: Record<string, any>) {
  return request.post('/production_manage/get_production_record_echart_data', params);
}
export async function get_production_record_item_echart_data(params: Record<string, any>) {
  return request.post('/production_manage/get_production_record_item_echart_data', params);
}
export async function merge_production_record(params: Record<string, any>) {
  return request.post('/production_manage/merge_production_record', params);
}
export async function update_production_record_items_conclusion_status(
  params: Record<string, any>,
) {
  return request.post(
    '/production_manage/update_production_record_items_conclusion_status',
    params,
  );
}

export async function update_opc_name(params: Record<string, any>) {
  return request.post('/production_manage/update_opc_name', params);
}


export async function get_plan_excel_import_setting_by_flag(params: Record<string, any>) {
  return request.post('/production_manage/get_plan_excel_import_setting_by_flag', params);
}

export async function exchange_production_record(params: Record<string, any>) {
  return request.post('/production_manage/exchange_production_record', params);
}

export async function update_production_record_prod_thick_and_prod_len(
  params: Record<string, any>,
) {
  return request.post(
    '/production_manage/update_production_record_prod_thick_and_prod_len',
    params,
  );
}

export async function recover_billet_grade(params: Record<string, any>) {
  return request.post('/production_manage/recover_billet_grade', params);
}

export async function delete_billet_grade(params: Record<string, any>) {
  return request.post('/production_manage/delete_billet_grade', params);
}

export async function update_billet_grade(params: Record<string, any>) {
  return request.post('/production_manage/update_billet_grade', params);
}

export async function create_billet_grade(params: Record<string, any>) {
  return request.post('/production_manage/create_billet_grade', params);
}

export async function query_billet_grade_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_billet_grade_list', params);
}

export async function query_billet_type_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_billet_type_list', params);
}

export async function recover_billet_type(params: Record<string, any>) {
  return request.post('/production_manage/recover_billet_type', params);
}

export async function delete_billet_type(params: Record<string, any>) {
  return request.post('/production_manage/delete_billet_type', params);
}

export async function update_billet_type(params: Record<string, any>) {
  return request.post('/production_manage/update_billet_type', params);
}

export async function create_steel_type(params: Record<string, any>) {
  return request.post('/production_manage/create_steel_type', params);
}

export async function create_billet_type(params: Record<string, any>) {
  return request.post('/production_manage/create_billet_type', params);
}

export async function delete_steel_type(params: Record<string, any>) {
  return request.post('/production_manage/delete_steel_type', params);
}

export async function recover_steel_type(params: Record<string, any>) {
  return request.post('/production_manage/recover_steel_type', params);
}

export async function update_steel_type(params: Record<string, any>) {
  return request.post('/production_manage/update_steel_type', params);
}

export async function create_steel_diameter(params: Record<string, any>) {
  return request.post('/production_manage/create_steel_diameter', params);
}

export async function update_steel_diameter(params: Record<string, any>) {
  return request.post('/production_manage/update_steel_diameter', params);
}

export async function delete_steel_diameter(params: Record<string, any>) {
  return request.post('/production_manage/delete_steel_diameter', params);
}

export async function recover_steel_diameter(params: Record<string, any>) {
  return request.post('/production_manage/recover_steel_diameter', params);
}

export async function recover_product_length(params: Record<string, any>) {
  return request.post('/production_manage/recover_product_length', params);
}

export async function delete_product_length(params: Record<string, any>) {
  return request.post('/production_manage/delete_product_length', params);
}

export async function update_product_length(params: Record<string, any>) {
  return request.post('/production_manage/update_product_length', params);
}

export async function create_product_length(params: Record<string, any>) {
  return request.post('/production_manage/create_product_length', params);
}

export async function create_billet_length(params: Record<string, any>) {
  return request.post('/production_manage/create_billet_length', params);
}

export async function recover_billet_length(params: Record<string, any>) {
  return request.post('/production_manage/recover_billet_length', params);
}

export async function update_billet_length(params: Record<string, any>) {
  return request.post('/production_manage/update_billet_length', params);
}

export async function delete_billet_length(params: Record<string, any>) {
  return request.post('/production_manage/delete_billet_length', params);
}

export async function create_billet_thick(params: Record<string, any>) {
  return request.post('/production_manage/create_billet_thick', params);
}

export async function update_billet_thick(params: Record<string, any>) {
  return request.post('/production_manage/update_billet_thick', params);
}

export async function delete_billet_thick(params: Record<string, any>) {
  return request.post('/production_manage/delete_billet_thick', params);
}

export async function recover_billet_thick(params: Record<string, any>) {
  return request.post('/production_manage/recover_billet_thick', params);
}

export async function create_shape_type(params: Record<string, any>) {
  return request.post('/production_manage/create_shape_type', params);
}

export async function update_shape_type(params: Record<string, any>) {
  return request.post('/production_manage/update_shape_type', params);
}

export async function delete_shape_type(params: Record<string, any>) {
  return request.post('/production_manage/delete_shape_type', params);
}

export async function recover_shape_type(params: Record<string, any>) {
  return request.post('/production_manage/recover_shape_type', params);
}

export async function create_product_type(params: Record<string, any>) {
  return request.post('/production_manage/create_product_type', params);
}

export async function delete_product_type(params: Record<string, any>) {
  return request.post('/production_manage/delete_product_type', params);
}

export async function recover_product_type(params: Record<string, any>) {
  return request.post('/production_manage/recover_product_type', params);
}

export async function update_product_type(params: Record<string, any>) {
  return request.post('/production_manage/update_product_type', params);
}

export async function create_check_status(params: Record<string, any>) {
  return request.post('/production_manage/create_check_status', params);
}

export async function update_check_status(params: Record<string, any>) {
  return request.post('/production_manage/update_check_status', params);
}

export async function delete_check_status(params: Record<string, any>) {
  return request.post('/production_manage/delete_check_status', params);
}

export async function recover_check_status(params: Record<string, any>) {
  return request.post('/production_manage/recover_check_status', params);
}

export async function query_check_status_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_check_status_list', params);
}

export async function query_steel_diameter_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_steel_diameter_list', params);
}

export async function query_product_type_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_product_type_list', params);
}

export async function query_shape_type_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_shape_type_list', params);
}

export async function query_steel_type_by_plan_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_steel_type_by_plan_list', params);
}

export async function query_billet_thick_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_billet_thick_list', params);
}

export async function query_billet_length_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_billet_length_list', params);
}

export async function query_product_length_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_product_length_list', params);
}

export async function query_steel_type_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_steel_type_list', params);
}

export async function query_steel_type_name_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_steel_type_name_list', params);
}

export async function query_billet_type_name_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_billet_type_name_list', params);
}

export async function query_energy_info_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_energy_info_list', params);
}

export async function delete_weight_priority_selection_item(params: Record<string, any>) {
  return request.post('/production_manage/delete_weight_priority_selection_item', params);
}
export async function export_production_record_info(params: Record<string, any>) {
  return request.post('/production_manage/export_production_record_info', params);
}
export async function split_production_record(params: Record<string, any>) {
  return request.post('/production_manage/split_production_record', params);
}

export async function query_kpi_format_by_calculation_method(params: Record<string, any>) {
  return request.post('/production_manage/query_kpi_format_by_calculation_method', params);
}

export async function update_rulu_production_record_item_by_sort(
  params: Record<string, any>,
  config?: any,
) {
  return request.post(
    '/production_manage/update_rulu_production_record_item_by_sort',
    params,
    config,
  );
}

export async function query_erp_kpi_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_erp_kpi_list', params);
}
export async function query_custom_production_record_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_custom_production_record_list', params);
}

export async function query_production_record_item_by_return_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_item_by_return_list', params);
}

export async function add_groups_to_kpi_tag(params: Record<string, any>) {
  return request.post('/production_manage/add_groups_to_kpi_tag', params);
}

export async function create_kpidownloadset(params: Record<string, any>) {
  return request.post('/production_manage/create_kpidownloadset', params);
}

export async function query_production_record_prod_thick_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_prod_thick_list', params);
}

export async function query_zhong_zha_wen_du_by_shift_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_zhong_zha_wen_du_by_shift_list', params);
}

export async function delete_product_item(params: Record<string, any>) {
  return request.post('/production_manage/delete_product_item', params);
}

export async function custom_new_production_record_item(params: Record<string, any>) {
  return request.post('/production_manage/custom_new_production_record_item', params);
}

export async function create_kpi_tag(params: Record<string, any>) {
  return request.post('/production_manage/create_kpi_tag', params);
}

export async function get_erp_time(params: Record<string, any>) {
  return request.post('/production_manage/get_erp_time', params);
}

export async function update_kpi_format(params: Record<string, any>) {
  return request.post('/production_manage/update_kpi_format', params);
}

export async function all_custom_new_production_record_item(params: Record<string, any>) {
  return request.post('/production_manage/all_custom_new_production_record_item', params);
}

export async function query_production_record_by_week_for_chart(params: Record<string, any>) {
  return request.post('/production_manage/query_production_record_by_week_for_chart', params);
}

export async function inport_create_production_plan(params: Record<string, any>) {
  return request.post('/production_manage/inport_create_production_plan', params);
}

export async function query_production_resource_by_rate_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_resource_by_rate_list', params);
}

export async function analyse_shift_stop_time_result(params: Record<string, any>) {
  return request.post('/production_manage/analyse_shift_stop_time_result', params);
}

export async function check_report(params: Record<string, any>) {
  return request.post('/production_manage/check_report', params);
}

export async function query_kpigroup_by_download_set_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_kpigroup_by_download_set_list', params);
}

export async function del_groups_to_kpidownloadset(params: Record<string, any>) {
  return request.post('/production_manage/del_groups_to_kpidownloadset', params);
}

export async function update_production_resource_rate(params: Record<string, any>) {
  return request.post('/production_manage/update_production_resource_rate', params);
}

export async function delete_device_weight_sign_record(params: Record<string, any>) {
  return request.post('/production_manage/delete_device_weight_sign_record', params);
}

export async function download_excel_production_plan(params: Record<string, any>) {
  return request.post('/production_manage/download_excel_production_plan', params);
}

export async function query_plan_item_by_heat_no_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_plan_item_by_heat_no_list', params);
}

export async function query_cheng_zhong_by_shift_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_cheng_zhong_by_shift_list', params);
}

export async function query_product_item_shift_weight_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_product_item_shift_weight_list', params);
}

export async function delete_device_weight_sign(params: Record<string, any>) {
  return request.post('/production_manage/delete_device_weight_sign', params);
}

export async function query_production_record_by_shift_for_chart(params: Record<string, any>) {
  return request.post('/production_manage/query_production_record_by_shift_for_chart', params);
}

export async function analyse_production_daily_num_weight(params: Record<string, any>) {
  return request.post('/production_manage/analyse_production_daily_num_weight', params);
}

export async function get_kpi_group_record_json(params: Record<string, any>) {
  return request.post('/production_manage/get_kpi_group_record_json', params);
}

export async function analyse_product_and_stop_event_daily(params: Record<string, any>) {
  return request.post('/production_manage/analyse_product_and_stop_event_daily', params);
}

export async function get_erp_order_data(params: Record<string, any>) {
  return request.post('/production_manage/get_erp_order_data', params);
}

export async function get_production_record_by_roll_no(params: Record<string, any>) {
  return request.post('/production_manage/get_production_record_by_roll_no', params);
}

export async function update_all_production_edit_plan(params: Record<string, any>) {
  return request.post('/production_manage/update_all_production_edit_plan', params);
}

export async function cancel_product_item_by_order(params: Record<string, any>) {
  return request.post('/production_manage/cancel_product_item_by_order', params);
}

export async function query_kpi_tag_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_kpi_tag_list', params);
}

export async function analyse_work_rate_by_shift_result(params: Record<string, any>) {
  return request.post('/production_manage/analyse_work_rate_by_shift_result', params);
}

export async function get_kpi_group(params: Record<string, any>) {
  return request.post('/production_manage/get_kpi_group', params);
}

export async function create_product_gongyi(params: Record<string, any>) {
  return request.post('/production_manage/create_product_gongyi', params);
}

export async function query_production_record_info_by_record_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_info_by_record_list', params);
}

export async function set_kpi_value(params: Record<string, any>) {
  return request.post('/production_manage/set_kpi_value', params);
}

export async function query_production_record_info_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_info_list', params);
}

export async function add_row_by_kpi_group(params: Record<string, any>) {
  return request.post('/production_manage/add_row_by_kpi_group', params);
}

export async function query_all_product_gongyi_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_all_product_gongyi_list', params);
}

export async function create_production_resource_rate(params: Record<string, any>) {
  return request.post('/production_manage/create_production_resource_rate', params);
}

export async function update_product_item_product_type_sandbox(params: Record<string, any>) {
  return request.post('/production_manage/update_product_item_product_type_sandbox', params);
}

export async function delete_production_plan(params: Record<string, any>) {
  return request.post('/production_manage/delete_production_plan', params);
}

export async function create_kpi_echart(params: Record<string, any>) {
  return request.post('/production_manage/create_kpi_echart', params);
}

export async function update_product_gongyi(params: Record<string, any>) {
  return request.post('/production_manage/update_product_gongyi', params);
}

export async function update_production_return_record(params: Record<string, any>) {
  return request.post('/production_manage/update_production_return_record', params);
}

export async function query_product_item_list(params?: { [key: string]: any }) {
  return request.post('/production_manage/query_product_item_list', params);
}

export async function query_opc_name_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_opc_name_list', params);
}

export async function create_production_record_info(params: Record<string, any>) {
  return request.post('/production_manage/create_production_record_info', params);
}

export async function create_production_record(params: Record<string, any>) {
  return request.post('/production_manage/create_production_record', params);
}

export async function delete_production_record_by_roll_no_and_heat_no(params: Record<string, any>) {
  return request.post('/production_manage/delete_production_record_by_roll_no_and_heat_no', params);
}

export async function query_production_plan_shift_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_plan_shift_list', params);
}

export async function query_production_plan_org(params: Record<string, any>) {
  return request.post('/production_manage/query_production_plan_org', params);
}

export async function query_production_plan_org_list(params: Record<string, any>) {
  return request.post('/production_manage/query_production_plan_org_list', params);
}

export async function compare_excel_and_system_kpi_format(params: Record<string, any>) {
  return request.post('/production_manage/compare_excel_and_system_kpi_format', params);
}

export async function create_production_plan_send_email(params: Record<string, any>) {
  return request.post('/production_manage/create_production_plan_send_email', params);
}

export async function start_load_by_order_truck_no(params: Record<string, any>) {
  return request.post('/production_manage/start_load_by_order_truck_no', params);
}

export async function delete_product_item_by_bind_no(params: Record<string, any>) {
  return request.post('/production_manage/delete_product_item_by_bind_no', params);
}

export async function query_production_record_and_item_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_and_item_list', params);
}

export async function delete_roller_position_and_conclusion(params: Record<string, any>) {
  return request.post('/production_manage/delete_roller_position_and_conclusion', params);
}

export async function export_mutil_kpi_group_record(params: Record<string, any>) {
  return request.post('/production_manage/export_mutil_kpi_group_record', params);
}

export async function query_production_billet_accept_record_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_billet_accept_record_list', params);
}

export async function delete_production_resource_rate(params: Record<string, any>) {
  return request.post('/production_manage/delete_production_resource_rate', params);
}

export async function get_production_record_info_by_roll_no(params: Record<string, any>) {
  return request.post('/production_manage/get_production_record_info_by_roll_no', params);
}

export async function update_erp_kpi(params: Record<string, any>) {
  return request.post('/production_manage/update_erp_kpi', params);
}

export async function query_info_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_info_list', params);
}

export async function create_multi_product_item_by_sandbox(params: Record<string, any>) {
  return request.post('/production_manage/create_multi_product_item_by_sandbox', params);
}

export async function add_column_by_kpi_group(params: Record<string, any>) {
  return request.post('/production_manage/add_column_by_kpi_group', params);
}

export async function create_erp_kpi(params: Record<string, any>) {
  return request.post('/production_manage/create_erp_kpi', params);
}

export async function recover_product_gongyi(params: Record<string, any>) {
  return request.post('/production_manage/recover_product_gongyi', params);
}

export async function set_kpi_bz(params: Record<string, any>) {
  return request.post('/production_manage/set_kpi_bz', params);
}

export async function query_finish_proudcution_info_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_finish_proudcution_info_list', params);
}

export async function query_plan_item_by_production_record_id_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_plan_item_by_production_record_id_list', params);
}

export async function query_kpi_formate_by_id_flags_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_kpi_formate_by_id_flags_list', params);
}

export async function get_kpi_info_by_group_and_date(params: Record<string, any>) {
  return request.post('/production_manage/get_kpi_info_by_group_and_date', params);
}

export async function clear_kpi_update_value(params: Record<string, any>) {
  return request.post('/production_manage/clear_kpi_update_value', params);
}

export async function recover_kpi_group(params: Record<string, any>) {
  return request.post('/production_manage/recover_kpi_group', params);
}

export async function query_kpi_formate_by_name_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_kpi_formate_by_name_list', params);
}

export async function analyse_product_item_storage(params: Record<string, any>) {
  return request.post('/production_manage/analyse_product_item_storage', params);
}

export async function create_kpi_format(params: Record<string, any>) {
  return request.post('/production_manage/create_kpi_format', params);
}

export async function export_production_plan_multiply_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/export_production_plan_multiply_list', params);
}

export async function query_production_record_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_list', params);
}
export async function query_production_record_list1(
  params: Record<string, any>,
): Promise<PageResult> {
  const dataSource: PageResult = await request.post('/production_manage/query_production_record_list', params);
  dataSource.data.map((obj: any, index: number) => {
    if (dataSource.data[index + 1]) {
      const roll_no_previous = obj.roll_no;
      const result_previous = roll_no_previous.match(/[a-zA-Z]+/);
      const roll_no_next = dataSource.data[index + 1].roll_no;
      const result_next = roll_no_next.match(/[a-zA-Z]+/);
      if (result_previous && result_next &&
        parseInt(roll_no_previous.replace(result_previous[0], '')) -
        parseInt(roll_no_next.replace(result_next[0], '')) ==
        1
      ) {
        obj.plan_item__roll_no_co = false;
      } else {
        obj.plan_item__roll_no_co = true;
      }
      if (
        obj.plan_item__grade__name == dataSource.data[index + 1].plan_item__grade__name &&
        obj.plan_item__trade_no__name == dataSource.data[index + 1].plan_item__trade_no__name
      ) {
        obj.plan_item__grade__name_co = false;
      } else {
        obj.plan_item__grade__name_co = true;
      }
      if (obj.plan_item__billet_thick == dataSource.data[index + 1].plan_item__billet_thick) {
        obj.plan_item__billet_thick_co = false;
      } else {
        obj.plan_item__billet_thick_co = true;
      }
      if (
        obj.plan_item__weight / obj.plan_item__num ==
        dataSource.data[index + 1].plan_item__weight /
        dataSource.data[index + 1].plan_item__num
      ) {
        obj.plan_item__weight_co = false;
      } else {
        obj.plan_item__weight_co = true;
      }
    } else {
      obj.plan_item__roll_no_co = false;
      obj.plan_item__grade__name_co = false;
      obj.plan_item__billet_thick_co = false;
      obj.plan_item__weight_co = true;
    }
    if (dataSource.data[index - 1]) {
      if (obj.know_num == 0 && dataSource.data[index - 1].know_num == 1) {
        obj.know_num_co = true;
      } else {
        obj.know_num_co = false;
      }
    } else {
      obj.know_num_co = false;
    }
    try {
      obj.fc = JSON.parse(obj.fc_data);
    } catch (e) {}
  });
  return dataSource;
}

export async function analyse_daily_cost_by_report(params: Record<string, any>) {
  return request.post('/production_manage/analyse_daily_cost_by_report', params);
}

export async function query_production_record_custom_info_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_custom_info_list', params);
}

export async function set_roll_over_by_one_button(params: Record<string, any>) {
  return request.post('/production_manage/set_roll_over_by_one_button', params);
}

export async function query_product_truck_order_dispatch_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_product_truck_order_dispatch_list', params);
}

export async function get_excel_sheet_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/get_excel_sheet_list', params);
}

export async function query_product_item_truck_bind_no_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_product_item_truck_bind_no_list', params);
}

export async function upload_erp_product_item_by_order_again(params: Record<string, any>) {
  return request.post('/production_manage/upload_erp_product_item_by_order_again', params);
}

export async function query_production_return_record_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_return_record_list', params);
}

export async function query_production_record_by_print_tm_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_by_print_tm_list', params);
}

export async function create_coil_product_item_by_sandbox(params: Record<string, any>) {
  return request.post('/production_manage/create_coil_product_item_by_sandbox', params);
}

export async function query_production_record_info_tag(params: Record<string, any>) {
  return request.post('/production_manage/query_production_record_info_tag', params);
}

export async function upload_excel_production_plan(params: Record<string, any>) {
  return request.post('/production_manage/upload_excel_production_plan', params);
}

export async function get_hour_weight_by_lines(params: Record<string, any>) {
  return request.post('/production_manage/get_hour_weight_by_lines', params);
}

export async function analyse_production_by_shift_result(params: Record<string, any>) {
  return request.post('/production_manage/analyse_production_by_shift_result', params);
}

export async function create_product_item(params: Record<string, any>) {
  return request.post('/production_manage/create_product_item', params);
}

export async function query_kpi_echart_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_kpi_echart_list', params);
}

export async function analyse_production_by_shift_steel_type_result(params: Record<string, any>) {
  return request.post('/production_manage/analyse_production_by_shift_steel_type_result', params);
}

export async function create_or_update_production_plan_rate(params: Record<string, any>) {
  return request.post('/production_manage/create_or_update_production_plan_rate', params);
}

export async function create_stop_event_plan_and_roller_position_plan(params: Record<string, any>) {
  return request.post('/production_manage/create_stop_event_plan_and_roller_position_plan', params);
}

export async function query_product_truck_order_item_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_product_truck_order_item_list', params);
}

export async function query_production_record_item_by_production_record_id_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post(
    '/production_manage/query_production_record_item_by_production_record_id_list',
    params,
  );
}

export async function upload_product_item_to_erp(params: Record<string, any>) {
  return request.post('/production_manage/upload_product_item_to_erp', params);
}

export async function update_device_pass_weight_by_down_time(params: Record<string, any>) {
  return request.post('/production_manage/update_device_pass_weight_by_down_time', params);
}

export async function del_groups_to_kpi_tag(params: Record<string, any>) {
  return request.post('/production_manage/del_groups_to_kpi_tag', params);
}

export async function delete_kpi_group(params: Record<string, any>) {
  return request.post('/production_manage/delete_kpi_group', params);
}

export async function create_roller_position_and_conclusion(params: Record<string, any>) {
  return request.post('/production_manage/create_roller_position_and_conclusion', params);
}

export async function query_no_roll_no_production_record_item_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_no_roll_no_production_record_item_list', params);
}

export async function query_production_plan_edit_multiply_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_plan_edit_multiply_list', params);
}

export async function create_kpi_format_chart(params: Record<string, any>) {
  return request.post('/production_manage/create_kpi_format_chart', params);
}

export async function analyse_production_record_item_by_shift_result(params: Record<string, any>) {
  return request.post('/production_manage/analyse_production_record_item_by_shift_result', params);
}

export async function query_kpidownloadset_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_kpidownloadset_list', params);
}

export async function lock_kpi_group_record(params: Record<string, any>) {
  return request.post('/production_manage/lock_kpi_group_record', params);
}

export async function analyse_production_every_date_result(params: Record<string, any>) {
  return request.post('/production_manage/analyse_production_every_date_result', params);
}

export async function delete_pile_position(params: Record<string, any>) {
  return request.post('/production_manage/delete_pile_position', params);
}

export async function analyse_production_plant_pici_total(params: Record<string, any>) {
  return request.post('/production_manage/analyse_production_plant_pici_total', params);
}

export async function query_product_gongyi_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_product_gongyi_list', params);
}

export async function query_production_plan_by_rate_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_plan_by_rate_list', params);
}

export async function change_roll_shift(params: Record<string, any>) {
  return request.post('/production_manage/change_roll_shift', params);
}

export async function update_product_item_shift(params: Record<string, any>) {
  return request.post('/production_manage/update_product_item_shift', params);
}

export async function delete_product_gongyi(params: Record<string, any>) {
  return request.post('/production_manage/delete_product_gongyi', params);
}

export async function set_mutli_roller_change_by_stop_event_plan(params: Record<string, any>) {
  return request.post('/production_manage/set_mutli_roller_change_by_stop_event_plan', params);
}

export async function analyse_production_plant_finish_rate(params: Record<string, any>) {
  return request.post('/production_manage/analyse_production_plant_finish_rate', params);
}

export async function query_fix_weight_by_shift_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_fix_weight_by_shift_list', params);
}

export async function analyse_production_gp_used_by_shift_result(params: Record<string, any>) {
  return request.post('/production_manage/analyse_production_gp_used_by_shift_result', params);
}

export async function update_product_item_weight(params: Record<string, any>) {
  return request.post('/production_manage/update_product_item_weight', params);
}

export async function query_production_record_by_month_for_chart(params: Record<string, any>) {
  return request.post('/production_manage/query_production_record_by_month_for_chart', params);
}

export async function query_production_plan_rate_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_plan_rate_list', params);
}

export async function get_kpi_info_update_time_by_group_record_id(params: Record<string, any>) {
  return request.post('/production_manage/get_kpi_info_update_time_by_group_record_id', params);
}

export async function analyse_work_rate_of_zhagangchang(params: Record<string, any>) {
  return request.post('/production_manage/analyse_work_rate_of_zhagangchang', params);
}

export async function get_production_plan_shift_by_current_shift(params: Record<string, any>) {
  return request.post('/production_manage/get_production_plan_shift_by_current_shift', params);
}

export async function delete_production_plan_edit(params: Record<string, any>) {
  return request.post('/production_manage/delete_production_plan_edit', params);
}

export async function upload_product_item_bind_no_to_erp(params: Record<string, any>) {
  return request.post('/production_manage/upload_product_item_bind_no_to_erp', params);
}

export async function analyse_performance_result(params: Record<string, any>) {
  return request.post('/production_manage/analyse_performance_result', params);
}

export async function set_production_plan_shift_time_long(params: Record<string, any>) {
  return request.post('/production_manage/set_production_plan_shift_time_long', params);
}

export async function update_production_plan_multiply(params: Record<string, any>) {
  return request.post('/production_manage/update_production_plan_multiply', params);
}

export async function create_opc_name(params: Record<string, any>) {
  return request.post('/production_manage/create_opc_name', params);
}

export async function query_no_work_by_opcua_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_no_work_by_opcua_list', params);
}

export async function update_steel_inport_setting(params: Record<string, any>) {
  return request.post('/production_manage/update_steel_inport_setting', params);
}

export async function get_all_kpiformat_json(params: Record<string, any>) {
  return request.post('/production_manage/get_all_kpiformat_json', params);
}

export async function delete_kpi_format_chart(params: Record<string, any>) {
  return request.post('/production_manage/delete_kpi_format_chart', params);
}

export async function analyse_production_record_result(params: Record<string, any>) {
  return request.post('/production_manage/analyse_production_record_result', params);
}

export async function query_production_plan_list_combox(params: Record<string, any>) {
  return request.post('/production_manage/query_production_plan_list_combox', params);
}

export async function query_production_finish_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_finish_list', params);
}

export async function produce_in_bort(params: Record<string, any>) {
  return request.post('/production_manage/produce_in_bort', params);
}

export async function query_device_weight_sign_record_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_device_weight_sign_record_list', params);
}

export async function update_stop_time_spec(params: Record<string, any>) {
  return request.post('/production_manage/update_stop_time_spec', params);
}

export async function set_erp_status(params: Record<string, any>) {
  return request.post('/production_manage/set_erp_status', params);
}

export async function analyse_kpi_echart_result(params: Record<string, any>) {
  return request.post('/production_manage/analyse_kpi_echart_result', params);
}

export async function query_pile_position_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_pile_position_list', params);
}

export async function create_production_plan_rate(params: Record<string, any>) {
  return request.post('/production_manage/create_production_plan_rate', params);
}

export async function query_position_stop_event_plan_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_position_stop_event_plan_list', params);
}

export async function analyse_work_rate_by_shift(params: Record<string, any>) {
  return request.post('/production_manage/analyse_work_rate_by_shift', params);
}

export async function get_kpi_group_record_mutil_json(params: Record<string, any>) {
  return request.post('/production_manage/get_kpi_group_record_mutil_json', params);
}

export async function create_or_update_production_plan_day_weight(params: Record<string, any>) {
  return request.post('/production_manage/create_or_update_production_plan_day_weight', params);
}

export async function create_stop_event_plan(params: Record<string, any>) {
  return request.post('/production_manage/create_stop_event_plan', params);
}

export async function recover_stop_time_spec(params: Record<string, any>) {
  return request.post('/production_manage/recover_stop_time_spec', params);
}

export async function query_kpi_value_by_id_flags_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_kpi_value_by_id_flags_list', params);
}

export async function create_multi_coil_product_item_by_sandbox(params: Record<string, any>) {
  return request.post('/production_manage/create_multi_coil_product_item_by_sandbox', params);
}

export async function upload_erp_product_item_by_order(params: Record<string, any>) {
  return request.post('/production_manage/upload_erp_product_item_by_order', params);
}

export async function set_know_no_to_more(params: Record<string, any>) {
  return request.post('/production_manage/set_know_no_to_more', params);
}

export async function query_product_truck_order_shift_record_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_product_truck_order_shift_record_list', params);
}

export async function query_roller_position_and_conclusion_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_roller_position_and_conclusion_list', params);
}

export async function query_product_truck_order_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_product_truck_order_list', params);
}

export async function recover_production_plan_rate(params: Record<string, any>) {
  return request.post('/production_manage/recover_production_plan_rate', params);
}

export async function set_compute_kpi_task_by_date(params: Record<string, any>) {
  return request.post('/production_manage/set_compute_kpi_task_by_date', params);
}

export async function set_compute_production_plan_info_by_date(params: Record<string, any>) {
  return request.post('/production_manage/set_compute_production_plan_info_by_date', params);
}

export async function print_product_items(params: Record<string, any>) {
  return request.post('/production_manage/print_product_items', params);
}

export async function get_kpi_echart(params: Record<string, any>) {
  return request.post('/production_manage/get_kpi_echart', params);
}

export async function recover_production_resource_rate(params: Record<string, any>) {
  return request.post('/production_manage/recover_production_resource_rate', params);
}

export async function compute_average_temperature(params: Record<string, any>) {
  return request.post('/production_manage/compute_average_temperature', params);
}

export async function query_production_billet_loading_record_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_billet_loading_record_list', params);
}

export async function update_product_item_print_type(params: Record<string, any>) {
  return request.post('/production_manage/update_product_item_print_type', params);
}

export async function find_relevance_flag(params: Record<string, any>) {
  return request.post('/production_manage/find_relevance_flag', params);
}

export async function recover_pile_position(params: Record<string, any>) {
  return request.post('/production_manage/recover_pile_position', params);
}

export async function query_product_item_by_banci_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_product_item_by_banci_list', params);
}

export async function import_base_kpi_value_by_day_excel(params: Record<string, any>) {
  return request.post('/production_manage/import_base_kpi_value_by_day_excel', params);
}

export async function unlock_kpi_group_record(params: Record<string, any>) {
  return request.post('/production_manage/unlock_kpi_group_record', params);
}

export async function get_material_production_info(params: Record<string, any>) {
  return request.post('/production_manage/get_material_production_info', params);
}

export async function get_last_item_inform(params: Record<string, any>) {
  return request.post('/production_manage/get_last_item_inform', params);
}

export async function cancel_load_by_order_truck_no(params: Record<string, any>) {
  return request.post('/production_manage/cancel_load_by_order_truck_no', params);
}

export async function query_production_plan_multiply_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_plan_multiply_list', params);
}

export async function query_production_billet_baked_record_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_billet_baked_record_list', params);
}

export async function create_stop_time_spec(params: Record<string, any>) {
  return request.post('/production_manage/create_stop_time_spec', params);
}

export async function set_erp_production_sql_result(params: Record<string, any>) {
  return request.post('/production_manage/set_erp_production_sql_result', params);
}

export async function analyse_production_total_result(params: Record<string, any>) {
  return request.post('/production_manage/analyse_production_total_result', params);
}

export async function close_product_record_by_tm(params: Record<string, any>) {
  return request.post('/production_manage/close_product_record_by_tm', params);
}

export async function export_opc_value_datas_files(params: Record<string, any>) {
  return request.post('/production_manage/export_opc_value_datas_files', params);
}

export async function query_zhishu_tem_by_shift_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_zhishu_tem_by_shift_list', params);
}

export async function update_kpi_echart_custom_data(params: Record<string, any>) {
  return request.post('/production_manage/update_kpi_echart_custom_data', params);
}

export async function delete_kpi_tag(params: Record<string, any>) {
  return request.post('/production_manage/delete_kpi_tag', params);
}

export async function delete_column_by_kpi_group(params: Record<string, any>) {
  return request.post('/production_manage/delete_column_by_kpi_group', params);
}

export async function get_production_plan_html(params: Record<string, any>) {
  return request.post('/production_manage/get_production_plan_html', params);
}

export async function update_stop_event_plan(params: Record<string, any>) {
  return request.post('/production_manage/update_stop_event_plan', params);
}

export async function clear_item_star_weight(params: Record<string, any>) {
  return request.post('/production_manage/clear_item_star_weight', params);
}

export async function query_production_item_inform(params: Record<string, any>) {
  return request.post('/production_manage/query_production_item_inform', params);
}

export async function set_know_no_to_one(params: Record<string, any>) {
  return request.post('/production_manage/set_know_no_to_one', params);
}

export async function create_amount_plan(params: Record<string, any>) {
  return request.post('/production_manage/create_amount_plan', params);
}

export async function query_production_record_by_year_for_chart(params: Record<string, any>) {
  return request.post('/production_manage/query_production_record_by_year_for_chart', params);
}

export async function recover_kpidownloadset(params: Record<string, any>) {
  return request.post('/production_manage/recover_kpidownloadset', params);
}

export async function set_item_star_weight(params: Record<string, any>) {
  return request.post('/production_manage/set_item_star_weight', params);
}

export async function query_pile_position_name_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_pile_position_name_list', params);
}

export async function update_kpi_group(params: Record<string, any>) {
  return request.post('/production_manage/update_kpi_group', params);
}

export async function query_product_item_by_tm_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/production_manage/query_product_item_by_tm_list', params);
}

export async function analyse_every_date_work_rate_result(params: Record<string, any>) {
  return request.post('/production_manage/analyse_every_date_work_rate_result', params);
}

export async function query_item_id_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_item_id_list', params);
}

export async function export_productionrecorditem_datas(params: Record<string, any>) {
  return request.post('/production_manage/export_productionrecorditem_datas', params);
}

export async function query_production_plan_shift_by_plan_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_plan_shift_by_plan_list', params);
}

export async function delete_kpi_format(params: Record<string, any>) {
  return request.post('/production_manage/delete_kpi_format', params);
}

export async function get_production_record_for_statistics_by_line_current_shift(
  params: Record<string, any>,
  config?: any,
) {
  return request.post(
    '/production_manage/get_production_record_for_statistics_by_line_current_shift',
    params,
    config,
  );
}

export async function set_roller_waste(params: Record<string, any>) {
  return request.post('/production_manage/set_roller_waste', params);
}

export async function update_product_item_reset_weight(params: Record<string, any>) {
  return request.post('/production_manage/update_product_item_reset_weight', params);
}

export async function recover_kpi_tag(params: Record<string, any>) {
  return request.post('/production_manage/recover_kpi_tag', params);
}

export async function production_plan_send_email(params: Record<string, any>) {
  return request.post('/production_manage/production_plan_send_email', params);
}

export async function query_kpi_group_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_kpi_group_list', params);
}

export async function delete_product_item_by_bind_no_sandbox(params: Record<string, any>) {
  return request.post('/production_manage/delete_product_item_by_bind_no_sandbox', params);
}

export async function update_kpidownloadset(params: Record<string, any>) {
  return request.post('/production_manage/update_kpidownloadset', params);
}

export async function analyse_production_daily_cost(params: Record<string, any>) {
  return request.post('/production_manage/analyse_production_daily_cost', params);
}

export async function delete_stop_time_spec(params: Record<string, any>) {
  return request.post('/production_manage/delete_stop_time_spec', params);
}

export async function get_kpi_value_by_id_flag_and_date_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/get_kpi_value_by_id_flag_and_date_list', params);
}

export async function query_kpi_format_chart_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_kpi_format_chart_list', params);
}

export async function update_start_production_plan(params: Record<string, any>) {
  return request.post('/production_manage/update_start_production_plan', params);
}

export async function get_kpi_format_by_id(params: Record<string, any>) {
  return request.post('/production_manage/get_kpi_format_by_id', params);
}

export async function query_stop_time_spec_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_stop_time_spec_list', params);
}

export async function query_production_record_item_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_item_list', params);
}
export async function query_production_record_item_list1(
  params: Record<string, any>,
): Promise<PageResult> {
  return new Promise(resolve=>{
    request.post('/production_manage/query_production_record_item_list', params).then((res1:any)=>{
      get_plan_excel_import_setting_by_flag({
        org_id: current_org.id,
        flag: 'product_record_item_' + current_org.id,
      }).then((res: any) => {
        res1.data.map((item: any) => {
          res.head_list.map((item1: any) => {
            if(item['data_json'] && item['data_json'][item1.flag] ){
              if( item['data_json'][item1.flag][item1.value_type] || item['data_json'][item1.flag][item1.value_type] == 0){
                item['data_json_' + item1.flag + '_' + item1.value_type] = item['data_json'][item1.flag][item1.value_type];
              }
            }
          });
        });
        resolve(res1);
      });
    });
  });
}

export async function query_production_record_item_list2(
  params: Record<string, any>,
): Promise<PageResult> {
  return new Promise(resolve=>{
    request.post('/production_manage/query_production_record_item_data_list', params).then((res:any)=>{
      let configuration_file: any = ls.get(CONFIGURATION_FILE);
      const roll_no = ls.get(ROLL_NO);
      if(configuration_file){
        configuration_file = '_' + configuration_file;
      }else {
        configuration_file = '';
      }
      get_plan_excel_import_setting_by_flag({
        org_id: current_org.id,
        flag: 'product_record_item_' + current_org.id + configuration_file,
      }).then((res2: any) => {
        get_product_info_by_test_opc_info({
          org_id: current_org.id,
          is_mongo: false,
          roll_no: roll_no,
          opc_info: JSON.stringify(res2),
        }).then((res1: any) => {
          res.data.reverse();
          res.data.map((item: any) => {
            Object.keys(res1).map((item1: any) => {
              if(item.id == item1){
                // let a = item.data_json;
                item.lu_temp_data = res1[item1];
                Object.assign(item.lu_temp_data ,item.data_json);
              }
            });
          });
          res.data.forEach((item: any, index: number) => {
            res2.head_list.map((item1: any) => {
              if(item['lu_temp_data'] && item['lu_temp_data'][item1.flag] ){
                if( item['lu_temp_data'][item1.flag][item1.value_type] || item['lu_temp_data'][item1.flag][item1.value_type] == 0){
                  item['data_json_' + item1.flag + '_' + item1.value_type] = item['lu_temp_data'][item1.flag][item1.value_type];
                }
              }
            });
          });
          resolve(res);
        })
      });
    });
  });
}
export async function query_error_product_item_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_error_product_item_list', params);
}

export async function update_batch_product_item_shift(params: Record<string, any>) {
  return request.post('/production_manage/update_batch_product_item_shift', params);
}
export async function pull_production_plan_edit_to_production_plan(params: Record<string, any>) {
  return request.post('/production_manage/pull_production_plan_edit_to_production_plan', params);
}

export async function add_groups_to_kpidownloadset(params: Record<string, any>) {
  return request.post('/production_manage/add_groups_to_kpidownloadset', params);
}

export async function open_product_record_by_tm(params: Record<string, any>) {
  return request.post('/production_manage/open_product_record_by_tm', params);
}

export async function custom_new_production_record_item_scrap_with_stop_event(
  params: Record<string, any>,
) {
  return request.post(
    '/production_manage/custom_new_production_record_item_scrap_with_stop_event',
    params,
  );
}

export async function analysis_jiarelu_temp_data(params: Record<string, any>) {
  return request.post('/production_manage/analysis_jiarelu_temp_data', params);
}

export async function get_all_steel_type_name_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/get_all_steel_type_name_list', params);
}

export async function analyse_production_dc_by_shift_result(params: Record<string, any>) {
  return request.post('/production_manage/analyse_production_dc_by_shift_result', params);
}

export async function delete_production_return_record(params: Record<string, any>) {
  return request.post('/production_manage/delete_production_return_record', params);
}

export async function create_pile_position(params: Record<string, any>) {
  return request.post('/production_manage/create_pile_position', params);
}

export async function get_product_info_by_test_opc_info(params: Record<string, any>) {
  return request.post('/production_manage/get_product_info_by_test_opc_info', params);
}

export async function analyse_hot_charging_rate_by_shift_every_day(params: Record<string, any>) {
  return request.post('/production_manage/analyse_hot_charging_rate_by_shift_every_day', params);
}

export async function analyse_production_by_specifications_and_steel_type_result(
  params: Record<string, any>,
) {
  return request.post(
    '/production_manage/analyse_production_by_specifications_and_steel_type_result',
    params,
  );
}

export async function get_kpi_info_by_group_record_id(params: Record<string, any>) {
  return request.post('/production_manage/get_kpi_info_by_group_record_id', params);
}

export async function create_production_return_record(params: Record<string, any>) {
  return request.post('/production_manage/create_production_return_record', params);
}

export async function query_production_record_item_by_heat_no_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_item_by_heat_no_list', params);
}

export async function create_multi_product_item(params: Record<string, any>) {
  return request.post('/production_manage/create_multi_product_item', params);
}

export async function get_kpi_format_by_row_column(params: Record<string, any>) {
  return request.post('/production_manage/get_kpi_format_by_row_column', params);
}

export async function update_kpi_echart(params: Record<string, any>) {
  return request.post('/production_manage/update_kpi_echart', params);
}

export async function query_kpi_group_record_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_kpi_group_record_list', params);
}

export async function check_kpi_format_calculation_method(params: Record<string, any>) {
  return request.post('/production_manage/check_kpi_format_calculation_method', params);
}

export async function set_roll_over(params: Record<string, any>) {
  return request.post('/production_manage/set_roll_over', params);
}

export async function delete_stop_event_plan(params: Record<string, any>) {
  return request.post('/production_manage/delete_stop_event_plan', params);
}

export async function update_kpi_format_chart(params: Record<string, any>) {
  return request.post('/production_manage/update_kpi_format_chart', params);
}

export async function query_product_item_storage_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_product_item_storage_list', params);
}

export async function delete_erp_kpi(params: Record<string, any>) {
  return request.post('/production_manage/delete_erp_kpi', params);
}

export async function get_hour_weight_by_specifications_id(params: Record<string, any>) {
  return request.post('/production_manage/get_hour_weight_by_specifications_id', params);
}

export async function update_production_record_item_forward_or_back(params: Record<string, any>) {
  return request.post('/production_manage/update_production_record_item_forward_or_back', params);
}

export async function get_product_item(params: Record<string, any>) {
  return request.post('/production_manage/get_product_item', params);
}

export async function query_productitem_inventory_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_productitem_inventory_list', params);
}

export async function get_production_record_item_by_id(params: Record<string, any>) {
  return request.post('/production_manage/get_production_record_item_by_id', params);
}

export async function get_production_plan_org_settings(params: Record<string, any>) {
  return request.post('/production_manage/get_production_plan_org_settings', params);
}

export async function export_report(params: Record<string, any>) {
  return request.post('/production_manage/export_report', params);
}

export async function update_production_plan_rate(params: Record<string, any>) {
  return request.post('/production_manage/update_production_plan_rate', params);
}

export async function delete_row_by_kpi_group(params: Record<string, any>) {
  return request.post('/production_manage/delete_row_by_kpi_group', params);
}

export async function set_plan_product_by_shift(params: Record<string, any>) {
  return request.post('/production_manage/set_plan_product_by_shift', params);
}

export async function export_item_roller_weight_datas(params: Record<string, any>) {
  return request.post('/production_manage/export_item_roller_weight_datas', params);
}

export async function update_device_sign_weight_record(params: Record<string, any>) {
  return request.post('/production_manage/update_device_sign_weight_record', params);
}

export async function add_product_item_by_order(params: Record<string, any>) {
  return request.post('/production_manage/add_product_item_by_order', params);
}

export async function delete_kpi_echart(params: Record<string, any>) {
  return request.post('/production_manage/delete_kpi_echart', params);
}

export async function update_production_record_product_plan_num(params: Record<string, any>) {
  return request.post('/production_manage/update_production_record_product_plan_num', params);
}

export async function query_device_weight_sign_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_device_weight_sign_list', params);
}

export async function delete_kpidownloadset(params: Record<string, any>) {
  return request.post('/production_manage/delete_kpidownloadset', params);
}

export async function query_production_record_for_statistics_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_for_statistics_list', params);
}

export async function create_production_edit_plan(params: Record<string, any>) {
  return request.post('/production_manage/create_production_edit_plan', params);
}

export async function query_product_item_sum_of_weight_by_roll_no_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post(
    '/production_manage/query_product_item_sum_of_weight_by_roll_no_list',
    params,
  );
}

export async function update_pile_position(params: Record<string, any>) {
  return request.post('/production_manage/update_pile_position', params);
}

export async function update_production_record_item_note(params: Record<string, any>) {
  return request.post('/production_manage/update_production_record_item_note', params);
}

export async function change_production_record_information(params: Record<string, any>) {
  return request.post('/production_manage/change_production_record_information', params);
}

export async function analyse_hot_charging_rate_by_shift(params: Record<string, any>) {
  return request.post('/production_manage/analyse_hot_charging_rate_by_shift', params);
}

export async function down_line_device_sign_weight_record(params: Record<string, any>) {
  return request.post('/production_manage/down_line_device_sign_weight_record', params);
}
export async function query_production_record_item_for_jrl_detail_list(params: Record<string, any>) {
  return request.post('/production_manage/query_production_record_item_for_jrl_detail_list', params);
}
export async function update_blank_production_record_item_for_jrl_detail(params: Record<string, any>) {
  return request.post('/production_manage/update_blank_production_record_item_for_jrl_detail', params);
}

export async function delete_custom_product_item(params: Record<string, any>) {
  return request.post('/production_manage/delete_custom_product_item', params);
}

export async function update_production_record_merge_no(params: Record<string, any>) {
  return request.post('/production_manage/update_production_record_merge_no', params);
}

export async function modify_product_line_obj_status_by_write_plc(params: Record<string, any>) {
  return request.post('/production_manage/modify_product_line_obj_status_by_write_plc', params);
}

export async function update_custom_production_record_merge_no(params: Record<string, any>) {
  return request.post('/production_manage/update_custom_production_record_merge_no', params);
}

export async function query_opcua_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_opcua_list', params);
}

export async function query_tech_str_for_custom_production_record_list (params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_tech_str_for_custom_production_record_list ', params);
}

export async function query_production_plan_send_email_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_plan_send_email_list', params);
}

export async function query_plan_item_name_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_plan_item_name_list', params);
}

export async function query_kpi_fun_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_kpi_fun_list', params);
}

export async function delete_product_item_by_sandbox(params: Record<string, any>) {
  return request.post('/production_manage/delete_product_item_by_sandbox', params);
}

export async function close_product_record_by_tm_without_pwd(params: Record<string, any>) {
  return request.post('/production_manage/close_product_record_by_tm_without_pwd', params);
}
export async function close_custom_product_record_by_tm_without_pwd(params: Record<string, any>) {
  return request.post('/production_manage/close_custom_product_record_by_tm_without_pwd', params);
}
export async function open_custom_product_record_by_tm(params: Record<string, any>) {
  return request.post('/production_manage/open_custom_product_record_by_tm', params);
}

export async function update_production_plan(params: Record<string, any>) {
  return request.post('/production_manage/update_production_plan', params);
}

export async function analyse_production_dc_of_zhagangchang(params: Record<string, any>) {
  return request.post('/production_manage/analyse_production_dc_of_zhagangchang', params);
}

export async function refresh_production_record_item(params: Record<string, any>) {
  return request.post('/production_manage/refresh_production_record_item', params);
}

export async function recover_kpi_echart(params: Record<string, any>) {
  return request.post('/production_manage/recover_kpi_echart', params);
}

export async function query_production_record_list_without_person(params: Record<string, any>) {
  return request.post('/production_manage/query_production_record_list_without_person', params);
}

export async function create_product_item_by_sandbox(params: Record<string, any>) {
  return request.post('/production_manage/create_product_item_by_sandbox', params);
}

export async function query_kpi_echart_name_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_kpi_echart_name_list', params);
}

export async function get_no_production_record_id_item(params: Record<string, any>) {
  return request.post('/production_manage/get_no_production_record_id_item', params);
}

export async function query_heat_time_tongji_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_heat_time_tongji_list', params);
}

export async function get_kpi_value_by_id_flag_and_date(params: Record<string, any>) {
  return request.post('/production_manage/get_kpi_value_by_id_flag_and_date', params);
}

export async function update_plan_state(params: Record<string, any>) {
  return request.post('/production_manage/update_plan_state', params);
}

export async function get_production_record_for_statistics_by_line_current_shift_without_login(
  params: Record<string, any>,
) {
  return request.post(
    '/production_manage/get_production_record_for_statistics_by_line_current_shift_without_login',
    params,
  );
}

export async function query_kpi_info_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_kpi_info_list', params);
}

export async function create_kpi_group(params: Record<string, any>) {
  return request.post('/production_manage/create_kpi_group', params);
}

export async function update_kpi_format_show_num(params: Record<string, any>) {
  return request.post('/production_manage/update_kpi_format_show_num', params);
}

export async function upload_erp_kpi_data(params: Record<string, any>) {
  return request.post('/production_manage/upload_erp_kpi_data', params);
}

export async function set_kpi_value_by_custom_kpi_format(params: Record<string, any>) {
  return request.post('/production_manage/set_kpi_value_by_custom_kpi_format', params);
}

export async function delete_production_record_info(params: Record<string, any>) {
  return request.post('/production_manage/delete_production_record_info', params);
}

export async function create_device_weight_sign_record(params: Record<string, any>) {
  return request.post('/production_manage/create_device_weight_sign_record', params);
}

export async function recover_kpi_format(params: Record<string, any>) {
  return request.post('/production_manage/recover_kpi_format', params);
}

export async function recompute_kpi_base_data(params: Record<string, any>) {
  return request.post('/production_manage/recompute_kpi_base_data', params);
}

export async function get_erp_production_sql(params: Record<string, any>) {
  return request.post('/production_manage/get_erp_production_sql', params);
}

export async function query_production_record_item_data_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_item_data_list', params);
}

export async function query_production_record_item_data_list1(
  params: Record<string, any>,
): Promise<PageResult> {
  return new Promise(resolve=>{
    request.post('/production_manage/query_production_record_item_data_list', params).then((res1:any)=>{
      get_plan_excel_import_setting_by_flag({
        org_id: current_org.id,
        flag: 'product_record_item_' + current_org.id,
      }).then((res: any) => {
        res1.data.map((item: any) => {
          res.head_list.map((item1: any) => {
            if(item['data_json'] && item['data_json'][item1.flag] ){
              if( item['data_json'][item1.flag][item1.value_type] || item['data_json'][item1.flag][item1.value_type] == 0){
                item['data_json_' + item1.flag + '_' + item1.value_type] = item['data_json'][item1.flag][item1.value_type];
              }
            }
          });
        });
        resolve(res1);
      });
    });
  });
}

export async function query_production_plan_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_production_plan_list', params);
}

export async function update_production_record_info(params: Record<string, any>) {
  return request.post('/production_manage/update_production_record_info', params);
}

export async function query_production_record_printed_num_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_printed_num_list', params);
}

export async function query_cheng_zhong_by_time_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_cheng_zhong_by_time_list', params);
}

export async function query_stop_event_plan_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/production_manage/query_stop_event_plan_list', params);
}

export async function export_report_by_flag(params: Record<string, any>) {
  return request.post('/production_manage/export_report_by_flag', params);
}

export async function query_production_record_by_day_for_chart(params: Record<string, any>) {
  return request.post('/production_manage/query_production_record_by_day_for_chart', params);
}

export async function delete_production_plan_rate(params: Record<string, any>) {
  return request.post('/production_manage/delete_production_plan_rate', params);
}

export async function query_production_record_item_by_roll_no_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_production_record_item_by_roll_no_list', params);
}

export async function query_erp_order_sub_data_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_erp_order_sub_data_list', params);
}

export async function delete_production_plan_send_email(params: Record<string, any>) {
  return request.post('/production_manage/delete_production_plan_send_email', params);
}

export async function update_kpi_tag(params: Record<string, any>) {
  return request.post('/production_manage/update_kpi_tag', params);
}

export async function query_product_item_by_bind_no_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_product_item_by_bind_no_list', params);
}

export async function set_kpi_update_value(params: Record<string, any>) {
  return request.post('/production_manage/set_kpi_update_value', params);
}

export async function analyse_enegry_daily_cost(params: Record<string, any>) {
  return request.post('/production_manage/analyse_enegry_daily_cost', params);
}

export async function query_opc_name_by_script_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/production_manage/query_opc_name_by_script_list', params);
}

export async function stop_current_compute_kpi_task(params: Record<string, any>) {
  return request.post('/production_manage/stop_current_compute_kpi_task', params);
}

export async function create_production_plan(params: Record<string, any>) {
  return request.post('/production_manage/create_production_plan', params);
}
export async function update_product_item_appearance_status(params: Record<string, any>) {
  return request.post('/production_manage/update_product_item_appearance_status', params);
}
export async function query_product_item_collect_status_select_list(params: Record<string, any>) {
  return request.post('/production_manage/query_product_item_collect_status_select_list', params);
}
export async function query_product_item_quality_status_select_list(params: Record<string, any>) {
  return request.post('/production_manage/query_product_item_quality_status_select_list', params);
}
export async function update_product_item_quality_status(params: Record<string, any>) {
  return request.post('/production_manage/update_product_item_quality_status', params);
}

export async function update_platform_print_type(params: Record<string, any>) {
  return request.post('/production_manage/update_platform_print_type', params);
}
export async function reset_product_item_error_message(params: Record<string, any>) {
  return request.post('/production_manage/reset_product_item_error_message', params);
}
export async function update_custom_production_record_shift(params: Record<string, any>) {
  return request.post('/production_manage/update_custom_production_record_shift', params);
}

export async function update_product_item_real_weight(params: Record<string, any>) {
  return request.post('/production_manage/update_product_item_real_weight', params);
}

export async function stop_production_record_match(params: Record<string, any>) {
  return request.post('/production_manage/stop_production_record_match', params);
}
export async function delete_error_product_item(params: Record<string, any>) {
  return request.post('/production_manage/delete_error_product_item', params);
}
