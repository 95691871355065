import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function create_stop_reason(params: Record<string, any>) {
  return request.post('/dispatch/create_stop_reason', params);
}

export async function query_stop_event_name_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/dispatch/query_stop_event_name_list', params);
}

export async function split_stop_event(params: Record<string, any>): Promise<PageResult> {
  return request.post('/dispatch/split_stop_event', params);
}

export async function merge_stop_event(params: Record<string, any>): Promise<PageResult> {
  return request.post('/dispatch/merge_stop_event', params);
}

export async function get_stop_reason(params: Record<string, any>) {
  return request.post('/dispatch/get_stop_reason', params);
}

export async function create_stop_envet_break_down_time(params: Record<string, any>) {
  return request.post('/dispatch/create_stop_envet_break_down_time', params);
}

export async function recover_stopunit(params: Record<string, any>) {
  return request.post('/dispatch/recover_stopunit', params);
}

export async function calculation_work_stability(params: Record<string, any>) {
  return request.post('/dispatch/calculation_work_stability', params);
}

export async function query_sub_stop_event_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/dispatch/query_sub_stop_event_list', params);
}

export async function set_accounting_event(params: Record<string, any>) {
  return request.post('/dispatch/set_accounting_event', params);
}

export async function query_stop_reason_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/dispatch/query_stop_reason_list', params);
}

export async function query_stop_reason_list1(params: Record<string, any>): Promise<PageResult> {
  const res: PageResult = await request.post('/dispatch/query_stop_reason_list', params);
  const list: any = [];
  res.data.map((item: any) => {
    if (!item.stop_reason__name) {
      list.push({
        key: item.id,
        value: item.id,
        title: item.name,
        ...item,
        children: [],
        selectable: true,
      });
    }
  });
  res.data.map((item: any) => {
    if (item.stop_reason__name) {
      list.map((item1: any) => {
        if (item.stop_reason__name == item1.name) {
          item1.children.push({
            key: item.id,
            ...item,
            value: item.id,
            title: item.name,
            children: [],
            selectable: true,
          });
        }
      });
    }
  });
  res.data.map((item: any) => {
    if (item.stop_reason__name && item.is_leaf) {
      list.map((item1: any) => {
        if (item1.children.length > 0) {
          item1.children.map((obj: any) => {
            if (obj.name == item.stop_reason__name) {
              obj.children.push({
                key: item.id,
                value: item.id,
                title: item.name,
                ...item,
                selectable: false,
              });
            }
          });
        }
      });
    }
  });
  res.data = list;
  console.log('res', res);
  return res;
}

export async function query_stop_reason_list2(params: Record<string, any>): Promise<PageResult> {
  const res: PageResult = await request.post('/dispatch/query_stop_reason_list', params);
  const list: any = [];
  res.data.map((item: any) => {
    if (!item.stop_reason__name) {
      list.push({
        key: item.id,
        value: item.id,
        title: item.name,
        ...item,
        children: [],
        selectable: false,
      });
    }
  });
  res.data.map((item: any) => {
    if (item.stop_reason__name) {
      list.map((item1: any) => {
        if (item.stop_reason__name == item1.name) {
          item1.children.push({
            key: item.id,
            ...item,
            value: item.id,
            title: item.name,
            children: [],
            selectable: false,
          });
        }
      });
    }
  });
  res.data.map((item: any) => {
    if (item.stop_reason__name && item.is_leaf) {
      list.map((item1: any) => {
        if (item1.children.length > 0) {
          item1.children.map((obj: any) => {
            if (obj.name == item.stop_reason__name) {
              obj.children.push({
                key: item.id,
                value: item.id,
                title: item.name,
                ...item,
                selectable: true,
              });
            }
          });
        }
      });
    }
  });
  res.data = list;
  console.log('res', res);
  return res;
}

export async function update_stopunit(params: Record<string, any>) {
  return request.post('/dispatch/update_stopunit', params);
}

export async function query_stop_event_name_by_roller_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/dispatch/query_stop_event_name_by_roller_list', params);
}

export async function update_init_stop_event(params: Record<string, any>) {
  return request.post('/dispatch/update_init_stop_event', params);
}

export async function create_init_stop_event(params: Record<string, any>) {
  return request.post('/dispatch/create_init_stop_event', params);
}

export async function create_stop_event(params: Record<string, any>) {
  return request.post('/dispatch/create_stop_event', params);
}

export async function delete_stop_reason(params: Record<string, any>) {
  return request.post('/dispatch/delete_stop_reason', params);
}

export async function check_stop_event(params: Record<string, any>) {
  return request.post('/dispatch/check_stop_event', params);
}

export async function check_stop_event_sanbao(params: Record<string, any>) {
  return request.post('/dispatch/check_stop_event_sanbao', params);
}

export async function calculation_stop_event_time(params: Record<string, any>) {
  return request.post('/dispatch/calculation_stop_event_time', params);
}

export async function query_stop_event_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/dispatch/query_stop_event_list', params);
}

export async function query_stop_event_list_with_pre_process(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/dispatch/query_stop_event_list', params);
}

export async function set_arrange_shift_no_work_time_long(params: Record<string, any>) {
  return request.post('/dispatch/set_arrange_shift_no_work_time_long', params);
}

export async function set_stop_event_erp_status_auto(params: Record<string, any>) {
  return request.post('/dispatch/set_stop_event_erp_status_auto', params);
}

export async function analyse_stop_reason(params: Record<string, any>) {
  return request.post('/dispatch/analyse_stop_reason', params);
}

export async function update_stop_event(params: Record<string, any>) {
  return request.post('/dispatch/update_stop_event', params);
}

export async function update_stop_reason(params: Record<string, any>) {
  return request.post('/dispatch/update_stop_reason', params);
}

export async function delete_stopunit(params: Record<string, any>) {
  return request.post('/dispatch/delete_stopunit', params);
}

export async function delete_stop_envet_all_break_down_time(params: Record<string, any>) {
  return request.post('/dispatch/delete_stop_envet_all_break_down_time', params);
}

export async function query_stop_work_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/dispatch/query_stop_work_list', params);
}

export async function recover_stop_reason(params: Record<string, any>) {
  return request.post('/dispatch/recover_stop_reason', params);
}

export async function create_stopunit(params: Record<string, any>) {
  return request.post('/dispatch/create_stopunit', params);
}

export async function delete_stop_event(params: Record<string, any>) {
  return request.post('/dispatch/delete_stop_event', params);
}

export async function query_stopunit_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/dispatch/query_stopunit_list', params);
}

export async function get_arrange_shift_stop_event_erp_data(params: Record<string, any>) {
  return request.post('/dispatch/get_arrange_shift_stop_event_erp_data', params);
}

export async function query_stop_reason_name_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/dispatch/query_stop_reason_name_list', params);
}

export async function change_stop_event_is_roller(params: Record<string, any>) {
  return request.post('/dispatch/change_stop_event_is_roller', params);
}

export async function analyse_stop_event(params: Record<string, any>) {
  return request.post('/dispatch/analyse_stop_event', params);
}

export async function create_stop_fault(params: Record<string, any>) {
  return request.post('/dispatch/create_stop_fault', params);
}

export async function update_stop_fault(params: Record<string, any>) {
  return request.post('/dispatch/update_stop_fault', params);
}

export async function delete_stop_fault(params: Record<string, any>) {
  return request.post('/dispatch/delete_stop_fault', params);
}

export async function recover_stop_fault(params: Record<string, any>) {
  return request.post('/dispatch/recover_stop_fault', params);
}

export async function query_stop_fault_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/dispatch/query_stop_fault_list', params);
}
