import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    style: _normalizeStyle(_ctx.column.cb ? _ctx.column.cb(_ctx.record) : '')
  }, [
    (_ctx.column.content)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.column.content(_ctx.record)), 1))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.text), 1))
  ], 4))
}