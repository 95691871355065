import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${_ctx.prefixedClassName}-detail`)
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`${_ctx.prefixedClassName}-main`)
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(`${_ctx.prefixedClassName}-row`)
      }, [
        (_ctx.hasContent)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`${_ctx.prefixedClassName}-content`)
            }, [
              _renderSlot(_ctx.$slots, "content")
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.hasExtraContent)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(`${_ctx.prefixedClassName}-extraContent`)
            }, [
              _renderSlot(_ctx.$slots, "extraContent")
            ], 2))
          : _createCommentVNode("", true)
      ], 2)
    ], 2)
  ], 2))
}