import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_grid_content = _resolveComponent("grid-content")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.classNames)
  }, [
    _createVNode(_component_grid_content, null, {
      default: _withCtx(() => [
        (_ctx.hasChildren)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(`${_ctx.prefixedClassName}-children-content`)
            }, [
              _renderSlot(_ctx.$slots, "default")
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 2))
}