import { query_org_by_flag_list } from '@/api/sys-model';


export default () => {
  const search_config: any = {
    model: {
      org_flag: 'zhaxian',
    },
    watch: [],
  };
  return {
    getData: query_org_by_flag_list,
    searchConfig: search_config,
  };
};
