import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"font-size":"25px","font-weight":"bolder"} }
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_global_outlined = _resolveComponent("global-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!

  return (_openBlock(), _createBlock(_component_a_dropdown, {
    class: "ant-pro-dropdown ant-pro-dropdown-action",
    placement: "bottomRight"
  }, {
    overlay: _withCtx(() => [
      _createVNode(_component_a_menu, {
        class: "ant-pro-dropdown-menu",
        "selected-keys": [_ctx.currentLang],
        onClick: _ctx.handleMenuClick
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.locales, (locale) => {
            return (_openBlock(), _createBlock(_component_a_menu_item, {
              key: locale,
              disabled: !_ctx.languageSupports[locale]
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  role: "img",
                  "aria-label": _ctx.languageLabels[locale]
                }, _toDisplayString(_ctx.languageIcons[locale]), 9, _hoisted_2),
                _createTextVNode(" " + _toDisplayString(_ctx.languageLabels[locale]), 1)
              ]),
              _: 2
            }, 1032, ["disabled"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["selected-keys", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("span", null, [
        _createVNode(_component_global_outlined),
        _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.lang), 1)
      ])
    ]),
    _: 1
  }))
}