import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_layout_block = _resolveComponent("layout-block")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.prefixCls)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
      return (_openBlock(), _createBlock(_component_layout_block, {
        key: item.key,
        theme: item.key,
        checked: item.key === _ctx.value,
        disabled: item.disabled,
        title: item.title,
        onClick: 
          () => {
            !item.disabled && _ctx.handleChange(item.key);
          }
        
      }, null, 8, ["theme", "checked", "disabled", "title", "onClick"]))
    }), 128))
  ], 2))
}