
import { defineComponent } from 'vue';
export default defineComponent({
  name: 'StatementModal',
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const handleSubmit = () => {

    };
    return {
      props,
      handleSubmit,
    };
  },
});
