import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "ant-pro-header-account-name anticon" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_user_outlined = _resolveComponent("user-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_dropdown = _resolveComponent("a-dropdown")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_ctx.current_org && _ctx.current_org.name)
    ? (_openBlock(), _createBlock(_component_a_dropdown, {
        key: 0,
        class: "ant-pro-dropdown ant-pro-dropdown-action",
        placement: "bottomRight",
        overlayClassName: "pro-components-header-dropdown-index-container"
      }, {
        overlay: _withCtx(() => [
          _createVNode(_component_a_menu, {
            class: "ant-pro-dropdown-menu",
            "selected-keys": []
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.org_list, (obj) => {
                return (_openBlock(), _createBlock(_component_a_menu_item, {
                  key: obj.id,
                  onClick: ($event: any) => (_ctx.select_org(obj))
                }, {
                  default: _withCtx(() => [
                    (_ctx.current_org.id == obj.id)
                      ? (_openBlock(), _createBlock(_component_user_outlined, {
                          key: 0,
                          class: "prefixIcon"
                        }))
                      : _createCommentVNode("", true),
                    (_ctx.current_org.id != obj.id)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "     "))
                      : _createCommentVNode("", true),
                    _createTextVNode(" " + _toDisplayString(obj.name), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("span", null, [
            _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.current_org.name), 1)
          ])
        ]),
        _: 1
      }))
    : (_openBlock(), _createElementBlock("span", _hoisted_3, [
        _createVNode(_component_a_spin, {
          size: 'small',
          style: { marginLeft: 8, marginRight: 8 }
        })
      ]))
}