import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.text)
      ? (_openBlock(), _createBlock(_component_a_tag, {
          key: 0,
          color: "green"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.column.name1), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.text)
      ? (_openBlock(), _createBlock(_component_a_tag, {
          key: 1,
          color: "red"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.column.name2), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}