import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_result = _resolveComponent("a-result")!

  return (!_ctx.auth && _ctx.noMatch)
    ? (_openBlock(), _createBlock(_component_a_result, {
        key: 0,
        staus: 403,
        title: "403",
        "sub-title": "无权限"
      }))
    : (_ctx.auth)
      ? _renderSlot(_ctx.$slots, "default", { key: 1 })
      : _createCommentVNode("", true)
}