import {
  delete_beneficiary,
  recover_beneficiary,
  query_beneficiary_list,
} from '@/api/cerp-model';
import { TableColumn } from '@/typing';
import { SearchConfig, Record } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import {STORAGE_CURRENT_ADVISER_KEY, STORAGE_CURRENT_ORG_KEY} from '@/store/mutation-type';
import {
  slotList,
  btn_list,
  head_btn_list,
  event_obj,
  config,
} from '@/components/page-model4/cerp/cerp-beneficiary-config';

import { reactive } from 'vue';
import { create_country } from '@/api/baseinfo-model';
import { message } from 'ant-design-vue';
import modalBox from '@/components/form-modal/modal-tools';
import { getDate, getDateTime } from '@/utils/function';

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const adviser = ls.get(STORAGE_CURRENT_ADVISER_KEY);

const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '姓名',
      label_i18n: t('page.cerp.beneficiary.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓名',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.name'),
    },
    {
      type: 'radio',
      name: 'gender',
      label: '性别',
      label_i18n: t('page.cerp.beneficiary.form.label.gender'),
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'datepicker',
      name: 'date_of_birth',
      label: '出生日期',
      label_i18n: t('page.cerp.beneficiary.form.label.date_of_birth'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写出生日期',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.date_of_birth'),
    },
    {
      type: 'remoteselect',
      name: 'id_type_id',
      label: '证件类别',
      label_i18n: t('page.cerp.beneficiary.form.label.id_type_id'),
      disabled: false,
      placeholder: '请选择证件类别',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.id_type_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-idtype',
    },
    {
      type: 'input',
      name: 'id_number',
      label: '证件号',
      label_i18n: t('page.cerp.beneficiary.form.label.id_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写证件号',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.id_number'),
    },
    {
      type: 'datepicker',
      name: 'id_expiry_date',
      label: '证件有效期',
      label_i18n: t('page.cerp.beneficiary.form.label.id_expiry_date'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写证件有效期',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.id_expiry_date'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: adviser.adviser_id,
    name: null,
    gender: null,
    date_of_birth: null,
    id_type_id: null,
    id_number: null,
    id_expiry_date: null,
  }),
};
const search_options = {
  gender: [
    { value: 0, name: '男' },
    { value: 1, name: '女' },
  ],
  is_active: [
    { value: None, name: '全部' },
    { value: True, name: '可用' },
    { value: False, name: '禁用' },
  ],
};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '姓名',
      label_i18n: t('page.cerp.beneficiary.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓名',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.name'),
    },
    {
      type: 'radio',
      name: 'gender',
      label: '性别',
      label_i18n: t('page.cerp.beneficiary.form.label.gender'),
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'datepicker',
      name: 'date_of_birth',
      label: '出生日期',
      label_i18n: t('page.cerp.beneficiary.form.label.date_of_birth'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写出生日期',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.date_of_birth'),
    },
    {
      type: 'remoteselect',
      name: 'id_type_id',
      label: '证件类别',
      label_i18n: t('page.cerp.beneficiary.form.label.id_type_id'),
      disabled: false,
      placeholder: '请选择证件类别',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.id_type_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-idtype',
    },
    {
      type: 'input',
      name: 'id_number',
      label: '证件号',
      label_i18n: t('page.cerp.beneficiary.form.label.id_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写证件号',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.id_number'),
    },
    {
      type: 'datepicker',
      name: 'id_expiry_date',
      label: '证件有效期',
      label_i18n: t('page.cerp.beneficiary.form.label.id_expiry_date'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写证件有效期',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.id_expiry_date'),
    },
    {
      type: 'select',
      name: 'relation',
      label: '与被保险人关系',
      label_i18n: t('page.cerp.beneficiary.form.label.relation'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择与被保险人关系',
      datasource: 'relation',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.relation'),
    },
    {
      type: 'input',
      name: 'ratio',
      label: '受益比例',
      label_i18n: t('page.cerp.beneficiary.form.label.ratio'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写受益比例',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.ratio'),
    },
  ],
  rules: {
    name: [{ required: true, message: '请填输入姓名' }],
    gender: [{ required: true, message: '请填输入性别' }],
    date_of_birth: [{ required: true, message: '请填输入出生日期' }],
    id_number: [{ required: true, message: '请填输入证件号' }],
    // id_expiry_date: [{ required: true, message: '请填输入证件有效期' }],
  },
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: adviser.adviser_id,
    name: null,
    relation: null,
    gender: null,
    date_of_birth: null,
    id_type_id: null,
    id_number: null,
    id_expiry_date: null,
  }),
};
const edit_model_config_options = {
  gender: [
    { value: 0, name: '男' },
    { value: 1, name: '女' },
  ],
  relation: [
    { value: 0, name: '本人' },
    { value: 1, name: '配偶' },
    { value: 2, name: '子女' },
    { value: 3, name: '父母' },
    { value: 4, name: '其他' },
  ],
};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [
    {
      type: 'input',
      name: 'name',
      label: '姓名',
      label_i18n: t('page.cerp.beneficiary.form.label.name'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写姓名',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.name'),
    },
    {
      type: 'radio',
      name: 'gender',
      label: '性别',
      label_i18n: t('page.cerp.beneficiary.form.label.gender'),
      disabled: false,
      labelKey: 'name',
      valueKey: 'value',
      defaultValue: '',
    },
    {
      type: 'datepicker',
      name: 'date_of_birth',
      label: '出生日期',
      label_i18n: t('page.cerp.beneficiary.form.label.date_of_birth'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写出生日期',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.date_of_birth'),
    },
    {
      type: 'remoteselect',
      name: 'id_type_id',
      label: '证件类别',
      label_i18n: t('page.cerp.beneficiary.form.label.id_type_id'),
      disabled: false,
      placeholder: '请选择证件类别',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.id_type_id'),
      datasourceType: 'remote',
      mode: 'default',
      allowClear: true,
      modalType: 'baseinfo-idtype',
    },
    {
      type: 'input',
      name: 'id_number',
      label: '证件号',
      label_i18n: t('page.cerp.beneficiary.form.label.id_number'),
      disabled: false,
      allowClear: true,
      inputType: 'text',
      defaultValue: '',
      placeholder: '请填写证件号',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.id_number'),
    },
    {
      type: 'datepicker',
      name: 'id_expiry_date',
      label: '证件有效期',
      label_i18n: t('page.cerp.beneficiary.form.label.id_expiry_date'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      showTime: false,
      valueFormat: 'YYYY-MM-DD',
      placeholder: '请填写证件有效期',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.id_expiry_date'),
    },
    {
      type: 'select',
      name: 'relation',
      label: '与被保险人关系',
      label_i18n: t('page.cerp.beneficiary.form.label.relation'),
      mode: 'default',
      disabled: false,
      defaultValue: '',
      labelKey: 'name',
      valueKey: 'value',
      placeholder: '请选择与被保险人关系',
      datasource: 'relation',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.relation'),
    },
    {
      type: 'input',
      name: 'ratio',
      label: '受益比例',
      label_i18n: t('page.cerp.beneficiary.form.label.ratio'),
      disabled: false,
      allowClear: true,
      inputType: 'number',
      defaultValue: '',
      placeholder: '请填写受益比例',
      placeholder_i18n: t('page.cerp.beneficiary.form.placeholder.ratio'),
    },
  ],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_id: adviser.adviser_id,
    name: null,
    gender: null,
    ratio: null,
    relation: null,
    date_of_birth: null,
    id_type_id: null,
    id_number: null,
    id_expiry_date: null,
  }),
};
const update_model_config_options = {
  gender: [
    { value: 0, name: '男' },
    { value: 1, name: '女' },
  ],
  relation: [
    { value: 0, name: '本人' },
    { value: 1, name: '配偶' },
    { value: 2, name: '子女' },
    { value: 3, name: '父母' },
    { value: 4, name: '其他' },
  ],
};

const titleList = {
  title: '受益人管理',
  addModalTitle: '新建受益人',
  updateTitle: '修改受益人',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: true, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    beneficiary_id: 'id',
  },
  delete: {
    beneficiary_id: 'id',
  },
  recover: {
    beneficiary_id: 'id',
  },
  updateData: {
    org_id: 'org_id',
    beneficiary_id: 'id',
  },
};

const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.beneficiary.column.title.index'),
    dataIndex: 'index',
    width: 50,
    fixed: 'left',
  },
  {
    title: t('page.cerp.beneficiary.column.title.id'),
    width: 150,
    dataIndex: 'id',
    checked: false,
  },
  {
    title: t('page.cerp.beneficiary.column.title.adviser__full_name'),
    minWidth: 150,
    dataIndex: 'adviser__full_name',
    checked: false,
    fixed: 'left',
  },
  {
    title: t('page.cerp.beneficiary.column.title.adviser__employee_number'),
    width: 150,
    dataIndex: 'adviser__employee_number',
    checked: false,
    fixed: 'left',
  },
  {
    title: t('page.cerp.beneficiary.column.title.name'),
    minWidth: 150,
    dataIndex: 'name',
    checked: true,
    fixed: 'left',
  },
  {
    title: t('page.cerp.beneficiary.column.title.gender'),
    width: 150,
    dataIndex: 'gender',
    checked: true,
    cellComponent: 'custom',
    field_0: t('page.Induction.options_Insurance_beneficiary.gender0'),
    field_1: t('page.Induction.options_Insurance_beneficiary.gender1'),
  },
  {
    title: t('page.cerp.beneficiary.column.title.date_of_birth'),
    width: 150,
    dataIndex: 'date_of_birth',
    checked: true,
    content: (record: any) => {
      return getDate(record.date_of_birth);
    },
  },
  {
    title: t('page.cerp.beneficiary.column.title.id_type__name'),
    width: 150,
    dataIndex: 'id_type__name',
    checked: true,
  },
  {
    title: t('page.cerp.beneficiary.column.title.id_number'),
    width: 150,
    dataIndex: 'id_number',
    checked: true,
  },
  {
    title: t('page.cerp.beneficiary.column.title.id_expiry_date'),
    width: 150,
    dataIndex: 'id_expiry_date',
    checked: true,
    content: (record: any) => {
      return getDate(record.id_expiry_date);
    },
  },
  {
    title: t('page.cerp.beneficiary.column.title.ratio'),
    width: 150,
    dataIndex: 'ratio',
    checked: true,
  },
  {
    title: t('page.cerp.beneficiary.column.title.relation'),
    width: 150,
    dataIndex: 'relation',
    checked: true,
    cellComponent: 'custom',
    field_0: t('page.Induction.options_Insurance_beneficiary.relation0'),
    field_1: t('page.Induction.options_Insurance_beneficiary.relation1'),
    field_2: t('page.Induction.options_Insurance_beneficiary.relation2'),
    field_3: t('page.Induction.options_Insurance_beneficiary.relation3'),
    field_4: t('page.Induction.options_Insurance_beneficiary.relation4'),
  },
  // {
  //   title: t('page.cerp.beneficiary.column.title.insurance__policy_no'),
  //   width: 150,
  //   dataIndex: 'insurance__policy_no',
  //   checked: true,
  // },
  {
    title: t('page.cerp.beneficiary.column.title.create_time'),
    width: 150,
    dataIndex: 'create_time',
    checked: false,
    content: (record: any) => {
      return getDateTime(record.create_time);
    },
  },
  {
    title: t('page.cerp.beneficiary.column.title.update_time'),
    width: 150,
    dataIndex: 'update_time',
    checked: false,

    content: (record: any) => {
      return getDateTime(record.update_time);
    },
  },
  {
    title: t('page.cerp.bankaccount.column.title.is_active'),
    width: 150,
    dataIndex: 'is_active',
    checked: false,
    cellComponent: 'boolean',
    name1: t('page.cerp.bankaccount.column.title.is_active.true'),
    name2: t('page.cerp.bankaccount.column.title.is_active.false'),
    filterMultiple: false,
    filters: [
      { text: t('page.cerp.bankaccount.column.title.is_active.all'), value: '' },
      { text: t('page.cerp.bankaccount.column.title.is_active.true'), value: true },
      { text: t('page.cerp.bankaccount.column.title.is_active.false'), value: false },
    ],
  },

  {
    title: t('page.cerp.beneficiary.column.title.action'),
    width: 180,
    dataIndex: 'action',
    checked: false,
    cellComponent: 'action',
    fixed: 'right',
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  slotName: 'gender',
  field_0: '男',
  field_1: '女',
},
  {
  type: 'custom',
  slotName: 'relation',
  field_0: '本人',
  field_1: '配偶',
  field_2: '子女',
  field_3: '父母',
  field_4: '其他',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_beneficiary_list,
    addData: null,
    updateData: null,
    removeData: delete_beneficiary,
    recoverData: recover_beneficiary,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
