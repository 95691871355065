import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createSlots as _createSlots, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, mergeProps as _mergeProps, normalizeStyle as _normalizeStyle, renderList as _renderList } from "vue"

const _hoisted_1 = {
  id: "card",
  class: "ant-pro-table"
}
const _hoisted_2 = {
  key: 0,
  class: "ant-pro-table-list-toolbar",
  ref: "elToolbar"
}
const _hoisted_3 = { class: "ant-pro-table-list-toolbar-container" }
const _hoisted_4 = { class: "ant-pro-table-list-toolbar-left" }
const _hoisted_5 = { class: "ant-pro-table-list-toolbar-title" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_radio_button = _resolveComponent("a-radio-button")!
  const _component_a_badge = _resolveComponent("a-badge")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_setting_outlined = _resolveComponent("setting-outlined")!
  const _component_s_table_summary_cell = _resolveComponent("s-table-summary-cell")!
  const _component_s_table_summary_row = _resolveComponent("s-table-summary-row")!
  const _component_s_table = _resolveComponent("s-table")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_form_modal = _resolveComponent("form-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_a_card, {
        "body-style": { padding: 0 },
        ref: "elRef",
        class: "mytable"
      }, {
        default: _withCtx(() => [
          (_ctx.is_tooltip_show)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode(_component_a_radio_group, {
                        value: _ctx.closed_value,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.closed_value) = $event)),
                        onChange: _ctx.closed
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_badge, {
                            count: _ctx.advicer_no_read_number,
                            style: {"margin-top":"5px","margin-right":"5px"}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_a_radio_button, { value: false }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('page.kefu.Consulting')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["count"]),
                          _createVNode(_component_a_radio_button, { value: true }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('page.kefu.Closed')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value", "onChange"])
                    ])
                  ]),
                  _createVNode(_component_a_space, { align: "center" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_button, {
                        size: 'small',
                        style: {"margin-right":"3px"},
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleConsult())),
                        type: 'primary'
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.t('page.kefu.consult')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ])
              ], 512))
            : _createCommentVNode("", true),
          (_ctx.is_show_table)
            ? (_openBlock(), _createBlock(_component_s_table, {
                key: 1,
                bordered: false,
                height: _ctx.tableHeight,
                size: _ctx.state.tableSize,
                columns: _ctx.dynamicColumns,
                "onUpdate:columns": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.dynamicColumns) = $event)),
                "data-source": _ctx.state.dataSource,
                indentSize: 0,
                autoRowHeight: true,
                wrapText: true,
                "row-height": _ctx.rowheight,
                rowKey: _ctx.rowkey,
                loading: _ctx.state.loading,
                virtual: _ctx.virtual || _ctx.is_virtual,
                customRow: _ctx.custom,
                customHeaderCell: _ctx.customHeaderCell,
                customCell: _ctx.customCell,
                rowClassName: _ctx.setRowClassName,
                showHeader: _ctx.showHeader,
                stripe: false,
                sticky: "",
                "summary-fixed": "",
                pagination: false,
                onChange: _ctx.handleTableChange,
                onResizeColumn: _ctx.resizeColumn,
                rowSelection: _ctx.rowSelection,
                expandedRowRender: _ctx.expandedRowRender,
                selectedRowKeys: _ctx.selectedRowKeys,
                "onUpdate:selectedRowKeys": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedRowKeys) = $event))
              }, _createSlots({
                headerCell: _withCtx(({ title, column }) => [
                  (column.headerCell)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createTextVNode(_toDisplayString(title) + " ", 1),
                        _createVNode(_component_a_button, {
                          danger: column?.headerCell?.danger,
                          type: column?.headerCell.type,
                          disabled: column?.headerCell.disabled,
                          htmlType: column?.headerCell.htmlType,
                          loading: column?.headerCell.loading,
                          shape: column?.headerCell.shape,
                          size: column?.headerCell?.size ? column.headerCell.size : 'small',
                          onClick: ($event: any) => (column?.headerCell.cb ? column.headerCell.cb(column) : null)
                        }, _createSlots({
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(column?.headerCell?.title) + " ", 1)
                          ]),
                          _: 2
                        }, [
                          (column?.headerCell?.icon)
                            ? {
                                name: "icon",
                                fn: _withCtx(() => [
                                  (column?.headerCell?.icon == 'settingOutlined')
                                    ? (_openBlock(), _createBlock(_component_setting_outlined, { key: 0 }))
                                    : (_openBlock(), _createElementBlock("span", _hoisted_7))
                                ])
                              }
                            : undefined
                        ]), 1032, ["danger", "type", "disabled", "htmlType", "loading", "shape", "size", "onClick"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(title), 1))
                ]),
                bodyCell: _withCtx(({ column, index, text, record }) => [
                  (column.dataIndex == 'index')
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(index + 1 + _ctx.state.pageSize * (_ctx.state.current - 1)), 1)
                      ], 64))
                    : (column.cellComponent)
                      ? (_openBlock(), _createBlock(_resolveDynamicComponent('Cell_' + column.cellComponent), _normalizeProps(_mergeProps({ key: 1 }, {
              column: column,
              index: index,
              text: text,
              record: record,
            })), null, 16))
                      : (_openBlock(), _createElementBlock("span", {
                          key: 2,
                          style: _normalizeStyle(column.color ? column.color(record, text) : null)
                        }, _toDisplayString(column.content ? column.content(record, text) : text), 5))
                ]),
                _: 2
              }, [
                (_ctx.expandedRowRenderComponent)
                  ? {
                      name: "expandedRowRender",
                      fn: _withCtx(({ record }) => [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.expandedRowRenderComponent(record)), _normalizeProps(_guardReactiveProps(record)), null, 16))
                      ])
                    }
                  : undefined,
                (_ctx.summaryColumnsShow)
                  ? {
                      name: "summary",
                      fn: _withCtx(() => [
                        _createVNode(_component_s_table_summary_row, null, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.summaryColumns, (item) => {
                              return (_openBlock(), _createBlock(_component_s_table_summary_cell, {
                                columnKey: item.columnKey,
                                key: item.index
                              }, _createSlots({
                                default: _withCtx(() => [
                                  (item.index == 0)
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, "合计"))
                                    : (!item.summary)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_10, " "))
                                      : _createCommentVNode("", true)
                                ]),
                                _: 2
                              }, [
                                (item.summary)
                                  ? {
                                      name: "default",
                                      fn: _withCtx(({ total }) => [
                                        _createElementVNode("span", {
                                          style: _normalizeStyle(item.total_color ? item.total_color(total) : null)
                                        }, _toDisplayString(item.total_content ? item.total_content(total) : total), 5)
                                      ])
                                    }
                                  : undefined
                              ]), 1032, ["columnKey"]))
                            }), 128))
                          ]),
                          _: 1
                        })
                      ])
                    }
                  : undefined
              ]), 1032, ["height", "size", "columns", "data-source", "row-height", "rowKey", "loading", "virtual", "customRow", "customHeaderCell", "customCell", "rowClassName", "showHeader", "onChange", "onResizeColumn", "rowSelection", "expandedRowRender", "selectedRowKeys"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 512)
    ]),
    (_ctx.editModalAdd.visible)
      ? (_openBlock(), _createBlock(_component_form_modal, _mergeProps({ key: 0 }, _ctx.editModalAdd, {
          loading: _ctx.confirmLoading,
          onCancel: _cache[4] || (_cache[4] = 
      () => {
        _ctx.editModalAdd.visible = false;
      }
    ),
          onOk: _ctx.handlerOk
        }), null, 16, ["loading", "onOk"]))
      : _createCommentVNode("", true),
    (_ctx.editModalUpdate.visible)
      ? (_openBlock(), _createBlock(_component_form_modal, _mergeProps({ key: 1 }, _ctx.editModalUpdate, {
          loading: _ctx.confirmLoading,
          onCancel: _cache[5] || (_cache[5] = 
      () => {
        _ctx.editModalUpdate.visible = false;
      }
    ),
          onOk: _ctx.handlerOk
        }), null, 16, ["loading", "onOk"]))
      : _createCommentVNode("", true)
  ], 64))
}