import { query_login_person_all_menu_list } from '@/api/permission/menu-manage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import ls from '@/utils/local-storage';
import { reactive,ref } from 'vue';
export default () => {
  const datasource: any = reactive([]);
  const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const loadData = (props: any) => {
    query_login_person_all_menu_list({ org_id: current_org?.id }).then((res: any) => {
      if (res.length > 0) {
        datasource.length = 0;
        res.forEach((item: any) => {
          datasource.push(item);
        });
      }
    });
  };

  return {
    loadData,
    datasource,
  };
};