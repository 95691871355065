export const STORAGE_TOKEN_KEY = 'access_token';
export const STORAGE_ORG_LIST_KEY = 'org_list';
export const STORAGE_SHOP_LIST_KEY = 'shop_list';
export const STORAGE_CURRENT_SHOP_KEY = 'current_shop';
export const STORAGE_CURRENT_ORG_KEY = 'current_org';
export const STORAGE_CURRENT_ADVISER_KEY = 'current_adviser';
export const STORAGE_CURRENT_MENU_KEY = 'current_menu';
export const STORAGE_CURRENT_JSC_KEY = 'current_jsc';
export const STORAGE_LANG_KEY = 'app_lang';
export const STORAGE_SESSIONID2 = 'sessionid2';
export const STORAGE_SESSIONID = 'sessionid';
export const HTTP_TOKEN = 'http_token';
export const OPERATING_TIME_KEY = 'operating_time';
export const SHOU_DONG_DI_WD01HN_LAST = 'shou_dong_di_WD01HN_last';
export const WEIGHING_PLATFORM_FLAG = 'weighing_platform_flag';
export const SEND_ROBOT = 'send_robot';
export const AREA_ID = 'area_id';
export const CONFIGURATION_FILE = 'configuration_file';
export const ROLL_NO = 'roll_no';
export const IS_INDUCTION_PROCESS = 'is_induction_process';
export const NO_READ_NUMBER = 'no_read_number';
export const STORAGE_CURRENT_COMPANY = 'current_company';
