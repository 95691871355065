import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createSlots as _createSlots } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { key: 3 }
const _hoisted_5 = { key: 4 }
const _hoisted_6 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_table = _resolveComponent("a-table")!

  return (_openBlock(), _createBlock(_component_a_table, {
    bordered: true,
    size: 'small',
    loading: _ctx.state.loading,
    scroll: { y: 400, x: _ctx.columnState.tableWidth },
    "row-selection": _ctx.rowSelection,
    columns: _ctx.dynamicColumns,
    "row-key": "id",
    "data-source": _ctx.state.dataSource,
    pagination: {
      current: _ctx.state.current,
      pageSize: _ctx.state.pageSize,
      total: _ctx.state.total,
    },
    onChange: _ctx.handleTableChange
  }, _createSlots({ _: 2 }, [
    _renderList(_ctx.slotList, (item) => {
      return {
        name: item.slotName,
        fn: _withCtx(({ index, text }) => [
          (item.slotName === 'index')
            ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(index + 1 + _ctx.state.pageSize * (_ctx.state.current - 1)), 1))
            : _createCommentVNode("", true),
          (item.type === 'custom')
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(item['field_' + text]), 1))
            : _createCommentVNode("", true),
          (item.type === 'datetime')
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.getDateTime(text)), 1))
            : _createCommentVNode("", true),
          (item.type === 'date')
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.getDate(text)), 1))
            : _createCommentVNode("", true),
          (item.type === 'full_date')
            ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.getFullDate(text)), 1))
            : _createCommentVNode("", true),
          (item.type === 'time')
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.getTime(text)), 1))
            : _createCommentVNode("", true),
          (text && item.type === 'boolean')
            ? (_openBlock(), _createBlock(_component_a_tag, {
                key: 6,
                color: "green"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.name1), 1)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (!text && item.type === 'boolean')
            ? (_openBlock(), _createBlock(_component_a_tag, {
                key: 7,
                color: "red"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.name2), 1)
                ]),
                _: 2
              }, 1024))
            : _createCommentVNode("", true),
          (item.type === 'list')
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 8 }, _renderList(text, (obj) => {
                return (_openBlock(), _createBlock(_component_a_tag, {
                  color: "#87d068",
                  key: obj.id,
                  style: {"margin-bottom":"1px"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(obj.name), 1)
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            : _createCommentVNode("", true)
        ])
      }
    })
  ]), 1032, ["loading", "scroll", "row-selection", "columns", "data-source", "pagination", "onChange"]))
}