import { query_my_rel_org_list } from '@/api/org-model';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';

export default () => {
  const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const search_config: any = {
    model: {
      org_id: org.id,
    },
    watch: [],
  };

  return {
    getData: query_my_rel_org_list,
    searchConfig: search_config,
  };
};
