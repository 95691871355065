import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function get_upload_files_url(params: Record<string, any>) {
  return request.post('/nsbcs/get_upload_files_url', params);
}

export async function get_file(params: Record<string, any>) {
  return request.post('/nsbcs/get_file', params);
}

export async function query_all_nsfile_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/nsbcs/query_all_nsfile_list', params);
}

export async function get_view_by_file_html(params: Record<string, any>) {
  return request.post('/nsbcs/get_view_by_file_html', params);
}

export async function upload_file(params: Record<string, any>, options: any) {
  return request.post('/nsbcs/upload_file', params, options);
}

export async function get_file_url(params: Record<string, any>) {
  return request.post('/nsbcs/get_file_url', params);
}

export async function delete_file_by_id_oss(params: Record<string, any>) {
  return request.post('/nsbcs/delete_file_by_id_oss', params);
}

export async function get_file_url_public(params: Record<string, any>) {
  return request.post('/nsbcs/get_file_url_public', params);
}

export async function delete_file_by_id(params: Record<string, any>) {
  return request.post('/nsbcs/delete_file_by_id', params);
}

export async function get_upload_files_url_sdk(params: Record<string, any>) {
  return request.post('/nsbcs/get_upload_files_url_sdk', params);
}

export async function query_all_nsfile_nologin_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/nsbcs/query_all_nsfile_nologin_list', params);
}
