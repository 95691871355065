
import {defineComponent, ref} from 'vue';
import {message, notification} from 'ant-design-vue';
import {reactive} from 'vue';
import ls from '@/utils/local-storage';
import {STORAGE_CURRENT_ORG_KEY} from '@/store/mutation-type';
import {PlusOutlined, CloseCircleFilled} from '@ant-design/icons-vue';
import { useForm } from 'ant-design-vue/lib/form';
import { query_opcname_list } from '@/api/data_acquisition-model';
export default defineComponent({
  name: 'ConfigureModal',
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: () => false,
    },
    obj: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    opc_list: {
      type: Object,
      required: true,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, {emit}) {
    const cardDataSource: Array<any> = reactive([]);
    const cardDataSource1: Array<any> = reactive([]);
    if (props.type == 'update' && props.obj.relative_opc_name_start_list && props.obj.relative_opc_name_start_list.length > 0) {
      cardDataSource.push(...props.obj.relative_opc_name_start_list);
    } else {
      cardDataSource.push({
        opc_name: null,
        seconds: null,
      });
    }
    if (props.type == 'update' && props.obj.relative_opc_name_start_list && props.obj.relative_opc_name_end_list.length > 0) {
      cardDataSource1.push(...props.obj.relative_opc_name_end_list);
    } else {
      cardDataSource1.push({
        opc_name: null,
        seconds: null,
      });
    }
    const opc_list: any = ref([]);
    props.opc_list.map((item: any) => {
      opc_list.value.push({
        value: item,
        label: item,
      })
    })
    const confirmLoading = ref<boolean>(false);

    const modifyModal = reactive({
      title: props.title,
      form: {
        settings: {
          labelAlign: 'right',
          layout: 'vertical',
          col: [],
        },
        fields: [
          {
            type: 'input',
            name: 'name',
            label: '名称',
            disabled: false,
            allowClear: true,
            inputType: 'text',
            defaultValue: '',
            placeholder: '请填写名称',
          },
          {
            type: 'remotetreeselect',
            name: 'group_id',
            label: '分组',
            disabled: false,
            placeholder: '请选择分组',
            datasourceType: 'remote',
            mode: 'default',
            labelKey: 'name',
            valueKey: 'id',
            allowClear: true,
            modalType: 'data_acquisition-opcgroup',
          },
          {
            type: 'remoteselect',
            name: 'flag',
            label: '标记',
            disabled: false,
            placeholder: '请选择标记',
            datasourceType: 'remote',
            mode: 'default',
            labelKeyList: ['flag', 'name'],
            valueKey: 'flag',
            allowClear: true,
            modalType: 'data_acquisition-opcname',
          },
          {
            type: 'input',
            name: 'min',
            label: '最小值',
            disabled: false,
            allowClear: true,
            inputType: 'text',
            defaultValue: '',
            placeholder: '请填写最小值',
          },
          {
            type: 'select',
            name: 'value_type',
            label: '数据值',
            disabled: false,
            allowClear: true,
            inputType: 'text',
            defaultValue: '',
            labelKey: 'name',
            valueKey: 'value',
            datasource: 'value_type',
            placeholder: '请填写数据值',
          },
        ],
        rules: reactive({
          name: [{required: true, message: '请填写名称'}],
          flag: [{required: true, message: '请填写标记'}],
          value_type: [{required: true, message: '请填写数据值'}],
          value_type1: [{required: true, message: '请填写数据值1'}],
        }),
        model: reactive({
          name: props.obj.name,
          flag: props.obj.flag,
          group_id: null,
          index: props.obj.index,
          min: props.obj.min,
          value_type: props.obj.value_type,
          value_type1: props.obj.value_type1,
        }),
        watch: {
          flag: (newModel: any) => {
            const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
            query_opcname_list({
              org_id: org?.id,
              flag: newModel.flag,
              is_active: true,
              page_index: 1,
              page_size: 1,
            }).then((res: any) => {
              if (res && res.data && res.data.length > 0) {
                newModel.name = res.data[0].name;
              }
            });
          },
        },
      },
      options: {
        value_type: [
          { name: '中值', value: 'mid_value' },
          { name: '均值', value: 'avg_value' },
          { name: '值', value: 'value' },
          { name: '时间', value: 'time' },
        ],
      },
    });
    const {resetFields, validate, validateInfos} = useForm(modifyModal.form.model, modifyModal.form.rules);
    const state = {
      card: {
        columns: [
          {
            title: '参考开始位置',
            dataIndex: 'opc_name',
            key: 'opc_name',
            width: '150px',
            slots: {customRender: 'opc_name'},
          },
          {
            title: '参考开始位置时间差',
            dataIndex: 'seconds',
            key: 'seconds',
            width: '100px',
            slots: {customRender: 'seconds'},
          },
          {
            title: '',
            dataIndex: 'del',
            key: 'del',
            width: '10px',
            slots: {customRender: 'del'},
          },
        ],
        dataSource: cardDataSource,
      },
      card1: {
        columns: [
          {
            title: '参考结束位置',
            dataIndex: 'opc_name',
            key: 'opc_name',
            width: '150px',
            slots: {customRender: 'opc_name'},
          },
          {
            title: '参考结束位置时间差',
            dataIndex: 'seconds',
            key: 'seconds',
            width: '100px',
            slots: {customRender: 'seconds'},
          },
          {
            title: '',
            dataIndex: 'del',
            key: 'del',
            width: '10px',
            slots: {customRender: 'del'},
          },
        ],
        dataSource: cardDataSource1,
      },

    };
    const handleCancel = () => {
      emit('cancel');
    };
    const handleOk = () => {
      const obj: any = {};
      if(modifyModal.form.model.name){
        obj.name = modifyModal.form.model.name;
      }else{
        notification.error({
          message: '提示',
          description: '请填写名称',
        });
        return;
      }
      if(modifyModal.form.model.flag){
        obj.flag = modifyModal.form.model.flag;
      }else{
        notification.error({
          message: '提示',
          description: '请填写标记',
        });
        return;
      }
      if(modifyModal.form.model.index){
        obj.index = modifyModal.form.model.index;
      }else{
        obj.index = 0;
      }

      if(modifyModal.form.model.value_type) {
        obj.value_type = modifyModal.form.model.value_type;
        if (obj.value_type == 'mid_value') {
          obj.value_type1 = 'mid';
        } else if (obj.value_type == 'avg_value') {
          obj.value_type1 = 'avg';
        } else if (obj.value_type == 'time') {
          obj.value_type1 = '';
        } else if (obj.value_type == 'value') {
          obj.value_type1 = '';
        }
      } else {
        notification.error({
          message: '提示',
          description: '请填写数据值',
        });
        return;
      }
      // if(modifyModal.form.model.value_type1){
      //   obj.value_type1 = modifyModal.form.model.value_type1;
      // }
      if(modifyModal.form.model.min){
        obj.min = modifyModal.form.model.min;
      }
      obj.relative_opc_name_start_list = [...cardDataSource];
      obj.relative_opc_name_end_list = [...cardDataSource1];
      emit('ok', obj);
    };
    const add_custom_field = () => {
        cardDataSource.push({
          opc_name: null,
          seconds: null,
        });
    };
    const add_custom_field1 = () => {
        cardDataSource1.push({
          opc_name: null,
          seconds: null,
        });
    };
    const del_custom_field = (index: any) => {
      cardDataSource.splice(index, 1);
    };
    const del_custom_field1 = (index: any) => {
      cardDataSource1.splice(index, 1);
    };
    return {
      message,
      state,
      opc_list,
      modifyModal,
      validateInfos,
      confirmLoading,
      add_custom_field,
      add_custom_field1,
      del_custom_field,
      del_custom_field1,
      handleCancel,
      handleOk,
    };
  },
  components: {
    PlusOutlined,
    CloseCircleFilled,
  },
});
