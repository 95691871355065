import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_popover = _resolveComponent("a-popover")!

  return (_openBlock(), _createBlock(_component_a_popover, {
    title: "快速跳转",
    trigger: "click"
  }, {
    content: _withCtx(() => [
      _createVNode(_component_a_space, {
        size: 3,
        direction: "vertical"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.column.linkList, (item) => {
            return (_openBlock(), _createBlock(_component_a_button, {
              type: "link",
              onClick: ($event: any) => (_ctx.gotoUrl(item.route(_ctx.record, _ctx.text)))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(item.title), 1)
              ]),
              _: 2
            }, 1032, ["onClick"]))
          }), 256))
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_button, { type: "link" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.column.content?_ctx.column.content(_ctx.record, _ctx.text):_ctx.text), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}