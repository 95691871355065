
import {defineComponent} from 'vue';
import {
  ColumnHeightOutlined,
  DeleteOutlined,
  EditOutlined, ForwardFilled,
  FullscreenExitOutlined,
  FullscreenOutlined,
  PlusOutlined,
  RedoOutlined,
  ReloadOutlined,
  SettingOutlined,
} from '@ant-design/icons-vue';
import FormModal from "@/components/form-modal/form-modal.vue";
import SearchRender from "@/components/FormRender/SearchRender.vue";

export default defineComponent({
  name: 'Cell_clicklist',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String, Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {
      },
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
  components: {
    DeleteOutlined,
  },
});
