import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d189d15"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  style: {"height":"300px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: _ctx.title,
    width: "800px",
    visible: _ctx.visible,
    transitionName: "",
    maskTransitionName: "",
    destroyOnClose: true,
    confirmLoading: _ctx.loading,
    centered: "",
    onOk: _ctx.handleSubmit,
    onCancel: _cache[0] || (_cache[0] = 
      () => {
        _ctx.$emit('cancel');
      }
    )
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout, { id: "components-layout-demo-basic" }, {
        default: _withCtx(() => [
          _createVNode(_component_a_layout_sider, { width: "400px" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.selected, (obj) => {
                return (_openBlock(), _createBlock(_component_a_tag, {
                  closable: "",
                  color: "#87d068",
                  key: obj.key,
                  onClose: ($event: any) => (_ctx.closeTag(obj.key)),
                  style: {"float":"left","margin-bottom":"1px","margin-right":"2px"}
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(obj.name), 1)
                  ]),
                  _: 2
                }, 1032, ["onClose"]))
              }), 128))
            ]),
            _: 1
          }),
          _createVNode(_component_a_layout_content, null, {
            default: _withCtx(() => [
              (_ctx.person__list.length)
                ? (_openBlock(), _createBlock(_component_a_table, {
                    key: 0,
                    bordered: "",
                    scroll: { y: 550 },
                    defaultExpandedRowKeys: _ctx.state.expandedRowKeys,
                    size: 'small',
                    columns: _ctx.columns,
                    loading: _ctx.state.loading,
                    "data-source": _ctx.person__list,
                    "row-selection": {
            selectedRowKeys: _ctx.state.selectedRowKeys,
            onSelect: _ctx.onSelect,
            onChange: _ctx.onChange,
            onSelectAll: _ctx.onSelectAll,
          },
                    pagination: false
                  }, null, 8, ["defaultExpandedRowKeys", "columns", "loading", "data-source", "row-selection"]))
                : _createCommentVNode("", true),
              (!_ctx.person__list.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_a_spin, {
                      spinning: true,
                      tip: "加载中..."
                    })
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["title", "visible", "confirmLoading", "onOk"]))
}