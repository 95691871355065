
import { defineComponent, ref, reactive, watch, computed, onMounted, onActivated } from 'vue';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { RequestData, useFetchData } from '@/utils/hooks/useFetchData';
import { useForm } from 'ant-design-vue/es/form';
import { Pagination, TableColumn, TableColumnSortOrder, TableFilters } from '@/typing';
import { useStore } from 'vuex';
import getFormConfig from '@/components/FormRender/RemoteModal/index';
import { useTableDynamicColumns } from '@/utils/hooks/useTableColumn';
import { getTime, getFullDate, getDate, getDateTime } from '@/utils/function';

type Key = ColumnProps['key'];

interface DataType {
  id: number;
  key: Key;
  name: string;
}

export default defineComponent({
  name: 'RemoteModal',
  props: {
    field: {
      type: Object,
      required: true,
    },
    modalType: {
      type: String,
      required: true,
      default: () => '',
    },
    value: {
      type: [Array, String, Number],
      required: false,
      default: () => [],
    },
    init_model: {
      type: Object,
      default: () => {},
    },
    field_status: {
      type: Object,
      default: () => {},
    },
    watch: {
      type: Array,
      default: () => [],
    },
    formModel: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  emits: ['update:value', 'update:label'],
  setup(props, { emit }) {
    const loading = ref(false);
    const store = useStore();
    const current_org = store.getters['user/current_org'];
    const { searchConfig, searchConfigOptions, getData, columns, slotList } = getFormConfig(
      props.modalType,
    );
    const search_modelRef = reactive({
      ...searchConfig.model,
      org_id: current_org.id,
      is_active: true,
      ...props.init_model,
    });

    for (let field of searchConfig.fields) {
      if (props.field_status && props.field_status.hasOwnProperty(field.name)) {
        if (props.field_status[field.name] == 0) {
          field.disabled = true;
          field.hidden = false;
        } else if (props.field_status[field.name] == 1) {
          field.disabled = false;
          field.hidden = true;
        } else {
          field.disabled = false;
          field.hidden = false;
        }
      }
    }

    const old_obj: { [key: string]: any } = {};
    onMounted(() => {
      if (props.watch) {
        for (let k of props.watch) {
          if (props.formModel) {
            old_obj[k as string] = props.formModel[k as string];
          }
        }
      }
      if (props.watch && props.formModel) {
        for (let k of props.watch) {
          search_modelRef[k as string] = props.formModel[k as string];
          watch(
            () => props.formModel[k as string],
            () => {
              if (props.watch && props.formModel) {
                for (let k of props.watch) {
                  if (old_obj[k as string] != props.formModel[k as string]) {
                    search_modelRef[k as string] = props.formModel[k as string];
                  }
                }
              }
            },
            { immediate: true },
          );
        }
      }
    });

    const { state: columnState, dynamicColumns } = useTableDynamicColumns(
      columns as TableColumn[],
      {
        checkAll: false,
        needRowIndex: false,
      },
      store,
    );
    const get_value_key = () => {
      return props.field.valueKey ? (props.field.valueKey as string) : 'id';
    };
    const get_label_key = () => {
      return props.field.labelKey ? (props.field.labelKey as string) : 'name';
    };
    const datasource = computed(() => {
      return modalRef.datasource.map(item => {
        if (props.field.labelKeyList) {
          let label = [];
          for (let key of props.field.labelKeyList) {
            label.push(item[key as string]);
          }
          return {
            value: item[get_value_key()],
            label: label.join(props.field.labelSpace),
          };
        } else {
          return {
            value: item[get_value_key()],
            label: item[get_label_key()],
          };
        }
      });
    });
    const modalRef = reactive({
      visible: false,
      selectedRows: new Array<number>(),
      selectedRowsKey: new Array<number>(),
      selectedRowsTotal: new Array<any>(),
      datasource: new Array<any>(),
    });

    const rowSelection = {
      type:
        props.field.mode == 'default'
          ? 'radio'
          : props.field.mode == 'multiple'
          ? 'checkbox'
          : 'checkbox',
      columnWidth: '30px',
      selectedRowKeys: modalRef.selectedRowsKey,
      onChange: (selectedRowKeys: Key[], selectedRows: DataType[]) => {
        // modalRef.selectedRowsKey.length = 0;
        let remove_key: Array<number> = modalRef.selectedRowsKey.filter(
          item => selectedRowKeys.indexOf(Number(item)) == -1,
        );
        modalRef.selectedRowsTotal = modalRef.selectedRowsTotal.filter(
          item => remove_key.indexOf(item.id) == -1,
        );
        selectedRows.forEach((item: DataType) => {
          if (modalRef.selectedRowsKey.indexOf(item.id) == -1) {
            modalRef.selectedRowsTotal.push(item);
          }
        });

        modalRef.selectedRowsKey.length = 0;
        selectedRows.forEach((item: DataType) => {
          modalRef.selectedRowsKey.push(item.id);
          modalRef.datasource.push(item);
        });
      },
    };

    const {
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getData, {
      current: 1,
      pageSize: 20,
      tableSize: 'middle', // 'default' | 'middle' | 'small'
      stripe: true,
      requestParams: {
        ...search_modelRef,
      },
    });
    watch(
      () => state.dataSource,
      () => {
        let total_ids: Array<number> = modalRef.selectedRowsTotal.map(item => item.id);
        state.dataSource.map((item: any) => {
          if (total_ids.indexOf(item.id) > -1) {
            modalRef.selectedRowsKey.push(item.id);
          }
        });
      },
    );
    const init = () => {
      let list: any[] = [];
      if (props.value instanceof Array) {
        if (props.value.length > 0) {
          list = props.value as Array<number>;
        }
      } else if(typeof props.value == 'string') {
        (props.value as String).split(',').map((item: any) => {
          list.push(Number(item));
        });
      } else {
        if (props.value) {
          list.push(props.value);
        }
      }
      if (list.length > 0) {
        let tmp_ids: any[] = [];
        let tmp_item_ids: any[] = [];
        modalRef.datasource.map(item => tmp_item_ids.push(item.id));
        list.map(item => {
          if (modalRef.selectedRows.indexOf(Number(item)) == -1) {
            modalRef.selectedRows.push(Number(item));
            modalRef.selectedRowsKey.push(Number(item));
          }
          if (tmp_item_ids.indexOf(item) == -1) {
            tmp_ids.push(item);
          }
        });
        if (tmp_ids.length > 0) {
          getData({ current: 1, ...search_modelRef, pageSize: 2000, ids: tmp_ids.join(',') }).then(
            function (res: RequestData<any>) {
              res.data.forEach(item => {
                if (list.indexOf(item.id) >= 0) {
                  modalRef.datasource.push(item);
                  modalRef.selectedRowsTotal.push(item);
                }
              });
              if (props.field.mode == 'default') {
                if (modalRef.selectedRows.length == 1) {
                  const selectItems:any = datasource.value.filter((item:any)=>modalRef.selectedRows.includes(item.value)).map((item:any)=>item.label);
                  emit('update:label', selectItems.length>0?selectItems[0]:'');
                } else {
                  emit('update:label', '');
                }
              } else {
                emit('update:label', datasource.value.filter((item:any)=>modalRef.selectedRows.includes(item.value)).map((item:any)=>item.label));
              }
            },
          );
        }
      } else {
        modalRef.selectedRows.length = 0;
        modalRef.selectedRowsKey.length = 0;
        modalRef.selectedRowsTotal.length = 0;
      }
    };
    init();

    watch(
      () => props.value,
      () => {
        init();
      },
    );

    const handleTableChange = (
      { current, pageSize }: Pagination,
      filters: TableFilters,
      sorter: TableColumnSortOrder,
    ) => {
      console.log(sorter);
      setPageInfo({
        current,
        pageSize,
        ...filters,
        ...search_modelRef,
      });

      reload();
    };

    const { validateInfos: searchValidateInfos } = useForm(search_modelRef, searchConfig.rules);

    const search = () => {
      setPageInfo({
        current: 1,
        ...search_modelRef,
      });
      reload();
    };

    const openModal = () => {
      modalRef.visible = true;
      let remove_ids: Array<number> = modalRef.datasource
        .filter(item => modalRef.selectedRows.indexOf(item.id) == -1)
        .map(item => item.id);
      remove_ids.forEach(item => {
        if (modalRef.selectedRowsKey.indexOf(item) != -1) {
          modalRef.selectedRowsKey.splice(modalRef.selectedRowsKey.indexOf(item), 1);
        }
      });

      let remove_total: Array<any> = modalRef.selectedRowsTotal.filter(
        item => modalRef.selectedRows.indexOf(item.id) == -1,
      );
      remove_total.forEach(item => {
        if (modalRef.selectedRowsTotal.indexOf(item) != -1) {
          modalRef.selectedRowsTotal.splice(modalRef.selectedRowsTotal.indexOf(item), 1);
        }
      });
    };

    const handleSubmit = (e: Event) => {
      e.preventDefault();
      modalRef.visible = false;
      if (modalRef.selectedRows == undefined) {
        modalRef.selectedRows = new Array<number>();
      }
      modalRef.selectedRows.length = 0;
      modalRef.selectedRowsTotal.map(item =>
        modalRef.selectedRows.push(
          item[props.field.valueKey ? (props.field.valueKey as string) : 'id'],
        ),
      );
      modalRef.datasource.length = 0;
      modalRef.selectedRowsTotal.map(item => modalRef.datasource.push(item));
      if (props.field.mode == 'default') {
        if (modalRef.selectedRows.length == 1) {
          emit('update:value', modalRef.selectedRows[0]);
          const selectItems:any = datasource.value.filter((item:any)=>{
            return modalRef.selectedRows==item.value;
          }).map((item:any)=>item.label);
          emit('update:label', selectItems.length>0?selectItems[0]:'');
        } else {
          emit('update:value', null);
          emit('update:label', '');
        }

      } else {
        emit('update:value', modalRef.selectedRows);
        emit('update:label', datasource.value.filter((item:any)=>modalRef.selectedRows.includes(item.value)).map((item:any)=>item.label));
      }
    };
    const handleChange = () => {
      if (modalRef.selectedRows == undefined) {
        modalRef.selectedRows = new Array<number>();
      }
      if (props.field.mode == 'default') {
        if (modalRef.selectedRows.length == 1) {
          emit('update:value', modalRef.selectedRows[0]);
          const selectItems:any = datasource.value.filter((item:any)=>modalRef.selectedRows.includes(item.value)).map((item:any)=>item.label);
          emit('update:label', selectItems.length>0?selectItems[0]:'');
        } else {
          emit('update:value', null);
          emit('update:label', '');
        }

      } else {
        emit('update:value', modalRef.selectedRows);
        emit('update:label', datasource.value.filter((item:any)=>modalRef.selectedRows.includes(item.value)).map((item:any)=>item.label));
      }
    };

    return {
      props,
      modalRef,
      datasource,
      state,
      loading,
      getDateTime,
      getDate,
      getFullDate,
      getTime,
      search,
      openModal,
      handleSubmit,
      handleChange,
      handleTableChange,
      rowSelection,
      searchValidateInfos,
      search_modelRef,
      searchConfig,
      searchConfigOptions,
      getData,
      columnState,
      dynamicColumns,
      slotList,
    };
  },

  data() {
    return {
      model: this.value,
    };
  },
});
