import request from '@/utils/request';

export type LoginType = 'account' | 'telephone';
export type LoginStatus = 'ok' | 'error';

export interface LoginParams {
  type: LoginType;
  username: string;
  password: string;
  org_id?:number;
}

export interface LoginResp {
  type?: LoginType;
  org_list: any[];
  sessionid: string;
  user_id: string;
  mqtt_password: string;
  // currentAuthority: string;
}

export interface UserInfo {
  date_joined: string;
  email: string;
  id: number;
  impassword: string;
  imusername: string;
  is_active: boolean;
  is_staff: boolean;
  realname: string;
  username: string;
  role?: string;
}

export interface CaptchaResp {
  captcha: number;
}

export interface SmsCaptchaRequest {
  mobile: string;
}

// 后端的结构体定义
export type RouteItem = {
  id: number | string;
  parentId: number | string;
  name: string;
  path: string;
  redirect: string;
  component: string;
  meta: {
    title: string | false;
    icon?: string;
    target?: '_blank' | '_self';
    hideInMenu?: boolean;
    hideChildrenInMenu?: boolean;
    authority?: string | string[];
    [key: string]: any;
  };
};

export async function postAccountLogin(params: LoginParams) {
  return request.post<LoginParams, LoginResp>('/sys/simple_login_adviser', params);
}

export async function getCurrentUser() {
  return request.post<any, UserInfo>('/sys/my_userinfo');
}

export async function queryMyShopList(params: any) {
  return request.post<any, UserInfo>('/mjstore/query_my_shop_list', params);
}

export async function getCurrentUserNav() {
  return request.get<any, RouteItem[]>('/currentUserNav');
}

export async function postLogout() {
  return request.post<any, any>('/sys/logout');
}

export async function getSmsCaptcha(params: SmsCaptchaRequest) {
  return request.get<SmsCaptchaRequest, CaptchaResp>('/message/captcha/sms', {
    params,
  });
}
