
import { defineComponent } from 'vue';
import { UserOutlined } from '@ant-design/icons-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

import { CHANGE_CURRENT_ORG } from '@/store/modules/user/actions';

export default defineComponent({
  name: 'OrgNameNow',
  props: {
    org_list: {
      type: Array,
      default: () => [],
    },
    current_org: {
      type: Object,
      default: () => {},
    },
    menu: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { t } = useI18n();
    const select_org = (obj: any) => {
      // ls.set(STORAGE_CURRENT_ORG_KEY, obj);
      store.dispatch(`user/${CHANGE_CURRENT_ORG}`, obj).then(() => {
        router.push({
          path: '/adviser_workplace',
        });
      });
    };

    return {
      t,
      select_org,
    };
  },
  components: {
    UserOutlined,
  },
});
