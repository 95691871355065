import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-646a00ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"height":"62px","width":"100%"}
}
const _hoisted_2 = {
  key: 2,
  style: {"height":"30px","position":"relative"}
}
const _hoisted_3 = { style: {"position":"absolute","bottom":"0","padding":"0","margin":"0","font-weight":"bolder","font-size":"17px"} }
const _hoisted_4 = {
  key: 3,
  style: {"height":"30px","position":"relative"}
}
const _hoisted_5 = { style: {"position":"absolute","bottom":"0","color":"#999999","padding":"0","margin-left":"50px","font-size":"12px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StarFilled = _resolveComponent("StarFilled")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_AttributeTable = _resolveComponent("AttributeTable")!
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_cascader = _resolveComponent("a-cascader")!
  const _component_a_month_picker = _resolveComponent("a-month-picker")!
  const _component_a_date_picker = _resolveComponent("a-date-picker")!
  const _component_a_range_picker = _resolveComponent("a-range-picker")!
  const _component_a_time_picker = _resolveComponent("a-time-picker")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_checkbox_group = _resolveComponent("a-checkbox-group")!
  const _component_a_tree_select = _resolveComponent("a-tree-select")!
  const _component_remote_select = _resolveComponent("remote-select")!
  const _component_remote_tree_select = _resolveComponent("remote-tree-select")!
  const _component_remote_modal = _resolveComponent("remote-modal")!
  const _component_remote_table = _resolveComponent("remote-table")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_divider = _resolveComponent("a-divider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.field.type !== 'divider')
      ? (_openBlock(), _createBlock(_component_a_form_item, _mergeProps({
          key: 0,
          label: _ctx.field.label_i18n == undefined ? _ctx.field.label : _ctx.t(_ctx.field.label_i18n),
          name: _ctx.field.name,
          extra: _ctx.field.extra
        }, _ctx.validateInfos ? _ctx.validateInfos[_ctx.field.name] : null), {
          default: _withCtx(() => [
            (_ctx.field.type === 'kong')
              ? (_openBlock(), _createElementBlock("div", _hoisted_1))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'kong_title')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  style: _normalizeStyle('height: ' + _ctx.field.height + 'px')
                }, null, 4))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'title')
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.field.name_i18n == undefined ? _ctx.field.name : _ctx.t(_ctx.field.name_i18n)), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'tip')
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.field.name_i18n == undefined ? _ctx.field.name : _ctx.t(_ctx.field.name_i18n)), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'input')
              ? (_openBlock(), _createBlock(_component_a_input, {
                  key: 4,
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  value: _ctx.model,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model) = $event)),
                  placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请填写' + _ctx.field.label,
                  autocomplete: _ctx.field.autocomplete == undefined ? 'off' : _ctx.field.autocomplete,
                  disabled: _ctx.field.disabled,
                  "default-value": _ctx.field.defaultValue,
                  "allow-clear": _ctx.field.allowClear,
                  type: _ctx.field.type ? _ctx.field.type : 'input',
                  maxlength: _ctx.field.maxlength,
                  "addon-before": _ctx.field.addonBefore,
                  "addon-after": _ctx.field.addonAfter
                }, _createSlots({ _: 2 }, [
                  (_ctx.field?.suffix_icon == undefined ? false : _ctx.field?.suffix_icon)
                    ? {
                        name: "suffix",
                        fn: _withCtx(() => [
                          _createVNode(_component_StarFilled, { style: {"color":"#00BBFF"} })
                        ])
                      }
                    : undefined
                ]), 1032, ["size", "value", "placeholder", "autocomplete", "disabled", "default-value", "allow-clear", "type", "maxlength", "addon-before", "addon-after"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'volume')
              ? (_openBlock(), _createBlock(_component_a_input, {
                  key: 5,
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  value: _ctx.model,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model) = $event)),
                  placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请填写' + _ctx.field.label,
                  autocomplete: _ctx.field.autocomplete == undefined ? 'off' : _ctx.field.autocomplete,
                  disabled: _ctx.field.disabled,
                  "default-value": _ctx.field.defaultValue,
                  "allow-clear": _ctx.field.allowClear,
                  type: "number",
                  maxlength: _ctx.field.maxlength,
                  "addon-after": "L"
                }, _createSlots({ _: 2 }, [
                  (_ctx.field?.suffix_icon == undefined ? false : _ctx.field?.suffix_icon)
                    ? {
                        name: "suffix",
                        fn: _withCtx(() => [
                          _createVNode(_component_StarFilled, { style: {"color":"#00BBFF"} })
                        ])
                      }
                    : undefined
                ]), 1032, ["size", "value", "placeholder", "autocomplete", "disabled", "default-value", "allow-clear", "maxlength"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'weight')
              ? (_openBlock(), _createBlock(_component_a_input, {
                  key: 6,
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  value: _ctx.model,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model) = $event)),
                  placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请填写' + _ctx.field.label,
                  autocomplete: _ctx.field.autocomplete == undefined ? 'off' : _ctx.field.autocomplete,
                  disabled: _ctx.field.disabled,
                  "default-value": _ctx.field.defaultValue,
                  "allow-clear": _ctx.field.allowClear,
                  type: "number",
                  maxlength: _ctx.field.maxlength,
                  "addon-after": "Kg"
                }, _createSlots({ _: 2 }, [
                  (_ctx.field?.suffix_icon == undefined ? false : _ctx.field?.suffix_icon)
                    ? {
                        name: "suffix",
                        fn: _withCtx(() => [
                          _createVNode(_component_StarFilled, { style: {"color":"#00BBFF"} })
                        ])
                      }
                    : undefined
                ]), 1032, ["size", "value", "placeholder", "autocomplete", "disabled", "default-value", "allow-clear", "maxlength"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'attributetable')
              ? (_openBlock(), _createBlock(_component_AttributeTable, {
                  key: 7,
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  value: _ctx.model,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model) = $event)),
                  field: _ctx.field,
                  formModel: _ctx.formModel,
                  init_model: _ctx.field.init_model
                }, null, 8, ["size", "value", "field", "formModel", "init_model"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'textarea')
              ? (_openBlock(), _createBlock(_component_a_textarea, {
                  key: 8,
                  value: _ctx.model,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model) = $event)),
                  autocomplete: _ctx.field.autocomplete == undefined ? 'off' : _ctx.field.autocomplete,
                  placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请填写' + _ctx.field.label,
                  disabled: _ctx.field.disabled,
                  "allow-clear": _ctx.field.allowClear,
                  autoSize: {
        minRows: _ctx.field.minRows ? _ctx.field.minRows : 5,
        maxRows: _ctx.field.maxRows ? _ctx.field.maxRows : null,
      }
                }, null, 8, ["value", "autocomplete", "placeholder", "disabled", "allow-clear", "autoSize"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'number')
              ? (_openBlock(), _createBlock(_component_a_input_number, {
                  key: 9,
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  value: _ctx.model,
                  "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.model) = $event)),
                  autocomplete: _ctx.field.autocomplete == undefined ? 'off' : _ctx.field.autocomplete,
                  disabled: _ctx.field.disabled,
                  "default-value": _ctx.field.defaultValue,
                  min: _ctx.field.min,
                  max: _ctx.field.max,
                  formatter: _ctx.field.formatter,
                  precision: _ctx.field.precision,
                  step: _ctx.field.step,
                  placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请填写' + _ctx.field.label,
                  "addon-before": _ctx.field.addonBefore,
                  "addon-after": _ctx.field.addonAfter,
                  style: {"width":"100%"}
                }, null, 8, ["size", "value", "autocomplete", "disabled", "default-value", "min", "max", "formatter", "precision", "step", "placeholder", "addon-before", "addon-after"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'password')
              ? (_openBlock(), _createBlock(_component_a_input_password, {
                  key: 10,
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  value: _ctx.model,
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.model) = $event)),
                  autocomplete: _ctx.field.autocomplete == undefined ? 'off' : _ctx.field.autocomplete,
                  disabled: _ctx.field.disabled,
                  "default-value": _ctx.field.defaultValue,
                  min: _ctx.field.min,
                  max: _ctx.field.max,
                  formatter: _ctx.field.formatter,
                  precision: _ctx.field.precision,
                  step: _ctx.field.step,
                  placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请填写' + _ctx.field.label,
                  style: {"width":"100%"}
                }, null, 8, ["size", "value", "autocomplete", "disabled", "default-value", "min", "max", "formatter", "precision", "step", "placeholder"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'select')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 11 }, [
                  (_ctx.field.mode === 'default')
                    ? (_openBlock(), _createBlock(_component_a_select, {
                        key: 0,
                        size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                        value: _ctx.model,
                        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.model) = $event)),
                        style: {"min-width":"150px"},
                        autocomplete: _ctx.field.autocomplete == undefined ? 'off' : _ctx.field.autocomplete,
                        "show-search": !!_ctx.field.show_search,
                        disabled: _ctx.field.disabled,
                        placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请选择' + _ctx.field.label,
                        "default-value": _ctx.field.defaultValue,
                        "allow-clear": _ctx.field.allowClear,
                        maxTagCount: _ctx.field.maxTagCount
                      }, _createSlots({
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: option[_ctx.field.valueKey ? _ctx.field.valueKey : 'value'],
                              value: option[_ctx.field.valueKey ? _ctx.field.valueKey : 'value']
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(option[_ctx.field.labelKey ? _ctx.field.labelKey : 'label']), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, [
                        (_ctx.field?.suffix_icon == undefined ? false : _ctx.field?.suffix_icon)
                          ? {
                              name: "suffixIcon",
                              fn: _withCtx(() => [
                                _createVNode(_component_StarFilled, { style: {"color":"#00BBFF"} })
                              ])
                            }
                          : undefined
                      ]), 1032, ["size", "value", "autocomplete", "show-search", "disabled", "placeholder", "default-value", "allow-clear", "maxTagCount"]))
                    : (_openBlock(), _createBlock(_component_a_select, {
                        key: 1,
                        size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                        value: _ctx.model,
                        "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.model) = $event)),
                        style: {"min-width":"150px"},
                        "show-search": !!_ctx.field.show_search,
                        disabled: _ctx.field.disabled,
                        placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请选择' + _ctx.field.label,
                        "default-value": _ctx.field.defaultValue,
                        "allow-clear": _ctx.field.allowClear,
                        mode: _ctx.field.mode,
                        maxTagCount: _ctx.field.maxTagCount
                      }, _createSlots({
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
                            return (_openBlock(), _createBlock(_component_a_select_option, {
                              key: index,
                              value: option[_ctx.field.valueKey ? _ctx.field.valueKey : 'value']
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(option[_ctx.field.labelKey ? _ctx.field.labelKey : 'label']), 1)
                              ]),
                              _: 2
                            }, 1032, ["value"]))
                          }), 128))
                        ]),
                        _: 2
                      }, [
                        (_ctx.field?.suffix_icon == undefined ? false : _ctx.field?.suffix_icon)
                          ? {
                              name: "suffixIcon",
                              fn: _withCtx(() => [
                                _createVNode(_component_StarFilled, { style: {"color":"#00BBFF"} })
                              ])
                            }
                          : undefined
                      ]), 1032, ["size", "value", "show-search", "disabled", "placeholder", "default-value", "allow-clear", "mode", "maxTagCount"]))
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'groupselect')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 12 }, [
                  (_ctx.field.mode === 'default')
                    ? (_openBlock(), _createBlock(_component_a_select, {
                        key: 0,
                        size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                        value: _ctx.model,
                        "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.model) = $event)),
                        style: {"min-width":"150px"},
                        autocomplete: _ctx.field.autocomplete == undefined ? 'off' : _ctx.field.autocomplete,
                        "show-search": !!_ctx.field.show_search,
                        disabled: _ctx.field.disabled,
                        placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请选择' + _ctx.field.label,
                        "default-value": _ctx.field.defaultValue,
                        "allow-clear": _ctx.field.allowClear,
                        maxTagCount: _ctx.field.maxTagCount,
                        labelInValue: _ctx.field.labelInValue === true,
                        options: _ctx.options,
                        getPopupContainer: _ctx.field.getPopupContainer == undefined ? null : _ctx.field.getPopupContainer
                      }, null, 8, ["size", "value", "autocomplete", "show-search", "disabled", "placeholder", "default-value", "allow-clear", "maxTagCount", "labelInValue", "options", "getPopupContainer"]))
                    : (_openBlock(), _createBlock(_component_a_select, {
                        key: 1,
                        size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                        value: _ctx.model,
                        "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.model) = $event)),
                        style: {"min-width":"150px"},
                        "show-search": !!_ctx.field.show_search,
                        disabled: _ctx.field.disabled,
                        placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请选择' + _ctx.field.label,
                        "default-value": _ctx.field.defaultValue,
                        "allow-clear": _ctx.field.allowClear,
                        mode: _ctx.field.mode,
                        maxTagCount: _ctx.field.maxTagCount,
                        labelInValue: _ctx.field.labelInValue === true,
                        options: _ctx.options,
                        getPopupContainer: _ctx.field.getPopupContainer == undefined ? null : _ctx.field.getPopupContainer
                      }, null, 8, ["size", "value", "show-search", "disabled", "placeholder", "default-value", "allow-clear", "mode", "maxTagCount", "labelInValue", "options", "getPopupContainer"]))
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'cascader')
              ? (_openBlock(), _createBlock(_component_a_cascader, {
                  key: 13,
                  value: _ctx.model,
                  "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.model) = $event)),
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  disabled: _ctx.field.disabled,
                  options: _ctx.options,
                  placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请选择' + _ctx.field.label,
                  "default-value": _ctx.field.defaultValue,
                  "allow-clear": _ctx.field.allowClear
                }, null, 8, ["value", "size", "disabled", "options", "placeholder", "default-value", "allow-clear"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'monthpicker')
              ? (_openBlock(), _createBlock(_component_a_month_picker, {
                  key: 14,
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  value: _ctx.model,
                  "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.model) = $event)),
                  autocomplete: _ctx.field.autocomplete == undefined ? 'off' : _ctx.field.autocomplete,
                  disabled: _ctx.field.disabled,
                  "allow-clear": _ctx.field.allowClear,
                  "default-value": _ctx.field.defaultValue,
                  format: _ctx.field.format,
                  "disabled-date": _ctx.field.disabledTime,
                  "value-format": _ctx.field.valueFormat,
                  "show-time": _ctx.field.showTime,
                  placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请选择' + _ctx.field.label,
                  style: {"width":"100%"}
                }, _createSlots({ _: 2 }, [
                  (_ctx.field?.suffix_icon == undefined ? false : _ctx.field?.suffix_icon)
                    ? {
                        name: "suffixIcon",
                        fn: _withCtx(() => [
                          _createVNode(_component_StarFilled, { style: {"color":"#00BBFF"} })
                        ])
                      }
                    : undefined
                ]), 1032, ["size", "value", "autocomplete", "disabled", "allow-clear", "default-value", "format", "disabled-date", "value-format", "show-time", "placeholder"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'datepicker')
              ? (_openBlock(), _createBlock(_component_a_date_picker, {
                  key: 15,
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  value: _ctx.model,
                  "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.model) = $event)),
                  autocomplete: _ctx.field.autocomplete == undefined ? 'off' : _ctx.field.autocomplete,
                  disabled: _ctx.field.disabled,
                  "allow-clear": _ctx.field.allowClear,
                  "default-value": _ctx.field.defaultValue,
                  format: _ctx.field.format,
                  "disabled-date": _ctx.field.disabledTime,
                  "value-format": _ctx.field.valueFormat,
                  "show-time": _ctx.field.showTime,
                  placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请选择' + _ctx.field.label,
                  style: {"width":"100%"}
                }, _createSlots({ _: 2 }, [
                  (_ctx.field?.suffix_icon == undefined ? false : _ctx.field?.suffix_icon)
                    ? {
                        name: "suffixIcon",
                        fn: _withCtx(() => [
                          _createVNode(_component_StarFilled, { style: {"color":"#00BBFF"} })
                        ])
                      }
                    : undefined
                ]), 1032, ["size", "value", "autocomplete", "disabled", "allow-clear", "default-value", "format", "disabled-date", "value-format", "show-time", "placeholder"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'rangepicker')
              ? (_openBlock(), _createBlock(_component_a_range_picker, {
                  key: 16,
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  value: _ctx.model,
                  "onUpdate:value": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.model) = $event)),
                  "default-value": _ctx.field.defaultValue,
                  disabled: _ctx.field.disabled,
                  "allow-clear": _ctx.field.allowClear,
                  format: _ctx.field.format,
                  "value-format": _ctx.field.valueFormat,
                  "show-time": _ctx.field.showTime,
                  placeholder: _ctx.field.placeholder ? _ctx.field.placeholder : [ '开始时间','结束时间' ],
                  style: {"width":"100%"}
                }, _createSlots({ _: 2 }, [
                  (_ctx.field?.suffix_icon == undefined ? false : _ctx.field?.suffix_icon)
                    ? {
                        name: "suffixIcon",
                        fn: _withCtx(() => [
                          _createVNode(_component_StarFilled, { style: {"color":"#00BBFF"} })
                        ])
                      }
                    : undefined
                ]), 1032, ["size", "value", "default-value", "disabled", "allow-clear", "format", "value-format", "show-time", "placeholder"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'timepicker')
              ? (_openBlock(), _createBlock(_component_a_time_picker, {
                  key: 17,
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  value: _ctx.model,
                  "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.model) = $event)),
                  "default-value": _ctx.field.defaultValue,
                  disabled: _ctx.field.disabled,
                  "allow-clear": _ctx.field.allowClear,
                  format: _ctx.field.format ? _ctx.field.format : 'HH:mm',
                  "value-format": _ctx.field.valueFormat ? _ctx.field.valueFormat : 'HH:mm',
                  placeholder: _ctx.field.placeholder ? _ctx.field.placeholder : '请选择' + _ctx.field.label,
                  style: {"width":"100%"}
                }, _createSlots({ _: 2 }, [
                  (_ctx.field?.suffix_icon == undefined ? false : _ctx.field?.suffix_icon)
                    ? {
                        name: "suffixIcon",
                        fn: _withCtx(() => [
                          _createVNode(_component_StarFilled, { style: {"color":"#00BBFF"} })
                        ])
                      }
                    : undefined
                ]), 1032, ["size", "value", "default-value", "disabled", "allow-clear", "format", "value-format", "placeholder"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'radio')
              ? (_openBlock(), _createBlock(_component_a_radio_group, {
                  key: 18,
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  value: _ctx.model,
                  "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.model) = $event)),
                  disabled: _ctx.field.disabled,
                  "default-value": _ctx.field.defaultValue
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                      return (_openBlock(), _createBlock(_component_a_radio, {
                        key: option[_ctx.field.valueKey ? _ctx.field.valueKey : 'value'],
                        value: option[_ctx.field.valueKey ? _ctx.field.valueKey : 'value'],
                        disabled: option.disabled
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(option[_ctx.field.labelKey ? _ctx.field.labelKey : 'label']), 1)
                        ]),
                        _: 2
                      }, 1032, ["value", "disabled"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["size", "value", "disabled", "default-value"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'checkbox')
              ? (_openBlock(), _createBlock(_component_a_checkbox_group, {
                  key: 19,
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  value: _ctx.model,
                  "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.model) = $event)),
                  disabled: _ctx.field.disabled,
                  "default-value": _ctx.field.defaultValue
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
                      return (_openBlock(), _createBlock(_component_a_checkbox, {
                        key: option[_ctx.field.valueKey ? _ctx.field.valueKey : 'value'],
                        value: option[_ctx.field.valueKey ? _ctx.field.valueKey : 'value'],
                        disabled: option.disabled
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(option[_ctx.field.labelKey ? _ctx.field.labelKey : 'label']), 1)
                        ]),
                        _: 2
                      }, 1032, ["value", "disabled"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["size", "value", "disabled", "default-value"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'treeselect')
              ? (_openBlock(), _createBlock(_component_a_tree_select, {
                  key: 20,
                  size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
                  value: _ctx.model,
                  "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.model) = $event)),
                  placeholder: _ctx.field.placeholder_i18n == undefined ? _ctx.field.placeholder : _ctx.t(_ctx.field.placeholder_i18n) ? _ctx.t(_ctx.field.placeholder_i18n) : '请选择' + _ctx.field.label,
                  disabled: _ctx.field.disabled,
                  "default-value": _ctx.field.defaultValue,
                  "allow-clear": _ctx.field.allowClear,
                  "tree-data": _ctx.options,
                  "tree-checkable": _ctx.field.multiple,
                  "tree-node-filter-prop": _ctx.field.valueKey,
                  "tree-label-prop": _ctx.field.labelKey
                }, null, 8, ["size", "value", "placeholder", "disabled", "default-value", "allow-clear", "tree-data", "tree-checkable", "tree-node-filter-prop", "tree-label-prop"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'remoteselect')
              ? (_openBlock(), _createBlock(_component_remote_select, {
                  key: 21,
                  value: _ctx.model,
                  "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.model) = $event)),
                  label: _ctx.labelModel,
                  "onUpdate:label": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.labelModel) = $event)),
                  field: _ctx.field,
                  formModel: _ctx.formModel,
                  "get-data": _ctx.field.getData,
                  "label-key": _ctx.field.labelKey,
                  "value-key": _ctx.field.valueKey,
                  "label-key-list": _ctx.field.labelKeyList,
                  "label-space": _ctx.field.labelSpace,
                  init_model: _ctx.field.init_model,
                  search_config: _ctx.field.search_config
                }, null, 8, ["value", "label", "field", "formModel", "get-data", "label-key", "value-key", "label-key-list", "label-space", "init_model", "search_config"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'remotetreeselect')
              ? (_openBlock(), _createBlock(_component_remote_tree_select, {
                  key: 22,
                  value: _ctx.model,
                  "onUpdate:value": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.model) = $event)),
                  label: _ctx.labelModel,
                  "onUpdate:label": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.labelModel) = $event)),
                  field: _ctx.field,
                  "get-data": _ctx.field.getData,
                  "label-key": _ctx.field.labelKey,
                  "value-key": _ctx.field.valueKey,
                  search_config: _ctx.field.search_config,
                  disabled: _ctx.field.disabled,
                  "default-value": _ctx.field.defaultValue,
                  "allow-clear": _ctx.field.allowClear
                }, null, 8, ["value", "label", "field", "get-data", "label-key", "value-key", "search_config", "disabled", "default-value", "allow-clear"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'remotemodal')
              ? (_openBlock(), _createBlock(_component_remote_modal, {
                  key: 23,
                  value: _ctx.model,
                  "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.model) = $event)),
                  label: _ctx.labelModel,
                  "onUpdate:label": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.labelModel) = $event)),
                  field: _ctx.field,
                  columns: _ctx.field.columns,
                  init_model: _ctx.field.init_model,
                  field_status: _ctx.field.field_status,
                  watch: _ctx.field.watch,
                  "get-data": _ctx.field.getData,
                  modalType: _ctx.field.modalType,
                  search_config: _ctx.field.search_config
                }, null, 8, ["value", "label", "field", "columns", "init_model", "field_status", "watch", "get-data", "modalType", "search_config"]))
              : _createCommentVNode("", true),
            (_ctx.field.type === 'remotetable')
              ? (_openBlock(), _createBlock(_component_remote_table, {
                  key: 24,
                  value: _ctx.model,
                  "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.model) = $event)),
                  field: _ctx.field,
                  columns: _ctx.field.columns,
                  init_model: _ctx.field.init_model,
                  field_status: _ctx.field.field_status,
                  watch: _ctx.field.watch,
                  "get-data": _ctx.field.getData,
                  modalType: _ctx.field.modalType,
                  search_config: _ctx.field.search_config
                }, null, 8, ["value", "field", "columns", "init_model", "field_status", "watch", "get-data", "modalType", "search_config"]))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 16, ["label", "name", "extra"]))
      : _createCommentVNode("", true),
    (_ctx.field.type === 'divider')
      ? (_openBlock(), _createBlock(_component_a_divider, {
          key: 1,
          orientation: _ctx.field.orientation
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.field.label), 1)
          ]),
          _: 1
        }, 8, ["orientation"]))
      : _createCommentVNode("", true)
  ], 64))
}