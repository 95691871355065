import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_from_render = _resolveComponent("from-render")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_a_table = _resolveComponent("a-table")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: _ctx.title,
    width: _ctx.width,
    visible: true,
    confirmLoading: _ctx.loading,
    cancelText: "取消",
    okText: "确定",
    centered: "",
    wrapClassName: "custom-form-modal1",
    onOk: _ctx.handleSubmit,
    onCancel: _cache[0] || (_cache[0] = 
      () => {
        // resetFields();
        _ctx.$emit('cancel');
      }
    )
  }, {
    default: _withCtx(() => [
      _createVNode(_component_from_render, {
        ref: "render",
        form: _ctx.form,
        model: _ctx.modelRef,
        options: _ctx.options,
        validateInfos: _ctx.validateInfos
      }, null, 8, ["form", "model", "options", "validateInfos"]),
      (_ctx.cardDataSource.length > 0)
        ? (_openBlock(), _createBlock(_component_a_table, {
            key: 0,
            class: "steel_tapping2",
            bordered: true,
            scroll: { y: 300 },
            columns: _ctx.columns,
            "data-source": _ctx.cardDataSource,
            pagination: false
          }, _createSlots({ _: 2 }, [
            _renderList(_ctx.slotList, (col) => {
              return {
                name: col,
                fn: _withCtx(({ record }) => [
                  _createVNode(_component_a_checkbox, {
                    checked: record[col],
                    "onUpdate:checked": ($event: any) => ((record[col]) = $event)
                  }, null, 8, ["checked", "onUpdate:checked"])
                ])
              }
            })
          ]), 1032, ["columns", "data-source"]))
        : _createCommentVNode("", true),
      (_ctx.cardDataSource1.length > 0)
        ? (_openBlock(), _createBlock(_component_a_table, {
            key: 1,
            class: "steel_tapping2",
            bordered: true,
            scroll: { y: 300, x: _ctx.width },
            columns: _ctx.columns1,
            "data-source": _ctx.cardDataSource1,
            pagination: false
          }, _createSlots({ _: 2 }, [
            _renderList(_ctx.slotList1, (col) => {
              return {
                name: col,
                fn: _withCtx(({ record }) => [
                  _createVNode(_component_a_checkbox, {
                    checked: record[col],
                    "onUpdate:checked": ($event: any) => ((record[col]) = $event)
                  }, null, 8, ["checked", "onUpdate:checked"])
                ])
              }
            })
          ]), 1032, ["scroll", "columns", "data-source"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "width", "confirmLoading", "onOk"]))
}