import {
  query_session_record_list,
} from '@/api/service-model';
import { TableColumn } from '@/typing';
import { SearchConfig, Record } from '@/components/page-model/typing';
import { useI18n } from 'vue-i18n';
import ls from '@/utils/local-storage';
import {STORAGE_CURRENT_ADVISER_KEY, STORAGE_CURRENT_ORG_KEY} from '@/store/mutation-type';
import {
  slotList,
  btn_list,
  head_btn_list,
  event_obj,
  config,
} from '@/components/page-model_kefu/cerp-nextofkin-config';

import { reactive } from 'vue';
import {getDateTime} from "@/utils/function";

const None = null;
const True = true;
const False = false;

const current_org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
const adviser = ls.get(STORAGE_CURRENT_ADVISER_KEY);
const { t } = useI18n();
const search_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'inline',
  },
  fields: [],
  rules: {},
  model: reactive({
    org_id: current_org?.id,
    is_active: true,
    adviser_user_id: adviser.adviser_user_id,
  }),
};
const search_options = {};

const edit_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {},
  model: reactive({}),
};
const edit_model_config_options = {};

const update_model_config: SearchConfig = {
  settings: {
    labelAlign: 'right',
    layout: 'vertical',
    col: config.creatCol,
  },
  fields: [],
  rules: {},
  model: reactive({}),
};
const update_model_config_options = {};

const titleList = {
  title: '紧急联系人管理',
  addModalTitle: '新建紧急联系人',
  updateTitle: '修改紧急联系人',
  is_create: false, // 是否有【新建】按钮
  is_update: false, // 是否有【修改】按钮
  is_remove: false, // 是否有【删除】按钮
  is_recover: false, // 是否有【恢复】按钮
};

const api_fields: Record = {
  update: {
    next_of_kin: 'id',
  },
  delete: {
    next_of_kin: 'id',
  },
  recover: {
    next_of_kin: 'id',
  },
  updateData: {
    org_id: 'org_id',
    next_of_kin: 'id',
  },
};
const customCell = (obj: any) => {
    return { style: { background: '#F1F2F3' } };
};
const baseColumns: TableColumn[] = [
  {
    title: t('page.cerp.nextofkin.column.title.id'),
    width: 60,
    dataIndex: 'id',
    checked: true,
    cellComponent: 'name',
    content: (record: any) => {
      if(record.user_id != adviser.adviser_user_id){
        return true;
      }
    }
  },
  {
    title: t('page.cerp.nextofkin.column.title.adviser__full_name'),
    minWidth: 150,
    dataIndex: 'adviser__full_name',
    checked: true,
    cellComponent: 'talk',
    content: (record: any) => {
      if(record.user_id != adviser.adviser_user_id){
        return true;
      }
    },
  },
  {
    title: t('page.cerp.nextofkin.column.title.name'),
    width: 60,
    dataIndex: 'name',
    checked: true,
    cellComponent: 'name',
    content: (record: any) => {
      if(record.user_id == adviser.adviser_user_id){
        return true;
      }
    }
  },
];
const newSlotList: any = [];
Object.assign(newSlotList, slotList);
newSlotList.push({
  type: 'custom',
  // slotName: 'is_active',
  field_true: '可用',
  field_false: '禁用',
});

export default () => {
  return {
    searchConfig: search_config,
    searchConfigOptions: search_options,
    formModalConfig: edit_model_config,
    formModalConfigOptions: edit_model_config_options,
    updateFormModalConfig: update_model_config,
    updateFormModalConfigOptions: update_model_config_options,
    queryData: query_session_record_list,
    addData: null,
    updateData: null,
    removeData: null,
    recoverData: null,
    baseColumns: baseColumns,
    slotList: newSlotList,
    titleList: titleList,
    customCell: customCell,
    api_fields: api_fields,
    btn_list: btn_list,
    head_btn_list: head_btn_list,
    event_obj: event_obj,
  };
};
