import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { style: {"overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap","display":"inline-block","width":"150px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_delete_outlined = _resolveComponent("delete-outlined")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.text, (file) => {
    return (_openBlock(), _createElementBlock(_Fragment, {
      key: file.file_id
    }, [
      _createVNode(_component_a_tooltip, null, {
        title: _withCtx(() => [
          _createElementVNode("span", null, _toDisplayString(file.name), 1)
        ]),
        default: _withCtx(() => [
          (file.file_id)
            ? (_openBlock(), _createBlock(_component_a_button, {
                key: 0,
                style: {"padding":"1px"},
                type: "link",
                onClick: ($event: any) => (_ctx.column.cb(file)),
                title: file.name
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_1, _toDisplayString(file.name), 1)
                ]),
                _: 2
              }, 1032, ["onClick", "title"]))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1024),
      (_ctx.column.del)
        ? (_openBlock(), _createBlock(_component_delete_outlined, {
            key: 0,
            onClick: ($event: any) => (_ctx.column.del(_ctx.record, _ctx.text, file)),
            style: {"color":"#ff0000"}
          }, null, 8, ["onClick"]))
        : _createCommentVNode("", true)
    ], 64))
  }), 128))
}