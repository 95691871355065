
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro',
    },
  },
  setup(props, { slots }) {
    const hasContent = slots.content;
    const hasExtraContent = slots.extraContent;

    return {
      hasContent,
      hasExtraContent,
      prefixedClassName: props.prefixCls,
    };
  },
});
