
import { defineComponent, ref, computed } from 'vue';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro',
    },
    layout: {
      type: String,
      default: 'side',
    },
    theme: {
      type: String,
      default: 'dark',
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    collapsedButton: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['collapse'],
  setup(props, { emit }) {
    const baseClassName = `${props.prefixCls}-global-header`;
    const classNames = ref({
      [baseClassName]: true,
      [`${baseClassName}-layout-${props.layout}`]: props.layout,
    });
    const is_logo: any = ref(false);
    const str: any = window.location.host;
    if(str == 'consultants.cmes.work'){
      is_logo.value = true;
    }
    const isSide = computed(() => props.layout === 'side');
    const isLeft = computed(() => props.layout === 'left');
    const handleClick = (): void => {
      emit('collapse', !props.collapsed);
    };
    return {
      baseClassName,
      classNames,
      isSide,
      isLeft,
      is_logo,
      handleClick,
    };
  },
  components: {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
  },
});
