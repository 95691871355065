
import {defineComponent, computed, watchEffect, ref} from 'vue';
import { useStore } from 'vuex';
import { AppState } from '@/store/modules/app/state';
import { locales } from '@/locales';
import { GlobalOutlined } from '@ant-design/icons-vue';

const languageLabels = {
  'zh-CN': '简体中文',
  'en-US': 'English',
};
const languageIcons = {
  'zh-CN': '🇨🇳',
  'en-US': '🇺🇸',
};
const languageSupports = {
  'zh-CN': true,
  'en-US': true,
};

interface MenuClick {
  item: Record<string, unknown>;
  key: string;
  keyPath: string;
}

export default defineComponent({
  setup() {
    const store = useStore<AppState>();
    const currentLang = computed(() => store.getters['app/lang']);
    const lang: any = ref('');
    watchEffect(() => {
      if (currentLang.value == 'zh-CN') {
        lang.value = ' CH';
      }else if(currentLang.value == 'en-US'){
        lang.value = ' EN';
      }
    });
    const handleMenuClick = ({ key }: MenuClick): void => {
      store.dispatch('app/SET_LANG', key);
    };

    return {
      currentLang,
      lang,
      locales,
      languageLabels,
      languageIcons,
      languageSupports,
      handleMenuClick,
    };
  },
  components: {
    GlobalOutlined,
  },
});
