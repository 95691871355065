const urlmap: any = {
  //'/permission/apiurl-manage/index-1': 'http://localhost:3105/#/main/apiurl_manage',
  // '/permission/apiurl-manage/index-2': 'http://localhost:3105/#/main/apiurl_manage',
};

const isNeedGotoOld = (path: string): boolean => {
  if (urlmap[path]) {
    window.location.href = urlmap[path];
    return true;
  }
  return false;
};
export default isNeedGotoOld;
