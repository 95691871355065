
import { defineComponent, ref } from 'vue';
import { message } from 'ant-design-vue';
import {
  PlusOutlined,
  CloseCircleFilled,
  FileTwoTone,
  FolderOpenTwoTone,
} from '@ant-design/icons-vue';

export default defineComponent({
  name: 'TableTreeModal',
  props: {
    height: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    cardDataSource: {
      type: Array,
      required: true,
    },
  },
  emits: ['ok'],
  setup(props, { emit }) {
    const steel_type_id: any = ref(0);
    const columns = [
      {
        title: '分组',
        dataIndex: 'name',
        key: 'name',
      },
    ];
    const handleCancel = () => {};
    const setRowClassName = (record: any, index: number) => {
      return record.key === steel_type_id.value ? 'clickRowStyl3' : ''; //赋予点击行样式
    };
    const custom = (record: any) => {
      return {
        onClick: (event: any) => {
          if (record && record.key) {
            emit('ok', record);
            steel_type_id.value = record.key;
          }
        },
      };
    };
    return {
      message,
      columns,
      handleCancel,
      setRowClassName,
      custom,
    };
  },
  components: {
    PlusOutlined,
    CloseCircleFilled,
    FileTwoTone,
    FolderOpenTwoTone,
  },
});
