
import { defineComponent, onMounted, reactive, ref} from 'vue';
import _ from './index';
import { AppstoreOutlined } from '@ant-design/icons-vue';
import { localStorage } from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';
import { useStore } from 'vuex';
import {
  CHANGE_CURRENT_ORG,
  GET_CUSTOME_DATA_USER,
  GET_MY_MENU_DATA_LIST,
} from '@/store/modules/user/actions';
import { message } from 'ant-design-vue';
import { SET_JUMP_NAMPSPACE } from '@/store/modules/user/mutations';
import {Modal} from "ant-design-vue/es";

export default defineComponent({
  name: 'MenuDrawer',
  props: {},
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const DEFAULT_WIDTH = 600;
    const store = useStore();
    const { loadData, datasource } = _(); //data
    const current_org = localStorage.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
    const activeKey: any = ref([current_org?.id.toString()]);
    const state = reactive({
      width: DEFAULT_WIDTH,
      placement: 'left',
      visible: true,
      datasource: datasource,
    });

    onMounted(() => {
      loadData(props);
    });

    const onClose = () => {
      state.visible = false;
    };
    const handleGoMenu = (org_id: number, menu_url: number) => {
      const org_list = store.getters['user/org_list'];
      let namespace = process.env.VUE_APP_PUBLIC_PATH;
      if (process.env.NODE_ENV == 'production') {
        namespace = `${menu_url}_${org_id}`;
      }
      // const nameStore = getStoreByName(namespace);
      let modalInstance:any = null;
      org_list.map((item: any) => {
        if (item.id == org_id) {
          // nameStore.set(STORAGE_CURRENT_ORG_KEY, item);
          store
            .dispatch(`user/${SET_JUMP_NAMPSPACE}`, namespace)
            .then(() => {
              return store.dispatch(`user/${CHANGE_CURRENT_ORG}`, item);
            })
            .then(() => {
              if(current_org.id==org_id){
                return new Promise<any>((resolve)=>{
                  resolve(null);
                });
              }else{
                modalInstance = Modal.info({
                  title: () => '正在获取菜单数据',
                  mask:true,
                  okText: '知道了',
                });
                return store.dispatch(`user/${GET_MY_MENU_DATA_LIST}`, {
                  org_id: Number(org_id),
                });
              }

            })
            .then(() => {
              if(modalInstance){
                modalInstance.update({title: () => '正在获取个人配置数据',})
              }else{
                Modal.info({
                  title: () => '正在获取个人配置数据',
                  mask:true,
                  okText: '知道了',
                });
              }
              return store.dispatch(`user/${GET_CUSTOME_DATA_USER}`, {
                org_id: Number(org_id),
              });
            })
            .then(() => {
              if (process.env.NODE_ENV == 'production') {
                location.href = '/' + namespace + '/' + '?t=' + new Date().getTime();
              } else {
                location.href = '/' + '?t=' + new Date().getTime();
              }
            })
            .catch((e: any) => {
              console.error(e);
              message.error('切换组织错误');
            })
            .finally(() => {
              store.dispatch(`user/${SET_JUMP_NAMPSPACE}`, null);
              Modal.destroyAll();
            });
        }
      });
    };

    return {
      props,
      state,
      activeKey,
      handleGoMenu,
      onClose,
    };
  },
  components: {
    AppstoreOutlined,
  },
});
