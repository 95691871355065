import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.text && _ctx.text.length > 0)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.text, (obj, obj_index) => {
          return (_openBlock(), _createBlock(_component_a_button, {
            key: obj.id==undefined?obj_index:obj.id,
            type: "link",
            onClick: ($event: any) => (_ctx.column.cb ? _ctx.column.cb(_ctx.record, obj) : ''),
            style: {"margin-right":"3px"}
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(obj.name), 1)
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      : _createCommentVNode("", true),
    (_ctx.text && _ctx.text.length == 0)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createTextVNode(_toDisplayString(), 1)
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}