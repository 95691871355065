import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tree_select = _resolveComponent("a-tree-select")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_a_tree_select, {
      value: _ctx.modalRef.selectedRows,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modalRef.selectedRows) = $event)),
      size: _ctx.field.size == undefined ? 'default' : _ctx.field.size,
      style: {"min-width":"150px"},
      placeholder: _ctx.field.placeholder,
      disabled: _ctx.field.disabled,
      "default-value": _ctx.field.defaultValue,
      "allow-clear": _ctx.field.allowClear,
      "tree-data": _ctx.modalRef.datasource,
      "tree-checkable": _ctx.field.multiple,
      "tree-node-filter-prop": _ctx.field.valueKey,
      "tree-label-prop": _ctx.field.labelKey,
      onChange: _ctx.handleChange
    }, null, 8, ["value", "size", "placeholder", "disabled", "default-value", "allow-clear", "tree-data", "tree-checkable", "tree-node-filter-prop", "tree-label-prop", "onChange"])
  ]))
}