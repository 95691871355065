export default {
  'pages.layouts.userLayout.title':
    'Ant Design is the most influential web design specification in Xihu district',
  'page.login.accountLogin.tab': 'Account Login',
  'page.login.accountLogin.errorMessage': 'Incorrect username/password（admin/ant.design)',
  'page.login.username.placeholder': 'Username: admin or user',
  'page.login.username.required': 'Please input your username!',
  'page.login.password.placeholder': 'Password: ant.design',
  'page.login.password.required': 'Please input your password!',
  'page.login.phoneLogin.tab': 'Phone Login',
  'page.login.phoneLogin.errorMessage': 'Verification Code Error',
  'page.login.phoneNumber.placeholder': 'Phone Number',
  'page.login.phoneNumber.required': 'Please input your phone number!',
  'page.login.phoneNumber.invalid': 'Phone number is invalid!',
  'page.login.captcha.placeholder': 'Verification Code',
  'page.login.captcha.required': 'Please input verification code!',
  'page.login.phoneLogin.getVerificationCode': 'Get Code',
  'pages.getCaptchaSecondText': 'sec(s)',
  'page.login.rememberMe': 'Remember me',
  'page.login.forgotPassword': 'Forgot Password ?',
  'page.login.submit': 'Login',
  'page.login.loginWith': 'Login with :',
  'page.login.registerAccount': 'Register Account',
  'pages.welcome.advancedComponent': 'Advanced Component',
  'pages.welcome.link': 'Welcome',
  'pages.welcome.advancedLayout': 'Advanced Layout',
  'pages.welcome.alertMessage': 'Faster and stronger heavy-duty components have been released.',
  'pages.admin.subPage.title': 'This page can only be viewed by Admin',
  'pages.admin.subPage.alertMessage':
    'Umi ui is now released, welcome to use npm run ui to start the experience.',
  'pages.searchTable.createForm.newRule': 'New Rule',
  'pages.searchTable.updateForm.ruleConfig': 'Rule configuration',
  'pages.searchTable.updateForm.basicConfig': 'Basic Information',
  'pages.searchTable.updateForm.ruleName.nameLabel': 'Rule Name',
  'pages.searchTable.updateForm.ruleName.nameRules': 'Please enter the rule name!',
  'pages.searchTable.updateForm.ruleDesc.descLabel': 'Rule Description',
  'pages.searchTable.updateForm.ruleDesc.descPlaceholder': 'Please enter at least five characters',
  'pages.searchTable.updateForm.ruleDesc.descRules':
    'Please enter a rule description of at least five characters!',
  'pages.searchTable.updateForm.ruleProps.title': 'Configure Properties',
  'pages.searchTable.updateForm.object': 'Monitoring Object',
  'pages.searchTable.updateForm.ruleProps.templateLabel': 'Rule Template',
  'pages.searchTable.updateForm.ruleProps.typeLabel': 'Rule Type',
  'pages.searchTable.updateForm.schedulingPeriod.title': 'Set Scheduling Period',
  'pages.searchTable.updateForm.schedulingPeriod.timeLabel': 'Starting Time',
  'pages.searchTable.updateForm.schedulingPeriod.timeRules': 'Please choose a start time!',
  'pages.searchTable.titleDesc': 'Description',
  'pages.searchTable.ruleName': 'Rule name is required',
  'pages.searchTable.titleCallNo': 'Number of Service Calls',
  'pages.searchTable.titleStatus': 'Status',
  'pages.searchTable.nameStatus.default': 'default',
  'pages.searchTable.nameStatus.running': 'running',
  'pages.searchTable.nameStatus.online': 'online',
  'pages.searchTable.nameStatus.abnormal': 'abnormal',
  'pages.searchTable.titleUpdatedAt': 'Last Scheduled at',
  'pages.searchTable.exception': 'Please enter the reason for the exception!',
  'pages.searchTable.titleOption': 'Option',
  'pages.searchTable.config': 'Configuration',
  'pages.searchTable.subscribeAlert': 'Subscribe to alerts',
  'pages.searchTable.title': 'Enquiry Form',
  'pages.searchTable.new': 'New',
  'pages.searchTable.chosen': 'chosen',
  'pages.searchTable.item': 'item',
  'pages.searchTable.totalServiceCalls': 'Total Number of Service Calls',
  'pages.searchTable.tenThousand': '0000',
  'pages.searchTable.batchDeletion': 'bacth deletion',
  'pages.searchTable.batchApproval': 'batch approval',



  'pages.workplace.CompanyAnnouncement': 'Company announcement', // '公司公告',
  'pages.workplace.ImportantInformation': 'Important information', // '重要信息',
  'pages.workplace.GoodMorning':'Welcome to CC Management Self Service System', // '欢迎来到成诚管理公司自助系统',
  'pages.workplace.happy':'Have a nice day!', // '祝你开心每一天',
  'pages.workplace.progress': 'progress check', // '进度查询',
  'pages.workplace.announcement':'Unread announcement', //  '未读公告',
  'pages.workplace.customer':'Unread message', //  '未读客服信息',
  'pages.workplace.Personal':'Incomplete personal information', //  '个人资料未填写',
  'pages.workplace.pay':'Payment status', // '工资状态',
  "pages.workplace.receipted_man_hour":"Timesheet received", //  "收到确认工时",
  "pages.workplace.payment_request_submitted":"Invoice submitted", // "已向客户请款",
  "pages.workplace.remittance_received":"Remittance received", //  "收到客户已付款通知",
  "pages.workplace.receipted_payment": "Payment received", //  "收到客户付款",
  "pages.workplace.paid":"Payment sent", //  "已付款",
  'router.title.workplace':'Home', //  '欢迎页', // '欢迎页'
  'router.title.personal_information':'Personal Profile', //  '个人信息', // '个人信息'
  'router.title.notice':'Notice', //  '公告详情', // '公告详情'
  'router.title.cerp-beneficiary':'Insurance beneficiary', //  '保险受益人', // '保险受益人'
  'router.title.adviser-manage':'Service management', //  '数据管理', // '数据管理'
  'router.title.cerp-bankaccount':'Bank account', //  '顾问银行账户', // '顾问银行账户'
  'router.title.cerp-personreferencenumber':'Personal Doc', //  '个人参考号', // '个人参考号'
  'router.title.cerp-nextofkin':'Next of Kin', //  '紧急联系人', // '紧急联系人'
  'router.title.cerp-contract':'Contract', //  '合同', // '合同'
  'router.title.cerp-insurance':'Insurance', //  '保险', // '保险'
  'router.title.cerp-payslip':'Payslip', //  '工资单', // '工资单'
  'router.title.payment_progress':'Payment Progress', //  '付款状态', // '付款状态'
  'router.title.Induction_process':'Induction', //  '入职流程', // '入职流程'
  'router.title.curriculum_vitae':'Curriculum vitae', //  '个人简历', // '个人简历'
  'router.title.kefu':'Customer Service', //  '客服', // '个人简历'
  'router.title.kefu_question':'Question Reply', //  '问题回复', // '个人简历'
  'page.cerp.beneficiary.column.title.action':'Action', //  '操作',
  'page.cerp.beneficiary.column.title.create_time':'Time created', //  '创建时间',
  'page.cerp.beneficiary.column.title.date_of_birth':'Date of birth', //  '出生日期',
  'page.cerp.beneficiary.column.title.gender':'Gender', //  '性别',
  'page.cerp.beneficiary.column.title.id':'ID', //  'ID',
  'page.cerp.beneficiary.column.title.id_expiry_date':'ID expiry date', //  '证件有效期',
  'page.cerp.beneficiary.column.title.relation':'Relation', //  '与被保险人关系',
  'page.cerp.beneficiary.column.title.ratio':'Beneficial percentage(%)', //  '受益比例',
  'page.cerp.beneficiary.column.title.id_number':'ID number', //  '证件号',
  'page.cerp.beneficiary.column.title.id_type__name':'ID type', //  '证件类别名',
  'page.cerp.beneficiary.column.title.index':'Index', //  '序号',
  'page.cerp.beneficiary.column.title.insurance__policy_no': 'Policy no', //  '保险单号',
  'page.cerp.beneficiary.column.title.is_active':'Is active', //  '是否可用',
  'page.cerp.beneficiary.column.title.name':'Name', //  '姓名',
  'page.cerp.beneficiary.column.title.adviser__full_name':'Full name', //  '顾问名',
  'page.cerp.beneficiary.column.title.adviser__employee_number':'Employee number', //  '顾问工号',
  'page.cerp.beneficiary.column.title.update_time':'Time updated', //  '更新时间',
  'page.cerp.beneficiary.form.label.adviser_id':'Consultant ID', //  '隶属顾问',
  'page.cerp.beneficiary.form.label.date_of_birth':'Date of birth', //  '出生日期',
  'page.cerp.beneficiary.form.label.gender': 'Gender', //  '性别',
  'page.cerp.beneficiary.form.label.id_expiry_date': 'ID expiry date', //  '证件有效期',
  'page.cerp.beneficiary.form.label.relation':'Relationship', //  '与被保险人关系',
  'page.cerp.beneficiary.form.label.ratio':'Beneficial percentage', //  '受益比例',
  'page.cerp.beneficiary.form.label.id_number':'ID number', //  '证件号',
  'page.cerp.beneficiary.form.label.id_type_id':'ID type', //  '证件类别',
  'page.cerp.beneficiary.form.label.name':'Name', //  '姓名',
  'page.cerp.beneficiary.form.placeholder.adviser_id':'Consultant ID', //  '隶属顾问',
  'page.cerp.beneficiary.form.placeholder.date_of_birth': 'Please select your date of birth', //  '请填写出生日期',
  'page.cerp.beneficiary.form.placeholder.id_expiry_date':'Please enter ID expiry date', //  '请填写证件有效期',
  'page.cerp.beneficiary.form.placeholder.relation':'Please enter relationship with the policy holder', //  '请填写与被保险人关系',
  'page.cerp.beneficiary.form.placeholder.ratio':'Please enter the beneficial percentage', //  '请填写受益比例',
  'page.cerp.beneficiary.form.placeholder.id_number':' Please enter ID number', //  '请填写证件号',
  'page.cerp.beneficiary.form.placeholder.id_type_id':'Please enter ID type', //  '请选择证件类别',
  'page.cerp.beneficiary.form.placeholder.name':'Please enter your name', //  '请填写姓名',
  'page.cerp.bankaccount.column.title.account_holder':'Account holder', //  '账户持有人',
  'page.cerp.bankaccount.column.title.account_number':'Account number', //  '账号',
  'page.cerp.bankaccount.column.title.action':'Action', //  '操作',
  'page.cerp.bankaccount.column.title.adviser__full_name':'Consultant full name', //  '隶属顾问',
  'page.cerp.bankaccount.column.title.bank_address':'Bank address', //  '开户行地址',
  'page.cerp.bankaccount.column.title.bank_name':'Bank name', //  '银行名称',
  'page.cerp.bankaccount.column.title.bic_or_swift_code':'BIC or swift code', //  'bic_or_swift_code',
  'page.cerp.bankaccount.column.title.cnaps': 'cnaps', //  'cnaps',
  'page.cerp.bankaccount.column.title.create_time': 'Time created', //  '创建时间',
  'page.cerp.bankaccount.column.title.english_name': 'Bank name in English', //  '银行英文名称',
  'page.cerp.bankaccount.column.title.iban': 'IBAN', //  'iban',
  'page.cerp.bankaccount.column.title.id': 'ID', //  'ID',
  'page.cerp.bankaccount.column.title.index': 'Index', //  '序号',
  'page.cerp.bankaccount.column.title.insurance_company__company_name': 'Insurance company name', //  '保险公司',
  'page.cerp.bankaccount.column.title.is_active': 'Is active', //  '是否可用',
  'page.cerp.bankaccount.column.title.is_current': 'Is current', //  '是否当前账号',
  'page.cerp.bankaccount.column.title.note': 'Note', //  '备注',
  'page.cerp.bankaccount.column.title.holder_address': 'Holder address', // '开户时向银行提供的住址',
  'page.cerp.bankaccount.column.title.sort_code_or_branch_code': 'Sort code or branch code', //  'sort_code_or_branch_code',
  'page.cerp.bankaccount.column.title.update_time': 'Time updated', //  '更新时间',
  'page.cerp.bankaccount.form.label.account_holder': 'Account holder', //  '账户持有人',
  'page.cerp.bankaccount.form.label.account_number': 'Account number', //  '账号',
  'page.cerp.bankaccount.form.label.adviser__full_name': 'Consultant full name', //  '顾问',
  'page.cerp.bankaccount.form.label.adviser_id': 'Consultant ID', //  '隶属顾问',
  'page.cerp.bankaccount.form.label.bank_address': 'Bank address', //  '开户行地址',
  'page.cerp.bankaccount.form.label.bank_name': 'Bank name', //  '银行名称',
  'page.cerp.bankaccount.form.label.bic_or_swift_code': 'BIC or swift code', //  'bic_or_swift_code',
  'page.cerp.bankaccount.form.label.cnaps': 'cnaps', //  'cnaps',
  'page.cerp.bankaccount.form.label.english_name':'Bank name in English', //  '银行英文名称',
  'page.cerp.bankaccount.form.label.iban':'IBAN', //  'iban',
  'page.cerp.bankaccount.form.label.insurance_company_id':'Insurance company ID', //  '保险公司',
  'page.cerp.bankaccount.form.label.is_active':'Is active', //  '是否可用',
  'page.cerp.bankaccount.form.label.is_current':'Iscurrent', //  '是否当前账号',
  'page.cerp.bankaccount.form.label.kind':'Account type', //  '账号种类',
  'page.cerp.bankaccount.form.label.sort_code_or_branch_code':'Sort code or branch code', //  'sort_code_or_branch_code',
  'page.cerp.bankaccount.form.placeholder.account_holder':'Please enter account holder', //  '请填写账户持有人',
  'page.cerp.bankaccount.form.placeholder.account_number': 'Please enter account number', //  '请填写账号',
  'page.cerp.bankaccount.form.placeholder.adviser__full_name':'Please select consultant', //  '请选择隶属顾问',
  'page.cerp.bankaccount.form.placeholder.adviser_id':'Consultant ID', //  '隶属顾问',
  'page.cerp.bankaccount.form.placeholder.bank_address': 'Please enter bank address', //  '请填写开户行地址',
  'page.cerp.bankaccount.form.placeholder.bank_name':'Please enter bank name', //  '请填写银行名称',
  'page.cerp.bankaccount.form.placeholder.bic_or_swift_code':'Please enter BIC or swift code', //  '请填写bic_or_swift_code',
  'page.cerp.bankaccount.form.placeholder.cnaps':'Please enter cnaps', //  '请填写cnaps',
  'page.cerp.bankaccount.form.placeholder.english_name':'Please enter bank name in English', //  '请填写银行英文名称',
  'page.cerp.bankaccount.form.placeholder.iban':'Please enter IBAN', //  '请填写iban',
  'page.cerp.bankaccount.form.placeholder.insurance_company_id':'Please select insurance company', //  '请选择保险公司',
  'page.cerp.bankaccount.form.placeholder.is_current': 'Please select if current', //  '请填写是否当前账号',
  'page.cerp.bankaccount.form.placeholder.sort_code_or_branch_code':'Please enter sort code or branch code', //'请填写sort_code_or_branch_code',
  'page.cerp.personreferencenumber.column.title.action':'Action', //  '操作',
  'page.cerp.personreferencenumber.column.title.adviser__full_name':'Consultant full name', //  '隶属顾问',
  'page.cerp.personreferencenumber.column.title.create_time':  'Time created', //  '创建时间',
  'page.cerp.personreferencenumber.column.title.description':   'Description', //  '描述',
  'page.cerp.personreferencenumber.column.title.end_date':  'End date', //  '截止时间',
  'page.cerp.personreferencenumber.column.title.id':  'ID', //  'ID',
  'page.cerp.personreferencenumber.column.title.index':   'Index', //  '序号',
  'page.cerp.personreferencenumber.column.title.is_active':  'Is active', //  '是否可用',
  'page.cerp.personreferencenumber.column.title.name': 'Name', //  '名称',
  'page.cerp.personreferencenumber.column.title.reference_number_type__name': 'Reference number type', //  '参考号类别',
  'page.cerp.personreferencenumber.column.title.appendix_json_list':  'Attachment', //  '附件',
  'page.cerp.personreferencenumber.column.title.start_date':   'Start date', //  '开始时间',
  'page.cerp.personreferencenumber.column.title.update_time': 'Time updated', //  '更新时间',
  'page.cerp.personreferencenumber.form.label.adviser__full_name':  'Consultant full name', //  '隶属顾问',
  'page.cerp.personreferencenumber.form.label.adviser_id':  'Consultant ID', //  '隶属顾问',
  'page.cerp.personreferencenumber.form.label.description': 'Description', //  '描述',
  'page.cerp.personreferencenumber.form.label.end_date':'End date', //  '截止时间',
  'page.cerp.personreferencenumber.form.label.is_active': 'Is active', //  '是否可用',
  'page.cerp.personreferencenumber.form.label.name': 'Name', //  '名称',
  'page.cerp.personreferencenumber.form.label.reference_number_type_id': 'Reference number type ID', //  '参考号类别',
  'page.cerp.personreferencenumber.form.label.search_text':  'Search text', //  '模糊搜索',
  'page.cerp.personreferencenumber.form.label.start_date': 'Start date', //  '开始时间',
  'page.cerp.personreferencenumber.form.placeholder.adviser_id': 'Consultant ID', //  '隶属顾问',
  'page.cerp.personreferencenumber.form.placeholder.description': 'Please enter description', //  '请填写描述',
  'page.cerp.personreferencenumber.form.placeholder.end_date':   'Please select end date', //  '请填写截止时间',
  'page.cerp.personreferencenumber.form.placeholder.is_active': 'Please select if active', //  '请选择是否可用',
  'page.cerp.personreferencenumber.form.placeholder.name':'Please enter reference number name', //  '请填写名称',
  'page.cerp.personreferencenumber.form.placeholder.reference_number_type_id': 'Please select reference number type', //  '请选择参考号类别',
  'page.cerp.personreferencenumber.form.placeholder.search_text': 'Please enter search text', //  '请填写搜索内容',
  'page.cerp.personreferencenumber.form.placeholder.start_date':'Please select start date', //  '请填写开始时间',
  'page.baseinfo.emailtemplate.column.title.appendix_json_list': 'Attachment', //  '附件',
  'page.cerp.nextofkin.column.title.action':'Action', //  '操作',
  'page.cerp.nextofkin.column.title.adviser__full_name':'Consultant full name', //  '隶属顾问',
  'page.cerp.nextofkin.column.title.contact_number':'Contact number', //  '联系电话',
  'page.cerp.nextofkin.column.title.create_time': 'Time created', //  '创建时间',
  'page.cerp.nextofkin.column.title.email':  'Email', //  '联系邮箱',
  'page.cerp.nextofkin.column.title.id':  'ID', //  'ID',
  'page.cerp.nextofkin.column.title.index': 'Index', //  '序号',
  'page.cerp.nextofkin.column.title.is_active':'Isactive', //  '是否可用',
  'page.cerp.nextofkin.column.title.name':'Name', //  '姓名',
  'page.cerp.nextofkin.column.title.releationship': 'Releationship', //  '关系',
  'page.cerp.nextofkin.column.title.update_time': 'Time updated', //  '更新时间',
  'page.cerp.nextofkin.form.label.adviser__full_name': 'Consultant full name', //  '隶属顾问',
  'page.cerp.nextofkin.form.label.adviser_id':  'Consultant ID', //  '隶属顾问',
  'page.cerp.nextofkin.form.label.contact_number':  'Contact number', //  '联系电话',
  'page.cerp.nextofkin.form.label.email':  'Email', //  '联系邮箱',
  'page.cerp.nextofkin.form.label.is_active':  'Is active', //  '是否可用',
  'page.cerp.nextofkin.form.label.name': 'Name', //  '姓名',
  'page.cerp.nextofkin.form.label.releationship':  'Releationship', //  '关系',
  'page.cerp.nextofkin.form.label.search_text':'Search text', //  '模糊搜索',
  'page.cerp.nextofkin.form.placeholder.adviser_id': 'Consultant ID', //  '隶属顾问',
  'page.cerp.nextofkin.form.placeholder.contact_number':  'Please enter contact number', //  '请填写联系电话',
  'page.cerp.nextofkin.form.placeholder.email':  'Please enter email', //  '请填写联系邮箱',
  'page.cerp.nextofkin.form.placeholder.is_active': 'Please select if active', //  '请选择是否可用',
  'page.cerp.nextofkin.form.placeholder.name':   'Please enter name', //  '请填写姓名',
  'page.cerp.nextofkin.form.placeholder.releationship': 'Please enter releationship', //  '请填写关系',
  'page.cerp.nextofkin.form.placeholder.search_text':  'Please enter search text', //  '请填写搜索内容',
  'page.Induction.personal_information':'Basic Info', //  '基本信息',
  'page.Induction.Contract_signing': 'Contract Signing', //  '合同签署',
  'page.Induction.bank_account':  'Bank Account', //  '银行账户',
  'page.Induction.Insurance_beneficiary':  'Insurance Beneficiary', //  '保险受益人',
  'page.Induction.Important_tips': 'Important Notice', //  '重要提示',
  'page.Induction.Complete_the_onboarding_process':  'Finish', //  '完成入职流程',
  'page.Induction.preservation':  'Save', //  '保存',
  'page.Induction.New_bank_account': 'Add bank account', //  '新建银行账户',
  'page.Induction.Modify_bank_account':  'Modify bank account', //  '修改银行账户',
  'page.Induction.Required_reading_tips':  'I give my consent to CC management company to collect my bank account info, I understand such information can be updated by myself and it is only used for salary payment purpose.', //  '本人已充分知悉并同意由成诚公司收集员工的银行账户信息，并且员工可以自助修改。数据仅用于发放工资时使用。',
  'page.Induction.Click_to_view': 'Click to view the privacy policy', //  '点击查看用户协议及隐私声明',
  'page.Induction.Re_sign': 'Resign', //  '重新签字',
  'page.Induction.sign':'Sign', //  '签字',
  'page.Induction.confirm': 'Confirm', //  '确认',
  'page.Induction.New_insurance_beneficiary': 'Add insurance beneficiary', //  '新建保险受益人',
  'page.Induction.Modify_insurance_beneficiary':  'Modify insurance beneficiary', //  '修改保险受益人',
  'page.Induction.No_contract_signed':  'No contract to be signed', //  '当前无待签署合同',
  'page.Induction.form.label.full_name':'Full name', //  '姓名',
  'page.Induction.form.label.other_name':'Other names', //  '中文姓名',
  'page.Induction.form.label.nationality': 'Nationality', //  '国籍',
  'page.Induction.form.label.mobile': 'Mobile', //  '手机',
  'page.Induction.form.label.person_type_id': 'Person type', //  '个人类别',
  'page.Induction.form.label.wechat':   'WeChat', //  '微信账号',
  'page.Induction.form.label.qq':  'QQ', //  'QQ账号',
  'page.Induction.form.label.personal_email':   'Personal email', //  '个人邮箱',
  'page.Induction.form.label.work_address_id': 'Work address', //  '工作地址',
  'page.Induction.form.label.title': 'Title', //  '称谓',
  'page.Induction.form.label.birth_date': 'Date of birth', //  '出生日期',
  'page.Induction.form.label.phone':   'Phone', //  '电话',
  'page.Induction.form.label.status':   'Status', //  '个人状态',
  'page.Induction.form.label.pay_currency_id':'Payment currency', //  '付款币种',
  'page.Induction.form.label.whats_app': 'WhatsApp', //  'WhatsApp账号',
  'page.Induction.form.label.skypelid': 'Skype', //  'Skype ID',
  'page.Induction.form.label.work_email': 'Work email', //  '工作邮箱',
  'page.Induction.form.label.home_address_id': 'Home address', //  '家庭地址',
  'page.Induction.form.title.New_work': 'New work address', //  '新建工作地址',
  'page.Induction.form.title.New_home': 'New home address', //  '新建家庭地址',
  'page.Induction.form.title.Created_successfully': 'Created successfully', //  '创建成功',
  'page.Induction.form.placeholder.full_name': 'Please enter full name', //  '请填写姓名',
  'page.Induction.form.placeholder.other_name': 'Please enter other names', //  '请填写中文姓名',
  'page.Induction.form.placeholder.nationality': 'Please select nationality', //  '请选择国籍',
  'page.Induction.form.placeholder.mobile': 'Please fill in mobile number (start with 00, e.g. 00447545xxxxxx）', //  '请填写手机',
  'page.Induction.form.placeholder.person_type_id': 'Please select person type', //  '请选择个人类别',
  'page.Induction.form.placeholder.wechat': 'Please enter WeChat', //  '请填写微信账号',
  'page.Induction.form.placeholder.qq': 'Please enter QQ', //  '请填写QQ账号',
  'page.Induction.form.placeholder.personal_email': 'Please enter your personal email', //  '请填写个人邮箱',
  'page.Induction.form.placeholder.work_address_id': 'Please select work address', //  '请选择工作地址',
  'page.Induction.form.placeholder.title': 'Please select title', //  '请选择称谓',
  'page.Induction.form.placeholder.birth_date': 'Please select birth of date', //  '请选择出生日期',
  'page.Induction.form.placeholder.phone': 'Please enter phone', //  '请填写电话',
  'page.Induction.form.placeholder.status': 'Please select status', //  '请选择个人状态',
  'page.Induction.form.placeholder.pay_currency_id': 'Please select payment currency', //  '请选择付款币种',
  'page.Induction.form.placeholder.whats_app': 'Please enter WhatsApp', //  '请填写WhatsApp账号',
  'page.Induction.form.placeholder.skypelid':  'Please enter Skype', //  '请填写Skype ID',
  'page.Induction.form.placeholder.work_email':'Please enter work email', //  '请填写工作邮箱',
  'page.Induction.form.placeholder.home_address_id': 'Please enter home address', //  '请选择家庭地址',
  'page.Induction.form.label.work_address_id.country_id':'Country', //  '国家',
  'page.Induction.form.label.work_address_id.state':  'State/Province', //  '州/省',
  'page.Induction.form.label.work_address_id.city':'City', //  '城市',
  'page.Induction.form.label.work_address_id.street': 'Street', //  '街道',
  'page.Induction.form.label.work_address_id.postcode':'Zip code', //  '邮编',
  'page.Induction.form.placeholder.work_address_id.country_id': 'Please select country', //  '请选择国家',
  'page.Induction.form.placeholder.work_address_id.state':'Please enter state', //  '请填写州/省',
  'page.Induction.form.placeholder.work_address_id.city':  'please enter city', //  '请填写城市',
  'page.Induction.form.placeholder.work_address_id.street':'Please enter street', //  '请填写街道',
  'page.Induction.form.placeholder.work_address_id.postcode': 'Please enter postcode', //  '请填写邮编',
  'page.Induction.options.status_1':  'None', //  '-无-',
  'page.Induction.options.status_2': 'Working', //  '工作中',
  'page.Induction.options.status_3': 'Finished', //  '结束',
  'page.Induction.options.status_4': 'Visa processing', //  '申请签证中',
  'page.Induction.options.status_5': 'Not started', //  '尚未开始',
  'page.Induction.options.status_6':'Candidate', //  '潜在雇员',
  'page.Induction.options.status_7':'Left', //  '离职',
  'page.Induction.options.status_8':'Pending', //  '待定',



  'page.cerp.insurance.column.title.action':  'Action', //  '操作',
  'page.cerp.insurance.column.title.adviser.full_name': 'Consultant full name', //  '隶属顾问',
  'page.cerp.insurance.column.title.appendix_json': 'Attachment', //  '附件',
  'page.cerp.insurance.column.title.beneficiaries':   'Beneficiaries', //  '受益人',
  'page.cerp.insurance.column.title.create_time': 'Time created', //  '创建时间',
  'page.cerp.insurance.column.title.effective_date_time': 'Effective date time', //  '起保日期时间',
  'page.cerp.insurance.column.title.expiry_date_time':'Expiry date time', //  '停保日期时间',
  'page.cerp.insurance.column.title.id': 'ID', //  'ID',
  'page.cerp.insurance.column.title.index':'Index', //  '序号',
  'page.cerp.insurance.column.title.insurance_company.company_name': 'Insurance company', //  '保险公司',
  'page.cerp.insurance.column.title.insurance_fee':  'Insurance fee', //  '保费',
  'page.cerp.insurance.column.title.is_active':  'Is active', //  '是否可用',
  'page.cerp.insurance.column.title.is_current':  'Is current', //  '当前保险',
  'page.cerp.insurance.column.title.month_insurance_fee':   'Monthly insurance fee', //  '每月保费',
  'page.cerp.insurance.column.title.policy_no': 'Policy Number', //  '保险单号',
  'page.cerp.insurance.column.title.update_time': 'Time updated', //  '更新时间',
  'page.cerp.insurance.form.label.adviser__full_name': 'Consultant full name', //  '顾问',
  'page.cerp.insurance.form.label.adviser_id':'Consultant ID', //  '隶属顾问',
  'page.cerp.insurance.form.label.effective_date_time': 'Effective date time', //  '起保日期时间',
  'page.cerp.insurance.form.label.expiry_date_time': 'page.cerp.insurance.form.label.expiry_date_time', //  '停保日期时间',
  'page.cerp.insurance.form.label.insurance_company_id': 'Insurance company ID', //  '保险公司',
  'page.cerp.insurance.form.label.insurance_fee': 'Insurance fee', //  '保费',
  'page.cerp.insurance.form.label.is_current': 'Is current', //  '当前保险',
  'page.cerp.insurance.form.label.month_insurance_fee':'Monthly insurance fee', //  '每月保费',
  'page.cerp.insurance.form.label.policy_no': 'Policy no', //  '保险单号',
  'page.cerp.insurance.form.placeholder.adviser__full_name': 'Please select consultant', //  '请选择隶属顾问',
  'page.cerp.insurance.form.placeholder.adviser_id':  'Consultant ID', //  '隶属顾问',
  'page.cerp.insurance.form.placeholder.effective_date_time':'Please select effective date time', //  '请填写起保日期时间',
  'page.cerp.insurance.form.placeholder.expiry_date_time': 'Please select expiry date time', //  '请填写停保日期时间',
  'page.cerp.insurance.form.placeholder.insurance_company_id':'Please select insurance company', //  '请选择保险公司',
  'page.cerp.insurance.form.placeholder.insurance_fee':   'Please enter insurance fee', //  '请填写保费',
  'page.cerp.insurance.form.placeholder.is_current': 'Please select if current', //  '请填写当前保险',
  'page.cerp.insurance.form.placeholder.month_insurance_fee': 'Please enter monthly insurance fee', //  '请填写每月保费',
  'page.cerp.insurance.form.placeholder.policy_no':  'Please enter policy no', //  '请填写保险单号',
  'page.cerp.social_insurance.form.label.join_date':'Date joined', //  '入职日期', // '入职日期',
  'page.cerp.social_insurance.form.label.start_date':'Start date', //  '起保月份', // '起保月份',
  'page.cerp.social_insurance.form.label.city': 'City', //  '缴纳城市', // '缴纳城市',
  'page.cerp.social_insurance.form.label.household_type':  'Household type', //  '户籍类型', // '户籍类型',
  'page.cerp.social_insurance.form.label.payment_item_ids':  'Payment item ID', //  '缴纳项目', // '缴纳项目',
  'page.cerp.social_insurance.form.placeholder.join_date': 'Please enter date joined', //  '请填写入职日期', // '请填写入职日期',
  'page.cerp.social_insurance.form.placeholder.start_date': 'Please enter start date', //  '请填写起保月份', // '请填写起保月份',
  'page.cerp.social_insurance.form.placeholder.city': 'Please enter city', //  '请填写缴纳城市', // '请填写缴纳城市',
  'page.cerp.social_insurance.form.placeholder.household_type': 'Please select household type', //  '请选择户籍类型', // '请选择户籍类型',
  'page.cerp.social_insurance.form.placeholder.payment_item_ids': 'Please select payment items', //  '请选择缴纳项目', // '请选择缴纳项目',
  'page.cerp.contract.colums.column.title.accommodation_rate': 'Accommodation rate', //  '住宿费率',
  'page.cerp.contract.colums.column.title.action': 'Action', //  '操作',
  'page.cerp.contract.colums.column.title.adviser__full_name': 'Consultant full name', //  '隶属顾问',
  'page.cerp.contract.colums.column.title.adviser__employee_number':  'Employee number', //  '工号',
  'page.cerp.contract.colums.column.title.appendix_json_list':  'Attachment', //  '附件',
  'page.cerp.contract.colums.column.title.qiyuesuo_status': 'Qiyuesuo status', // '合同状态',
  'page.cerp.contract.colums.column.title.insurance__effective_date_time': 'Effective date time', //  '保险起保日期', // '保险起保日期',
  'page.cerp.contract.colums.column.title.insurance__expiry_date_time': 'Expiry date time', //  '保险停保日期', // '保险停保日期',
  'page.cerp.contract.colums.column.title.bank_charge':'Bank charge', //  '银行转账费',
  'page.cerp.contract.colums.column.title.basic_rate':'Salary base rate', //  '基本单位薪资',
  'page.cerp.contract.colums.column.title.cc_email__name':  'CC email', //  'CC邮箱',
  'page.cerp.contract.colums.column.title.cc_management_fee':'CC management fee', //  'cc管理费',
  'page.cerp.contract.colums.column.title.cc_company__name':'CC company name', //  'CC公司',
  'page.cerp.contract.colums.column.title.cc_management_fee_rate': 'CC management fee rate', //  'cc管理费率（%）',
  'page.cerp.contract.colums.column.title.create_time': 'Date created', //  '创建时间',
  'page.cerp.contract.colums.column.title.creater':'Created by', //  '创建人',
  'page.cerp.contract.colums.column.title.currency__currency': 'Salary currency', //  '薪资币种',
  'page.cerp.contract.colums.column.title.pay_currency__currency':'Payment currency', //  '付款币种',
  'page.cerp.contract.colums.column.title.email_record__log':  'Email log', //  '邮件发送日志',
  'page.cerp.contract.colums.column.title.email_record__status':'Email status', //  '邮件发送状态',
  'page.cerp.contract.colums.column.title.email_record__create_time': 'Email sent time', //  '邮件发送时间',
  'page.cerp.contract.colums.column.title.end_client__name':  'End client', //  '终端客户',
  'page.cerp.contract.colums.column.title.end_date': 'End date', //  '合同结束日期',
  'page.cerp.contract.colums.column.title.id':'ID', //  'ID',
  'page.cerp.contract.colums.column.title.index': 'Index', //  '序号',
  'page.cerp.contract.colums.column.title.insurance_type__name':'Insurance type', //  '保险类别',
  'page.cerp.contract.colums.column.title.invoice_email_template__name': 'Invoice email template', //  '请款单邮箱模板',
  'page.cerp.contract.colums.column.title.is_active': 'Is active', //  '是否可用',
  'page.cerp.contract.colums.column.title.is_bank_charge_payment':'Bank charge paid by', //  '银行转账费支付方式',
  'page.cerp.contract.colums.column.title.is_current': 'Is current', //  '是否当前合同',
  'page.cerp.contract.colums.column.title.insurance_payment_flag':'Insurance paid by', //  '保险费支付方式',
  'page.cerp.contract.colums.column.title.local_deduct_fee':'Local deduction', //  '本地扣减费',
  'page.cerp.contract.colums.column.title.local_salary': 'Local salary figure', //  '本地发薪数值',
  'page.cerp.contract.colums.column.title.local_management_fee':  'Local management fee', //  '本地管理费',
  'page.cerp.contract.colums.column.title.local_management_fee_fixed': 'Local salary', //  '本地发薪工资',
  'page.cerp.contract.colums.column.title.local_pay_currency__currency':'Local payment currency', //  '本地发薪币种',
  'page.cerp.contract.colums.column.title.local_management_fee_rate':'Local management fee rate', //  '本地管理费率（%）',
  'page.cerp.contract.colums.column.title.management_fee_formula__name':  'Management fee formula', //  '管理费计算方式',
  'page.cerp.contract.colums.column.title.management_fee_payment_flag':'Management fee paid by', //  '管理费支付方式',
  'page.cerp.contract.colums.column.title.overtime_rate':  'Overtime rate', //  '加班费率',
  'page.cerp.contract.colums.column.title.is_three_upload':  'Is tripartite contract uploaded', //  '三方合同上传',
  'page.cerp.contract.colums.column.title.is_double_upload':  'Is bilateral contract uploaded', //  '双方合同上传',
  'page.cerp.contract.colums.column.title.payslip_email_template__name':'Payslip email template', //  '工资单邮箱模板',
  'page.cerp.contract.colums.column.title.wechat_appendix_json_list': 'WeChat attachment', //  '小程序附件',
  'page.cerp.contract.colums.column.title.qiyuesuo_contract_id': 'Qiyuesuo contract ID', //  '契约锁合同ID',
  'page.cerp.contract.colums.column.title.rate_type': 'Salary type', //  '薪资种类',
  'page.cerp.contract.colums.column.title.recuitment_company__company_name': 'Recuitment company', //  '顾问公司',
  'page.cerp.contract.colums.column.title.recuitment_company__vat_no':  'VAT no', //  'VATNo',
  'page.cerp.contract.colums.column.title.remark':  'Note', //  '备注',
  'page.cerp.contract.colums.column.title.start_date':'Start date', //  '合同起始日期',
  'page.cerp.contract.colums.column.title.termination_date':  'Termination date', //  '终止日期',
  'page.cerp.contract.colums.column.title.update_time': 'Time updated', //  '更新时间',
  'page.cerp.contract.colums.column.title.work_location__name':  'Work location', //  '工作地点',
  'page.Induction.form_yin_hang_ZH.label.bankmanage_id':'Bank ID', //  '预设银行名称',
  'page.Induction.form_yin_hang_ZH.label.bank_name': 'Bank name', //  '银行名称',
  'page.Induction.form_yin_hang_ZH.label.english_name': 'Bank name in English', //  '银行英文名称',
  'page.Induction.form_yin_hang_ZH.label.account_holder': 'Account holder', //  '账户持有人',
  'page.Induction.form_yin_hang_ZH.label.account_number':  'Account number', //  '账号',
  'page.Induction.form_yin_hang_ZH.label.note':'Note', //  '备注',
  'page.Induction.form_yin_hang_ZH.label.bank_address':  'Bank address', //  '开户行地址',
  'page.Induction.form_yin_hang_ZH.label.bic_or_swift_code': 'BIC or swift code', //  'BIC/SWIFT CODE',
  'page.Induction.form_yin_hang_ZH.label.iban': 'IBAN', //  'iban',
  'page.Induction.form_yin_hang_ZH.label.cnaps':'CNAPS', //  'cnaps',
  'page.Induction.form_yin_hang_ZH.label.is_current': 'Is current', //  '是否当前账号',
  'page.Induction.form_yin_hang_ZH.label.holder_address': 'Holder Address', //  '开户时向银行提供的住址',
  'page.Induction.form_yin_hang_ZH.label.sort_code_or_branch_code':'Sort code or branch code', //  'SORT/BRANCH CODE',
  'page.Induction.form_yin_hang_ZH.placeholder.bankmanage_id':'Please select your bank', //  '请选择预设银行名称',
  'page.Induction.form_yin_hang_ZH.placeholder.bank_name': 'Please enter your bank name', //  '请选择银行名称',
  'page.Induction.form_yin_hang_ZH.placeholder.english_name':  'Please enter your bank name in English', //  '请选择银行英文名称',
  'page.Induction.form_yin_hang_ZH.placeholder.account_holder':  'Please enter account holder', //  '请填写账户持有人',
  'page.Induction.form_yin_hang_ZH.placeholder.account_number':'Please enter account number', //  '请填写账号',
  'page.Induction.form_yin_hang_ZH.placeholder.note': 'Please enter note', //  '请填写备注',
  'page.Induction.form_yin_hang_ZH.placeholder.bank_address': 'Please enter bank address', //  '请填写开户行地址',
  'page.Induction.form_yin_hang_ZH.placeholder.bic_or_swift_code': 'Please enter BIC or swift code', //  '请填写BIC/SWIFT CODE',
  'page.Induction.form_yin_hang_ZH.placeholder.iban': 'Please enter IBAN', //  '请填写iban',
  'page.Induction.form_yin_hang_ZH.placeholder.sort_code_or_branch_code': 'Please enter sort code or branch code', //  '请填写SORT/BRANCH CODE',
  'page.Induction.form_yin_hang_ZH.placeholder.cnaps':  'Please enter CNAPS', //  '请填写cnaps',
  'page.Induction.form_yin_hang_ZH.placeholder.holder_address':  'Please enter Holder Address', //  '请填写开户时向银行提供的住址',
  'page.Induction.form_yin_hang_ZH.rules.bank_name':  'Please enter bank name', //  '请填输入银行名称',
  'page.Induction.form_yin_hang_ZH.rules.account_holder': 'Please enter account holder', //  '请填输入账户持有人',
  'page.Induction.form_yin_hang_ZH.rules.account_number':'Please enter account number', //  '请填输入账号',
  'page.Induction.form_yin_hang_ZH.rules.bank_address':  'Please enter bank address', //  '请填输入开户行地址',
  'page.Induction.form_yin_hang_ZH.rules.is_current': 'Please select if current', //  '请填输入是否当前账号',
  'page.Induction.options_yin_hang_ZH.is_current.true':  'Yes', //  '是',
  'page.Induction.options_yin_hang_ZH.is_current.false': 'No', //  '否',


  'page.Induction.bank.message':  'Please click 【Confirm】if there is no account info at the moment.', //  '若无【银行账户】可直接点击【确认】',
  'page.Induction.bank.beneficiary': 'Please click 【Confirm】if there is no beneficiary info at the moment.', //  '若无【保险受益人】可直接点击【确认】',
  'page.Induction.rules.beneficiary.required':'Please enter full name', //  '请填输入姓名',
  'page.Induction.rules.beneficiary.gender': 'Please select gender', //  '请填输入性别',
  'page.Induction.rules.beneficiary.date_of_birth':'Please select date of birth', //  '请填输入出生日期',
  'page.Induction.rules.beneficiary.id_number':'Please enter ID number', //  '请填输入证件号',
  'page.Induction.options_Insurance_beneficiary.gender0':'Male', //  '男',
  'page.Induction.options_Insurance_beneficiary.gender1':  'Female', //  '女',
  'page.Induction.options_Insurance_beneficiary.relation0':  'Myself', //  '本人',
  'page.Induction.options_Insurance_beneficiary.relation1': 'Spouse', //  '配偶',
  'page.Induction.options_Insurance_beneficiary.relation2': 'Child', //  '子女',
  'page.Induction.options_Insurance_beneficiary.relation3': 'Parent', //  '父母',
  'page.Induction.options_Insurance_beneficiary.relation4': 'Other', //  '其他',
  'page.Induction.bankaccount': 'Bank account', //  '银行账户',
  'page.Induction.beneficiary': 'Insurance beneficiary', //  '保险受益人',
  'page.Induction.message.adviser': 'Updated', //  '修改成功',
  'page.Induction.message.bank': 'Created', //  '新建成功',
  'page.Induction.message.confirm': 'Confirmed', //  '确认成功',
  'page.Induction.message.reminder': 'Reminder', //  '温馨提示',
  'page.Induction.adviser.description': '【Personal info】 not submit！', //  '【个人信息】 未提交！',
  'page.Induction.contract.description': '【Contract signing】 not submit！', //  '【合同】签署 未提交！',
  'page.Induction.bankaccount.description': '【Bank account info】 not submit！', //   '【银行账户信息】 未提交！',
  'page.Induction.beneficiary.description': '【Insurance beneficiary info】 not submit！', //   '【保险受益人信息】 未提交！',
  'page.Induction.tips.description': '【Important notice】 not submit！', //   '【重要提示】 未提交！',
  'page.Induction.message.sign_contract': 'Contract successfully signed', //   '顾问签署合同成功',
  'page.Induction.message.sign_contract_success': 'Induction processes completed', //   '入职流程完成',
  'page.Induction.information': 'Contact Details', //   '联系方式',
  'page.Induction.personal_address': 'Personal Address', //   '个人地址',
  'page.Induction.social_security': 'Social Security Info', //   '社保',
  'page.Induction.resume': 'CV', //   '个人简历',
  'page.Induction.form_can_kao_hao.label.name': 'Name', //   '名称',
  'page.Induction.form_can_kao_hao.label.start_end_date': 'Start - End date', //   '开始-截止时间',
  'page.Induction.form_can_kao_hao.label.description': 'No.', //   '描述',
  'page.Induction.form_can_kao_hao.label.reference_number_type_id': 'Reference number type ID', //   '参考号类别',
  'page.Induction.form_can_kao_hao.placeholder.name':'Please enter name', //   '请填写名称',
  'page.Induction.form_can_kao_hao.placeholder.start_end_date':'Please select start and end date', //   '请填写起保日期时间',
  'page.Induction.form_can_kao_hao.placeholder.description':'Please enter No.', //   '请填写描述',
  'page.Induction.form_can_kao_hao.placeholder.reference_number_type_id':'Please select reference number type', //   '请选择参考号类别',
  'page.Induction.form_can_kao_hao.rules.name':'Please enter name', //   '请填输入名称',
  'page.Induction.form_can_kao_hao.rules.relationship':'Please enter relationship', //   '请填输入关系',
  'page.Induction.form_can_kao_hao.rules.Telephone':'Please enter telephone', //   '请填写联系电话',
  'page.Induction.options_social_security.household_type0':'Local urban', //   '本地城镇',
  'page.Induction.options_social_security.household_type1':'Local suburban', //   '本地农村',
  'page.Induction.options_social_security.household_type2':'Non local urban', //   '外地城镇',
  'page.Induction.options_social_security.household_type3':'Non local suburban', //   '外地农村',
  'page.Induction.file':'Attachment', //   '上传附件',
  'page.Induction.work_experience':'Work experience', //   '工作经历',
  'page.Induction.Training_experience':'Training experience', //   '培训经历',
  'page.Induction.UpdateAdviser.content':'Previous request has not been approved, cannot be updated', //   '之前提交的记录还未审批，不可再修改',
  'page.Induction.UpdateAdviser.title':'Confirm submission', //   '是否确认提交？',
  'page.Induction.UpdateAdviser.content1':'Content can be updated again after the previous request is approved', //   '提交审批后，等待审批处理了可继续修改',
  'page.Induction.UpdateBank.message':'Request has been submitted, waiting for approval', //   '信息修改申请已提交，等待审核。',
  'page.Induction.openUrl.description':'No attachment found', //   '无对应附件',
  'page.Induction.delete_file.title':'Confirm deletion?', //   '是否删除?',
  'page.Induction.AddEducations.title':'Add education background', //   '添加教育背景',
  'page.cerp.AddEducations.form.label.start_end_date':'Start and End date', //   '起止时间',
  'page.cerp.AddEducations.form.label.school':'School', //   '学校',
  'page.cerp.AddEducations.form.label.major':'Major', //   '专业',
  'page.cerp.AddEducations.form.label.education':'Qualification', //   '学历',
  'page.cerp.AddEducations.form.placeholder.start_end_date':'Start and End date', //   '请填写起止时间',
  'page.cerp.AddEducations.form.placeholder.school':'Please enter school', //   '请填写学校',
  'page.cerp.AddEducations.form.placeholder.major':'Please enter major', //   '请填写专业',
  'page.cerp.AddEducations.form.placeholder.education':'Please enter qualification', //   '请填写学历',
  'page.cerp.AddEducations.form.placeholder.tip':"To update your CV, please click \"Confirm\" to save and give consent for us to keep your information. If you don't agree, please do not click \"Confirm\"", //   '为了更新你的简历，请你点击“确定”保存并同意我们是用你的简历必要信息。如你不同意，请不要确定。',
  'page.cerp.AddExperiences.form.label.post':'Job title', //  '职务',
  'page.cerp.AddExperiences.form.label.company':'Company', //  '公司',
  'page.cerp.AddExperiences.form.label.note':'Responsibilites', //  '工作描述',
  'page.cerp.AddExperiences.form.placeholder.start_end_date':'Please select start and end date', //  '请选择时间',
  'page.cerp.AddExperiences.form.placeholder.company':'Please enter company name', //  '请填写公司',
  'page.cerp.AddExperiences.form.placeholder.post':'Please enter job title', //  '请填写职务',
  'page.cerp.AddExperiences.form.placeholder.note':'Please enter job responsibilities', //  '请填写工作描述',
  'page.cerp.AddExperiences.form.tip':'To update your CV, please click \"Confirm\" to save changes and give consent for us to keep your information. If you don\'t agree, please do not click "Confirm".', //  '为了更新你的简历，请你点击“确定”保存并同意我们是用你的简历必要信息。如你不同意，请不要确定。',
  'page.cerp.AddTraining.form.label.start_end_date':'Start and End date', //  '时间',
  'page.cerp.AddTraining.form.label.book':'Training course', //  '培训课程',
  'page.cerp.AddTraining.form.label.mechanism':'Train organisation', //  '培训机构',
  'page.cerp.AddTraining.form.label.address':'Address', //  '培训地点',
  'page.cerp.AddTraining.form.label.note':'Training content description', //  '培训描述',
  'page.cerp.AddTraining.form.placeholder.start_end_date':'Please select start and end date', //  '请选择时间',
  'page.cerp.AddTraining.form.placeholder.book':'Please enter training course ', //  '请填写',
  'page.cerp.AddTraining.form.placeholder.mechanism':'Please enter the training organisation', //  '请填写',
  'page.cerp.AddTraining.form.placeholder.address':'Please enter training location ', //  '请填写（选填）',
  'page.cerp.AddTraining.form.placeholder.note':'Please enter job responsibities', //  '请填写工作描述',
  'page.cerp.AddTraining.form.placeholder.tip':'To update your CV, please click "Confirm" to save changes and give consent for us to keep your information. If you don\'t agree, please do not click "Confirm".', //  '为了更新你的简历，请你点击“确定”保存并同意我们是用你的简历必要信息。如你不同意，请不要确定。',
  'page.information.see':'View', //  '查看',
  'page.information.edit':'Edit', //  '编辑',
  'page.information.Apply_for_modification':'Update request', //  '申请修改',
  'page.information.New_personal_reference_number':'Add personal reference number', //  '新建个人参考号',
  'page.information.Modify_personal_reference_number':'Update personal reference number', //  '修改个人参考号',
  'page.information.New_emergency_contact':'Add emergency contact', //  '新建紧急联系人',
  'page.information.Modify_emergency_contact':'Update emergency contact', //  '修改紧急联系人',
  'page.information.New_insurance_beneficiary':'Add insurance beneficiary', //  '新建保险受益人',
  'page.information.Modify_insurance_beneficiary':'Update insurance beneficiary', //  '修改保险受益人',
  'page.information.Submit_payment_application':'Submit request for social security services', //  '提交代缴申请',
  'page.information.Resume_integrity':'Progress', //  '简历完整度',
  'page.information.personal_information':'Personal information', //  '个人信息',
  'page.information.full_name':'Full name', //  '姓名',
  'page.information.title':'Title', //  '称谓',
  'page.information.other_name':'Other names', //  '中文姓名',
  'page.information.birth_date':'Date of birth', //  '出生日期',
  'page.information.nationality__name':'Nationality', //  '国籍',
  'page.information.mobile':'Mobile', //  '手机',
  'page.information.phone':'Phone', //  '电话',
  'page.information.wechat':'WeChat', //  '微信账号',
  'page.information.qq':'QQ', //  'QQ账号',
  'page.information.personal_email':'Personal email', //  '个人邮箱',
  'page.information.work_email':'Work email', //  '工作邮箱',
  'page.information.whats_app':'WhatsApp', //  'WhatsApp账号',
  'page.information.skypelid':'Skype', //  'Skype ID',
  'page.information.home_address__display_name':'Home address', //  '家庭地址',
  'page.information.work_address__display_name':'Work address', //  '工作地址',
  'page.information.educational_background':'Education', //  '教育背景',
  'page.information.add':'Add', //  '添加',
  'page.information.delete':'Delete', //  '删除',
  'page.information.Missing_information':'Missing information', //  '缺少信息，请添加。',
  'page.information.educations.time':'Time', //  '时间',
  'page.information.educations.school':'School', //  '学校',
  'page.information.educations.major':'Major', //  '专业',
  'page.information.educations.education':'Qualification', //  '学历',
  'page.information.educations.New_training':'New training', //  '新增培训',
  'page.information.educations.Attachment_upload':'Upload attachment', //  '附件上传',
  'page.cerp.AddData.title': 'Additional info title', //  '编辑额外信息',
  'page.cerp.AddData.form.label.datas':'Additional info', //  '额外信息',
  'page.cerp.AddData.form.label.tip':'To update your CV, please click "Confirm" to save and give consent for us to keep your information. If you do not agree, please do not click "confirm".', //  '为了更新你的简历，请你点击“确定”保存并同意我们是用你的简历必要信息。如你不同意，请不要确定。',
  'page.cerp.AddData.form.placeholder.datas':'Please enter additional info', //  '请填写额外信息',
  'page.cerp.AddSkill.title': 'Certificate', //  '证书',
  'page.cerp.AddSkill.New_certificate': 'New certificate', //  '新增证书',
  'page.cerp.AddSkill.form.label.datepicker':'Issue date', //  '获得时间',
  'page.cerp.AddSkill.form.label.certificate':'Certificate', //  '证书',
  'page.cerp.AddSkill.form.label.achievement':'Qaulification', //  '成绩',
  'page.cerp.AddSkill.form.label.tip':'To update your CV, please click "Confirm" to save and give consent for us to keep your information. If you do not agree, please do not click "confirm".', //  '为了更新你的简历，请你点击“确定”保存并同意我们是用你的简历必要信息。如你不同意，请不要确定。',
  'page.cerp.AddSkill.form.placeholder.datepicker':'Please select issue date', //  '请选择',
  'page.cerp.AddSkill.form.placeholder.certificate':'Please enter certificate', //  '请填写证书',
  'page.cerp.AddSkill.form.placeholder.achievement':'Please enter qualification (optional)', //  '请填写（选填）',
  'page.cerp.AddLanguage.title':'Skill/Language', //  '技能/语言',
  'page.cerp.AddLanguage.new_title':'New skill/language', //  '新增技能/语言',
  'page.cerp.AddLanguage.form.label.language':'Skill/Language', //  '技能/语言',
  'page.cerp.AddLanguage.form.label.certificate':'Level', //  '掌握程度',
  'page.cerp.AddLanguage.form.placeholder.language':'Please enter language', //  '请填写',
  'page.cerp.AddLanguage.form.placeholder.tip':'To update your CV, please click "Confirm" to save and give consent for us to keep your information. If you do not agree, please do not click "confirm".', //  '为了更新你的简历，请你点击“确定”保存并同意我们是用你的简历必要信息。如你不同意，请不要确定。',
  'page.cerp.AddLanguage.options.certificate1':'OK', //  '一般',
  'page.cerp.AddLanguage.options.certificate2':'Good', //  '良好',
  'page.cerp.AddLanguage.options.certificate3':'Experienced', //  '熟练',
  'page.cerp.AddLanguage.options.certificate4':'Expert', //  '精通',
  'page.cerp.AddIntroduction.title':   'Edit personal statement', //  '编辑自我评价',
  'page.cerp.AddIntroduction.form.label.introduction':'Personal statement', //  '自我评价',
  'page.cerp.AddIntroduction.form.placeholder.introduction':'Please enter your personal statement', //  '请填写自我评价',

  'page.cerp.create_bank_account':'New create bank account', // '新增银行账户',
  'page.cerp.update':'Update', // '修改',

  'page.cerp.payslip.colums.column.title.index':'Index', //  '序号',
  'page.cerp.payslip.colums.column.title.service_month':'Service month', //  '请款月份',
  'page.cerp.payslip.colums.column.title.pay_time':'Pay Time', //  '付款时间',
  'page.cerp.payslip.colums.column.title.pay_amount':'Pay Amount', //  '工资单金额',
  'page.cerp.personreferencenumber.column.title.file_name': 'Enclosure', //  '附件',
  'page.cerp.payslip.colums.column.title.inovice_no':'Inovice no', //  '请款单号',
  'page.cerp.payslip.colums.column.title.payslip_no':'Payslip no', //  '工资单号',
  'page.cerp.payslip.colums.column.title.status':'Status', //  '状态',
  'page.cerp.payslip.colums.column.title.pdf':'PDF', //  'PDF',
  'page.cerp.payslip.wages':'Salary', //  '工资',
  'page.cerp.payslip.work_overtime':'Overtime', //  '加班',
  'page.cerp.payslip.Reimbursement':'Expense', //  '报销',
  'page.pagemodel4.open':'Expend', //  '展开',
  'page.pagemodel4.away':'Close', //  '收起',
  'page.pagemodel4.Basic_column':'Base columns', //  '基本列信息',
  'page.pagemodel4.Set_as_basic_configuration':'Set as default', //  '设为基础配置',
  'page.pagemodel4.Show_custom_columns':'Show custom columns', //  '展示自定义列',
  'page.pagemodel4.user':'User', //  '用户',
  'page.pagemodel4.original':'Original', //  '原始',
  'page.pagemodel4.column':'【Show/Hide】Action', //  '【显示、隐藏】操作列',
  'page.pagemodel4.operation':'Action', //  '操作',
  'page.pagemodel4.Refresh':'Refresh', //  '刷新',
  'page.pagemodel4.density':'Density', //  '密度',
  'page.pagemodel4.default':'Default', //  '默认',
  'page.pagemodel4.middle':'Medium', //  '中等',
  'page.pagemodel4.small':'Compact', //  '紧凑',
  'page.pagemodel4.Column_display':'Column display', //  '列展示',
  'page.pagemodel4.configure':'Configure', //  '配置',
  'page.pagemodel4.Close_configuration':'Close config', //  '关闭配置',
  'page.pagemodel4.display':'Display', //  '显示',
  'page.pagemodel4.hide':'Hide', //  '隐藏',
  'page.pagemodel4.Column_settings':'Column settings', //  '列设置',
  'page.pagemodel4.Exit_full_screen':'Exit full screen', //  '退出全屏',
  'page.pagemodel4.full_screen':'Full screen', //  '全屏',
  'page.pagemodel4.total':'Total', //  '合计',
  'page.pagemodel4.Delete_this':'Are you sure to delete this?', //  '是否删除此项记录?',
  'page.pagemodel4.Deleted_successfully ':'Deleted successfully ', //  '删除成功',
  'page.pagemodel4.recover_successful ':'Recovered successfully ', //  '恢复成功',
  'page.pagemodel4.Created_successfully ':'Created successfully ', //  '创建成功',
  'page.pagemodel4.update_successfully ':'Updated successfully ', //  '修改成功',
  'page.pagemodel4.Export_Excel':'Export to Excel', //  '导出Excel',
  'page.pagemodel4.Please_fill_in ':'Please enter ', //  '请填写',
  'page.pagemodel4.Please_select':'Please_select', //  '请选择',
  'page.cerp.bankaccount.column.title.is_current.true':'True', //  '是',
  'page.cerp.bankaccount.column.title.is_current.false':'False', //  '否',
  'page.cerp.bankaccount.column.title.is_active.true':'Available', //  '可用',
  'page.cerp.bankaccount.column.title.is_active.false':'Not available', //  '禁用',
  'page.cerp.bankaccount.column.title.is_active.all':'All', //  '全部',
  'page.information.create_beneficiary':'Create new beneficiary', //  '新建保险受益人',
  'page.information.update_beneficiary':'Update', //  '修改',
  'page.information.update_nextofkin':'Update', //  '修改',
  'page.information.update_personal':'Update', //  '修改',
  'page.information.create_nextofkin':'Create new next of kin', //  '新建紧急联系人',
  'page.information.create_personal':'Create personal reference number', //  '新建个人参考号',
  'page.cerp.social_insurance.column.title.index':'Index', //  '序号',
  'page.cerp.social_insurance.column.title.adviser__full_name':'Consultant', //  '顾问',
  'page.cerp.social_insurance.column.title.adviser__employee_number':'Employee number', //  '工号',
  'page.cerp.social_insurance.column.title.join_date':'Date joined', //  '入职日期',
  'page.cerp.social_insurance.column.title.start_date':'Start_date', //  '起保月份',
  'page.cerp.social_insurance.column.title.city': 'City', //  '缴纳城市',
  'page.cerp.social_insurance.column.title.household_type': 'Household type', //  '户籍类型',
  'page.cerp.social_insurance.column.title.payment_items': 'Payment items', //  '缴费项目',
  'page.cerp.social_insurance.column.title.create_time': 'Time created', //  '创建时间',
  'page.cerp.social_insurance.column.title.update_time': 'Time updated', //  '更新时间',
  'page.Induction.options_social_security.all': 'All', //  '全部',

  'page.cerp.login.form.label.email': 'Email', //  '邮箱地址',
  'page.cerp.login.form.placeholder.email': 'Please enter email', //  '请填写邮箱地址',
  'page.cerp.login.form.placeholder.Retrieve_password': 'Retrieve password via email', //  '通过邮箱找回密码',
  'page.login.pc': 'The current device is not a PC. Whether to switch to the mobile client',
  'page.login.iPhone': 'Switch the mobile client',
  'page.login.retrieve_password': 'Retrieve password', //  '找回密码',
  'page.login.Account_password_login': 'Account Login', //  '账号密码登录',
  'page.login.Wrong_account_or_password': 'Invalid username or password', //  '账户或密码错误',
  'page.login.Please_enter_your_account': 'Please enter your username', //  '请输入账户',
  'page.login.Please_input_a_password': 'Please enter your password', //  '请输入密码',
  'page.login.automatic_logon': 'Auto Login', //  '自动登录',
  'page.login.Forget_password': 'Forgot password', //  '忘记密码',
  'page.login.login': 'Login', //  '登录',
  'page.login.Please_enter': 'Please enter username or email address', //  '请输入帐户名或邮箱地址',
  'page.login.Modify_default_password': 'Modify default password', //  '修改默认密码',
  'page.login.enter':'pages.login.enter', //  '请输入',
  'page.login.oldpassword':'Old password', //  '旧密码',
  'page.login.newpassword':'New password', //  '新密码',
  'page.login.newpassword2':'Confirm new password', //  '确认密码',
  'page.login.Please_oldpassword':'Please enter the old password', //  '请输入旧密码',
  'page.login.Please_newpassword':'Please enter the new password', //  '请输入新密码',
  'page.login.reminder':'Reminder', //  '温馨提示',
  'page.login.The_two_passwords_are_inconsistent':'The two passwords do not match', //  '两次输入密码不一致',
  'page.login.Password_modified_successfully':'Password modified successfully', //  '修改密码成功',
  'page.login.welcome':'Welcome!', //  '欢迎',
  'page.login.welcome_back':'Welcome back!', //  '欢迎回来',
  'page.login.Send_mail':'Email sent successfully, please complete new password settings within 10 minutes', //  '发送邮件成功，请在10分钟内完成新密码设置。',
  'page.login.cancel':'Cancel', //  '取消',
  'page.login.determine':'Confirm', //  '确定',
  'page.retrieve_password.Set_new_password':'Set new password', //  '设置新密码',
  'page.retrieve_password.Update_password':'Update password', //  '更新密码',
  'page.retrieve_password.Account_login':'Account login', //  '账户登录',
  'page.retrieve_password.Password_updated_successfully':'Password updated successfully', //  '密码更新成功',
  'page.upload_image_modal.OK_to_upload':'Upload', //  '确定上传',
  'page.upload_image_modal.upload':'Upload', //  '上传',
  'page.upload_image_modal.Created_successfully':'Created successfully, please 【Upload】', //  '创建成功，可以【确认上传】',
  'page.upload_image_modal.Please_select':'Please select the attachment', //  '请选择需要上传的附件',


  'page.Induction.img':'Send image', //  '图片发送',
  'page.Induction.jianli':'CV Export', //  '导出简历',
  'page.Induction.Select_signing_method':'Please select signing method', //  '选择签字方式',
  'page.information.Establish_integrity':'CV building progress', // '简历完整度',
  'page.cerp.AddExperiences.form.label.start_end_date':'Start and end date', // '时间',
  'page.kefu.name':'Customer Service', // '客服',
  'page.kefu.name1': 'Robot', // '机器人',
  'page.kefu.close':'Close', // '关闭',
  'page.kefu.chat':'Click the sessions on the left hand side to start the conversation', // '点击左侧会话,开启聊天',
  'page.kefu.Send_pictures':'Send image', // '发送图片',
  'page.kefu.Send_link':'Send link', // '发送链接',
  'page.kefu.input':'Please type in ...', // '请输入...',
  'page.kefu.send':'Send', // '发送',
  'page.kefu.adviser':'Consultant', // '顾问',
  'page.kefu.No_more_information_loaded':'No more messages to load', // '无更多信息加载',
  'page.kefu.https':'Please fill in the link start with http or https', // '请写http/https开头的链接',
  'page.kefu.Cannot_send_link':'Link cannot be sent', // '当前不可发送链接',
  'page.kefu.Cannot_send_pictures':'Images cannot be sent', // '当前不可发送图片',
  'page.kefu.link':'Link', // '链接',
  'page.kefu.Link_content':'Please provide link', // '请填写链接内容',
  'page.kefu.Close_session':'Confirm to close the session?', // '确认关闭会话？',
  'page.kefu.Consulting':'In progress', // '咨询中',
  'page.kefu.Closed':'Closed', // '已关闭',
  'page.kefu.consult':'Start session', // '咨询',
  'page.kefu.Consultation_content':'Session content', // '咨询内容',
  'page.kefu.please_Consultation_content':'Please provide the tile of this session', // '请填写咨询内容',
  'page.kefu.Session_created_successfully':'Session created successfully', // '创建会话成功',
  'page.kefu.picture':'Image', // '[图片]',
  'page.kefu.Read':'Read', // '已读',
  'page.kefu.no_ask': 'No relevant problems found, please directly transfer to manual！',
  'page.kefu.ask':'Do you want to ask the following questions？', // '你是想问如下问题吗',
  'page.kefu.Unread':'Unread', // '未读',
  'page.kefu.Click_load_more':'Click to load more', // '点击加载更多',
  'page.kefu.labor':'Transfer to labor', // '转人工',
  'page.kefu.more': 'More questions', // '更多问题',
  'page.Induction.Resume_not_created_yet': 'Resume not created yet', // '简历还未创建',

  'page.Induction.Signature_completed': 'Signature completed', // '签字完成',
  'page.Induction.Signing_contract_generation': 'Signing contract generation', // '签字合同生成中...',
  'page.Induction.Exit_failed': 'Exit failed. Please try again or contact customer service', // '完成退出失败，请再次尝试或联系客服'
  'page.Induction.text':  'Text',  // 文字
  'page.Induction.Finger':  'Finger', // 手写

  'page.kefu.Please_sign_in_the_blue_box': 'Please sign in the blue box', // '请在蓝色方框内签字',
  'page.kefu.canvas': 'Pro, your browser doesn\'t support canvas. Try another browser!', // '亲，您的浏览器不支持canvas，换个浏览器试试吧！',
  'page.kefu.off_line': 'Off line', // '离线',
  'page.kefu.on_line': 'On line', // '在线',
  'page.kefu.No_push': 'No push', // '无推送',
  'page.kefu.back': 'Back', // '上一步',
  'page.kefu.next': 'Next', // '下一步',
  'page.kefu.customer_service': 'customer service', // '客服',
  'page.kefu.personal': 'Confirm that the personal information is correct', // '确认"个人信息"正确',
  'page.kefu.bank': 'Confirm that the bank account is correct',// '确认【银行账户】正确',
  'page.kefu.beneficiary': 'Confirm that the insured beneficiary is correct',// 'I confirm that I do not need insurance, or choose the insurance beneficiary according to the law.',
  'page.kefu.tips': 'Confirm that the important tips are correct',// '确认【重要提示】正确',
  'page.kefu.new_contract': 'Please sign the new contract',
  'page.kefu.new_contract1': 'The content of the contract has been read', // 确认已经完成[合同]内容的阅读。
  'page.kefu.tips_read': 'Confirm the \'Important Notice\' has been read and agreed', // '确认已经完成[重要提示]内容的阅读。',
  'page.kefu.gotoworkplace': 'Finish later',
  'page.kefu.gotoworkplace1': 'Confirm to finish later and return to the welcome page?',
  'page.Induction.Submit': 'Submit',
  'page.Induction.agree': 'Please tick agree', // '请勾选同意',
  'page.login.tip': 'I have read and agree to the',// '我已阅读并同意',
  'page.login.tip1': 'terms and conditions',// '用户协议及隐私声明',
  'page.login.I_Know': 'OK', // '知道了',
  'page.Personal.tip': 'Tip: at present, your modification application has not been approved. Please wait patiently!',// '提示:当前您有修改申请尚未通过审批，请耐心等待.',
  'page.Induction.del': 'The enrollment process has not been completed and cannot be deleted',// '有入职流程未完成，不可删除',
  'page.Induction.no': 'No ',// '无',
  'page.Induction.new_contract': 'Please sign the new contract', // '请签署新合同',
  'pages.workplace.diff_day': 'Your current contract has expired, and your account will be disabled in ',
  'pages.workplace.diff_day1': ' days. If you have planned to renew the contract, please contact your recuitment company as soon as possible. C.C. Team look forward to working with you again soon!',


  '票据合同签署': 'Signing of bill contract', // '票据合同签署',
  '票据合同': 'Bill contract', // '票据合同',
  '合同起始日期': 'Start date', // '合同起始日期',
  '合同结束日期': 'End date', // '合同结束日期',
  '操作': 'Action', // '操作',
  '合同签署详情': 'Contract signing', // '合同签署详情',
  'CC公司': 'CC company', // 'CC公司',
  '当前合同不支持客户签署': 'The current contract is not supported by the customer, please refresh the list and try again', // '当前合同不支持客户签署，请刷新列表后再尝试',
  '已完成签署': 'Completed signature', // '已完成签署',
  '发票清单': 'Receipt list', // '发票清单',
  '开票日期': 'DateOfDrawing',
  '服务月份': 'ServiceMonth',
  '付款日期': 'DateOfPayment',
  '请选择开票日期': 'PleaseSelectDateOfDrawing',
  '请选择服务月份': 'PleaseSelectServiceMonth',
  '请选择付款日期': 'PleaseSelectDateOfPayment',
  '序号': 'Index',
  'ID': 'ID',
  '票据种类': 'Receipt kind',
  '全部': 'all',
  '总金额': 'TotalMoney',
  '币种': 'Currency',
  '付款状态': 'PaymentStatus',
  '未付款': 'NonPayment',
  '已付款': 'AccountPaid',
  '附件': 'Appendix',
  '提示': 'Tips',
  '无对应附件': 'NoCorrespondingAccessories',
  '登录过期': 'Login expired',
  '请重新登录': 'Please log in again',
};
