
import RemoteSelect from '@/components/FormRender/RemoteSelect/RemoteSelect.vue';
import RemoteModal from '@/components/FormRender/RemoteModal/RemoteModal.vue';
import RemoteTreeSelect from '@/components/FormRender/RemoteTreeSelect/RemoteTreeSelect.vue';
import RemoteTable from '@/components/FormRender/RemoteTable/RemoteTable.vue';
import { defineComponent } from 'vue';
import {
  StarFilled,
} from '@ant-design/icons-vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
  name: 'FieldRender',
  props: {
    field: {
      type: Object,
      required: true,
    },
    value: {
      required: true,
    },
    label: {
      required: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    validateInfos: {
      type: Object,
      default: () => {},
    },
    formModel: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  data() {
    const rangeDate = (v:any)=>{
      if(typeof v == 'string' && v.indexOf(',')>=0){
        return v.split(',');
      }
      return v;
    };
    return {
      rangeDate,
      model: this.field.type == 'rangepicker'?rangeDate(this.value):this.value,
      labelModel: this.label,
    };
  },
  watch: {
    model: {
      handler(newValue) {
        if ((this.field.type === 'number' || this.field.type === 'money') &&  (newValue == ''||newValue == null)) {
          this.model = 0;
          this.$emit('update:value', 0);
        } else if(this.field.type === 'input' && newValue){
          this.$emit('update:value', newValue.replace(/(^\s*)|(\s*$)/g, ""));
        } else{
          this.$emit('update:value', newValue);
        }
      },
    },
    value: {
      handler(newValue) {
        if ((this.field.type === 'number' || this.field.type === 'money') && (newValue == ''||newValue == null)) {
          this.model = 0;
        } else if(this.field.type === 'input' && newValue){
          this.model = newValue.replace(/(^\s*)|(\s*$)/g, "");
        } else {
          this.model = newValue;
        }
      },
    },
    labelModel: {
      handler(newValue) {
        this.$emit('update:label', newValue);
      },
    },
    label: {
      handler(newValue) {
        this.labelModel = newValue;
      },
    },
  },
  created() {},
  components: {
    RemoteModal,
    RemoteSelect,
    RemoteTreeSelect,
    RemoteTable,
    StarFilled,
  },
});
