import request from '@/utils/request';
// 搜索栏 组织数据
export async function queryOrgList(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/org/query_org_for_login_list', params);
}
export async function query_person_by_org_list(params?: {
  [key: string]: any;
}): Promise<RoleResponse> {
  return request.post('/org/query_person_by_org_list', params);
}
// 搜索栏 组织数据
export async function simpleLoginMaintain(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/user/simple_login', params);
}
export async function querySwitchUserList(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/sys/query_switch_user_list', params);
}
export async function checkIsSuperUser(params?: { [key: string]: any }): Promise<RoleResponse> {
  return request.post('/sys/check_is_super_user', params);
}
export async function switchUser(params?: { [key: string]: any }): Promise<any> {
  return request.post('/sys/switch_user', params);
}

export async function change_password(params?: { [key: string]: any }): Promise<any> {
  return request.post('/sys/change_password', params);
}


export async function create_organization_by_user(params?: { [key: string]: any }): Promise<any> {
  return request.post('/org/create_organization_by_user', params);
}

export async function my_orginfo(params?: { [key: string]: any }): Promise<any> {
  return request.post('/sys/my_orginfo', params);
}

// 修改角色人员模态框  人员信息
export async function queryGroupList(params?: { [key: string]: any }): Promise<any> {
  const res: any = await request.post('/org/query_group_and_no_group_member', params);
  const group_list: Array<any> = [];
  let num = 0;
  res.group_list.forEach((item: any) => {
    const children: Array<any> = [];
    const obj = {
      key: num + 1,
      name: item.name,
      user_id: 0,
      person_id: 0,
      children: children,
    };
    num = num + 1;
    if (item && item.person_list && item.person_list.length > 0) {
      item.person_list.forEach((item1: any) => {
        const obj1: any = {
          key: num + 1,
          name: item1.user_id + '#' + item1.realname,
          user_id: item1.user_id,
          person_id: item1.id,
        };
        num = num + 1;
        obj.children.push(obj1);
      });
    }
    group_list.push(obj);
  });
  group_list.forEach((item: any) => {
    if (item.children && item.children.length > 0) {
      item.name = item.name + '(' + item.children.length + '人)';
    } else {
      item.name = item.name + '(0人)';
    }
  });
  res.person_list.forEach((item: any) => {
    const obj = {
      key: num + 1,
      name: item.user_id + '#' + item.realname,
      user_id: item.user_id,
      person_id: item.id,
    };
    num = num + 1;
    group_list.push(obj);
  });
  res.data = group_list;
  return res;
}

interface RuleItem {
  key: string | number;
  callNo: number;
  avatar: string;
  desc: string;
  disabled: false;
  href: string;
  name: string;
  owner: string;
  progress: number;
  status: number;
  updatedAt: string;
  createdAt: string;
}

interface RoleResponse {
  current: number;
  data: RuleItem[];
  pageSize: string;
  success: boolean;
  total: number;
}
