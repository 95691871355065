import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function query_finished_caliber_name_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_finished_caliber_name_list', params);
}

export async function query_heatmaterial_record_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_heatmaterial_record_list', params);
}

export async function query_material_info_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/material_storage/query_material_info_list', params);
}

export async function query_all_area_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/material_storage/query_all_area_list', params);
}

export async function delete_billet_type(params: Record<string, any>) {
  return request.post('/material_storage/delete_billet_type', params);
}

export async function delete_production_return_record(params: Record<string, any>) {
  return request.post('/material_storage/delete_production_return_record', params);
}

export async function create_steel_diameter(params: Record<string, any>) {
  return request.post('/material_storage/create_steel_diameter', params);
}

export async function update_steel_diameter(params: Record<string, any>) {
  return request.post('/material_storage/update_steel_diameter', params);
}

export async function delete_steel_diameter(params: Record<string, any>) {
  return request.post('/material_storage/delete_steel_diameter', params);
}

export async function recover_steel_diameter(params: Record<string, any>) {
  return request.post('/material_storage/recover_steel_diameter', params);
}

export async function create_billet_grade(params: Record<string, any>) {
  return request.post('/material_storage/create_billet_grade', params);
}

export async function update_billet_grade(params: Record<string, any>) {
  return request.post('/material_storage/update_billet_grade', params);
}

export async function delete_billet_grade(params: Record<string, any>) {
  return request.post('/material_storage/delete_billet_grade', params);
}

export async function recover_billet_grade(params: Record<string, any>) {
  return request.post('/material_storage/recover_billet_grade', params);
}

export async function query_material_length_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/material_storage/query_material_length_list', params);
}

export async function query_material_change_report_kind_str_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_material_change_report_kind_str_list', params);
}

export async function delete_finished_caliber(params: Record<string, any>) {
  return request.post('/material_storage/delete_finished_caliber', params);
}

export async function create_quality(params: Record<string, any>) {
  return request.post('/material_storage/create_quality', params);
}

export async function recover_quality(params: Record<string, any>) {
  return request.post('/material_storage/recover_quality', params);
}

export async function delete_quality(params: Record<string, any>) {
  return request.post('/material_storage/delete_quality', params);
}

export async function update_quality(params: Record<string, any>) {
  return request.post('/material_storage/update_quality', params);
}

export async function query_quality_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/material_storage/query_quality_list', params);
}

export async function query_steel_type_name_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/material_storage/query_steel_type_name_list', params);
}

export async function query_company_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/material_storage/query_company_list', params);
}

export async function create_specifications(params: Record<string, any>) {
  return request.post('/material_storage/create_specifications', params);
}

export async function create_company(params: Record<string, any>) {
  return request.post('/material_storage/create_company', params);
}

export async function update_company(params: Record<string, any>) {
  return request.post('/material_storage/update_company', params);
}

export async function delete_company(params: Record<string, any>) {
  return request.post('/material_storage/delete_company', params);
}

export async function recover_company(params: Record<string, any>) {
  return request.post('/material_storage/recover_company', params);
}

export async function update_billet_type(params: Record<string, any>) {
  return request.post('/material_storage/update_billet_type', params);
}

export async function query_roller_specification_by_all_org_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_roller_specification_by_all_org_list', params);
}

export async function query_heatmaterialinventory_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_heatmaterialinventory_list', params);
}

export async function query_steel_type_by_roller_position_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_steel_type_by_roller_position_list', params);
}

export async function delete_steel_type(params: Record<string, any>) {
  return request.post('/material_storage/delete_steel_type', params);
}

export async function delete_specifications(params: Record<string, any>) {
  return request.post('/material_storage/delete_specifications', params);
}

export async function update_org_roller_spec(params: Record<string, any>) {
  return request.post('/material_storage/update_org_roller_spec', params);
}

export async function create_steel_type(params: Record<string, any>) {
  return request.post('/material_storage/create_steel_type', params);
}

export async function recover_billet_type(params: Record<string, any>) {
  return request.post('/material_storage/recover_billet_type', params);
}

export async function update_specifications(params: Record<string, any>) {
  return request.post('/material_storage/update_specifications', params);
}

export async function create_or_update_styeel_or_org(params: Record<string, any>) {
  return request.post('/material_storage/create_or_update_styeel_or_org', params);
}

export async function query_steel_type_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/material_storage/query_steel_type_list', params);
}

export async function recover_specifications(params: Record<string, any>) {
  return request.post('/material_storage/recover_specifications', params);
}

export async function query_steel_type_by_roller_id_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_steel_type_by_roller_id_list', params);
}

export async function update_material(params: Record<string, any>) {
  return request.post('/material_storage/update_material', params);
}

export async function query_material_change_report_detail_by_record_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post(
    '/material_storage/query_material_change_report_detail_by_record_list',
    params,
  );
}

export async function query_steel_type_by_roller_specification_id_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_steel_type_by_roller_specification_id_list', params);
}

export async function query_parent_steel_type_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_parent_steel_type_list', params);
}

export async function update_finished_caliber(params: Record<string, any>) {
  return request.post('/material_storage/update_finished_caliber', params);
}

export async function create_or_update_production_plan_day_weight(params: Record<string, any>) {
  return request.post('/material_storage/create_or_update_production_plan_day_weight', params);
}

export async function get_material(params: Record<string, any>) {
  return request.post('/material_storage/get_material', params);
}

export async function get_materialinventory_result_by_date(params: Record<string, any>) {
  return request.post('/material_storage/get_materialinventory_result_by_date', params);
}

export async function delete_return_production_record_item_record(params: Record<string, any>) {
  return request.post('/material_storage/delete_return_production_record_item_record', params);
}

export async function set_material_check_info(params: Record<string, any>) {
  return request.post('/material_storage/set_material_check_info', params);
}

export async function recover_finished_caliber(params: Record<string, any>) {
  return request.post('/material_storage/recover_finished_caliber', params);
}

export async function recover_steel_type(params: Record<string, any>) {
  return request.post('/material_storage/recover_steel_type', params);
}

export async function query_material_length_result(params: Record<string, any>) {
  return request.post('/material_storage/query_material_length_result', params);
}

export async function delete_material(params: Record<string, any>) {
  return request.post('/material_storage/delete_material', params);
}

export async function update_steel_type(params: Record<string, any>) {
  return request.post('/material_storage/update_steel_type', params);
}

export async function query_specifications_by_roller_position_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_specifications_by_roller_position_list', params);
}

export async function get_steel_type_for_specification(params: Record<string, any>) {
  return request.post('/material_storage/get_steel_type_for_specification', params);
}

export async function get_hour_weight_by_specifications_id(params: Record<string, any>) {
  return request.post('/material_storage/get_hour_weight_by_specifications_id', params);
}

export async function get_material_by_heat_no(params: Record<string, any>) {
  return request.post('/material_storage/get_material_by_heat_no', params);
}

export async function query_steel_type_by_plan_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_steel_type_by_plan_list', params);
}

export async function query_specifications_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/material_storage/query_specifications_list', params);
}

export async function query_billet_type_name_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_billet_type_name_list', params);
}

export async function get_hour_weight_by_lines(params: Record<string, any>) {
  return request.post('/material_storage/get_hour_weight_by_lines', params);
}

export async function get_material_check_info(params: Record<string, any>) {
  return request.post('/material_storage/get_material_check_info', params);
}

export async function query_material_name_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/material_storage/query_material_name_list', params);
}

export async function create_material(params: Record<string, any>) {
  return request.post('/material_storage/create_material', params);
}

export async function query_roller_spec_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/material_storage/query_roller_spec_list', params);
}

export async function analyse_material_data(params: Record<string, any>) {
  return request.post('/material_storage/analyse_material_data', params);
}

export async function query_materialinventory_result(params: Record<string, any>) {
  return request.post('/material_storage/query_materialinventory_result', params);
}

export async function create_finished_caliber(params: Record<string, any>) {
  return request.post('/material_storage/create_finished_caliber', params);
}

export async function recover_material(params: Record<string, any>) {
  return request.post('/material_storage/recover_material', params);
}

export async function query_material_thick_result(params: Record<string, any>) {
  return request.post('/material_storage/query_material_thick_result', params);
}

export async function query_finished_caliber_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_finished_caliber_list', params);
}

export async function query_material_change_report_by_date_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/material_storage/query_material_change_report_by_date_list', params);
}

export async function create_billet_type(params: Record<string, any>) {
  return request.post('/material_storage/create_billet_type', params);
}

export async function query_material_thick_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/material_storage/query_material_thick_list', params);
}

export async function update_steel_type_for_specification(params: Record<string, any>) {
  return request.post('/material_storage/update_steel_type_for_specification', params);
}

export async function query_material_list(params: Record<string, any>): Promise<PageResult> {
  return request.post('/material_storage/query_material_list', params);
}
