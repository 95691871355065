export default {
  'app.setting.pagestyle': 'Page style setting',
  'app.setting.pagestyle.light': 'Light style',
  'app.setting.pagestyle.dark': 'Dark style',
  'app.setting.pagestyle.realdark': 'RealDark style',
  'app.setting.layout.side': 'Side Menu Layout',
  'app.setting.layout.top': 'Top Menu Layout',
  'app.setting.layout.mix': 'Mix Layout',
  'app.setting.layout.leftmenu': 'Left Layout',
  'app.setting.layout.mix.hint': 'Split Menus',
  'app.setting.themecolor': 'Theme Color',
  'app.setting.navigationmode': 'Navigation Mode',
  'app.setting.content-width': 'Content Width',
  'app.setting.fixedheader': 'Fixed Header',
  'app.setting.fixedheader.hint': 'Mix Required fixed header',
  'app.setting.fixedsidebar': 'Fixed Sidebar',
  'app.setting.fixedsidebar.hint': '',
  'app.setting.split.menus': 'Split Menus',
  'app.setting.content-width.fixed': 'Fixed',
  'app.setting.content-width.fluid': 'Fluid',
  'app.setting.othersettings': 'Other Settings',
  'app.setting.weakmode': 'Weak Mode',
  'app.setting.transitionname': 'TransitionName',
  'app.setting.multitab': 'Multi Tabs',
  'app.setting.multitab.fixed': 'Multi Tab Fixed',
  'app.setting.multitab.fixed.hit': 'Tabs Fixed Required open MultiTab and Fixed Header',
  'app.setting.unavailable': 'Unavailable',
  'app.setting.copy': 'Copy Setting',
  'app.setting.loading': 'Loading theme',
  'app.setting.copyinfo': 'copy success，please replace defaultSettings in src/models/setting.js',
  'app.setting.production.hint':
    'Setting panel shows in development environment only, please manually modify',
};
