import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, mergeProps as _mergeProps, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/logo.png'
import _imports_1 from '@/assets/logo1.png'


const _hoisted_1 = {
  key: 0,
  class: "ant-pro-sider-logo",
  style: {"line-height":"30px","padding-left":"0"}
}
const _hoisted_2 = {
  key: 0,
  src: _imports_0,
  alt: "logo",
  height: "30px"
}
const _hoisted_3 = {
  key: 1,
  src: _imports_1,
  alt: "logo",
  height: "30px"
}
const _hoisted_4 = { style: {"flex":"1","overflow":"hidden auto"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_base_menu = _resolveComponent("base-menu")!
  const _component_menu_unfold_outlined = _resolveComponent("menu-unfold-outlined")!
  const _component_menu_fold_outlined = _resolveComponent("menu-fold-outlined")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!
  const _component_a_layout_sider = _resolveComponent("a-layout-sider")!

  return (_ctx.computedMenus.length)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (_ctx.fixed)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "ant-pro-fixed-stuff",
              style: _normalizeStyle({
        width: `${_ctx.runtimeSideWidth}px`,
        overflow: 'hidden',
      })
            }, null, 4))
          : _createCommentVNode("", true),
        _createVNode(_component_a_layout_sider, _mergeProps(_ctx.$attrs, {
          class: {
        [_ctx.prefixCls]: true,
        [`${_ctx.prefixCls}-${_ctx.runtimeTheme}`]: true,
        [`${_ctx.prefixCls}-${_ctx.layout}`]: true,
        [`${_ctx.prefixCls}-fixed`]: _ctx.fixed,
      },
          breakpoint: _ctx.breakpoint,
          width: _ctx.runtimeSideWidth,
          collapsed: _ctx.collapsed,
          collapsible: false,
          "collapsed-width": _ctx.collapsedWidth,
          theme: _ctx.runtimeTheme,
          style: {
        overflow: 'hidden',
        paddingTop: _ctx.isMix ? `${_ctx.headerHeight}px` : undefined,
      }
        }), {
          default: _withCtx(() => [
            (!_ctx.isMix)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createVNode(_component_router_link, { to: { name: 'index' } }, {
                    default: _withCtx(() => [
                      (_ctx.is_logo)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_2))
                        : (_openBlock(), _createElementBlock("img", _hoisted_3))
                    ]),
                    _: 1
                  })
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_4, [
              _renderSlot(_ctx.$slots, "header"),
              _createVNode(_component_base_menu, {
                i18n: _ctx.i18n,
                theme: _ctx.runtimeTheme,
                menus: _ctx.computedMenus,
                collapsed: _ctx.collapsed,
                "selected-keys": _ctx.selectedKeys,
                "open-keys": _ctx.openKeys,
                "onUpdate:openKeys": _ctx.handleOpenKeys,
                "onUpdate:selectedKeys": _ctx.handleSelectedKeys,
                onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('mouseenter', $event))),
                onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('mouseleave', $event))),
                onItemHover: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('itemHover', $event))),
                customItem: _ctx.customItem
              }, null, 8, ["i18n", "theme", "menus", "collapsed", "selected-keys", "open-keys", "onUpdate:openKeys", "onUpdate:selectedKeys", "customItem"])
            ]),
            _createElementVNode("div", {
              class: _normalizeClass(`${_ctx.prefixCls}-links`)
            }, [
              (_ctx.collapsedButton)
                ? (_openBlock(), _createBlock(_component_a_menu, {
                    key: 0,
                    class: _normalizeClass(`${_ctx.prefixCls}-link-menu`),
                    "inline-indent": 16,
                    theme: _ctx.runtimeTheme,
                    "selected-keys": [],
                    "open-keys": [],
                    mode: "inline"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_menu_item, {
                        key: "collapsed-button",
                        class: _normalizeClass(`${_ctx.prefixCls}-collapsed-button`),
                        title: null,
                        onClick: _ctx.handleCollapse
                      }, {
                        icon: _withCtx(() => [
                          _renderSlot(_ctx.$slots, "collapsedButton", {}, () => [
                            (_ctx.collapsed)
                              ? (_openBlock(), _createBlock(_component_menu_unfold_outlined, { key: 0 }))
                              : (_openBlock(), _createBlock(_component_menu_fold_outlined, { key: 1 }))
                          ])
                        ]),
                        _: 3
                      }, 8, ["class", "onClick"])
                    ]),
                    _: 3
                  }, 8, ["class", "theme"]))
                : _createCommentVNode("", true)
            ], 2)
          ]),
          _: 3
        }, 16, ["class", "breakpoint", "width", "collapsed", "collapsed-width", "theme", "style"])
      ], 64))
    : _createCommentVNode("", true)
}