import { query_page_type_list } from '@/api/check_form-model';
import ls from '@/utils/local-storage';
import { STORAGE_CURRENT_ORG_KEY } from '@/store/mutation-type';




export default () => {

  const org = ls.get(STORAGE_CURRENT_ORG_KEY); // 当前组织org
  const search_config: any = {
    model: {
      org_id: org?.id,
      is_active: true,
    },
    watch: [],
  };
  return {
    getData: query_page_type_list,
    searchConfig: search_config,
  };
};
