import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!

  return (_ctx.text!==undefined && _ctx.text!==null)
    ? (_openBlock(), _createBlock(_component_a_tag, {
        key: 0,
        color: _ctx.get_tag_color_by_slots(_ctx.column, _ctx.text)
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.column['field_' + _ctx.text]), 1)
        ]),
        _: 1
      }, 8, ["color"]))
    : _createCommentVNode("", true)
}