import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function create_service_session(params: Record<string, any>) {
  return request.post('/service/create_service_session', params);
}
export async function create_session_record_for_adviser(params: Record<string, any>) {
  return request.post('/service/create_session_record_for_adviser', params);
}
export async function create_auto_reply_session_record_for_adviser(params: Record<string, any>) {
  return request.post('/service/create_auto_reply_session_record_for_adviser', params);
}
export async function transfer_service_session_to_human(params: Record<string, any>) {
  return request.post('/service/transfer_service_session_to_human', params);
}

export async function create_auto_reply_session_record_of_question_for_adviser(params: Record<string, any>) {
  return request.post('/service/create_auto_reply_session_record_of_question_for_adviser', params);
}

export async function create_session_record_for_service(params: Record<string, any>) {
  return request.post('/service/create_session_record_for_service', params);
}
export async function update_session_record(params: Record<string, any>) {
  return request.post('/service/update_session_record', params);
}
export async function delete_session_record(params: Record<string, any>) {
  return request.post('/service/delete_session_record', params);
}
export async function recover_session_record(params: Record<string, any>) {
  return request.post('/service/recover_session_record', params);
}
export async function query_session_record_list(params: Record<string, any>) {
  return request.post('/service/query_session_record_list', params);
}
export async function set_session_record_read(params: Record<string, any>) {
  return request.post('/service/set_session_record_read', params);
}
export async function update_service_session(params: Record<string, any>) {
  return request.post('/service/update_service_session', params);
}
export async function delete_service_session(params: Record<string, any>) {
  return request.post('/service/delete_service_session', params);
}
export async function recover_service_session(params: Record<string, any>) {
  return request.post('/service/recover_service_session', params);
}
export async function deliver_service_session(params: Record<string, any>) {
  return request.post('/service/deliver_service_session', params);
}
export async function close_service_session(params: Record<string, any>) {
  return request.post('/service/close_service_session', params);
}
export async function get_service_session_no_read_number(params: Record<string, any>) {
  return request.post('/service/get_service_session_no_read_number', params);
}
export async function create_service(params: Record<string, any>) {
  return request.post('/service/create_service', params);
}
export async function update_service(params: Record<string, any>) {
  return request.post('/service/update_service', params);
}
export async function delete_service(params: Record<string, any>) {
  return request.post('/service/delete_service', params);
}
export async function recover_service(params: Record<string, any>) {
  return request.post('/service/recover_service', params);
}

export async function query_service_session_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/service/query_service_session_list', params);
}

export async function query_service_list(
  params: Record<string, any>,
): Promise<PageResult> {
  return request.post('/service/query_service_list', params);
}

