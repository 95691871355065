import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ant-upload-text" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_plus_outlined = _resolveComponent("plus-outlined")!
  const _component_upload_outlined = _resolveComponent("upload-outlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_upload = _resolveComponent("a-upload")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_modal, {
      title: _ctx.title,
      width: "720px",
      visible: true,
      maskClosable: false,
      confirmLoading: _ctx.is_loading || _ctx.loading,
      cancelText: _ctx.t('page.login.cancel'),
      okText: _ctx.t('page.upload_image_modal.OK_to_upload'),
      centered: "",
      onOk: _ctx.handleSubmit,
      onCancel: _cache[1] || (_cache[1] = 
      () => {
        _ctx.$emit('cancel');
      }
    )
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_upload, {
          customRequest: _ctx.customRequest,
          multiple: _ctx.multiple,
          "file-list": _ctx.fileList,
          "onUpdate:file-list": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fileList) = $event)),
          "list-type": _ctx.type,
          remove: _ctx.handleRemove,
          previewFile: _ctx.addcard,
          onPreview: _ctx.handlePreview
        }, {
          default: _withCtx(() => [
            (_ctx.type === 'picture-card')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createVNode(_component_plus_outlined),
                  _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.t('page.upload_image_modal.upload')), 1)
                ], 64))
              : _createCommentVNode("", true),
            (_ctx.type === 'picture')
              ? (_openBlock(), _createBlock(_component_a_button, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_upload_outlined),
                    _createTextVNode(" " + _toDisplayString(_ctx.t('page.upload_image_modal.upload')), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["customRequest", "multiple", "file-list", "list-type", "remove", "previewFile", "onPreview"])
      ]),
      _: 1
    }, 8, ["title", "confirmLoading", "cancelText", "okText", "onOk"]),
    _createVNode(_component_a_modal, {
      visible: _ctx.previewVisible,
      footer: null,
      onCancel: _ctx.handleCancel
    }, {
      default: _withCtx(() => [
        _createElementVNode("img", {
          alt: "example",
          style: {"width":"100%"},
          src: _ctx.previewImage
        }, null, 8, _hoisted_2)
      ]),
      _: 1
    }, 8, ["visible", "onCancel"])
  ], 64))
}