import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_from_render = _resolveComponent("from-render")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: _ctx.title,
    width: _ctx.width,
    visible: true,
    confirmLoading: _ctx.loading,
    cancelText: _ctx.t('page.login.cancel'),
    okText: _ctx.t('page.login.determine'),
    centered: "",
    wrapClassName: _ctx.form.model.datatype == 'N' ? 'custom-form-modal1' : 'custom-form-modal',
    onOk: _ctx.handleSubmit,
    onCancel: _cache[0] || (_cache[0] = 
      () => {
        // resetFields();
        _ctx.$emit('cancel');
      }
    )
  }, {
    default: _withCtx(() => [
      _createVNode(_component_from_render, {
        ref: "render",
        form: _ctx.form,
        model: _ctx.modelRef,
        options: _ctx.options,
        validateInfos: _ctx.validateInfos
      }, null, 8, ["form", "model", "options", "validateInfos"])
    ]),
    _: 1
  }, 8, ["title", "width", "confirmLoading", "cancelText", "okText", "wrapClassName", "onOk"]))
}