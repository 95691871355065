import mitt from 'mitt';
import { btnTd } from '@/api/typing';
import defaultSlotList from '@/components/page-model/slotList';
const { t } = useI18n();
import bus from "@/utils/bus";
import {useI18n} from "vue-i18n";
export const event_obj = mitt();
export const btn_list = [
  {
    name: t('page.information.update_beneficiary'),
    clazz: 'primary',
    cb: (record: any) => {
      bus.emit('update_beneficiary', {...record});
    },
  },
  // {
  //   name: t('page.information.delete'),
  //   clazz: 'danger',
  //   cb: (record: any) => {
  //     bus.emit('del_beneficiary', {...record});
  //   },
  // },
];
export const slotList = defaultSlotList;
export const config = {
  creatCol: [],
  updateCol: [],
};

export const head_btn_list = [
  {
    name: t('page.information.New_insurance_beneficiary'),
    clazz: 'primary',
    cb: (record: any) => {
      bus.emit('create_beneficiary');
    },
  },
];
