import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createBlock(_component_a_button, {
    style: {"padding-left":"1px"},
    type: "link",
    size: 'small',
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.column.cb(_ctx.record, _ctx.text)))
  }, {
    default: _withCtx(() => [
      (_ctx.column.content)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            style: _normalizeStyle(_ctx.column.rt ? _ctx.column.rt(_ctx.record, _ctx.text) : '')
          }, _toDisplayString(_ctx.column.content(_ctx.record, _ctx.text)), 5))
        : (_openBlock(), _createElementBlock("span", {
            key: 1,
            style: _normalizeStyle(_ctx.column.rt ? _ctx.column.rt(_ctx.record, _ctx.text) : '')
          }, _toDisplayString(_ctx.text), 5))
    ]),
    _: 1
  }))
}