import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function create_area_position(params: Record<string, any>) {
  return request.post('/line_info/create_area_position', params);
}

export async function update_area_position(params: Record<string, any>) {
  return request.post('/line_info/update_area_position', params);
}

export async function delete_area_position(params: Record<string, any>) {
  return request.post('/line_info/delete_area_position', params);
}

export async function recover_area_position(params: Record<string, any>) {
  return request.post('/line_info/recover_area_position', params);
}

export async function query_area_position_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/line_info/query_area_position_list', params);
}

export async function query_billet_thick_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/line_info/query_billet_thick_list', params);
}

export async function query_line_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/line_info/query_line_list', params);
}

export async function create_position_type(params: Record<string, any>) {
  return request.post('/line_info/create_position_type', params);
}

export async function update_position_type(params: Record<string, any>) {
  return request.post('/line_info/update_position_type', params);
}

export async function delete_position_type(params: Record<string, any>) {
  return request.post('/line_info/delete_position_type', params);
}

export async function recover_position_type(params: Record<string, any>) {
  return request.post('/line_info/recover_position_type', params);
}

export async function query_position_type_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/line_info/query_position_type_list', params);
}

export async function create_rolled_position(params: Record<string, any>) {
  return request.post('/line_info/create_rolled_position', params);
}

export async function update_rolled_position(params: Record<string, any>) {
  return request.post('/line_info/update_rolled_position', params);
}

export async function delete_rolled_position(params: Record<string, any>) {
  return request.post('/line_info/delete_rolled_position', params);
}

export async function recover_rolled_position(params: Record<string, any>) {
  return request.post('/line_info/recover_rolled_position', params);
}

export async function query_rolled_position_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/line_info/query_rolled_position_list', params);
}

export async function query_all_rolled_position_by_roller_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/line_info/query_all_rolled_position_by_roller_list', params);
}

export async function query_rolled_position_by_roller_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/line_info/query_rolled_position_by_roller_list', params);
}

export async function query_rolled_position_exclude_roller_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/line_info/query_rolled_position_exclude_roller_list', params);
}

export async function query_steel_diameter_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/line_info/query_steel_diameter_list', params);
}

export async function query_steel_technics_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/line_info/query_steel_technics_list', params);
}

export async function query_steel_type_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/line_info/query_steel_type_list', params);
}

export async function create_sub_line(params: Record<string, any>) {
  return request.post('/line_info/create_sub_line', params);
}

export async function update_sub_line(params: Record<string, any>) {
  return request.post('/line_info/update_sub_line', params);
}

export async function delete_sub_line(params: Record<string, any>) {
  return request.post('/line_info/delete_sub_line', params);
}

export async function recover_sub_line(params: Record<string, any>) {
  return request.post('/line_info/recover_sub_line', params);
}

export async function query_sub_line_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/line_info/query_sub_line_list', params);
}

export async function create_sub_line_history(params: Record<string, any>) {
  return request.post('/line_info/create_sub_line_history', params);
}

export async function update_sub_line_history(params: Record<string, any>) {
  return request.post('/line_info/update_sub_line_history', params);
}

export async function delete_sub_line_history(params: Record<string, any>) {
  return request.post('/line_info/delete_sub_line_history', params);
}

export async function recover_sub_line_history(params: Record<string, any>) {
  return request.post('/line_info/recover_sub_line_history', params);
}

export async function query_sub_line_history_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/line_info/query_sub_line_history_list', params);
}

export async function create_billet_thick(params: Record<string, any>) {
  return request.post('/line_info/create_billet_thick', params);
}

export async function update_billet_thick(params: Record<string, any>) {
  return request.post('/line_info/update_billet_thick', params);
}

export async function delete_billet_thick(params: Record<string, any>) {
  return request.post('/line_info/delete_billet_thick', params);
}

export async function recover_billet_thick(params: Record<string, any>) {
  return request.post('/line_info/recover_billet_thick', params);
}

export async function create_line(params: Record<string, any>) {
  return request.post('/line_info/create_line', params);
}

export async function update_line(params: Record<string, any>) {
  return request.post('/line_info/update_line', params);
}

export async function delete_line(params: Record<string, any>) {
  return request.post('/line_info/delete_line', params);
}

export async function recover_line(params: Record<string, any>) {
  return request.post('/line_info/recover_line', params);
}

export async function update_orgtoline(params: Record<string, any>) {
  return request.post('/line_info/update_orgtoline', params);
}

export async function create_position_group(params: Record<string, any>) {
  return request.post('/line_info/create_position_group', params);
}

export async function update_position_group(params: Record<string, any>) {
  return request.post('/line_info/update_position_group', params);
}

export async function delete_position_group(params: Record<string, any>) {
  return request.post('/line_info/delete_position_group', params);
}

export async function recover_position_group(params: Record<string, any>) {
  return request.post('/line_info/recover_position_group', params);
}

export async function query_position_group_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/line_info/query_position_group_list', params);
}
