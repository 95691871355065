
import {
  query_production_record_item_for_jrl_detail_list,
  set_know_no_to_more,
  update_blank_production_record_item_for_jrl_detail,
  update_production_record_item_note
} from '@/api/production_manage-model';
import {computed, createVNode, defineComponent, onActivated, onDeactivated, reactive, ref} from 'vue';
import {ZoomInOutlined, ZoomOutOutlined, RedoOutlined, StopOutlined, ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {useStore} from 'vuex';
import bus from '@/utils/bus';
import moment from 'moment';
import {message, Modal} from "ant-design-vue";
import modalBox from "@/components/form-modal/modal-tools";
export default defineComponent({
  name: 'RollNoModal',
  props: {
    visible: {
      type: Boolean,
    },
  },
  emits: ['ok', 'cancel'],
  setup(props, {emit}) {
    const visible: any = ref(false);
    const width: any = ref(1450);
    visible.value = props.visible;
    const store = useStore();
    const bus_obj: any = bus;
    const current_org = store.getters['user/current_org'];
    const onClose = () => {
      visible.value = false;
    };
    const add_shiyan = (record: any) => {
      Modal.confirm({
        title: '温馨提示',
        content:
          '请确认是否修改炉号【' +
          record.production_record_item.heat_no +
          '】第' + record.production_record_item.index + '根品质',
        onOk() {
          const editModal = reactive({
            visible: true,
            title: '钢坯根支品质',
            loading: false,
            form: {
              settings: {
                labelAlign: 'right',
                layout: 'vertical',
                col: [],
              },
              fields: [
                {
                  type: 'radio',
                  name: 'custom_note',
                  label: '钢坯根支品质',
                  disabled: false,
                  labelKey: 'name',
                  valueKey: 'value',
                  defaultValue: '',
                },
              ],
              rules: reactive({}),
              model: reactive({
                org_id: current_org?.id,
                custom_note: record.production_record_item.custom_note ? record.production_record_item.custom_note : '',
                production_record_item_id: record.production_record_item.id,
              }),
            },
            options: {
              custom_note: [
                { value: '', name: '合格' },
                { value: '不合格', name: '不合格' },
                { value: '实验', name: '实验' },
              ]
            },
            ok: (payload: any) => {
              return new Promise(resolve => {
                editModal.loading = true;
                update_production_record_item_note(payload)
                  .then(() => {
                    message.success('修改成功');
                    resolve(null);
                    refresh();
                  })
                  .finally(() => {
                    editModal.loading = false;
                  });
              });
            },
          });
          modalBox(editModal);
        },
        onCancel() {},
      });
    }
    const add_kong = (record: any) => {
      Modal.confirm({
        title: '确认添加空位?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            update_blank_production_record_item_for_jrl_detail({
              org_id: current_org.id,
              blank_add_item_flag: record.item_flag,
            }).then(() => {
              message.success('添加空位成功');
              if(record.order <= 42){
                list1.value.map((item: any) => {
                  if(item.order == record.order){
                    item.is_blank = true;
                  }
                })
              }else if(record.order > 42 && record.order <= 84){
                list2.value.map((item: any) => {
                  if(item.order == record.order){
                    item.is_blank = true;
                  }
                })
              }else if(record.order > 84 && record.order <= 126){
                list3.value.map((item: any) => {
                  if(item.order == record.order){
                    item.is_blank = true;
                  }
                })
              }
            }).finally(() => {
              resolve(null);
              setTimeout(() => {
                refresh();
              }, 10000);
              setTimeout(() => {
                refresh();
              }, 15000);
              setTimeout(() => {
                refresh();
              }, 20000);
            });
          });
        },
        onCancel() {},
      });
    };
    const pop_kong = (record: any) => {
      Modal.confirm({
        title: '确认删除空位?',
        icon: createVNode(ExclamationCircleOutlined),
        onOk() {
          return new Promise(resolve => {
            update_blank_production_record_item_for_jrl_detail({
              org_id: current_org.id,
              blank_pop_step: record.step,
            }).then(() => {
              message.success('删除空位成功');
              if(record.order <= 42){
                list1.value.map((item: any, index: any) => {
                  if(item.order == record.order){
                    list1.value.splice(index, 1);
                  }
                })
              }else if(record.order > 42 && record.order <= 84){
                list2.value.map((item: any, index: any) => {
                  if(item.order == record.order){
                    list2.value.splice(index, 1);
                  }
                })
              }else if(record.order > 84 && record.order <= 126){
                list3.value.map((item: any, index: any) => {
                  if(item.order == record.order){
                    list3.value.splice(index, 1);
                  }
                })
              }
            }).finally(() => {
              resolve(null);
              setTimeout(() => {
                refresh();
              }, 10000);
              setTimeout(() => {
                refresh();
              }, 15000);
              setTimeout(() => {
                refresh();
              }, 20000);
            });
          });
        },
        onCancel() {},
      });
    };
    const getTime1 = (time: any) => {
      if (time) {
        return Math.floor(Number(time) / 3600) + '小时' + (Math.floor((Number(time) % 3600) / 60) < 10 ? '0'+Math.floor((Number(time) % 3600) / 60) : Math.floor((Number(time) % 3600) / 60)) + '分';
      }
    };
    const button_list: any = ['danger', 'warning', 'success', 'info', 'primary'];
    const list: any = ref([]);
    const list1: any = ref([]);
    const list2: any = ref([]);
    const list3: any = ref([]);
    const refresh = () => {
      list.value.length = 0;
      query_production_record_item_for_jrl_detail_list({
        org_id: current_org.id,
      }).then((res: any) => {
        let num = -1;
        let roll_no = '';
        res.data.map((item: any, index: any) => {
          item.order = item.step < 10 ? '0'+ item.step: item.step;
          if (item.is_blank || !item.production_record.plan_item__roll_no) {
            item.buttonColor = 'default';
            item.is_blank = true;
          } else {
            if (item.production_record.plan_item__roll_no != roll_no) {
              num = num + 1;
              roll_no = item.production_record.plan_item__roll_no;
              item.buttonColor = button_list[num % 5];
            } else {
              item.buttonColor = button_list[num % 5];
            }
            if(item.production_record_item.custom_note){
              item.buttonColor = 'shiyan';
            }
            let now: any = new Date((moment().format("YYYY-MM-DD HH:mm:ss")).replace(/-/g,"/"));//将字符串转化为时间
            let end_date: any = new Date((item.production_record_item.create_time).replace(/-/g,"/"));//将字符串转化为时间
            item.time_long = getTime1((now-end_date)/1000);//求出两个时间的时间差，这个是天数
            // item.time_long = moment() - moment(item.production_record_item.create_time, "YYYY-MM-DD HH:mm:ss");
          }
          console.log('time', moment())
          console.log('time1', moment(item.production_record_item.create_time, "YYYY-MM-DD HH:mm:ss"))
          list.value.push(item);
        })
        list1.value = list.value.slice(0, 42);
        list2.value = list.value.slice(42, 84);
        list3.value = list.value.slice(84, 126);
      })
    }
    refresh();
    bus_obj.on('root_branch_list', refresh);
    // onActivated(() => {
    //   bus_obj?.off('root_branch_list', refresh());
    //
    //   bus_obj?.on('root_branch_list', refresh());
    // });
    // onDeactivated(() => {
    //   bus_obj?.off('root_branch_list', refresh());
    // });
    const getTime = (time: any) => {
      if (time) {
        time = time.substring(11, 16);
      }
      return time;
    };
    return {
      list1,
      list2,
      list3,
      props,
      visible,
      width,
      getTime,
      onClose,
      add_kong,
      add_shiyan,
      pop_kong,
      refresh,
    };
  },
  components: {
    ZoomInOutlined,
    ZoomOutOutlined,
    RedoOutlined,
    StopOutlined,
  },
});
