
import {defineComponent} from 'vue';
export default defineComponent({
  name: 'Cell_obj',
  props: {
    column: {
      type: Object,
      required: true,
      default: () => {},
    },
    index: {
      type: Number,
      required: true,
    },
    text: {
      type: [String , Number],
      required: true,
    },
    record: {
      type: Object,
      required: true,
      default: () => {},
    },
    pageSize: {
      type: Number,
      required: true,
      default: () => 20,
    },
    current: {
      type: Number,
      required: true,
      default: () => 1,
    },
  },
  setup() {
    const getNestingValue = (dataIndex: string, record: any) => {
      let tempObj = { ...record };
      const dataIndexArray = dataIndex.split('.');
      const getKey = () => {
        const key = dataIndexArray.shift();
        if (tempObj && key) {
          tempObj = tempObj[key];
          getKey();
        }
      };
      getKey();
      return tempObj;
    };
    return {
      getNestingValue
    }
  }
});
