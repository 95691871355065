import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Transition as _Transition } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multi_tab_store_consumer = _resolveComponent("multi-tab-store-consumer")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createBlock(_component_router_view, null, {
    default: _withCtx(({ Component }) => [
      _createVNode(_Transition, {
        name: _ctx.transitionName,
        appear: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_multi_tab_store_consumer, null, {
            default: _withCtx(() => [
              Component
                ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { key: 0 }))
                : _renderSlot(_ctx.$slots, "default", { key: 1 })
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1032, ["name"])
    ]),
    _: 3
  }))
}