import { reactive } from 'vue';
import modalBox from '@/components/form-modal/modal-tools';

const defaultSlotList = [
  {
    slotName: 'index', // 槽名
  },
  {
    slotName: 'status',
    type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
    name1: '可用', // text字段有或true 展示
    name2: '作废', // text字段无或false 展示
  },
  {
    slotName: 'bool',
    type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
    name1: '是', // text字段有或true 展示
    name2: '否', // text字段无或false 展示
  },
  {
    slotName: 'is_open',
    type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
    name1: '开', // text字段有或true 展示
    name2: '关', // text字段无或false 展示
  },
  {
    slotName: 'is_active',
    type: 'boolean', // 类型：'boolean' - text字段有无、(true/false)
    name1: '可用', // text字段有或true 展示
    name2: '禁用', // text字段无或false 展示
  },
  {
    slotName: 'time',
    type: 'time', // 类型：'time' - text字段为时间
  },
  {
    slotName: 'time2',
    type: 'time2', // 类型：'time' - text字段为时间
  },
  {
    slotName: 'datetime',
    type: 'datetime', // 类型：'datetime' - text字段为日期 时间
  },
  {
    slotName: 'toFixed2',
    type: 'toFixed2', // 类型：'number' - text字段为float
  },
  {
    slotName: 'date',
    type: 'date', // 类型：'date' - text字段为日期
  },
  {
    slotName: 'list',
    type: 'list', // 类型：'list' - text字段为数组
  },
  {
    slotName: 'bolder',
    type: 'bolder', // 类型：'list' - text字段为数组
  },
  {
    slotName: 'format_datetime_hh_mm',
    type: 'format_datetime_hh_mm', // 类型：'list' - text字段为数组
  },
  {
    slotName: 'divided_by_thousand',
    type: 'divided_by_thousand', // 类型：'list' - text字段为数组
  },
  {
    slotName: 'tag',
    type: 'tag', // 类型：'list' - text字段为数组
  },
  {
    slotName: 'tag-tag',
    type: 'tag-tag', // 类型：'list' - text字段为数组
  },
  {
    slotName: 'attributetable',
    type: 'attributetable', // 类型：'list' - text字段为数组
    cb: (text: any, record: any, column: any) => {
      const editModal = reactive({
        visible: true,
        loading: false,
        title: (record.name ? record.name + '-' : '') + column.title,
        component: 'AttributeTableModal',
        value: text,
      });
      modalBox(editModal);
    },
  },
];
export default defaultSlotList;
