import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_model_4 = _resolveComponent("page-model-4")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    title: _ctx.title,
    width: '1000px',
    visible: true,
    cancelText: "取消",
    okText: "保存",
    centered: "",
    wrapClassName: "custom-form-modal1",
    onOk: _cache[0] || (_cache[0] = 
      () => {
        // resetFields();
        _ctx.$emit('ok');
      }
    ),
    onCancel: _cache[1] || (_cache[1] = 
      () => {
        // resetFields();
        _ctx.$emit('cancel');
      }
    )
  }, {
    default: _withCtx(() => [
      _createVNode(_component_page_model_4, {
        modalType: _ctx.roller_shift_time.modalType,
        search: _ctx.roller_shift_time.search,
        title: _ctx.roller_shift_time.title,
        column_flag: _ctx.roller_shift_time.column_flag,
        height: _ctx.roller_shift_time.height,
        mode: _ctx.roller_shift_time.mode,
        is_search: true
      }, null, 8, ["modalType", "search", "title", "column_flag", "height", "mode"])
    ]),
    _: 1
  }, 8, ["title"]))
}