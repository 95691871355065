import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeStyle as _normalizeStyle, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"text-align":"right"} }
const _hoisted_2 = {
  style: {"flex":"auto","overflow-x":"hidden"},
  class: "ant-pro-leftmenu-layout-content"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sider_menu = _resolveComponent("sider-menu")!
  const _component_avatar_dropdown = _resolveComponent("avatar-dropdown")!
  const _component_select_lang = _resolveComponent("select-lang")!
  const _component_header_view = _resolveComponent("header-view")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_wrap_content = _resolveComponent("wrap-content")!
  const _component_global_footer = _resolveComponent("global-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_setting_drawer = _resolveComponent("setting-drawer")!
  const _component_pro_provider = _resolveComponent("pro-provider")!

  return (_openBlock(), _createBlock(_component_pro_provider, {
    i18n: _ctx.t,
    "content-width": _ctx.contentWidth
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout, { class: "ant-pro-leftmenu-layout" }, {
        default: _withCtx(() => [
          _createVNode(_component_sider_menu, {
            theme: "dark",
            layout: "left",
            "prefix-cls": "ant-pro-leftmenu-sider",
            fixed: true,
            menus: _ctx.mainMenus,
            "sider-width": _ctx.sideWidth,
            "collapsed-width": _ctx.collapsedWidth,
            "open-keys": [],
            collapsed: _ctx.collapsed,
            "onUpdate:collapsed": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.collapsed) = $event)),
            "selected-keys": _ctx.firstSelectedKeys,
            onItemHover: _ctx.handleItemHover,
            onMouseleave: _ctx.handleMouseLeave,
            "onUpdate:selectedKeys": _ctx.handleSelectedKeysChange,
            customItem: _ctx.customItem
          }, null, 8, ["menus", "sider-width", "collapsed-width", "collapsed", "selected-keys", "onItemHover", "onMouseleave", "onUpdate:selectedKeys", "customItem"]),
          _createVNode(_component_a_layout, { style: {"position":"relative","flex-direction":"column"} }, {
            default: _withCtx(() => [
              _createVNode(_component_header_view, {
                theme: _ctx.theme,
                layout: _ctx.layout,
                menus: _ctx.menus,
                "has-sider-menu": true,
                "fixed-header": true,
                "split-menus": _ctx.splitMenus,
                "collapsed-button": false,
                "collapsed-width": _ctx.collapsed ? _ctx.collapsedWidth : _ctx.sideWidth,
                "selected-keys": [],
                "open-keys": []
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_1, [
                    _createVNode(_component_avatar_dropdown, { "current-user": _ctx.currentUser }, null, 8, ["current-user"]),
                    _createVNode(_component_select_lang)
                  ])
                ]),
                _: 1
              }, 8, ["theme", "layout", "menus", "split-menus", "collapsed-width"]),
              _createVNode(_component_a_layout, { style: {"width":"auto"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_sider_menu, {
                    key: "menu",
                    style: _normalizeStyle({
              zIndex: 7,
              position: 'fixed',
              left: `${_ctx.collapsed ? _ctx.collapsedWidth : _ctx.sideWidth}px`,
            }),
                    class: "ant-pro-has-leftmenu",
                    theme: "light",
                    layout: _ctx.layout,
                    fixed: true,
                    menus: _ctx.menus,
                    "sider-width": _ctx.secondSideWidth,
                    "split-menus": true,
                    "collapsed-button": false,
                    collapsed: false,
                    "open-keys": _ctx.openKeys,
                    "onUpdate:open-keys": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.openKeys) = $event)),
                    "selected-keys": _ctx.selectedKeys,
                    "onUpdate:selected-keys": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedKeys) = $event))
                  }, null, 8, ["style", "layout", "menus", "sider-width", "open-keys", "selected-keys"]),
                  _createElementVNode("section", _hoisted_2, [
                    _createVNode(_component_wrap_content, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_router_view),
                        _renderSlot(_ctx.$slots, "default")
                      ]),
                      _: 3
                    }),
                    _createVNode(_component_global_footer)
                  ])
                ]),
                _: 3
              }),
              _withDirectives(_createElementVNode("div", {
                style: _normalizeStyle({
            position: 'fixed',
            left: `${_ctx.collapsed ? _ctx.collapsedWidth : _ctx.sideWidth}px`,
            zIndex: 8,
            height: '100%',
          }),
                onMouseenter: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.cancelSetMenuHover && _ctx.cancelSetMenuHover(...args))),
                onMouseleave: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.handleMouseLeave && _ctx.handleMouseLeave(...args)))
              }, [
                _createVNode(_component_sider_menu, {
                  style: { height: '100%' },
                  key: "hoverMenu",
                  class: "ant-pro-has-leftmenu",
                  theme: "light",
                  layout: _ctx.layout,
                  fixed: false,
                  menus: _ctx.secondMenus,
                  "sider-width": _ctx.secondSideWidth,
                  "collapsed-button": false,
                  collapsed: false,
                  "open-keys": _ctx.openKeys,
                  "onUpdate:open-keys": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.openKeys) = $event)),
                  "selected-keys": _ctx.selectedKeys,
                  "onUpdate:selected-keys": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selectedKeys) = $event))
                }, null, 8, ["layout", "menus", "sider-width", "open-keys", "selected-keys"])
              ], 36), [
                [_vShow, _ctx.showHoverSiderMenu]
              ])
            ]),
            _: 3
          })
        ]),
        _: 3
      }),
      _createVNode(_component_setting_drawer)
    ]),
    _: 3
  }, 8, ["i18n", "content-width"]))
}