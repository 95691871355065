
import { defineComponent, reactive, ref } from 'vue';
import { exportJsonToExcel } from '@/utils/excel';
import { BookType } from 'xlsx';
import {
  getTime,
  getFullDate,
  toFixed6,
  toFixed0,
  toFixed2,
  getDate,
  getDateTime,
  getDateMonth,
} from '@/utils/function';

type StateType = {
  selectedFileType: string;
  fileTypeList: string[];
  selectedColumns: string[] | unknown[];
  filename: string;
};
import { DownloadOutlined } from '@ant-design/icons-vue';
import { isNumber } from 'lodash';
import axios from 'axios';
export default defineComponent({
  name: 'exportExcelModal',
  props: {
    fileType: {
      type: String,
      default: () => 'xlsx',
    },
    columnList: {
      type: Array,
      default: () => [],
    },
    filename: {
      type: String,
      default: () => 'exportFile',
    },
    datasource: {
      type: Array,
      default: () => [],
    },
    columns: {
      type: Array,
      default: () => [],
    },
    pageSize: {
      type: Number,
      default: 0,
    },
    current: {
      type: Number,
      default: 0,
    },
  },
  components: {
    DownloadOutlined,
  },
  emits: ['ok', 'cancel'],
  setup(props, { emit }) {
    const visible = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const state = reactive<StateType>({
      selectedFileType: 'xlsx',
      filename: props.filename,
      selectedColumns: props.columnList
        .filter((item: any) => item.checked)
        .map((item: any) => {
          return item.key;
        }),
      fileTypeList: ['xlsx', 'csv', 'txt', 'xlsx(服务端)'],
    });

    const handleOpen = () => {
      visible.value = true;
    };
    const handleCancel = () => {
      visible.value = false;
    };

    const column_dict: any = {};
    props.columns.forEach((item: any) => {
      column_dict[item.dataIndex ? item.dataIndex : item.key] = item;
    });

    // const slot_dict: any = {};
    // props.slotList.forEach((item: any) => {
    //   slot_dict[item.slotName] = item;
    // });

    const value_compute = (rowIndex: number, text: any, record: any, key: string) => {
      if (column_dict[key]?.dataIndex=='index') {
        return rowIndex + 1 + props.pageSize * (props.current - 1);
      } else if (column_dict[key]?.content) {
        return column_dict[key]?.content(record, text);
      } else if (column_dict[key]?.cellComponent) {
        return text;
      } else {
        return text;
      }
    };

    const handleExport = (e: Event) => {
      loading.value = true;
      e.preventDefault();

      const selectedColumns = props.columnList
        .map((item: any) => {
          if (state.selectedColumns.indexOf(item.key) > -1) {
            return item;
          } else {
            return null;
          }
        })
        .filter(item => {
          if (item) {
            return true;
          }
        });

      console.error('开始：' + new Date().getTime());
      const excel_data = props.datasource.map((item: any, rowIndex: number) =>
        selectedColumns.map((v: any) => value_compute(rowIndex, item[v.key], item, v.key)),
      );
      console.error('数据处理结束：' + new Date().getTime());
      if (state.selectedFileType == 'xlsx(服务端)') {
        excel_data.unshift(selectedColumns.map((item: any) => item.label));
        let url = '/api/sys/download_excel_by_data';
        if (process.env.NODE_ENV == 'production') {
          url = '/sys/download_excel_by_data';
        }
        axios
          .post(
            url,
            { filename: state.filename, data: excel_data },
            {
              responseType: 'blob',
            },
          )
          .then((res: any) => {
            var blob = res.data;
            // FileReader主要用于将文件内容读入内存
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            // onload当读取操作成功完成时调用
            reader.onload = (e: any) => {
              var a = document.createElement('a');
              // 获取文件名fileName
              // var fileName = res.config["url"].split("/");
              // fileName = fileName[fileName.length - 1];
              // fileName = fileName.replace(/"/g, "");
              a.download = state.filename + '.xlsx';
              a.href = e.target.result;
              document.body.appendChild(a);
              a.click();
              document.body.removeChild(a);
              visible.value = false;
            };
          })
          .finally(() => {
            loading.value = false;
          });
      } else {
        exportJsonToExcel({
          data: excel_data,
          header: selectedColumns.map((item: any) => item.label),
          filename: state.filename,
          bookType: state.selectedFileType as BookType,
          autoWidth: false,
        });
        loading.value = false;
        visible.value = false;
        console.error('导出结束：' + new Date().getTime());
      }
    };

    return {
      props,
      state,
      visible,
      loading,
      handleOpen,
      handleCancel,
      handleExport,
    };
  },
});
