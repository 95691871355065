import request from '@/utils/request';
import { PageResult } from '@/api/typing';

export async function create_device(params: Record<string, any>) {
  return request.post('/roll_device/create_device', params);
}

export async function update_device(params: Record<string, any>) {
  return request.post('/roll_device/update_device', params);
}

export async function delete_device(params: Record<string, any>) {
  return request.post('/roll_device/delete_device', params);
}

export async function recover_device(params: Record<string, any>) {
  return request.post('/roll_device/recover_device', params);
}

export async function query_device_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/roll_device/query_device_list', params);
}

export async function create_device_info(params: Record<string, any>) {
  return request.post('/roll_device/create_device_info', params);
}

export async function update_device_info(params: Record<string, any>) {
  return request.post('/roll_device/update_device_info', params);
}

export async function delete_device_info(params: Record<string, any>) {
  return request.post('/roll_device/delete_device_info', params);
}

export async function recover_device_info(params: Record<string, any>) {
  return request.post('/roll_device/recover_device_info', params);
}

export async function query_device_info_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/roll_device/query_device_info_list', params);
}

export async function create_device_record(params: Record<string, any>) {
  return request.post('/roll_device/create_device_record', params);
}

export async function update_device_record(params: Record<string, any>) {
  return request.post('/roll_device/update_device_record', params);
}

export async function delete_device_record(params: Record<string, any>) {
  return request.post('/roll_device/delete_device_record', params);
}

export async function recover_device_record(params: Record<string, any>) {
  return request.post('/roll_device/recover_device_record', params);
}

export async function query_device_record_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll_device/query_device_record_list', params);
}

export async function create_device_type(params: Record<string, any>) {
  return request.post('/roll_device/create_device_type', params);
}

export async function update_device_type(params: Record<string, any>) {
  return request.post('/roll_device/update_device_type', params);
}

export async function delete_device_type(params: Record<string, any>) {
  return request.post('/roll_device/delete_device_type', params);
}

export async function recover_device_type(params: Record<string, any>) {
  return request.post('/roll_device/recover_device_type', params);
}

export async function query_device_type_list(params?: { [key: string]: any }): Promise<PageResult> {
  return request.post('/roll_device/query_device_type_list', params);
}

export async function create_machine_device_suggest(params: Record<string, any>) {
  return request.post('/roll_device/create_machine_device_suggest', params);
}

export async function update_machine_device_suggest(params: Record<string, any>) {
  return request.post('/roll_device/update_machine_device_suggest', params);
}

export async function delete_machine_device_suggest(params: Record<string, any>) {
  return request.post('/roll_device/delete_machine_device_suggest', params);
}

export async function recover_machine_device_suggest(params: Record<string, any>) {
  return request.post('/roll_device/recover_machine_device_suggest', params);
}

export async function query_machine_device_suggest_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll_device/query_machine_device_suggest_list', params);
}

export async function create_rolled_machine_group_item(params: Record<string, any>) {
  return request.post('/roll_device/create_rolled_machine_group_item', params);
}

export async function update_rolled_machine_group_item(params: Record<string, any>) {
  return request.post('/roll_device/update_rolled_machine_group_item', params);
}

export async function delete_rolled_machine_group_item(params: Record<string, any>) {
  return request.post('/roll_device/delete_rolled_machine_group_item', params);
}

export async function recover_rolled_machine_group_item(params: Record<string, any>) {
  return request.post('/roll_device/recover_rolled_machine_group_item', params);
}

export async function query_rolled_machine_group_item_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll_device/query_rolled_machine_group_item_list', params);
}

export async function create_rolled_machine_position(params: Record<string, any>) {
  return request.post('/roll_device/create_rolled_machine_position', params);
}

export async function update_rolled_machine_position(params: Record<string, any>) {
  return request.post('/roll_device/update_rolled_machine_position', params);
}

export async function delete_rolled_machine_position(params: Record<string, any>) {
  return request.post('/roll_device/delete_rolled_machine_position', params);
}

export async function recover_rolled_machine_position(params: Record<string, any>) {
  return request.post('/roll_device/recover_rolled_machine_position', params);
}

export async function query_rolled_machine_position_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll_device/query_rolled_machine_position_list', params);
}

export async function query_device_record_bak_list(params?: {
  [key: string]: any;
}): Promise<PageResult> {
  return request.post('/roll_device/query_device_record_bak_list', params);
}

export async function device_record_up(params: Record<string, any>) {
  return request.post('/roll_device/device_record_up', params);
}

export async function device_record_down(params: Record<string, any>) {
  return request.post('/roll_device/device_record_down', params);
}

export async function set_device_record_reason(params: Record<string, any>) {
  return request.post('/roll_device/set_device_record_reason', params);
}
