
import { defineComponent, onMounted, onUnmounted } from 'vue';

export default defineComponent({
  name: 'UserLayout',
  setup() {
    onMounted(() => {
      document.body.classList.add('userLayout');
    });
    onUnmounted(() => {
      document.body.classList.remove('userLayout');
    });

    return {};
  },
});
