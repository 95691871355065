import { ref, reactive, Ref, watch } from 'vue';
import { TableColumn } from '@/typing';
import { debounce, isNumber, sum } from 'lodash-es';
import { Store } from 'vuex';
import { set_column_setting_for_org, set_column_setting_for_person } from '@/api/sys-model';
import { UPDATE_CUSTOME_DATA_USER } from '@/store/modules/user/actions';
export type TableOptions =
  | {
  checkAll?: boolean;
  needRowIndex?: boolean;
  tableWidth?: number;
}
  | boolean;

export type DynamicColumnItem = {
  key: string;
  label: string;
  checked: boolean;
  org_checked?: boolean;
  width?: number;
};

export type DynamicColumnState = {
  checkAll: boolean;
  checkedList: string[];
  needRowIndex: boolean;
  indeterminate: boolean;
  tableWidth: number;
  tableMinWidth: number;
};

export type DynamicColumnConfigState = {
  checkAll: boolean;
  showCheckAction: boolean;
  checkAction: boolean;
  is_column_config: boolean;
};

export interface DynamicColumns {
  dynamicColumns: Ref<TableColumn[]>;
  dynamicColumnItems: Ref<DynamicColumnItem[]>;
  state: DynamicColumnState;
  configState: DynamicColumnConfigState;
  reset: () => void;
  handleColumnChange: (e: Event, column: DynamicColumnItem) => void;
  handleColumnAllClick: (e: Event) => void;
  move: (index: number, targetIndex: number) => void;
  change_mode: (mode: any, org_mode: any) => void;
  update_column_org_config: () => void;
  update_column_data: () => void;
  resizeColumn: (width: number, column: any) => void;
  switch_column_config: () => void;
  columnAction: () => void;
  column_change: () => void;
  replace_column: (tmp_columns: TableColumn[]) => void;
}

const defaulttRowIndexColumn = {
  title: '序号',
  dataIndex: 'my-custom-show-index',
  customRender: ({ index }: any) => `${index + 1}`,
};

export const useTableDynamicColumns = (
  columns: TableColumn[],
  defOptions: TableOptions,
  store?: Store<any>,
  column_flag = '',
  column_list_change?: () => Promise<any>,
): DynamicColumns => {
  const current_org = store?.getters['user/current_org'];
  const custome_data_user = store?.getters['user/custome_data_user'];
  const custome_data_org = store?.getters['user/custome_data_org'];
  let source_columns: any = {};


  const options: {
    checkAll: boolean;
    needRowIndex: boolean;
    tableWidth: number;
  } =
    typeof defOptions === 'boolean'
      ? {
        checkAll: defOptions,
        needRowIndex: false,
        tableWidth: 1000,
      }
      : {
        checkAll: !!defOptions.checkAll,
        needRowIndex: !!defOptions.needRowIndex,
        tableWidth: defOptions.tableWidth != undefined ? Number(defOptions.tableWidth) : 500,
      };

  const state = reactive<DynamicColumnState>({
    checkAll: options.checkAll,
    needRowIndex: options.needRowIndex,
    checkedList: [],
    indeterminate: true,
    tableWidth: options.tableWidth,
    tableMinWidth: options.tableWidth,
  });
  if (options.needRowIndex) {
    columns.unshift(defaulttRowIndexColumn);
  }
  const dynamicColumns = ref(columns);

  const dynamicColumnItems: Ref<DynamicColumnItem[]> = ref(
    columns.map(column => {
      return {
        key: column?.key || column.dataIndex,
        label: column.title,
        checked: options.checkAll ? true : column.checked === undefined ? true : column.checked,
        width: column.width ? column.width : 150,
        org_checked: true,
      } as DynamicColumnItem;
    }),
  );
  let dynamicColumnValues: string[] = dynamicColumnItems.value
    .filter(item => (item.checked == undefined ? true : item.checked))
    .map(column => column.key);

  state.checkedList = dynamicColumnValues;
  state.checkAll = options.checkAll;
  state.indeterminate = !options.checkAll;

  const planColumns = () => {
    const keys = dynamicColumnItems.value.map(item => item.key);

    dynamicColumns.value = columns
      .filter(item => state.checkedList.includes(item.dataIndex))
      .sort((a, b) => {
        const aKey = a.key || a.dataIndex;
        const bKey = b.key || b.dataIndex;
        return keys.indexOf(aKey) - keys.indexOf(bKey);
      })
      .map(item => {
        const ditems = dynamicColumnItems.value.filter(oitem => oitem.key == item.key);
        if (ditems.length > 0 && ditems[0].width != undefined && item['width']!=undefined) {
          item['width'] = ditems[0].width;
        }
        if (ditems.length > 0 && ditems[0].org_checked != undefined) {
          item['org_checked'] = ditems[0].org_checked;
        } else {
          item['org_checked'] = true;
        }
        return item;
      });
    state.tableWidth = sum(
      dynamicColumns.value.map(item => (isNumber(item.width) ? item.width : 150)),
    );
    if (state.tableWidth < state.tableMinWidth) {
      state.tableWidth = state.tableMinWidth;
    }
    // console.error(dynamicColumns.value);
  };

  watch(
    () => state.checkedList,
    () => {
      state.checkAll =
        !!state.checkedList.length && state.checkedList.length === dynamicColumnValues.length;
      state.indeterminate =
        !!state.checkedList.length && state.checkedList.length < dynamicColumnValues.length;
      planColumns();
    },
    { deep: true },
  );

  watch(
    () => dynamicColumnItems,
    () => {
      planColumns();
    },
    { deep: true },
  );

  const handleColumnChange = (e: Event, column: DynamicColumnItem) => {
    const checked = (e.target as HTMLInputElement).checked;
    column.checked = checked;
    if (checked) {
      !state.checkedList.includes(column.key) && state.checkedList.push(column.key);
    } else {
      state.checkedList = state.checkedList.filter(item => item !== column.key);
    }
  };

  const handleColumnAllClick = (e: Event) => {
    const checked = (e.target as HTMLInputElement).checked;
    state.checkedList = checked ? dynamicColumnValues : [];
    dynamicColumnItems.value = dynamicColumnItems.value.map(column => {
      column.checked = checked;
      return column;
    });
  };

  const reset = () => {
    state.checkedList = dynamicColumnValues;
    dynamicColumnItems.value = columns.map(column => {
      return {
        key: column?.key || column.dataIndex,
        label: column.title,
        checked: options.checkAll,
      } as DynamicColumnItem;
    });
  };

  const move = (index: number, targetIndex: number) => {
    const newColumnItems = dynamicColumnItems.value;
    const columnItemKey = newColumnItems[index];
    newColumnItems.splice(index, 1);
    if (targetIndex === 0) {
      newColumnItems.unshift(columnItemKey);
    } else {
      newColumnItems.splice(targetIndex, 0, columnItemKey);
    }
    dynamicColumnItems.value = newColumnItems;
  };

  const change_mode = (mode: any, org_mode: any) => {
    dynamicColumnItems.value.map((item: any, index: number) => {
      item.sort_num = index;
      item.org_checked = true;
      if (mode[item.key]) {
        if (mode[item.key]['index'] != undefined) {
          item.sort_num = mode[item.key]['index'];
        }
        if (mode[item.key]['checked'] != undefined) {
          item.checked = mode[item.key]['checked'];
        }

        if (mode[item.key]['width'] != undefined) {
          item.width = mode[item.key]['width'];
        }
      }
      if (org_mode && org_mode[item.key] && org_mode[item.key]['org_checked'] != undefined) {
        if (org_mode[item.key]['org_checked'] == false) {
          item.org_checked = false;
          item.checked = false;
        }
      }
    });

    dynamicColumnItems.value = dynamicColumnItems.value.sort(
      (a: any, b: any) => a.sort_num - b.sort_num,
    );

    // change_column(0);
    dynamicColumnItems.value.map(column => {
      if (column.checked) {
        !state.checkedList.includes(column.key) && state.checkedList.push(column.key);
      } else if (state.checkedList.includes(column.key)) {
        state.checkedList = state.checkedList.filter(item => item !== column.key);
      }
    });
  };

  /**
   * 更新列的配置，上传到服务器,作为组织的基础配置，先判断是否有改变，没有改变则不上传
   */
  const update_column_org_config = () => {
    if (!configState.is_column_config) {
      return;
    }
    let equal_org = true;
    dynamicColumnItems.value.map((item, index) => {
      if (custome_data_org[column_flag]) {
        if (
          custome_data_org[column_flag][item.key] &&
          custome_data_org[column_flag][item.key]['index'] == index &&
          custome_data_org[column_flag][item.key]['key'] == item.key &&
          custome_data_org[column_flag][item.key]['checked'] == item.checked &&
          custome_data_org[column_flag][item.key]['width'] == item.width &&
          custome_data_org[column_flag][item.key]['org_checked'] == item.org_checked
        ) {
        } else {
          equal_org = false;
        }
      } else {
        equal_org = false;
      }
    });
    if (equal_org) {
      return;
    }
    const column_data_json: any = {};
    dynamicColumnItems.value.map((item, index) => {
      column_data_json[item.key] = {
        key: item.key,
        index: index,
        checked: item.checked,
        width: item.width,
        org_checked: item.org_checked,
      };
    });

    set_column_setting_for_org({
      org_id: current_org?.id,
      flag: column_flag,
      data: JSON.stringify(column_data_json),
    }).then(function () {
      const data: any = {};
      data[column_flag] = column_data_json;
      // custome_data_user[field.column_flag] = column_data_json;
      store?.dispatch(`user/${UPDATE_CUSTOME_DATA_USER}`, { data: data });
      configState.checkAll = false;
    });
  };
  /**
   * 更新列的配置，上传到服务器，先判断是否有改变，没有改变则不上传
   */
  const update_column_data = () => {
    if (configState.is_column_config) {
      return;
    }
    let equal_user = true;
    dynamicColumnItems.value.map((item, index) => {
      if (custome_data_user[column_flag]) {
        if (
          custome_data_user[column_flag][item.key] &&
          custome_data_user[column_flag][item.key]['index'] == index &&
          custome_data_user[column_flag][item.key]['key'] == item.key &&
          custome_data_user[column_flag][item.key]['checked'] == item.checked &&
          custome_data_user[column_flag][item.key]['width'] == item.width
        ) {
        } else {
          equal_user = false;
        }
      } else {
        equal_user = false;
      }
    });
    if (equal_user) {
      return;
    }
    const column_data_json: any = {};
    dynamicColumnItems.value.map((item, index) => {
      column_data_json[item.key] = {
        key: item.key,
        index: index,
        checked: item.checked,
        width: item.width,
      };
    });

    set_column_setting_for_person({
      org_id: current_org?.id,
      flag: column_flag,
      data: JSON.stringify(column_data_json),
    }).then(function () {
      const person_data: any = {};
      person_data[column_flag] = column_data_json;
      // custome_data_user[field.column_flag] = column_data_json;
      store?.dispatch(`user/${UPDATE_CUSTOME_DATA_USER}`, { person_data: person_data });
      configState.checkAll = true;
    });
  };

  const resizeColumn = debounce((width: number, column: any) => {
    dynamicColumnItems.value.map(item => {
      if (item.key == column.key) {
        item.width = width;
      }
    });
    update_column_data();
  }, 500);

  const switch_column_config = () => {
    configState.is_column_config = !configState.is_column_config;
    if (configState.checkAll) {
      if (custome_data_user[column_flag]) {
        change_mode(custome_data_user[column_flag], custome_data_org[column_flag]);
      }
    } else {
      if (custome_data_org[column_flag]) {
        change_mode(custome_data_org[column_flag], custome_data_org[column_flag]);
      } else {
        change_mode(source_columns, null);
      }
    }
  };

  const columnAction = () => {
    dynamicColumnItems.value.forEach(org => {
      if (org.key == 'action') {
        org.checked = configState.checkAction;
        const b: any = {
          ...org,
          checked: configState.checkAction,
        };
        const a: any = {};
        a.target = b;
        handleColumnChange(a, b);
      }
    });
    update_column_data();
  };

  // 列’默认‘ ’全部‘ 展示的切换
  const column_change = () => {
    if (configState.checkAll) {
      if (custome_data_user && custome_data_user[column_flag]) {
        change_mode(custome_data_user[column_flag], custome_data_org[column_flag]);
      }
    } else {
      if (custome_data_org && custome_data_org[column_flag]) {
        change_mode(custome_data_org[column_flag], custome_data_org[column_flag]);
      } else {
        change_mode(source_columns, null);
      }
    }
  };

  const configState = reactive<DynamicColumnConfigState>({
    checkAction:
      dynamicColumnItems.value.filter(item => item.key == 'action' && item.checked).length > 0,
    showCheckAction: dynamicColumnItems.value.filter(item => item.key == 'action').length > 0,
    checkAll: custome_data_user && custome_data_user[column_flag] != undefined,
    is_column_config: false,
  });

  const init = () => {
    columns.map((item: any) => {
      item.key = item.dataIndex;
      item.autoHeight = true;
      item.resizable = !(item.resizable === false);
    });
    source_columns = {};
    columns.map((item: any, index: number) => {
      source_columns[item.dataIndex] = {
        ...item,
        index: index,
        checked: item.checked == undefined ? true : item.checked,
        width: item.width,
      };
    });
    dynamicColumnItems.value = columns.map(column => {
      return {
        key: column?.key || column.dataIndex,
        label: column.title,
        checked: options.checkAll ? true : column.checked === undefined ? true : column.checked,
        width: column.width ? column.width : 150,
        org_checked: true,
      } as DynamicColumnItem;
    });
    dynamicColumnValues = dynamicColumnItems.value
      .filter(item => (item.checked == undefined ? true : item.checked))
      .map(column => column.key);
    state.checkedList = dynamicColumnValues;

    configState.checkAction =
      dynamicColumnItems.value.filter(item => item.key == 'action' && item.checked).length > 0;
    configState.showCheckAction =
      dynamicColumnItems.value.filter(item => item.key == 'action').length > 0;
    configState.checkAll = custome_data_user && custome_data_user[column_flag] != undefined;
    configState.is_column_config = false;

    if (custome_data_user && custome_data_user[column_flag]) {
      change_mode(custome_data_user[column_flag], custome_data_org[column_flag]);
    } else if (custome_data_org && custome_data_org[column_flag]) {
      change_mode(custome_data_org[column_flag], custome_data_org[column_flag]);
    }
    planColumns();
  };

  if (column_list_change) {
    column_list_change().then(res => {
      res.map((item: any) => {
        columns.push(item);
      });
      init();
    });
  }
  const replace_column = (tmp_columns: TableColumn[])=>{
    columns.length = 0;
    tmp_columns.map(item=>{
      columns.push(item);
    });
    // columns.concat(tmp_columns);
    init();
  };

  init();
  return {
    dynamicColumns,
    dynamicColumnItems,
    state,
    configState,
    reset,
    handleColumnAllClick,
    handleColumnChange,
    move,
    change_mode,
    update_column_org_config,
    update_column_data,
    resizeColumn,
    switch_column_config,
    columnAction,
    column_change,
    replace_column,
  };
};
