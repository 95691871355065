import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tag = _resolveComponent("a-tag")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_tag, {
      color: _ctx.column.color ? _ctx.column.color(_ctx.record) : 'green'
    }, {
      default: _withCtx(() => [
        _createElementVNode("span", null, _toDisplayString(_ctx.column.content(_ctx.record)), 1)
      ]),
      _: 1
    }, 8, ["color"]),
    _createElementVNode("span", null, _toDisplayString(_ctx.column.content1(_ctx.record)), 1)
  ], 64))
}