<template>
  <a-form
    ref="form"
    autocomplete="off"
    :model="model"
    :rules="form.rules"
    :layout="form.settings.layout"
    :label-col="
      form.settings.layout == 'vertical'
        ? undefined
        : form.settings.labelCol
        ? form.settings.labelCol
        : form.settings.col && form.settings.col.length > 0
        ? { span: 6 }
        : { span: 4 }
    "
    :wrapper-col="
      form.settings.layout == 'vertical'
        ? undefined
        : form.settings.wrapperCol
        ? form.settings.wrapperCol
        : form.settings.col && form.settings.col.length > 0
        ? { span: 18 }
        : { span: 20 }
    "
    :labelAlign="form.settings.labelAlign"
    scrollToFirstError
  >
    <a-row :gutter="20">
      <template v-if="form.settings.col && form.settings.col.length > 0">
        <a-col
          v-for="col in fieldList"
          :xl="24 / fieldList.length"
          :lg="24"
          :md="24"
          :sm="24"
          :xs="24"
        >
          <field-render
            v-for="field in form.fields.slice(col[0], col[1])"
            v-model:value="model[field.name]"
            :field="field"
            :formModel="model"
            :key="field.name"
            :validateInfos="validateInfos"
            :options="options[field.datasource ? field.datasource : field.name]"
          />
        </a-col>
      </template>
      <a-col
        v-if="!form.settings.col || form.settings.col.length === 0"
        :xl="24"
        :lg="24"
        :md="24"
        :sm="24"
        :xs="24"
      >
        <field-render
          v-model:value="model[field.name]"
          v-for="field in form.fields"
          :field="field"
          :formModel="model"
          :key="field.name"
          :validateInfos="validateInfos"
          :options="options[field.datasource ? field.datasource : field.name]"
        />
      </a-col>
    </a-row>
  </a-form>
</template>
<script language="ts">
import { defineComponent, watch } from 'vue';

export default defineComponent({
  name: 'FromRender',
  components: {},
  props: {
    model: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
    validateInfos: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    if (props.form.watch) {
      for (let k in props.form.watch) {
        watch(
          () => props.model[k],
          () => {
            props.form.watch[k](props.model, props.form);
          },
        );
      }
    }
  },

  data() {
    return {
      fieldList: [],
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.resetFields();
    },
    disable(name) {
      this.form.fields[name].disabled = true;
    },
    disableAll() {
      this.form.fields.forEach(item => {
        item.disabled = true;
      });
    },
  },
  created() {
    let a = 0;
    if (this.form.settings.col && this.form.settings.col.length > 0) {
      this.form.settings.col.forEach((item, i) => {
        a = a + item;
        if (i === 0) {
          this.fieldList.push([0, a]);
        } else {
          this.fieldList.push([a - item, a]);
        }
      });
    }
  },
});
</script>
