
import { defineComponent, ref, computed, VNodeChild, h, withCtx, createTextVNode } from 'vue';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons-vue';
import { injectMenuState } from '@/layouts/use-menu-state';
import { RouterLink } from 'vue-router';

export interface Tab {
  key: string;
  tab: string | VNodeChild;
}

export interface Route {
  path: string;
  breadcrumbName: string;
  children: Array<{
    path: string;
    breadcrumbName: string;
  }>;
}

export interface BreadcrumbItemRender {
  route: Route;
  params: Record<any, any>;
  routes: Route[];
  paths: string[];
}

export default defineComponent({
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro',
    },
    layout: {
      type: String,
      default: 'side',
    },
    theme: {
      type: String,
      default: 'dark',
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    collapsed: {
      type: Boolean,
      default: false,
    },
    collapsedButton: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['collapse'],
  setup(props, { emit }) {
    const baseClassName = `${props.prefixCls}-global-header`;
    const classNames = ref({
      [baseClassName]: true,
      [`${baseClassName}-layout-${props.layout}`]: props.layout,
    });
    const isSide = computed(() => props.layout === 'side');
    const isLeft = computed(() => props.layout === 'left');
    const handleClick = (): void => {
      emit('collapse', !props.collapsed);
    };

    const { breadcrumb } = injectMenuState();
    // 自定义面包屑渲染逻辑
    const customBreadcrumbRender = ({ route, routes }: BreadcrumbItemRender) => {
      const breadcrumbName = route.breadcrumbName;
      return (
        (routes.indexOf(route) === routes.length - 1 &&
          h('span', null, { default: withCtx(() => [createTextVNode(breadcrumbName)]) })) ||
        h('span', null, { default: withCtx(() => [createTextVNode(breadcrumbName)]) })
      );
    };

    return {
      baseClassName,
      classNames,
      isSide,
      isLeft,

      handleClick,
      breadcrumb,
      customBreadcrumbRender,
    };
  },
  components: {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
  },
});
