
import { computed, defineComponent, PropType } from 'vue';
import { useProProvider } from '../base-layouts/pro-provider';

export type LayoutBlockTheme = 'light' | 'dark' | 'realDark' | 'side' | 'top' | 'mix' | 'left';

export default defineComponent({
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String as PropType<LayoutBlockTheme>,
      default: 'light',
    },
    title: {
      type: [Boolean, String, Function],
      default: (key: string) => key,
    },
  },
  inheritAttrs: false,
  setup(props) {
    const { i18n: locale, getPrefixCls } = useProProvider();
    const baseClassName = getPrefixCls('checkbox-item');

    const disableStyle = {
      cursor: 'not-allowed',
    };

    const className = computed(() => {
      return {
        [baseClassName]: true,
        [`${baseClassName}-${props.theme}`]: props.theme,
        [`${baseClassName}-disabled`]: props.disabled,
      };
    });
    return {
      baseClassName,
      className,
      disableStyle,
      locale,
    };
  },
});
